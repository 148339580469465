.course-badge {
	//background: #134673;
	//color: white;
	//font-size: rem(11px);
	//text-transform: uppercase;
	//padding: rem(5px) rem(10px);
	//position: absolute;
	//top: 0;
	//right: 0;
	display: none;

	//@media (min-width: $break-grid-md) {
	//	display: block;
	//}
}

//.course-badge--hot {
//	background-color: #ffc20e;
//}