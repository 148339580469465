.page-course-detail {
	.hero {
		display: none;
	}

	.course-title {
		margin-bottom: rem(10px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(40px);
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(45px);
		}
	}

	.course-tagline {
		display: none !important;
	}

	.course-description {
		position: relative;
		margin-bottom: rem(20px);
	}

	.course-classes {
		margin: 0;
	}

	.course-features {
		display: none;
	}
}