// https://a11yproject.com/posts/how-to-hide-content/
@mixin visually-hidden {
	position: absolute !important;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	white-space: nowrap;
}

@mixin ie-only {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

@mixin link-underline($color: currentColor) {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		width: 100%;
		height: 2px;
		transform: scaleX(0);
		transform-origin: left center;
		transition: transform $transition-default, background-color $transition-default;
		background-color: $color;
	}
}

@mixin link-underline-hover($color: transparent) {
	&::after {
		transform: scaleX(1);
		background-color: $color;
	}
}
