.section-scroll {
    padding-top: rem(24);

    @media (min-width: $break-grid-md) {
        padding-top: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}