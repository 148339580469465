@use "sass:math";

.course-area-card {
  position: relative;
  margin-bottom: rem(30px);
  padding: rem(38px) 0;

  @media (min-width: $break-grid-sm) {
	&:nth-last-child(-n+2) {
	  margin-bottom: 0;
	}
  }

  @media (min-width: $break-grid-md) {
	margin-bottom: rem(50px);
  }

  @media (min-width: $break-grid-lg) {
	margin-bottom: rem(60px);

	&:nth-last-child(-n+3) {
	  margin-bottom: 0;
	}
  }

  @media (min-width: $break-grid-xl) {
	margin-bottom: rem(90px);
  }

  h3 {
	transition: color $transition-default;
  }

  &:hover,
  &:focus {
	h3 {
	  color: $shade-01;
	  transition: color $transition-default;
	}

	.course-area-card__tag {
	  background-color: $color-brand-01;
	}

	.course-area-card__arrow {
	  transform: translateX(rem(4px));
	}
  }

  &::before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 100%;
	height: rem(3px);
	margin: 0 auto;
	padding: 0 ;
	transform: translateX(-50%);
	transition: background-color $transition-default;
	background-color: black;
  }
}

.course-area-card__image-wrap {
  margin-bottom: rem(20px);

  @media (min-width: $break-grid-md) {
	margin-bottom: 0;
  }

  img {
	margin: auto;

	@media (min-width: $break-grid-md) {
	  margin: 0;
	}
  }
}

.course-area-card__copy {
  text-align: center;

  @media (min-width: $break-grid-md) {
	text-align: left;
  }
}

.course-area-card__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(12px);
  gap: rem(8px);

  @media (min-width: $break-grid-md) {
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;
	gap: rem(4px) rem(10px);
  }

  h3 {
	@include type-style-19;
	display: inline-flex;
	color: $color-brand-01;
	text-align: center;

	@media (min-width: $break-grid-md) {
	  position: relative;
	  top: rem(-4px);
	  text-align: left;
	}
  }
}

.course-area-card__tag {
  @include type-style-20;
  display: inline-flex;
  align-items: center;
  padding: rem(8px) rem(12px);
  transition: background-color $transition-default;
  border-radius: 9999px;
  background: $shade-01;
  color: #fff;
  order: 2;
}

.course-area-card__tag-icon {
  width: rem(16px);
  height: rem(16px);
  margin-right: rem(8px);
  fill: none;
}

.course-area-card__arrow {
  display: block;
  position: relative;
  top: rem(2px);
  margin: rem(10px) auto 0;
  transition: transform $transition-default;
  color: $color-brand-01;

  @media (min-width: $break-grid-md) {
	display: inline;
	margin: 0 0 0 rem(4px);
  }
}

.corporate-course-areas__footer {
  border-top: 1px solid $shade-05;
  padding-top: rem(40px);
}
