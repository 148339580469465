.secondary-menu__list {
    display: flex;
}

.secondary-menu__item {
    margin-right: rem(25px);

    @media (min-width: $break-grid-xl) {
        margin-right: rem(35px);
    }

    &:last-child {
        margin-right: 0;
    }
}

.secondary-menu__link {
    @include type-style-10-alt;
    display: flex;
    align-items: center;
    padding: rem(12px) 0;
    transition: color $transition-default;
}

.secondary-menu__label {
    position: relative;
	border-bottom: rem(1px) solid transparent;
	transition: $transition-default;

	.secondary-menu__link:hover & {
		border-color: $shade-01;
	}
}
