.cce-icon-block {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: rem(45px) 0 rem(9px);

	.copy &,
	.internal-page .page-content & {
		margin: rem(45px) 0 0;
	}

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(29px);
		margin-top: rem(50px);

		//.copy &,
		//.internal-page .page-content & {
		//	margin-bottom: rem(29px);
		//}
	}

	@media (min-width: em(1200px)) {
		margin-bottom: rem(50px);
		margin-top: rem(60px);
	}
}

.cce-icon-block__item {
	margin-bottom: rem(36px);
	padding: 0 rem(10px);
	text-align: center;

	.copy &,
	.internal-page .page-content & {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;
	}

	@media (min-width: em(600px)) and (max-width: em(899px)), (min-width: $break-grid-lg) {
		padding: 0 rem(20px);
	}

	@media (min-width: em(600px)) {
		width: 50%;

		&:nth-child(n+3) {
			margin-top: rem(20px);
		}
	}

	@media (min-width: $break-grid-md) {
		.copy &,
		.internal-page .page-content & {
			width: 33.3%;

			&:nth-child(n+3) {
				margin-top: 0;
			}
		}
	}

	@media (min-width: em(900px)) {
		width: 33.3%;

		&:nth-child(n+3) {
			margin-top: 0;
		}

		&:nth-child(n+4) {
			margin-top: rem(20px);
		}

	}

	@media (min-width: em(1200px)) {
		width: 25%;

		&:nth-child(n+4) {
			margin-top: 0;
		}

		&:nth-child(n+5) {
			margin-top: rem(20px);
		}

		.copy &,
		.internal-page .page-content & {
			width: 33.3%;
		}
	}
}

.cce-icon-block__img {
	display: inline-block;
	margin-bottom: rem(32px);
}

.cce-icon-block__title {
	font-family: $font-family-secondary;
	font-size: rem(24px) !important;
	font-weight: 100 !important;
	line-height: (36.5 / 28)  !important;
	margin-bottom: rem(16px)  !important;

	.copy &,
	.internal-page .page-content & {
		margin-top: 0 !important;
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(28px) !important;
	}
}

.cce-icon-block__desc {
	font-size: rem(16px);
	padding: 0 rem(20px);
}
