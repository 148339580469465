.why-us {
	background: #fff;
	margin-top: rem(-540px);

	@media (min-width: $break-grid-md) {
		margin-top: rem(-120px);
	}

	@media (min-width: $break-grid-lg) {
		margin-top: rem(-120px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(-240px);
	}
}

.why-us__first-cell {
	margin-bottom: rem(50px);

	@media (min-width: $break-grid-lg) and (max-width: $break-grid-lg-max) {
		padding-right: rem(30px);
	}
}

.why-us__middle-cell {
	margin-bottom: rem(50px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-lg) {
		margin-top: rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(120px);
	}
}

.why-us__text-image-wrap {
	position: relative;

	@media (min-width: $break-grid-xl) and (max-width: 1499px) {
		padding-top: 30%;
	}
}

.why-us__image {
	position: relative;
	display: block;
	height: 0;
	width: 100%;
	padding-bottom: percentage(160 / 280);
	background: no-repeat center bottom;
	background-size: cover;
	background-image: url('/s/images/custom-new/home-why-us-woman.jpg');

	@media (min-width: $break-grid-md) {
		padding-bottom: percentage(100 / 280);
		background-position: center 75%;
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: 100%;
		background-position: center bottom;
	}
}

.why-us__text-colour-block {
	@media (min-width: $break-grid-lg) {
		position: absolute;
		top: rem(-50px);
		left: 0;
		z-index: 1;
	}
}

.why-us__image {
	@media (min-width: $break-grid-lg) {
		padding-top: rem(50px);
	}
}
