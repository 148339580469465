.icon-text {
	text-align: center;
	margin-bottom: rem(40px);

	&:not(.icon-text--center) {
		@media (min-width: $break-grid-md) {
			text-align: left;
		}
	}
}

.icon-text--center {
	text-align: center;
}

.icon-text__media {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		justify-content: flex-start;
	}

	.icon-text--center & {
		@media (min-width: $break-grid-md) {
			justify-content: center;
		}
	}

}

.icon-text__title {
	@include type-style-39;
	color: $color-brand-01;
}

.icon-text__description {
	@include type-style-40;
	margin-top: rem(6px);
}



