.overview-section {
	padding-bottom: rem(35px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(45px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(50px);
	}

	.has-no-classes & {
		padding: 0;
	}
}

.overview {
	border: rem(1px) solid $shade-05;
	padding: rem(20px);

	@media (min-width: $break-grid-sm) {
		padding: rem(30px);
	}

	@media (min-width: $break-grid-xxl) {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	@include ie-only {
		align-items: flex-start;
	}
}

.overview__outer-cell--left {
	@media (min-width: $break-grid-xxl) {
		padding-right: rem(30px);
		width: 13%;
	}
}

.overview__outer-cell--right {
	@media (min-width: $break-grid-xxl) {
		width: 87%;
	}
}

.overview__inner-wrap {
	@media (min-width: $break-grid-md) {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	@include ie-only {
		align-items: flex-start;
	}
}

.overview__inner-cell {
	@media (min-width: $break-grid-xxl) {
		width: 50%;
	}
}

.overview__inner-cell--left {
	@media (min-width: $break-grid-sm) {
		display: flex;
		align-items: baseline;
	}

	@media (min-width: $break-grid-md) {
		padding-right: rem(30px);
	}
}

.overview__inner-cell--right {
	flex-shrink: 0;

	@media (min-width: $break-grid-sm) {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	@media (min-width: $break-grid-md) {
		display: block;
	}

	@media (min-width: $break-grid-xxl) {
		display: flex;
	}
}

.overview__cell {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-xxl) {
		margin-bottom: 0;
	}
}

.overview__cell--price {
	@media (min-width: $break-grid-xxl) {
		padding-right: rem(30px);
	}

	ul {
		margin: 0 !important;

		li {
			display: none;
			font-size: rem(16px);
			font-weight: bold;
			color: $color-brand-01;
			margin: 0 !important;
			padding: 0 !important;

			&:before {
				content: none !important;
			}

			&:first-child {
				display: inline-block;
			}

			&.gst {
				display: inline-block;
				color: $shade-01;
				margin-left: rem(5px) !important;
			}
		}
	}
}

.overview__cell--button {
	margin-bottom: 0;
	flex-shrink: 0;
}

.overview__cell--icon {
	display: flex;

	@media (min-width: $break-grid-sm) {
		flex-grow: 1;
		width: 50%;
		padding-right: rem(20px);
		padding-bottom: 0;

		&:last-child {
			padding-right: 0;
		}
	}

	@media (min-width: $break-grid-xxl) {
		width: auto;
		min-width: 40%;
		padding-right: rem(30px);
		padding-left: rem(30px);
		position: relative;
	}
}

.overview__heading {
	font-size: rem(16px);
	color: $color-brand-01;

	@media (min-width: $break-grid-xxl) {
		font-size: rem(19px);
	}
}

.overview__icon {
	color: $color-brand-01;
	margin-right: rem(10px);
	flex-shrink: 0;

	@media (min-width: $break-grid-xxl) {
		position: absolute;
		left: 0;
	}
}

.overview__icon--location {
	@media (min-width: $break-grid-xxl) {
		left: rem(3px);
	}
}

.overview__text {
	font-size: rem(16px);
	line-height: 1.2;
	margin-bottom: rem(5px);

	a {
		text-decoration: none;
		border-bottom: rem(1px) solid $color-brand-01-alt;
		transition: $transition-default;

		&:hover {
			border-color: transparent;
		}
	}
}

.overview__price {
	font-size: rem(16px);
	font-weight: bold;
	display: flex;
}

.overview__price__numbers {
	color: $color-brand-01;
}

.class-summary-block__separator {
	display: block;
	font-weight: bold;
}

.class-summary-note {
	display: none;

	font-size: rem(14px);
	text-align: center;
	margin-top: rem(8px);

	strong {
		color: $color-brand-01;
	}
 }
