.page-course-list:not(.page-courses-filter) {
	.course-description {
		margin-bottom: rem(50px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(70px);
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(80px);
		}
	}

	.filter__applied {
		display: none;
	}

	.courses-list-below {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		flex-direction: column-reverse;

		@media (min-width: $break-grid-md) {
			align-items: center;
			flex-direction: row;
		}
	}

	.courses-list-wrap {
		.courses-list-wrap {
			max-height: 0;
			overflow: hidden;

			.courses-list-more {
				display: block;
			}
		}

		.courses-list-more {
			display: none;
		}
	}

	.courses-list-wrap--visible {
		.courses-list-wrap {
			max-height: 99999px;
			overflow: visible;
		}
	}
}