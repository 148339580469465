.fact-list {
	&-box {
		margin-bottom: rem(40);
		padding: rem(25);
		&--red {
			background-color: #e64626;
			color: #fff;
		}
		&--grey {
			background-color: #424242;
			color: #fff;
		}
	}
	&-box--red .fact-list-content-type {
		color: #000;
	}
	&-content-type {
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(14);
		margin-bottom: rem(20);
	}
	&-title {
		line-height: rem(35);
		font-size: rem(24);
		font-weight: 700;
	    font-family: Apercu,Helvetica,sans-serif;
		margin-bottom: rem(20);
	}
	&__lure {
		display: block;
		line-height: 1.2;
		font-size: rem(30);
	}
	&-box--red .fact-list__detail {
		color: #000;
	}
	&__detail {
		font-size: rem(14);
	}
	&-group {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	&-rule--light-top {
		border-top: rem(1) solid #000;
	}
	&-group__item {
		margin: 0;
		padding: 0;
		display: block;
		position: relative;
		padding: rem(20) 0;
	}
	&-group__item + &-group__item {
		border-top: rem(1) solid #000;
	}
}
