.carousel-nav {
	display: flex;
}

.carousel-nav__button {
	@include type-style-10-alt;
	background: transparent;
	border: none;
	color: $color-brand-01;
	position: relative;
	display: block;
	transition: color $transition-default;

	&:hover {
		color: $color-brand-01-alt;
	}

	&:first-child {
		margin-right: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-right: rem(15px);
		}

		&:after {
			content: '';
			display: block;
			width: rem(1px);
			height: rem(14px);
			background: #b5b5b5;
			position: absolute;
			right: rem(-15px);
			top: 0;
			bottom: 0;

			@media (min-width: $break-grid-lg) {
				right: rem(-22px);
			}
		}
	}

	&:last-child {
		margin-left: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-left: rem(25px);
		}
	}
}

.carousel-nav__prev,
.carousel-nav__next {
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(56);
	width: rem(56);
	cursor: pointer;
	background-color: transparent;
	border: rem(1) solid $color-brand-01;
	border-radius: 50%;
	transition: all $transition-default;

	&:hover {
		background-color: transparent;
		border-color: white;
	}

	.image-card-carousel & {
		@media (max-width: $break-grid-sm) {
			width: rem(42);
			height: rem(42);
		}
	}
}

.carousel-nav__prev {
	transform: scaleX(-1);
}

.carousel-nav__icon {
	color: $color-brand-01;

	.carousel-nav__prev:hover &,
	.carousel-nav__next:hover & {
		color: white;
	}
}

.carousel-nav__dots {
	.slick-dots {
		display: flex !important;
		align-items: center;
		gap: rem(12);
	}

	li {
		display: flex;
	}

	button {
		font-size: 0 !important;
		padding: 0 !important;
		width: rem(10) !important;
		height: rem(10) !important;
		border-radius: 50% !important;
		background-color: white !important;

		&:hover {
			background-color: shade( white, 20 ) !important;
		}
	}

	.slick-active button {
		background-color: $color-brand-01 !important;
	}
}
