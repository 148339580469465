.copy {
	h1,
	h2,
	h3:not(.media-text-bar__title),
	h4,
	h5,
	h6 {
		margin-bottom: 1em;

		&.half-margin {
			margin-bottom: 0.5em;
		}

		&:not(.retain-margin):last-child {
			margin-bottom: 0;
		}
	}

	p:not(.media-text-bar__subheading) {
		margin-bottom: 1.5em;

		&.snippet {
			margin-bottom: 0.7em;
		}

		&.lead {
			margin-bottom: 0.9em;
		}

		&:not(.retain-margin):last-child {
			margin-bottom: 0;
		}
	}
}
