.site-header {
	background: white;
}

.site-header__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.site-header__cell {
    display: flex;
    align-items: center;
}

.site-header__unit {
    margin-left: rem(25px);

    @media (min-width: $break-grid-lg) {
        margin-left: rem(50px);
    }

    &:first-child,
    &--text-bottom {
        margin-left: 0;
    }
}

.site-header__unit--text-top,
.site-header__unit--secondary-menu,
.site-header__unit--primary-menu {
    display: none;

    @media (min-width: $break-grid-lg) {
        display: block;
    }
}

.site-header__unit--text-bottom,
.site-header__unit--offcanvas-trigger {
    @media (min-width: $break-grid-lg) {
        display: none;
    }
}

.site-header__unit--phone {
    display: flex;
    align-items: center;
	flex-wrap: wrap;
	justify-content: flex-end;
}

.site-header__unit--logo {
	position: relative;

    @media (min-width: $break-grid-lg) {
        padding-right: rem(30px);
    }

    @media (min-width: $break-grid-xl) {
        padding-right: rem(40px);
    }

	&:after {
		@media (min-width: $break-grid-lg) {
			content: '';
			display: block;
			width: rem(1px);
			height: rem(50px);
			background: #dcdcdc;
			position: absolute;
			right: -1px;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
}

.site-header__unit--text-top {
    margin-left: rem(30px);

    @media (min-width: $break-grid-xl) {
        margin-left: rem(40px);
    }
}



.site-header__top-section {
    padding: rem(20px) 0;

    @media (min-width: $break-grid-lg) {
        padding: rem(25px) 0;
    }
}

.site-header__top-section__logo {
    width: rem(100px);
    height: rem(35px);
    display: block;
    background: no-repeat center center;
    background-size: cover;
    background-image: url('/s/images/custom-new/logo-uni-syd.svg');

    @media (min-width: $break-grid-lg) {
        width: rem(130px);
        height: rem(45px);
    }

    @media (min-width: $break-grid-xl) {
        width: rem(180px);
        height: rem(62px);
    }
}

.site-header__top-section__text {
    color: $color-brand-01;
    font-size: rem(16px);
    font-weight: bold;

    &:hover {
        color: $color-brand-01;
    }

    @media (min-width: $break-grid-xl) {
        font-size: rem(19px);
    }
}



.site-header__bottom-section {
    padding: rem(15px) 0;
    color: black;

    @media (min-width: $break-grid-lg) {
		border-top: 1px solid $shade-06;
        padding: 0;
    }
}

.site-header__bottom-section__text {
    font-size: rem(14px);
    line-height: 1.28;
    font-weight: bold;

    br {
        @media (min-width: 380px) {
            display: none;
        }
    }
}

.site-header__bottom-section__phone-number {
    @include type-style-45;
	color: $color-brand-01;

    @media (min-width: $break-grid-lg) {
        margin-left: 10px;
    }
}

.site-header__bottom-section__phone-icon {
	color: $color-brand-01;
    display: none;

    @media (min-width: $break-grid-lg) {
        display: block;
    }
}

.site-header__bottom-section__phone-hours {
	font-size: rem(12px);
	opacity: 0.5;
	margin-top: rem(5px);
	text-align: right;
	width: 100%;
}
