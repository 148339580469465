.back-to-top {
	width: rem(36px);
	height: rem(36px);
	background: $color-brand-01;
	position: fixed;
	bottom: rem(13px);
	left: rem(13px);
	transition: $transition-default;
	z-index: 999;
	opacity: 0;
	pointer-events: none;

	@media (min-width: $break-grid-md) {
		width: rem(44px);
		height: rem(44px);
	}

	&:hover {
		background: $color-brand-01-alt;
	}
}

.back-to-top--active {
	opacity: 1;
	pointer-events: all;
}

.back-to-top__icon {
	width: rem(10px);
	height: rem(10px);
	border-left: rem(1px) solid white;
	border-top: rem(1px) solid white;
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	margin: auto;
	transform: rotate(45deg);

	@media (min-width: $break-grid-md) {
		width: rem(12px);
		height: rem(12px);
	}
}