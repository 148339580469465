.color-primary {
    color: $color-brand-01;
}

.font-secondary {
    font-family: $font-family-secondary;
}

.hide\@md {
    @media (min-width: $break-grid-md) {
        display: none !important;
    }
}

.show\@md {
    @media (max-width: max-width($break-grid-md)) {
        display: none !important;
    }
}