.card-carousel__header {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(40px);
	}
}

.card-carousel__carousel {
	.slick-list {
		margin-left: rem(-10px);
		margin-right: rem(-10px);

		@media (min-width: $break-grid-xl) {
			margin-left: rem(-20px);
			margin-right: rem(-20px);
		}
	}

	.slick-track {
		display: flex;
	}

	.slick-slide {
		height: inherit;
		margin-left: rem(10px);
		margin-right: rem(10px);

		@media (min-width: $break-grid-xl) {
			margin-left: rem(20px);
			margin-right: rem(20px);
		}

		> div {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}

	.card {
		display: flex !important;
		flex-direction: column;
		height: 100%;
	}

	.card__content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.card__link {
		margin-top: auto;
	}
}
