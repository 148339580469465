.image-content-carousel {}

.image-content-carousel__container {
    position: relative;
}

.image-content-carousel__copy {
    margin-bottom: rem(48);
}

.image-content-carousel__title {
    @include type-style-26;
    color: $color-brand-01;
    margin-bottom: rem(16) !important;
}

.image-content-carousel__text {
    @include type-style-25;
}

.image-content-carousel-item {
    display: flex !important;
}

.image-content-carousel__cell--content {
    @media (min-width: $break-grid-md) {
        width: 60% !important;
    }

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(50px);
	}
}

.image-content-carousel__cell--media {
    display: none;

    @media (min-width: $break-grid-md) {
        display: block;
        width: 40% !important;
    }
}

.image-content-carousel-item__copy {
    max-width: rem(600);
}

.image-content-carousel-item__title {
    display: flex;
    align-items: center;
    gap: rem(8);
    margin-bottom: rem(24);
}

.image-content-carousel-item__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
    color: $color-brand-01;
}

.image-content-carousel-item__title-text {
    @include type-style-31;
    color: $color-brand-01;
}

.image-content-carousel-item__text {
    @include type-style-32;
}

.image-content-carousel-item__image {
    aspect-ratio: 5 / 4;
    object-fit: cover;

	@media (min-width: $break-grid-xl) {
		margin-top: -25%;
	}
}

.image-content-carousel__nav {
    align-items: center;
    gap: rem(32);
    margin-top: rem(48);

    @media (min-width: $break-grid-md) {
        width: 60%;
    }

	@media (min-width: $break-grid-lg) {
		margin-top: 0;
	}

    @media (min-width: $break-grid-lg) {
        justify-content: center;
        position: absolute;
        bottom: 2vw;
        max-width: rem(600);
    }

    @media (min-width: $break-grid-xl) {
        bottom: 0;
    }
}

.image-content-carousel .slick-list {
    cursor: grab;

    &:active {
        cursor: grabbing;
    }
}
