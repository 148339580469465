@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-Medium.eot');
    src: url('../fonts/Antwerp-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-Medium.woff2') format('woff2'),
    url('../fonts/Antwerp-Medium.woff') format('woff'),
    url('../fonts/Antwerp-Medium.ttf') format('truetype'),
    url('../fonts/Antwerp-Medium.svg#Antwerp-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-Regular.eot');
    src: url('../fonts/Antwerp-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-Regular.woff2') format('woff2'),
    url('../fonts/Antwerp-Regular.woff') format('woff'),
    url('../fonts/Antwerp-Regular.ttf') format('truetype'),
    url('../fonts/Antwerp-Regular.svg#Antwerp-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-LightItalic.eot');
    src: url('../fonts/Antwerp-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-LightItalic.woff2') format('woff2'),
    url('../fonts/Antwerp-LightItalic.woff') format('woff'),
    url('../fonts/Antwerp-LightItalic.ttf') format('truetype'),
    url('../fonts/Antwerp-LightItalic.svg#Antwerp-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-Light.eot');
    src: url('../fonts/Antwerp-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-Light.woff2') format('woff2'),
    url('../fonts/Antwerp-Light.woff') format('woff'),
    url('../fonts/Antwerp-Light.ttf') format('truetype'),
    url('../fonts/Antwerp-Light.svg#Antwerp-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-BoldItalic.eot');
    src: url('../fonts/Antwerp-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-BoldItalic.woff2') format('woff2'),
    url('../fonts/Antwerp-BoldItalic.woff') format('woff'),
    url('../fonts/Antwerp-BoldItalic.ttf') format('truetype'),
    url('../fonts/Antwerp-BoldItalic.svg#Antwerp-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-Bold.eot');
    src: url('../fonts/Antwerp-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-Bold.woff2') format('woff2'),
    url('../fonts/Antwerp-Bold.woff') format('woff'),
    url('../fonts/Antwerp-Bold.ttf') format('truetype'),
    url('../fonts/Antwerp-Bold.svg#Antwerp-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-MediumItalic.eot');
    src: url('../fonts/Antwerp-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-MediumItalic.woff2') format('woff2'),
    url('../fonts/Antwerp-MediumItalic.woff') format('woff'),
    url('../fonts/Antwerp-MediumItalic.ttf') format('truetype'),
    url('../fonts/Antwerp-MediumItalic.svg#Antwerp-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-SemiBold.eot');
    src: url('../fonts/Antwerp-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-SemiBold.woff2') format('woff2'),
    url('../fonts/Antwerp-SemiBold.woff') format('woff'),
    url('../fonts/Antwerp-SemiBold.ttf') format('truetype'),
    url('../fonts/Antwerp-SemiBold.svg#Antwerp-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-Italic.eot');
    src: url('../fonts/Antwerp-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-Italic.woff2') format('woff2'),
    url('../fonts/Antwerp-Italic.woff') format('woff'),
    url('../fonts/Antwerp-Italic.ttf') format('truetype'),
    url('../fonts/Antwerp-Italic.svg#Antwerp-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Antwerp';
    src: url('../fonts/Antwerp-SemiBoldItalic.eot');
    src: url('../fonts/Antwerp-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Antwerp-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Antwerp-SemiBoldItalic.woff') format('woff'),
    url('../fonts/Antwerp-SemiBoldItalic.ttf') format('truetype'),
    url('../fonts/Antwerp-SemiBoldItalic.svg#Antwerp-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-Regular.eot');
    src: url('../fonts/Apercu-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Regular.woff2') format('woff2'),
    url('../fonts/Apercu-Regular.woff') format('woff'),
    url('../fonts/Apercu-Regular.ttf') format('truetype'),
    url('../fonts/Apercu-Regular.svg#Apercu-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-MediumItalic.eot');
    src: url('../fonts/Apercu-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-MediumItalic.woff2') format('woff2'),
    url('../fonts/Apercu-MediumItalic.woff') format('woff'),
    url('../fonts/Apercu-MediumItalic.ttf') format('truetype'),
    url('../fonts/Apercu-MediumItalic.svg#Apercu-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-Light.eot');
    src: url('../fonts/Apercu-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Light.woff2') format('woff2'),
    url('../fonts/Apercu-Light.woff') format('woff'),
    url('../fonts/Apercu-Light.ttf') format('truetype'),
    url('../fonts/Apercu-Light.svg#Apercu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-Medium.eot');
    src: url('../fonts/Apercu-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Medium.woff2') format('woff2'),
    url('../fonts/Apercu-Medium.woff') format('woff'),
    url('../fonts/Apercu-Medium.ttf') format('truetype'),
    url('../fonts/Apercu-Medium.svg#Apercu-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Mono';
    src: url('../fonts/Apercu-Mono.eot');
    src: url('../fonts/Apercu-Mono.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Mono.woff2') format('woff2'),
    url('../fonts/Apercu-Mono.woff') format('woff'),
    url('../fonts/Apercu-Mono.ttf') format('truetype'),
    url('../fonts/Apercu-Mono.svg#Apercu-Mono') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-BoldItalic.eot');
    src: url('../fonts/Apercu-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-BoldItalic.woff2') format('woff2'),
    url('../fonts/Apercu-BoldItalic.woff') format('woff'),
    url('../fonts/Apercu-BoldItalic.ttf') format('truetype'),
    url('../fonts/Apercu-BoldItalic.svg#Apercu-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-Italic.eot');
    src: url('../fonts/Apercu-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Italic.woff2') format('woff2'),
    url('../fonts/Apercu-Italic.woff') format('woff'),
    url('../fonts/Apercu-Italic.ttf') format('truetype'),
    url('../fonts/Apercu-Italic.svg#Apercu-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-LightItalic.eot');
    src: url('../fonts/Apercu-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-LightItalic.woff2') format('woff2'),
    url('../fonts/Apercu-LightItalic.woff') format('woff'),
    url('../fonts/Apercu-LightItalic.ttf') format('truetype'),
    url('../fonts/Apercu-LightItalic.svg#Apercu-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu';
    src: url('../fonts/Apercu-Bold.eot');
    src: url('../fonts/Apercu-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Apercu-Bold.woff2') format('woff2'),
    url('../fonts/Apercu-Bold.woff') format('woff'),
    url('../fonts/Apercu-Bold.ttf') format('truetype'),
    url('../fonts/Apercu-Bold.svg#Apercu-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
	font-family: icon-font;
	src: url('../fonts/icon-font-BDB237BF8.ttf') format("truetype");
}

@font-face {
	font-family: "Glyphicons Halflings";
	src: url('../fonts/glyphicons-halflings-regular.ttf') format("truetype");
}

@font-face {
    font-family: 'Lyon Light';
    src: url('../fonts/LyonDisplay-Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lyon Display';
    src: url('../fonts/LyonDisplay-Regular.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lyon Display';
    src: url('../fonts/LyonDisplay-Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lyon Display';
    src: url('../fonts/LyonDisplay-Bold');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lyon Display';
    src: url('../fonts/LyonDisplay-Black');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}