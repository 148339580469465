.sidebar-section {
	@media (min-width: $break-grid-xl) {
		padding: rem(80px) 0 rem(130px);
	}
}

.sidebar-section--no-padding {
	padding: 0;
}

.sidebar-section__sidebar {
	position: relative;
}

.sidebar-section__sidebar--left {
	padding-bottom: rem(50px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: 0;
	}
}

.sidebar-section__sidebar--related-articles {
	order: 1;
	padding-top: rem(50px);
	padding-bottom: 0;

	@media (min-width: $break-grid-lg) {
		order: -1;
		padding-top: 0;
	}
}

.sidebar-section__sidebar--share {
	order: -2;
	padding-bottom: rem(20px);

	@media (min-width: $break-grid-lg) {
		order: 2;
		padding-bottom: 0;
		padding-left: 0;
	}
}

.sidebar-section__sidebar--content-menu {
	@media (min-width: $break-grid-lg) and (max-width: 1150px) {
		padding-right: 0;
	}

	@media (min-width: $break-grid-xl) and (max-width: 1560px) {
		padding-right: 0;
	}
}

.sidebar-section__sidebar--filter {
	@media (min-width: $break-grid-xl) {
		padding-right: rem(40px);
	}

	.page-products-list &,
	.page-course-list:not(.page-courses-filter) & {
		order: 1;
		padding-top: rem(50px);
		padding-bottom: 0;

		@media (min-width: $break-grid-lg) {
			order: -1;
			padding-top: 0;
		}
	}

	.page-courses-filter & {
		@media (max-width: $break-grid-md-max) {
			padding-bottom: rem(20px);
		}
	}
}

.sidebar-section__sidebar--checkout {
	padding-top: rem(50px);
	padding-bottom: rem(50px);

	@media (min-width: $break-grid-lg) {
		padding-top: rem(60px);
		padding-bottom: rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(100px);
		padding-bottom: rem(100px);
	}

	&:before {
		position: absolute;
		content: '';
		right: -50vw;
		top:0;
		height: 100%;
		width: 150vw;
		background: $shade-02;
		z-index:-1;

		@media (min-width: $break-grid-lg) {
			left: 0;
		}
	}
}

.sidebar-section__sidebar--facts {
	display: none;

	@media (min-width: $break-grid-lg) {
		display: block;
	}
}

.sidebar-section__main-content__section {
	padding-bottom: rem(50px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(70px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(100px);
	}

	&:last-child {
		padding-bottom: 0;
	}
}

.sidebar-section__main-content__section--padding-sm {
	padding-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(75px);
	}
}

.sidebar-section__main-content__section--padding-xs {
	padding-bottom: rem(19px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(26px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(51px);
	}
}

.sidebar-section__main-content__section--border-top {
	border-top: rem(3px) solid black;
	padding-top: rem(20px);

	@media (min-width: $break-grid-lg) {
		padding-top: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(70px);
	}
}

.sidebar-section__main-content--checkout {
	padding-top: rem(50px);
	padding-bottom: rem(50px);

	@media (min-width: $break-grid-md) {
		padding-right: rem(52px);
	}

	@media (min-width: $break-grid-lg) {
		padding-top: rem(60px);
		padding-bottom: rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(100px);
		padding-bottom: rem(100px);
		padding-right: rem(132px);
	}
}

.sidebar-section__main-content__section--no-padding {
	padding: 0;
}

.sidebar-section__main-content__section__heading {
	margin-bottom: 1.2em !important;

	.relatedCourses > & {
		display: none;
	}
}

.sidebar-section__main-content__section__heading--has-border {
	border-bottom: rem(3px) solid #363636;
	padding-bottom: rem(25px);
}
