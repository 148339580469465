.image-card-carousel {
	position: relative;
	overflow-x: hidden;
	background: black;

	@media (max-width: max-width($break-grid-md)) {
		padding-top: 0;
	}
}

.image-card-carousel__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: left;
	opacity: 0.65;
	pointer-events: none;
}

.image-card-carousel__container {
	position: relative;
	z-index: 10;
}

.image-card-carousel__container--nav {
	@media (max-width: max-width($break-grid-sm)) {
		justify-content: flex-start;
	}
}

.image-card-carousel__cell {}

.image-card-carousel__cell--copy {
	@media (min-width: $break-grid-md) {
		width: 40%;
	}

	@media (min-width: $break-grid-lg) {
		width: 50%;
	}
}

.image-card-carousel__cell--media {
	@media (min-width: $break-grid-md) {
		width: 60%;
	}

	@media (min-width: $break-grid-lg) {
		width: 50%;
	}
}

.image-card-carousel__copy {
	position: relative;
	padding-top: rem(16);
	padding-bottom: rem(48);
	margin-bottom: rem(80);

	@media (min-width: $break-grid-md) {
		max-width: rem(550);
		padding: 0;
		margin-bottom: rem(72);
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: $shade-08;
		width: 100vw;
		height: 100%;
		margin: 0 rem(-20);
		z-index: -1;

		@media (min-width: $break-grid-md) {
			display: none;
		}
	}
}

.image-card-carousel__title {
	@include type-style-26--xl;
	margin-bottom: rem(16) !important;
	color: $color-brand-01;
}

.image-card-carousel__text {
	@include type-style-25--xl;
}

.image-card-slider {
	display: flex;
	align-items: center;
	gap: rem(32);
}

.image-card-slider__copy {
	@media (min-width: $break-grid-md) {
		max-width: rem(420);
	}
}

.image-card-slider__title {
	@include type-style-27;
	margin-bottom: rem(16) !important;

	@media (min-width: $break-grid-md) {
		display: none;
	}
}

.image-card-slider__text {
	@include type-style-28;
}

.image-card-carousel__nav {
	gap: rem(16);
	position: relative;
	z-index: 10;
}

.image-card-carousel__nav--arrow {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.image-card-slider__prev,
.image-card-slider__next {
	width: rem(96);
	height: rem(96);
}

.image-card-slider-card {
	display: flex !important;
	flex-direction: column;
	margin: 0 rem(24);
	position: relative;
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	transition: opacity $transition-default;

	@media (min-width: $break-grid-md) {
		justify-content: flex-end;
		padding: rem(32);
		height: rem(450);
		border-radius: rem(4);
	}

	@media (max-width: max-width($break-grid-md)) {
		background-size: 0 0;
	}

	&::before {
		@media (min-width: $break-grid-md) {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 23.33%, rgba(0, 0, 0, 0.80) 59.26%);
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			display: block;
			pointer-events: none;
		}
	}

	&:nth-child(odd) {
		@media (min-width: $break-grid-md) {
			margin-top: rem(100) !important;
		}
	}
}

.image-card-slider-card__copy {
	z-index: 10;
}

.image-card-slider-card__title {
	display: none;
	margin-bottom: rem(16) !important;
	@include type-style-29;

	@media (min-width: $break-grid-md) {
		display: block;
	}
}

.image-card-slider-card__text {
	@include type-style-30;
	opacity: 0.9;
}

.image-card-slider__nav {
	display: flex;
	align-items: center;
	gap: rem(32);
	margin-top: rem(48);

	@media (max-width: $break-grid-sm) {
		gap: rem(20);
	}
}

.image-card-slider .slick-list {
	cursor: grab;
	overflow: visible;
	width: 100% !important;
	margin: 0 rem(-24);

	&:active {
		cursor: grabbing;
	}
}

.image-card-slider .slick-track {
	display: flex !important;

	@media (min-width: $break-grid-lg) {
		margin: 0 rem(-24);
	}
}

.image-card-slider .slick-slide {
	opacity: 0;
	pointer-events: none;
}

.image-card-slider .slick-active,
.image-card-slider .slick-active ~ .slick-slide {
	opacity: 1;
	pointer-events: all;
}

.image-card-slider .slick-dots {
	display: flex !important;
	align-items: center;
	gap: rem(12);
	margin-top: rem(12);
}
