//---------------------------------------------------------------------------
//	onCourse
//---------------------------------------------------------------------------
.site-wrapper {
	min-height: 100%;
}

#content {
	float: none;
	width: 100%;
}

//---------------------------------------------------------------------------
//	Section
//---------------------------------------------------------------------------
.l-section {
	padding: rem(50px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(60px) 0;
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(100px) 0;
	}
}

.l-section--less-padding {
	padding: rem(32px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(48px) 0;
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(64px) 0;
	}
}

.l-section--extra-padding {
	padding: rem(70px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(100px) 0;
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(120px) 0;
	}
}

.l-section--no-padding {
	padding: 0;
}

.l-section--padding-bottom-none {
  	padding-bottom: 0;
}

.l-section--padding-top-none {
  	padding-top: 0;
}

.l-section--background_alt {
	background: $shade-02;
}

.l-section--dark {
	background: $shade-01;
	color: white;
}

.l-section--dark-alt {
	background: $shade-08;
	color: white;
}

.l-section--light {
	background: $shade-06;
}

.l-section--light-alt {
	background: $shade-09;
}

.l-section--light-alt-02 {
	background: $shade-10;
}

.l-section--primary {
	background: $color-brand-01;
	color: white;
}

//---------------------------------------------------------------------------
//	Container
//---------------------------------------------------------------------------

.l-container {
	max-width: $l-container-width + ($l-container-padding-default * 2);
	margin: 0 auto;
	padding: 0 $l-container-padding-default;

	@media (min-width: $break-grid-md) {
		padding: 0 $l-container-padding-md;
	}

	@media (min-width: $break-grid-lg) {
		padding: 0 $l-container-padding-lg;
	}

	@media (min-width: $break-grid-xl) {
		padding: 0 $l-container-padding-xl;
	}
}

.l-container--flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}


//---------------------------------------------------------------------------
//	Grid
//---------------------------------------------------------------------------

.l-grid {
	display: flex;
	flex-wrap: wrap;
	margin: 0 (-$l-grid-padding-default);

	@media (min-width: $break-grid-xl) {
		margin: 0 (-$l-grid-padding-xl);
	}
}

.l-grid--small-padding {
	margin: 0 (-$l-grid-padding-sm);
}

.l-grid--extra-padding {
	@media (min-width: $break-grid-md) {
		margin: 0 (-$l-grid-extra-padding-md);
	}

	@media (min-width: $break-grid-xl) {
		&--00 {
			margin: 0 (-$l-grid-extra-padding-00-xl);
		}
		&--01 {
			margin: 0 (-$l-grid-extra-padding-01-xl);
		}
		&--02 {
			margin: 0 (-$l-grid-extra-padding-02-xl);
		}
		&--03 {
			margin: 0 (-$l-grid-extra-padding-03-xl);
		}
		&--04 {
			margin: 0 (-$l-grid-extra-padding-04-xl);
		}
		&--05 {
			margin: 0 (-$l-grid-extra-padding-05-xl);
		}
		&--06 {
			margin: 0 (-$l-grid-extra-padding-06-xl);
		}
		&--07 {
			margin: 0 (-$l-grid-extra-padding-07-xl);
		}
		&--08 {
			margin: 0 (-$l-grid-extra-padding-08-xl);
		}
		&--09 {
			margin: 0 (-$l-grid-extra-padding-09-xl);
		}
	}
}

.l-grid--align-items-center {
	align-items: center;
}

.l-grid--row-reverse {
	@media (min-width: $break-grid-md) {
		flex-direction: row-reverse;
	}
}

.l-grid--column-reverse {
	@media (max-width: max-width($break-grid-md)) {
		flex-direction: column-reverse;
	}
}


//---------------------------------------------------------------------------
//	Grid Cell
//---------------------------------------------------------------------------

.l-grid__cell {
	width: 100%;
	padding: 0 $l-grid-padding-default;

	@media (min-width: $break-grid-xl) {
		padding: 0 $l-grid-padding-xl;
	}
}

.l-grid__cell--45 {
	width: 45%;
}

.l-grid__cell--50 {
	width: 50%;
}

.l-grid__cell--55 {
	width: 55%;
}

.l-grid__cell--small-padding {
	padding: 0 $l-grid-padding-sm;
}

.l-grid__cell--extra-padding {
	@media (min-width: $break-grid-md) {
		padding: 0 $l-grid-extra-padding-md;
	}

	@media (min-width: $break-grid-xl) {
		&--00 {
			padding: 0 $l-grid-extra-padding-00-xl;
		}
		&--01 {
			padding: 0 $l-grid-extra-padding-01-xl;
		}
		&--02 {
			padding: 0 $l-grid-extra-padding-02-xl;
		}
		&--03 {
			padding: 0 $l-grid-extra-padding-03-xl;
		}
		&--04 {
			padding: 0 $l-grid-extra-padding-04-xl;
		}
		&--05 {
			padding: 0 $l-grid-extra-padding-05-xl;
		}
		&--06 {
			padding: 0 $l-grid-extra-padding-06-xl;
		}
		&--07 {
			padding: 0 $l-grid-extra-padding-07-xl;
		}
		&--08 {
			padding: 0 $l-grid-extra-padding-08-xl;
		}
		&--09 {
			padding: 0 $l-grid-extra-padding-09-xl;
		}
	}
}

@media (min-width: $break-grid-xs) {
	.l-grid__cell--50\@xs {
		width: 50%;
	}

	.l-grid__cell--75\@xs {
		width: 75%;
	}
}

@media (min-width: $break-grid-sm) {
	.l-grid__cell--50\@sm {
		width: 50%;
	}

	.l-grid__cell--75\@sm {
		width: 75%;
	}
}

@media (min-width: em(600px)) {
	.l-grid__cell--50\@600 {
		width: 50%;
	}
}

@media (min-width: $break-grid-md) {
	.l-grid__cell--15\@md {
		width: 15%;
	}

	.l-grid__cell--20\@md {
		width: 20%;
	}

	.l-grid__cell--22\@md {
		width: 22%;
	}

	.l-grid__cell--25\@md {
		width: 25%;
	}

	.l-grid__cell--30\@md {
		width: 30%;
	}

	.l-grid__cell--33\@md {
		width: 33.33%;
	}

	.l-grid__cell--35\@md {
		width: 35%;
	}

	.l-grid__cell--40\@md {
		width: 40%;
	}

	.l-grid__cell--45\@md {
		width: 45%;
	}

	.l-grid__cell--50\@md {
		width: 50%;
	}

	.l-grid__cell--55\@md {
		width: 55%;
	}

	.l-grid__cell--60\@md {
		width: 60%;
	}

	.l-grid__cell--66\@md {
		width: 66.66%;
	}

	.l-grid__cell--70\@md {
		width: 70%;
	}

	.l-grid__cell--75\@md {
		width: 75%;
	}

	.l-grid__cell--78\@md {
		width: 78%;
	}

	.l-grid__cell--80\@md {
		width: 80%;
	}

	.l-grid__cell--100\@md {
		width: 100%;
	}
}

@media (min-width: $break-grid-ds) {
	.l-grid__cell--33\@ds {
		width: 33.3%;
	}
}

@media (min-width: $break-grid-lg) {
	.l-grid__cell--8\@lg {
		width: 8%;
	}

	.l-grid__cell--14\@lg {
		width: 14%;
	}

	.l-grid__cell--15\@lg {
		width: 15%;
	}

	.l-grid__cell--19\@lg {
		width: 19%;
	}

    .l-grid__cell--20\@lg {
        width: 20%;
    }

	.l-grid__cell--24\@lg {
		width: 24%;
	}

	.l-grid__cell--25\@lg {
		width: 25%;
	}

	.l-grid__cell--29\@lg {
		width: 29%;
	}

	.l-grid__cell--30\@lg {
		width: 30%;
	}

	.l-grid__cell--33\@lg {
		width: 33.33%;
	}

	.l-grid__cell--35\@lg {
		width: 35%;
	}

	.l-grid__cell--40\@lg {
		width: 40%;
	}

	.l-grid__cell--42\@lg {
		width: 42%;
	}

	.l-grid__cell--45\@lg {
		width: 45%;
	}

	.l-grid__cell--50\@lg {
		width: 50%;
	}

	.l-grid__cell--55\@lg {
		width: 55%;
	}

	.l-grid__cell--58\@lg {
		width: 58%;
	}

	.l-grid__cell--60\@lg {
		width: 60%;
	}

	.l-grid__cell--65\@lg {
		width: 65%;
	}

	.l-grid__cell--66\@lg {
		width: 66.66%;
	}

	.l-grid__cell--67\@lg {
		width: 67%;
	}

	.l-grid__cell--70\@lg {
		width: 70%;
	}

	.l-grid__cell--73\@lg {
		width: 73%;
	}

	.l-grid__cell--75\@lg {
		width: 75%;
	}

	.l-grid__cell--76\@lg {
		width: 76%;
	}

    .l-grid__cell--80\@lg {
        width: 80%;
    }

	.l-grid__cell--100\@lg {
		width: 100%;
	}
}

@media (min-width: $break-grid-xl) {
	.l-grid__cell--1\@xl {
		width: 1%;
	}

	.l-grid__cell--20\@xl {
		width: 20%;
	}

	.l-grid__cell--22\@xl {
		width: 22%;
	}

	.l-grid__cell--24\@xl {
		width: 24%;
	}

	.l-grid__cell--25\@xl {
		width: 25%;
	}

	.l-grid__cell--27\@xl {
		width: 27%;
	}

	.l-grid__cell--29\@xl {
		width: 29%;
	}

	.l-grid__cell--30\@xl {
		width: 30%;
	}

	.l-grid__cell--32\@xl {
		width: 32%;
	}

	.l-grid__cell--33\@xl {
		width: 33.33%;
	}

	.l-grid__cell--35\@xl {
		width: 35%;
	}

	.l-grid__cell--38\@xl {
		width: 38%;
	}

	.l-grid__cell--40\@xl {
		width: 40%;
	}

	.l-grid__cell--42\@xl {
		width: 42%;
	}

	.l-grid__cell--43\@xl {
		width: 43%;
	}

	.l-grid__cell--45\@xl {
		width: 45%;
	}

	.l-grid__cell--50\@xl {
		width: 50%;
	}

	.l-grid__cell--55\@xl {
		width: 55%;
	}

	.l-grid__cell--58\@xl {
		width: 58%;
	}

	.l-grid__cell--60\@xl {
		width: 60%;
	}

	.l-grid__cell--65\@xl {
		width: 65%;
	}

	.l-grid__cell--66\@xl {
		width: 66.66%;
	}

	.l-grid__cell--68\@xl {
		width: 68%;
	}

	.l-grid__cell--70\@xl {
		width: 70%;
	}

	.l-grid__cell--73\@xl {
		width: 73%;
	}

	.l-grid__cell--75\@xl {
		width: 75%;
	}

	.l-grid__cell--78\@xl {
		width: 78%;
	}

	.l-grid__cell--100\@xl {
		width: 100%;
	}
}

@media (min-width: $break-grid-xxl) {
	.l-grid__cell--22\@xxl {
		width: 22%;
	}

	.l-grid__cell--70\@xxl {
		width: 70%;
	}
}

.grid {
	display: block;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-left: -20px;
	font-size: 0;
}
.grid__cell {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	padding: 0;
	padding-left: 20px;
	margin: 0;
	vertical-align: top;
	font-size: 14px;
}
.grid--center {
	text-align: center;
}
.grid--center > .grid__cell {
	text-align: left;
}
.grid__cell--center {
	display: block;
	margin: 0 auto;
}
.grid--right {
	text-align: right;
}
.grid--right > .grid__cell {
	text-align: left;
}
.grid--middle > .grid__cell {
	vertical-align: middle;
}
.grid--flush {
	margin-left: 0;
}
.grid--flush > .grid__cell {
	padding-left: 0;
}
.grid--auto > .grid__cell {
	width: auto;
}
.grid--rev {
	direction: rtl;
}
.grid--rev > .grid__cell {
	direction: ltr;
}
.\31\/2,
.\31\30\/20,
.\31\32\/24,
.\31\34\/28,
.\32\/4,
.\33\/6,
.\33\30\/60,
.\34\/8,
.\35\/10,
.\36\/12,
.\36\30\/120,
.\37\/14,
.\38\/16,
.\39\/18 {
	width: 50%;
}
.\31\/3,
.\32\/6,
.\32\30\/60,
.\33\/9,
.\34\/12,
.\34\30\/120,
.\35\/15,
.\36\/18,
.\38\/24 {
	width: 33.33333%;
}
.\31\30\/15,
.\31\32\/18,
.\31\36\/24,
.\32\/3,
.\34\/6,
.\34\30\/60,
.\36\/9,
.\38\/12,
.\38\30\/120 {
	width: 66.66667%;
}
.\31\/4,
.\31\35\/60,
.\32\/8,
.\33\/12,
.\33\30\/120,
.\34\/16,
.\35\/20,
.\36\/24,
.\37\/28 {
	width: 25%;
}
.\31\32\/16,
.\31\35\/20,
.\31\38\/24,
.\32\31\/28,
.\33\/4,
.\34\35\/60,
.\36\/8,
.\39\/12,
.\39\30\/120 {
	width: 75%;
}
.\31\/5,
.\31\32\/60,
.\32\/10,
.\32\34\/120,
.\33\/15,
.\34\/20 {
	width: 20%;
}
.\32\/5,
.\32\34\/60,
.\34\/10,
.\34\38\/120,
.\36\/15,
.\38\/20 {
	width: 40%;
}
.\31\32\/20,
.\33\/5,
.\33\36\/60,
.\36\/10,
.\37\32\/120,
.\39\/15 {
	width: 60%;
}
.\31\32\/15,
.\31\36\/20,
.\34\/5,
.\34\38\/60,
.\38\/10,
.\39\36\/120 {
	width: 80%;
}
.\31\/6,
.\31\30\/60,
.\32\/12,
.\32\30\/120,
.\33\/18,
.\34\/24 {
	width: 16.66667%;
}
.\31\30\/12,
.\31\30\30\/120,
.\31\35\/18,
.\32\30\/24,
.\35\/6,
.\35\30\/60 {
	width: 83.33333%;
}
.\31\/7,
.\32\/14,
.\34\/28 {
	width: 14.28571%;
}
.\32\/7,
.\34\/14,
.\38\/28 {
	width: 28.57143%;
}
.\31\32\/28,
.\33\/7,
.\36\/14 {
	width: 42.85714%;
}
.\31\36\/28,
.\34\/7,
.\38\/14 {
	width: 57.14286%;
}
.\31\30\/14,
.\32\30\/28,
.\35\/7 {
	width: 71.42857%;
}
.\31\32\/14,
.\32\34\/28,
.\36\/7 {
	width: 85.71429%;
}
.\31\/8,
.\31\35\/120,
.\32\/16,
.\33\/24 {
	width: 12.5%;
}
.\33\/8,
.\34\35\/120,
.\36\/16,
.\39\/24 {
	width: 37.5%;
}
.\31\30\/16,
.\31\35\/24,
.\35\/8,
.\37\35\/120 {
	width: 62.5%;
}
.\31\30\35\/120,
.\31\34\/16,
.\32\31\/24,
.\37\/8 {
	width: 87.5%;
}
.\31\/9,
.\32\/18 {
	width: 11.11111%;
}
.\32\/9,
.\34\/18 {
	width: 22.22222%;
}
.\34\/9,
.\38\/18 {
	width: 44.44444%;
}
.\31\30\/18,
.\35\/9 {
	width: 55.55556%;
}
.\31\34\/18,
.\37\/9 {
	width: 77.77778%;
}
.\31\36\/18,
.\38\/9 {
	width: 88.88889%;
}
.\31\/10,
.\31\32\/120,
.\32\/20,
.\36\/60 {
	width: 10%;
}
.\31\38\/60,
.\33\/10,
.\33\36\/120,
.\36\/20 {
	width: 30%;
}
.\31\34\/20,
.\34\32\/60,
.\37\/10,
.\38\34\/120 {
	width: 70%;
}
.\31\30\38\/120,
.\31\38\/20,
.\35\34\/60,
.\39\/10 {
	width: 90%;
}
.\31\/11 {
	width: 9.09091%;
}
.\32\/11 {
	width: 18.18182%;
}
.\33\/11 {
	width: 27.27273%;
}
.\34\/11 {
	width: 36.36364%;
}
.\35\/11 {
	width: 45.45455%;
}
.\36\/11 {
	width: 54.54545%;
}
.\37\/11 {
	width: 63.63636%;
}
.\38\/11 {
	width: 72.72727%;
}
.\39\/11 {
	width: 81.81818%;
}
.\31\30\/11 {
	width: 90.90909%;
}
.\31\/12,
.\31\30\/120,
.\32\/24,
.\35\/60 {
	width: 8.33333%;
}
.\31\30\/24,
.\32\35\/60,
.\35\/12,
.\35\30\/120 {
	width: 41.66667%;
}
.\31\34\/24,
.\33\35\/60,
.\37\/12,
.\37\30\/120 {
	width: 58.33333%;
}
.\31\31\/12,
.\31\31\30\/120,
.\32\32\/24,
.\35\35\/60 {
	width: 91.66667%;
}
.\31\/13 {
	width: 7.69231%;
}
.\32\/13 {
	width: 15.38462%;
}
.\33\/13 {
	width: 23.07692%;
}
.\34\/13 {
	width: 30.76923%;
}
.\35\/13 {
	width: 38.46154%;
}
.\36\/13 {
	width: 46.15385%;
}
.\37\/13 {
	width: 53.84615%;
}
.\38\/13 {
	width: 61.53846%;
}
.\39\/13 {
	width: 69.23077%;
}
.\31\30\/13 {
	width: 76.92308%;
}
.\31\31\/13 {
	width: 84.61538%;
}
.\31\32\/13 {
	width: 92.30769%;
}
.\31\/14,
.\32\/28 {
	width: 7.14286%;
}
.\33\/14,
.\36\/28 {
	width: 21.42857%;
}
.\31\30\/28,
.\35\/14 {
	width: 35.71429%;
}
.\31\38\/28,
.\39\/14 {
	width: 64.28571%;
}
.\31\31\/14,
.\32\32\/28 {
	width: 78.57143%;
}
.\31\33\/14,
.\32\36\/28 {
	width: 92.85714%;
}
.\31\/15,
.\34\/60,
.\38\/120 {
	width: 6.66667%;
}
.\31\36\/120,
.\32\/15,
.\38\/60 {
	width: 13.33333%;
}
.\31\36\/60,
.\33\32\/120,
.\34\/15 {
	width: 26.66667%;
}
.\32\38\/60,
.\35\36\/120,
.\37\/15 {
	width: 46.66667%;
}
.\33\32\/60,
.\36\34\/120,
.\38\/15 {
	width: 53.33333%;
}
.\31\31\/15,
.\34\34\/60,
.\38\38\/120 {
	width: 73.33333%;
}
.\31\30\34\/120,
.\31\33\/15,
.\35\32\/60 {
	width: 86.66667%;
}
.\31\31\32\/120,
.\31\34\/15,
.\35\36\/60 {
	width: 93.33333%;
}
.\31\/16 {
	width: 6.25%;
}
.\33\/16 {
	width: 18.75%;
}
.\35\/16 {
	width: 31.25%;
}
.\37\/16 {
	width: 43.75%;
}
.\39\/16 {
	width: 56.25%;
}
.\31\31\/16 {
	width: 68.75%;
}
.\31\33\/16 {
	width: 81.25%;
}
.\31\35\/16 {
	width: 93.75%;
}
.\31\/17 {
	width: 5.88235%;
}
.\32\/17 {
	width: 11.76471%;
}
.\33\/17 {
	width: 17.64706%;
}
.\34\/17 {
	width: 23.52941%;
}
.\35\/17 {
	width: 29.41176%;
}
.\36\/17 {
	width: 35.29412%;
}
.\37\/17 {
	width: 41.17647%;
}
.\38\/17 {
	width: 47.05882%;
}
.\39\/17 {
	width: 52.94118%;
}
.\31\30\/17 {
	width: 58.82353%;
}
.\31\31\/17 {
	width: 64.70588%;
}
.\31\32\/17 {
	width: 70.58824%;
}
.\31\33\/17 {
	width: 76.47059%;
}
.\31\34\/17 {
	width: 82.35294%;
}
.\31\35\/17 {
	width: 88.23529%;
}
.\31\36\/17 {
	width: 94.11765%;
}
.\31\/18 {
	width: 5.55556%;
}
.\35\/18 {
	width: 27.77778%;
}
.\37\/18 {
	width: 38.88889%;
}
.\31\31\/18 {
	width: 61.11111%;
}
.\31\33\/18 {
	width: 72.22222%;
}
.\31\37\/18 {
	width: 94.44444%;
}
.\31\/19 {
	width: 5.26316%;
}
.\32\/19 {
	width: 10.52632%;
}
.\33\/19 {
	width: 15.78947%;
}
.\34\/19 {
	width: 21.05263%;
}
.\35\/19 {
	width: 26.31579%;
}
.\36\/19 {
	width: 31.57895%;
}
.\37\/19 {
	width: 36.84211%;
}
.\38\/19 {
	width: 42.10526%;
}
.\39\/19 {
	width: 47.36842%;
}
.\31\30\/19 {
	width: 52.63158%;
}
.\31\31\/19 {
	width: 57.89474%;
}
.\31\32\/19 {
	width: 63.15789%;
}
.\31\33\/19 {
	width: 68.42105%;
}
.\31\34\/19 {
	width: 73.68421%;
}
.\31\35\/19 {
	width: 78.94737%;
}
.\31\36\/19 {
	width: 84.21053%;
}
.\31\37\/19 {
	width: 89.47368%;
}
.\31\38\/19 {
	width: 94.73684%;
}
.\31\/20,
.\33\/60,
.\36\/120 {
	width: 5%;
}
.\31\38\/120,
.\33\/20,
.\39\/60 {
	width: 15%;
}
.\32\31\/60,
.\34\32\/120,
.\37\/20 {
	width: 35%;
}
.\32\37\/60,
.\35\34\/120,
.\39\/20 {
	width: 45%;
}
.\31\31\/20,
.\33\33\/60,
.\36\36\/120 {
	width: 55%;
}
.\31\33\/20,
.\33\39\/60,
.\37\38\/120 {
	width: 65%;
}
.\31\30\32\/120,
.\31\37\/20,
.\35\31\/60 {
	width: 85%;
}
.\31\31\34\/120,
.\31\39\/20,
.\35\37\/60 {
	width: 95%;
}
.\31\/24,
.\35\/120 {
	width: 4.16667%;
}
.\32\35\/120,
.\35\/24 {
	width: 20.83333%;
}
.\33\35\/120,
.\37\/24 {
	width: 29.16667%;
}
.\31\31\/24,
.\35\35\/120 {
	width: 45.83333%;
}
.\31\33\/24,
.\36\35\/120 {
	width: 54.16667%;
}
.\31\37\/24,
.\38\35\/120 {
	width: 70.83333%;
}
.\31\39\/24,
.\39\35\/120 {
	width: 79.16667%;
}
.\31\31\35\/120,
.\32\33\/24 {
	width: 95.83333%;
}
.\31\/28 {
	width: 3.57143%;
}
.\33\/28 {
	width: 10.71429%;
}
.\35\/28 {
	width: 17.85714%;
}
.\39\/28 {
	width: 32.14286%;
}
.\31\31\/28 {
	width: 39.28571%;
}
.\31\33\/28 {
	width: 46.42857%;
}
.\31\35\/28 {
	width: 53.57143%;
}
.\31\37\/28 {
	width: 60.71429%;
}
.\31\39\/28 {
	width: 67.85714%;
}
.\32\33\/28 {
	width: 82.14286%;
}
.\32\35\/28 {
	width: 89.28571%;
}
.\32\37\/28 {
	width: 96.42857%;
}
.\31\/60,
.\32\/120 {
	width: 1.66667%;
}
.\32\/60,
.\34\/120 {
	width: 3.33333%;
}
.\31\34\/120,
.\37\/60 {
	width: 11.66667%;
}
.\31\31\/60,
.\32\32\/120 {
	width: 18.33333%;
}
.\31\33\/60,
.\32\36\/120 {
	width: 21.66667%;
}
.\31\34\/60,
.\32\38\/120 {
	width: 23.33333%;
}
.\31\37\/60,
.\33\34\/120 {
	width: 28.33333%;
}
.\31\39\/60,
.\33\38\/120 {
	width: 31.66667%;
}
.\32\32\/60,
.\34\34\/120 {
	width: 36.66667%;
}
.\32\33\/60,
.\34\36\/120 {
	width: 38.33333%;
}
.\32\36\/60,
.\35\32\/120 {
	width: 43.33333%;
}
.\32\39\/60,
.\35\38\/120 {
	width: 48.33333%;
}
.\33\31\/60,
.\36\32\/120 {
	width: 51.66667%;
}
.\33\34\/60,
.\36\38\/120 {
	width: 56.66667%;
}
.\33\37\/60,
.\37\34\/120 {
	width: 61.66667%;
}
.\33\38\/60,
.\37\36\/120 {
	width: 63.33333%;
}
.\34\31\/60,
.\38\32\/120 {
	width: 68.33333%;
}
.\34\33\/60,
.\38\36\/120 {
	width: 71.66667%;
}
.\34\36\/60,
.\39\32\/120 {
	width: 76.66667%;
}
.\34\37\/60,
.\39\34\/120 {
	width: 78.33333%;
}
.\34\39\/60,
.\39\38\/120 {
	width: 81.66667%;
}
.\31\30\36\/120,
.\35\33\/60 {
	width: 88.33333%;
}
.\31\31\36\/120,
.\35\38\/60 {
	width: 96.66667%;
}
.\31\31\38\/120,
.\35\39\/60 {
	width: 98.33333%;
}
.\31\/120 {
	width: 0.83333%;
}
.\33\/120 {
	width: 2.5%;
}
.\37\/120 {
	width: 5.83333%;
}
.\39\/120 {
	width: 7.5%;
}
.\31\31\/120 {
	width: 9.16667%;
}
.\31\33\/120 {
	width: 10.83333%;
}
.\31\37\/120 {
	width: 14.16667%;
}
.\31\39\/120 {
	width: 15.83333%;
}
.\32\31\/120 {
	width: 17.5%;
}
.\32\33\/120 {
	width: 19.16667%;
}
.\32\37\/120 {
	width: 22.5%;
}
.\32\39\/120 {
	width: 24.16667%;
}
.\33\31\/120 {
	width: 25.83333%;
}
.\33\33\/120 {
	width: 27.5%;
}
.\33\37\/120 {
	width: 30.83333%;
}
.\33\39\/120 {
	width: 32.5%;
}
.\34\31\/120 {
	width: 34.16667%;
}
.\34\33\/120 {
	width: 35.83333%;
}
.\34\37\/120 {
	width: 39.16667%;
}
.\34\39\/120 {
	width: 40.83333%;
}
.\35\31\/120 {
	width: 42.5%;
}
.\35\33\/120 {
	width: 44.16667%;
}
.\35\37\/120 {
	width: 47.5%;
}
.\35\39\/120 {
	width: 49.16667%;
}
.\36\31\/120 {
	width: 50.83333%;
}
.\36\33\/120 {
	width: 52.5%;
}
.\36\37\/120 {
	width: 55.83333%;
}
.\36\39\/120 {
	width: 57.5%;
}
.\37\31\/120 {
	width: 59.16667%;
}
.\37\33\/120 {
	width: 60.83333%;
}
.\37\37\/120 {
	width: 64.16667%;
}
.\37\39\/120 {
	width: 65.83333%;
}
.\38\31\/120 {
	width: 67.5%;
}
.\38\33\/120 {
	width: 69.16667%;
}
.\38\37\/120 {
	width: 72.5%;
}
.\38\39\/120 {
	width: 74.16667%;
}
.\39\31\/120 {
	width: 75.83333%;
}
.\39\33\/120 {
	width: 77.5%;
}
.\39\37\/120 {
	width: 80.83333%;
}
.\39\39\/120 {
	width: 82.5%;
}
.\31\30\31\/120 {
	width: 84.16667%;
}
.\31\30\33\/120 {
	width: 85.83333%;
}
.\31\30\37\/120 {
	width: 89.16667%;
}
.\31\30\39\/120 {
	width: 90.83333%;
}
.\31\31\31\/120 {
	width: 92.5%;
}
.\31\31\33\/120 {
	width: 94.16667%;
}
.\31\31\37\/120 {
	width: 97.5%;
}
.\31\31\39\/120 {
	width: 99.16667%;
}
@media screen and (max-width: 479px) {
	.\31\/2--mobile-down,
	.\31\30\/20--mobile-down,
	.\31\32\/24--mobile-down,
	.\31\34\/28--mobile-down,
	.\32\/4--mobile-down,
	.\33\/6--mobile-down,
	.\33\30\/60--mobile-down,
	.\34\/8--mobile-down,
	.\35\/10--mobile-down,
	.\36\/12--mobile-down,
	.\36\30\/120--mobile-down,
	.\37\/14--mobile-down,
	.\38\/16--mobile-down,
	.\39\/18--mobile-down {
		width: 50%;
	}
	.\31\/3--mobile-down,
	.\32\/6--mobile-down,
	.\32\30\/60--mobile-down,
	.\33\/9--mobile-down,
	.\34\/12--mobile-down,
	.\34\30\/120--mobile-down,
	.\35\/15--mobile-down,
	.\36\/18--mobile-down,
	.\38\/24--mobile-down {
		width: 33.33333%;
	}
	.\31\30\/15--mobile-down,
	.\31\32\/18--mobile-down,
	.\31\36\/24--mobile-down,
	.\32\/3--mobile-down,
	.\34\/6--mobile-down,
	.\34\30\/60--mobile-down,
	.\36\/9--mobile-down,
	.\38\/12--mobile-down,
	.\38\30\/120--mobile-down {
		width: 66.66667%;
	}
	.\31\/4--mobile-down,
	.\31\35\/60--mobile-down,
	.\32\/8--mobile-down,
	.\33\/12--mobile-down,
	.\33\30\/120--mobile-down,
	.\34\/16--mobile-down,
	.\35\/20--mobile-down,
	.\36\/24--mobile-down,
	.\37\/28--mobile-down {
		width: 25%;
	}
	.\31\32\/16--mobile-down,
	.\31\35\/20--mobile-down,
	.\31\38\/24--mobile-down,
	.\32\31\/28--mobile-down,
	.\33\/4--mobile-down,
	.\34\35\/60--mobile-down,
	.\36\/8--mobile-down,
	.\39\/12--mobile-down,
	.\39\30\/120--mobile-down {
		width: 75%;
	}
	.\31\/5--mobile-down,
	.\31\32\/60--mobile-down,
	.\32\/10--mobile-down,
	.\32\34\/120--mobile-down,
	.\33\/15--mobile-down,
	.\34\/20--mobile-down {
		width: 20%;
	}
	.\32\/5--mobile-down,
	.\32\34\/60--mobile-down,
	.\34\/10--mobile-down,
	.\34\38\/120--mobile-down,
	.\36\/15--mobile-down,
	.\38\/20--mobile-down {
		width: 40%;
	}
	.\31\32\/20--mobile-down,
	.\33\/5--mobile-down,
	.\33\36\/60--mobile-down,
	.\36\/10--mobile-down,
	.\37\32\/120--mobile-down,
	.\39\/15--mobile-down {
		width: 60%;
	}
	.\31\32\/15--mobile-down,
	.\31\36\/20--mobile-down,
	.\34\/5--mobile-down,
	.\34\38\/60--mobile-down,
	.\38\/10--mobile-down,
	.\39\36\/120--mobile-down {
		width: 80%;
	}
	.\31\/6--mobile-down,
	.\31\30\/60--mobile-down,
	.\32\/12--mobile-down,
	.\32\30\/120--mobile-down,
	.\33\/18--mobile-down,
	.\34\/24--mobile-down {
		width: 16.66667%;
	}
	.\31\30\/12--mobile-down,
	.\31\30\30\/120--mobile-down,
	.\31\35\/18--mobile-down,
	.\32\30\/24--mobile-down,
	.\35\/6--mobile-down,
	.\35\30\/60--mobile-down {
		width: 83.33333%;
	}
	.\31\/7--mobile-down,
	.\32\/14--mobile-down,
	.\34\/28--mobile-down {
		width: 14.28571%;
	}
	.\32\/7--mobile-down,
	.\34\/14--mobile-down,
	.\38\/28--mobile-down {
		width: 28.57143%;
	}
	.\31\32\/28--mobile-down,
	.\33\/7--mobile-down,
	.\36\/14--mobile-down {
		width: 42.85714%;
	}
	.\31\36\/28--mobile-down,
	.\34\/7--mobile-down,
	.\38\/14--mobile-down {
		width: 57.14286%;
	}
	.\31\30\/14--mobile-down,
	.\32\30\/28--mobile-down,
	.\35\/7--mobile-down {
		width: 71.42857%;
	}
	.\31\32\/14--mobile-down,
	.\32\34\/28--mobile-down,
	.\36\/7--mobile-down {
		width: 85.71429%;
	}
	.\31\/8--mobile-down,
	.\31\35\/120--mobile-down,
	.\32\/16--mobile-down,
	.\33\/24--mobile-down {
		width: 12.5%;
	}
	.\33\/8--mobile-down,
	.\34\35\/120--mobile-down,
	.\36\/16--mobile-down,
	.\39\/24--mobile-down {
		width: 37.5%;
	}
	.\31\30\/16--mobile-down,
	.\31\35\/24--mobile-down,
	.\35\/8--mobile-down,
	.\37\35\/120--mobile-down {
		width: 62.5%;
	}
	.\31\30\35\/120--mobile-down,
	.\31\34\/16--mobile-down,
	.\32\31\/24--mobile-down,
	.\37\/8--mobile-down {
		width: 87.5%;
	}
	.\31\/9--mobile-down,
	.\32\/18--mobile-down {
		width: 11.11111%;
	}
	.\32\/9--mobile-down,
	.\34\/18--mobile-down {
		width: 22.22222%;
	}
	.\34\/9--mobile-down,
	.\38\/18--mobile-down {
		width: 44.44444%;
	}
	.\31\30\/18--mobile-down,
	.\35\/9--mobile-down {
		width: 55.55556%;
	}
	.\31\34\/18--mobile-down,
	.\37\/9--mobile-down {
		width: 77.77778%;
	}
	.\31\36\/18--mobile-down,
	.\38\/9--mobile-down {
		width: 88.88889%;
	}
	.\31\/10--mobile-down,
	.\31\32\/120--mobile-down,
	.\32\/20--mobile-down,
	.\36\/60--mobile-down {
		width: 10%;
	}
	.\31\38\/60--mobile-down,
	.\33\/10--mobile-down,
	.\33\36\/120--mobile-down,
	.\36\/20--mobile-down {
		width: 30%;
	}
	.\31\34\/20--mobile-down,
	.\34\32\/60--mobile-down,
	.\37\/10--mobile-down,
	.\38\34\/120--mobile-down {
		width: 70%;
	}
	.\31\30\38\/120--mobile-down,
	.\31\38\/20--mobile-down,
	.\35\34\/60--mobile-down,
	.\39\/10--mobile-down {
		width: 90%;
	}
	.\31\/11--mobile-down {
		width: 9.09091%;
	}
	.\32\/11--mobile-down {
		width: 18.18182%;
	}
	.\33\/11--mobile-down {
		width: 27.27273%;
	}
	.\34\/11--mobile-down {
		width: 36.36364%;
	}
	.\35\/11--mobile-down {
		width: 45.45455%;
	}
	.\36\/11--mobile-down {
		width: 54.54545%;
	}
	.\37\/11--mobile-down {
		width: 63.63636%;
	}
	.\38\/11--mobile-down {
		width: 72.72727%;
	}
	.\39\/11--mobile-down {
		width: 81.81818%;
	}
	.\31\30\/11--mobile-down {
		width: 90.90909%;
	}
	.\31\/12--mobile-down,
	.\31\30\/120--mobile-down,
	.\32\/24--mobile-down,
	.\35\/60--mobile-down {
		width: 8.33333%;
	}
	.\31\30\/24--mobile-down,
	.\32\35\/60--mobile-down,
	.\35\/12--mobile-down,
	.\35\30\/120--mobile-down {
		width: 41.66667%;
	}
	.\31\34\/24--mobile-down,
	.\33\35\/60--mobile-down,
	.\37\/12--mobile-down,
	.\37\30\/120--mobile-down {
		width: 58.33333%;
	}
	.\31\31\/12--mobile-down,
	.\31\31\30\/120--mobile-down,
	.\32\32\/24--mobile-down,
	.\35\35\/60--mobile-down {
		width: 91.66667%;
	}
	.\31\/13--mobile-down {
		width: 7.69231%;
	}
	.\32\/13--mobile-down {
		width: 15.38462%;
	}
	.\33\/13--mobile-down {
		width: 23.07692%;
	}
	.\34\/13--mobile-down {
		width: 30.76923%;
	}
	.\35\/13--mobile-down {
		width: 38.46154%;
	}
	.\36\/13--mobile-down {
		width: 46.15385%;
	}
	.\37\/13--mobile-down {
		width: 53.84615%;
	}
	.\38\/13--mobile-down {
		width: 61.53846%;
	}
	.\39\/13--mobile-down {
		width: 69.23077%;
	}
	.\31\30\/13--mobile-down {
		width: 76.92308%;
	}
	.\31\31\/13--mobile-down {
		width: 84.61538%;
	}
	.\31\32\/13--mobile-down {
		width: 92.30769%;
	}
	.\31\/14--mobile-down,
	.\32\/28--mobile-down {
		width: 7.14286%;
	}
	.\33\/14--mobile-down,
	.\36\/28--mobile-down {
		width: 21.42857%;
	}
	.\31\30\/28--mobile-down,
	.\35\/14--mobile-down {
		width: 35.71429%;
	}
	.\31\38\/28--mobile-down,
	.\39\/14--mobile-down {
		width: 64.28571%;
	}
	.\31\31\/14--mobile-down,
	.\32\32\/28--mobile-down {
		width: 78.57143%;
	}
	.\31\33\/14--mobile-down,
	.\32\36\/28--mobile-down {
		width: 92.85714%;
	}
	.\31\/15--mobile-down,
	.\34\/60--mobile-down,
	.\38\/120--mobile-down {
		width: 6.66667%;
	}
	.\31\36\/120--mobile-down,
	.\32\/15--mobile-down,
	.\38\/60--mobile-down {
		width: 13.33333%;
	}
	.\31\36\/60--mobile-down,
	.\33\32\/120--mobile-down,
	.\34\/15--mobile-down {
		width: 26.66667%;
	}
	.\32\38\/60--mobile-down,
	.\35\36\/120--mobile-down,
	.\37\/15--mobile-down {
		width: 46.66667%;
	}
	.\33\32\/60--mobile-down,
	.\36\34\/120--mobile-down,
	.\38\/15--mobile-down {
		width: 53.33333%;
	}
	.\31\31\/15--mobile-down,
	.\34\34\/60--mobile-down,
	.\38\38\/120--mobile-down {
		width: 73.33333%;
	}
	.\31\30\34\/120--mobile-down,
	.\31\33\/15--mobile-down,
	.\35\32\/60--mobile-down {
		width: 86.66667%;
	}
	.\31\31\32\/120--mobile-down,
	.\31\34\/15--mobile-down,
	.\35\36\/60--mobile-down {
		width: 93.33333%;
	}
	.\31\/16--mobile-down {
		width: 6.25%;
	}
	.\33\/16--mobile-down {
		width: 18.75%;
	}
	.\35\/16--mobile-down {
		width: 31.25%;
	}
	.\37\/16--mobile-down {
		width: 43.75%;
	}
	.\39\/16--mobile-down {
		width: 56.25%;
	}
	.\31\31\/16--mobile-down {
		width: 68.75%;
	}
	.\31\33\/16--mobile-down {
		width: 81.25%;
	}
	.\31\35\/16--mobile-down {
		width: 93.75%;
	}
	.\31\/17--mobile-down {
		width: 5.88235%;
	}
	.\32\/17--mobile-down {
		width: 11.76471%;
	}
	.\33\/17--mobile-down {
		width: 17.64706%;
	}
	.\34\/17--mobile-down {
		width: 23.52941%;
	}
	.\35\/17--mobile-down {
		width: 29.41176%;
	}
	.\36\/17--mobile-down {
		width: 35.29412%;
	}
	.\37\/17--mobile-down {
		width: 41.17647%;
	}
	.\38\/17--mobile-down {
		width: 47.05882%;
	}
	.\39\/17--mobile-down {
		width: 52.94118%;
	}
	.\31\30\/17--mobile-down {
		width: 58.82353%;
	}
	.\31\31\/17--mobile-down {
		width: 64.70588%;
	}
	.\31\32\/17--mobile-down {
		width: 70.58824%;
	}
	.\31\33\/17--mobile-down {
		width: 76.47059%;
	}
	.\31\34\/17--mobile-down {
		width: 82.35294%;
	}
	.\31\35\/17--mobile-down {
		width: 88.23529%;
	}
	.\31\36\/17--mobile-down {
		width: 94.11765%;
	}
	.\31\/18--mobile-down {
		width: 5.55556%;
	}
	.\35\/18--mobile-down {
		width: 27.77778%;
	}
	.\37\/18--mobile-down {
		width: 38.88889%;
	}
	.\31\31\/18--mobile-down {
		width: 61.11111%;
	}
	.\31\33\/18--mobile-down {
		width: 72.22222%;
	}
	.\31\37\/18--mobile-down {
		width: 94.44444%;
	}
	.\31\/19--mobile-down {
		width: 5.26316%;
	}
	.\32\/19--mobile-down {
		width: 10.52632%;
	}
	.\33\/19--mobile-down {
		width: 15.78947%;
	}
	.\34\/19--mobile-down {
		width: 21.05263%;
	}
	.\35\/19--mobile-down {
		width: 26.31579%;
	}
	.\36\/19--mobile-down {
		width: 31.57895%;
	}
	.\37\/19--mobile-down {
		width: 36.84211%;
	}
	.\38\/19--mobile-down {
		width: 42.10526%;
	}
	.\39\/19--mobile-down {
		width: 47.36842%;
	}
	.\31\30\/19--mobile-down {
		width: 52.63158%;
	}
	.\31\31\/19--mobile-down {
		width: 57.89474%;
	}
	.\31\32\/19--mobile-down {
		width: 63.15789%;
	}
	.\31\33\/19--mobile-down {
		width: 68.42105%;
	}
	.\31\34\/19--mobile-down {
		width: 73.68421%;
	}
	.\31\35\/19--mobile-down {
		width: 78.94737%;
	}
	.\31\36\/19--mobile-down {
		width: 84.21053%;
	}
	.\31\37\/19--mobile-down {
		width: 89.47368%;
	}
	.\31\38\/19--mobile-down {
		width: 94.73684%;
	}
	.\31\/20--mobile-down,
	.\33\/60--mobile-down,
	.\36\/120--mobile-down {
		width: 5%;
	}
	.\31\38\/120--mobile-down,
	.\33\/20--mobile-down,
	.\39\/60--mobile-down {
		width: 15%;
	}
	.\32\31\/60--mobile-down,
	.\34\32\/120--mobile-down,
	.\37\/20--mobile-down {
		width: 35%;
	}
	.\32\37\/60--mobile-down,
	.\35\34\/120--mobile-down,
	.\39\/20--mobile-down {
		width: 45%;
	}
	.\31\31\/20--mobile-down,
	.\33\33\/60--mobile-down,
	.\36\36\/120--mobile-down {
		width: 55%;
	}
	.\31\33\/20--mobile-down,
	.\33\39\/60--mobile-down,
	.\37\38\/120--mobile-down {
		width: 65%;
	}
	.\31\30\32\/120--mobile-down,
	.\31\37\/20--mobile-down,
	.\35\31\/60--mobile-down {
		width: 85%;
	}
	.\31\31\34\/120--mobile-down,
	.\31\39\/20--mobile-down,
	.\35\37\/60--mobile-down {
		width: 95%;
	}
	.\31\/24--mobile-down,
	.\35\/120--mobile-down {
		width: 4.16667%;
	}
	.\32\35\/120--mobile-down,
	.\35\/24--mobile-down {
		width: 20.83333%;
	}
	.\33\35\/120--mobile-down,
	.\37\/24--mobile-down {
		width: 29.16667%;
	}
	.\31\31\/24--mobile-down,
	.\35\35\/120--mobile-down {
		width: 45.83333%;
	}
	.\31\33\/24--mobile-down,
	.\36\35\/120--mobile-down {
		width: 54.16667%;
	}
	.\31\37\/24--mobile-down,
	.\38\35\/120--mobile-down {
		width: 70.83333%;
	}
	.\31\39\/24--mobile-down,
	.\39\35\/120--mobile-down {
		width: 79.16667%;
	}
	.\31\31\35\/120--mobile-down,
	.\32\33\/24--mobile-down {
		width: 95.83333%;
	}
	.\31\/28--mobile-down {
		width: 3.57143%;
	}
	.\33\/28--mobile-down {
		width: 10.71429%;
	}
	.\35\/28--mobile-down {
		width: 17.85714%;
	}
	.\39\/28--mobile-down {
		width: 32.14286%;
	}
	.\31\31\/28--mobile-down {
		width: 39.28571%;
	}
	.\31\33\/28--mobile-down {
		width: 46.42857%;
	}
	.\31\35\/28--mobile-down {
		width: 53.57143%;
	}
	.\31\37\/28--mobile-down {
		width: 60.71429%;
	}
	.\31\39\/28--mobile-down {
		width: 67.85714%;
	}
	.\32\33\/28--mobile-down {
		width: 82.14286%;
	}
	.\32\35\/28--mobile-down {
		width: 89.28571%;
	}
	.\32\37\/28--mobile-down {
		width: 96.42857%;
	}
	.\31\/60--mobile-down,
	.\32\/120--mobile-down {
		width: 1.66667%;
	}
	.\32\/60--mobile-down,
	.\34\/120--mobile-down {
		width: 3.33333%;
	}
	.\31\34\/120--mobile-down,
	.\37\/60--mobile-down {
		width: 11.66667%;
	}
	.\31\31\/60--mobile-down,
	.\32\32\/120--mobile-down {
		width: 18.33333%;
	}
	.\31\33\/60--mobile-down,
	.\32\36\/120--mobile-down {
		width: 21.66667%;
	}
	.\31\34\/60--mobile-down,
	.\32\38\/120--mobile-down {
		width: 23.33333%;
	}
	.\31\37\/60--mobile-down,
	.\33\34\/120--mobile-down {
		width: 28.33333%;
	}
	.\31\39\/60--mobile-down,
	.\33\38\/120--mobile-down {
		width: 31.66667%;
	}
	.\32\32\/60--mobile-down,
	.\34\34\/120--mobile-down {
		width: 36.66667%;
	}
	.\32\33\/60--mobile-down,
	.\34\36\/120--mobile-down {
		width: 38.33333%;
	}
	.\32\36\/60--mobile-down,
	.\35\32\/120--mobile-down {
		width: 43.33333%;
	}
	.\32\39\/60--mobile-down,
	.\35\38\/120--mobile-down {
		width: 48.33333%;
	}
	.\33\31\/60--mobile-down,
	.\36\32\/120--mobile-down {
		width: 51.66667%;
	}
	.\33\34\/60--mobile-down,
	.\36\38\/120--mobile-down {
		width: 56.66667%;
	}
	.\33\37\/60--mobile-down,
	.\37\34\/120--mobile-down {
		width: 61.66667%;
	}
	.\33\38\/60--mobile-down,
	.\37\36\/120--mobile-down {
		width: 63.33333%;
	}
	.\34\31\/60--mobile-down,
	.\38\32\/120--mobile-down {
		width: 68.33333%;
	}
	.\34\33\/60--mobile-down,
	.\38\36\/120--mobile-down {
		width: 71.66667%;
	}
	.\34\36\/60--mobile-down,
	.\39\32\/120--mobile-down {
		width: 76.66667%;
	}
	.\34\37\/60--mobile-down,
	.\39\34\/120--mobile-down {
		width: 78.33333%;
	}
	.\34\39\/60--mobile-down,
	.\39\38\/120--mobile-down {
		width: 81.66667%;
	}
	.\31\30\36\/120--mobile-down,
	.\35\33\/60--mobile-down {
		width: 88.33333%;
	}
	.\31\31\36\/120--mobile-down,
	.\35\38\/60--mobile-down {
		width: 96.66667%;
	}
	.\31\31\38\/120--mobile-down,
	.\35\39\/60--mobile-down {
		width: 98.33333%;
	}
	.\31\/120--mobile-down {
		width: 0.83333%;
	}
	.\33\/120--mobile-down {
		width: 2.5%;
	}
	.\37\/120--mobile-down {
		width: 5.83333%;
	}
	.\39\/120--mobile-down {
		width: 7.5%;
	}
	.\31\31\/120--mobile-down {
		width: 9.16667%;
	}
	.\31\33\/120--mobile-down {
		width: 10.83333%;
	}
	.\31\37\/120--mobile-down {
		width: 14.16667%;
	}
	.\31\39\/120--mobile-down {
		width: 15.83333%;
	}
	.\32\31\/120--mobile-down {
		width: 17.5%;
	}
	.\32\33\/120--mobile-down {
		width: 19.16667%;
	}
	.\32\37\/120--mobile-down {
		width: 22.5%;
	}
	.\32\39\/120--mobile-down {
		width: 24.16667%;
	}
	.\33\31\/120--mobile-down {
		width: 25.83333%;
	}
	.\33\33\/120--mobile-down {
		width: 27.5%;
	}
	.\33\37\/120--mobile-down {
		width: 30.83333%;
	}
	.\33\39\/120--mobile-down {
		width: 32.5%;
	}
	.\34\31\/120--mobile-down {
		width: 34.16667%;
	}
	.\34\33\/120--mobile-down {
		width: 35.83333%;
	}
	.\34\37\/120--mobile-down {
		width: 39.16667%;
	}
	.\34\39\/120--mobile-down {
		width: 40.83333%;
	}
	.\35\31\/120--mobile-down {
		width: 42.5%;
	}
	.\35\33\/120--mobile-down {
		width: 44.16667%;
	}
	.\35\37\/120--mobile-down {
		width: 47.5%;
	}
	.\35\39\/120--mobile-down {
		width: 49.16667%;
	}
	.\36\31\/120--mobile-down {
		width: 50.83333%;
	}
	.\36\33\/120--mobile-down {
		width: 52.5%;
	}
	.\36\37\/120--mobile-down {
		width: 55.83333%;
	}
	.\36\39\/120--mobile-down {
		width: 57.5%;
	}
	.\37\31\/120--mobile-down {
		width: 59.16667%;
	}
	.\37\33\/120--mobile-down {
		width: 60.83333%;
	}
	.\37\37\/120--mobile-down {
		width: 64.16667%;
	}
	.\37\39\/120--mobile-down {
		width: 65.83333%;
	}
	.\38\31\/120--mobile-down {
		width: 67.5%;
	}
	.\38\33\/120--mobile-down {
		width: 69.16667%;
	}
	.\38\37\/120--mobile-down {
		width: 72.5%;
	}
	.\38\39\/120--mobile-down {
		width: 74.16667%;
	}
	.\39\31\/120--mobile-down {
		width: 75.83333%;
	}
	.\39\33\/120--mobile-down {
		width: 77.5%;
	}
	.\39\37\/120--mobile-down {
		width: 80.83333%;
	}
	.\39\39\/120--mobile-down {
		width: 82.5%;
	}
	.\31\30\31\/120--mobile-down {
		width: 84.16667%;
	}
	.\31\30\33\/120--mobile-down {
		width: 85.83333%;
	}
	.\31\30\37\/120--mobile-down {
		width: 89.16667%;
	}
	.\31\30\39\/120--mobile-down {
		width: 90.83333%;
	}
	.\31\31\31\/120--mobile-down {
		width: 92.5%;
	}
	.\31\31\33\/120--mobile-down {
		width: 94.16667%;
	}
	.\31\31\37\/120--mobile-down {
		width: 97.5%;
	}
	.\31\31\39\/120--mobile-down {
		width: 99.16667%;
	}
}
@media screen and (min-width: 480px) {
	.\31\/2--mobile-up,
	.\31\30\/20--mobile-up,
	.\31\32\/24--mobile-up,
	.\31\34\/28--mobile-up,
	.\32\/4--mobile-up,
	.\33\/6--mobile-up,
	.\33\30\/60--mobile-up,
	.\34\/8--mobile-up,
	.\35\/10--mobile-up,
	.\36\/12--mobile-up,
	.\36\30\/120--mobile-up,
	.\37\/14--mobile-up,
	.\38\/16--mobile-up,
	.\39\/18--mobile-up {
		width: 50%;
	}
	.\31\/3--mobile-up,
	.\32\/6--mobile-up,
	.\32\30\/60--mobile-up,
	.\33\/9--mobile-up,
	.\34\/12--mobile-up,
	.\34\30\/120--mobile-up,
	.\35\/15--mobile-up,
	.\36\/18--mobile-up,
	.\38\/24--mobile-up {
		width: 33.33333%;
	}
	.\31\30\/15--mobile-up,
	.\31\32\/18--mobile-up,
	.\31\36\/24--mobile-up,
	.\32\/3--mobile-up,
	.\34\/6--mobile-up,
	.\34\30\/60--mobile-up,
	.\36\/9--mobile-up,
	.\38\/12--mobile-up,
	.\38\30\/120--mobile-up {
		width: 66.66667%;
	}
	.\31\/4--mobile-up,
	.\31\35\/60--mobile-up,
	.\32\/8--mobile-up,
	.\33\/12--mobile-up,
	.\33\30\/120--mobile-up,
	.\34\/16--mobile-up,
	.\35\/20--mobile-up,
	.\36\/24--mobile-up,
	.\37\/28--mobile-up {
		width: 25%;
	}
	.\31\32\/16--mobile-up,
	.\31\35\/20--mobile-up,
	.\31\38\/24--mobile-up,
	.\32\31\/28--mobile-up,
	.\33\/4--mobile-up,
	.\34\35\/60--mobile-up,
	.\36\/8--mobile-up,
	.\39\/12--mobile-up,
	.\39\30\/120--mobile-up {
		width: 75%;
	}
	.\31\/5--mobile-up,
	.\31\32\/60--mobile-up,
	.\32\/10--mobile-up,
	.\32\34\/120--mobile-up,
	.\33\/15--mobile-up,
	.\34\/20--mobile-up {
		width: 20%;
	}
	.\32\/5--mobile-up,
	.\32\34\/60--mobile-up,
	.\34\/10--mobile-up,
	.\34\38\/120--mobile-up,
	.\36\/15--mobile-up,
	.\38\/20--mobile-up {
		width: 40%;
	}
	.\31\32\/20--mobile-up,
	.\33\/5--mobile-up,
	.\33\36\/60--mobile-up,
	.\36\/10--mobile-up,
	.\37\32\/120--mobile-up,
	.\39\/15--mobile-up {
		width: 60%;
	}
	.\31\32\/15--mobile-up,
	.\31\36\/20--mobile-up,
	.\34\/5--mobile-up,
	.\34\38\/60--mobile-up,
	.\38\/10--mobile-up,
	.\39\36\/120--mobile-up {
		width: 80%;
	}
	.\31\/6--mobile-up,
	.\31\30\/60--mobile-up,
	.\32\/12--mobile-up,
	.\32\30\/120--mobile-up,
	.\33\/18--mobile-up,
	.\34\/24--mobile-up {
		width: 16.66667%;
	}
	.\31\30\/12--mobile-up,
	.\31\30\30\/120--mobile-up,
	.\31\35\/18--mobile-up,
	.\32\30\/24--mobile-up,
	.\35\/6--mobile-up,
	.\35\30\/60--mobile-up {
		width: 83.33333%;
	}
	.\31\/7--mobile-up,
	.\32\/14--mobile-up,
	.\34\/28--mobile-up {
		width: 14.28571%;
	}
	.\32\/7--mobile-up,
	.\34\/14--mobile-up,
	.\38\/28--mobile-up {
		width: 28.57143%;
	}
	.\31\32\/28--mobile-up,
	.\33\/7--mobile-up,
	.\36\/14--mobile-up {
		width: 42.85714%;
	}
	.\31\36\/28--mobile-up,
	.\34\/7--mobile-up,
	.\38\/14--mobile-up {
		width: 57.14286%;
	}
	.\31\30\/14--mobile-up,
	.\32\30\/28--mobile-up,
	.\35\/7--mobile-up {
		width: 71.42857%;
	}
	.\31\32\/14--mobile-up,
	.\32\34\/28--mobile-up,
	.\36\/7--mobile-up {
		width: 85.71429%;
	}
	.\31\/8--mobile-up,
	.\31\35\/120--mobile-up,
	.\32\/16--mobile-up,
	.\33\/24--mobile-up {
		width: 12.5%;
	}
	.\33\/8--mobile-up,
	.\34\35\/120--mobile-up,
	.\36\/16--mobile-up,
	.\39\/24--mobile-up {
		width: 37.5%;
	}
	.\31\30\/16--mobile-up,
	.\31\35\/24--mobile-up,
	.\35\/8--mobile-up,
	.\37\35\/120--mobile-up {
		width: 62.5%;
	}
	.\31\30\35\/120--mobile-up,
	.\31\34\/16--mobile-up,
	.\32\31\/24--mobile-up,
	.\37\/8--mobile-up {
		width: 87.5%;
	}
	.\31\/9--mobile-up,
	.\32\/18--mobile-up {
		width: 11.11111%;
	}
	.\32\/9--mobile-up,
	.\34\/18--mobile-up {
		width: 22.22222%;
	}
	.\34\/9--mobile-up,
	.\38\/18--mobile-up {
		width: 44.44444%;
	}
	.\31\30\/18--mobile-up,
	.\35\/9--mobile-up {
		width: 55.55556%;
	}
	.\31\34\/18--mobile-up,
	.\37\/9--mobile-up {
		width: 77.77778%;
	}
	.\31\36\/18--mobile-up,
	.\38\/9--mobile-up {
		width: 88.88889%;
	}
	.\31\/10--mobile-up,
	.\31\32\/120--mobile-up,
	.\32\/20--mobile-up,
	.\36\/60--mobile-up {
		width: 10%;
	}
	.\31\38\/60--mobile-up,
	.\33\/10--mobile-up,
	.\33\36\/120--mobile-up,
	.\36\/20--mobile-up {
		width: 30%;
	}
	.\31\34\/20--mobile-up,
	.\34\32\/60--mobile-up,
	.\37\/10--mobile-up,
	.\38\34\/120--mobile-up {
		width: 70%;
	}
	.\31\30\38\/120--mobile-up,
	.\31\38\/20--mobile-up,
	.\35\34\/60--mobile-up,
	.\39\/10--mobile-up {
		width: 90%;
	}
	.\31\/11--mobile-up {
		width: 9.09091%;
	}
	.\32\/11--mobile-up {
		width: 18.18182%;
	}
	.\33\/11--mobile-up {
		width: 27.27273%;
	}
	.\34\/11--mobile-up {
		width: 36.36364%;
	}
	.\35\/11--mobile-up {
		width: 45.45455%;
	}
	.\36\/11--mobile-up {
		width: 54.54545%;
	}
	.\37\/11--mobile-up {
		width: 63.63636%;
	}
	.\38\/11--mobile-up {
		width: 72.72727%;
	}
	.\39\/11--mobile-up {
		width: 81.81818%;
	}
	.\31\30\/11--mobile-up {
		width: 90.90909%;
	}
	.\31\/12--mobile-up,
	.\31\30\/120--mobile-up,
	.\32\/24--mobile-up,
	.\35\/60--mobile-up {
		width: 8.33333%;
	}
	.\31\30\/24--mobile-up,
	.\32\35\/60--mobile-up,
	.\35\/12--mobile-up,
	.\35\30\/120--mobile-up {
		width: 41.66667%;
	}
	.\31\34\/24--mobile-up,
	.\33\35\/60--mobile-up,
	.\37\/12--mobile-up,
	.\37\30\/120--mobile-up {
		width: 58.33333%;
	}
	.\31\31\/12--mobile-up,
	.\31\31\30\/120--mobile-up,
	.\32\32\/24--mobile-up,
	.\35\35\/60--mobile-up {
		width: 91.66667%;
	}
	.\31\/13--mobile-up {
		width: 7.69231%;
	}
	.\32\/13--mobile-up {
		width: 15.38462%;
	}
	.\33\/13--mobile-up {
		width: 23.07692%;
	}
	.\34\/13--mobile-up {
		width: 30.76923%;
	}
	.\35\/13--mobile-up {
		width: 38.46154%;
	}
	.\36\/13--mobile-up {
		width: 46.15385%;
	}
	.\37\/13--mobile-up {
		width: 53.84615%;
	}
	.\38\/13--mobile-up {
		width: 61.53846%;
	}
	.\39\/13--mobile-up {
		width: 69.23077%;
	}
	.\31\30\/13--mobile-up {
		width: 76.92308%;
	}
	.\31\31\/13--mobile-up {
		width: 84.61538%;
	}
	.\31\32\/13--mobile-up {
		width: 92.30769%;
	}
	.\31\/14--mobile-up,
	.\32\/28--mobile-up {
		width: 7.14286%;
	}
	.\33\/14--mobile-up,
	.\36\/28--mobile-up {
		width: 21.42857%;
	}
	.\31\30\/28--mobile-up,
	.\35\/14--mobile-up {
		width: 35.71429%;
	}
	.\31\38\/28--mobile-up,
	.\39\/14--mobile-up {
		width: 64.28571%;
	}
	.\31\31\/14--mobile-up,
	.\32\32\/28--mobile-up {
		width: 78.57143%;
	}
	.\31\33\/14--mobile-up,
	.\32\36\/28--mobile-up {
		width: 92.85714%;
	}
	.\31\/15--mobile-up,
	.\34\/60--mobile-up,
	.\38\/120--mobile-up {
		width: 6.66667%;
	}
	.\31\36\/120--mobile-up,
	.\32\/15--mobile-up,
	.\38\/60--mobile-up {
		width: 13.33333%;
	}
	.\31\36\/60--mobile-up,
	.\33\32\/120--mobile-up,
	.\34\/15--mobile-up {
		width: 26.66667%;
	}
	.\32\38\/60--mobile-up,
	.\35\36\/120--mobile-up,
	.\37\/15--mobile-up {
		width: 46.66667%;
	}
	.\33\32\/60--mobile-up,
	.\36\34\/120--mobile-up,
	.\38\/15--mobile-up {
		width: 53.33333%;
	}
	.\31\31\/15--mobile-up,
	.\34\34\/60--mobile-up,
	.\38\38\/120--mobile-up {
		width: 73.33333%;
	}
	.\31\30\34\/120--mobile-up,
	.\31\33\/15--mobile-up,
	.\35\32\/60--mobile-up {
		width: 86.66667%;
	}
	.\31\31\32\/120--mobile-up,
	.\31\34\/15--mobile-up,
	.\35\36\/60--mobile-up {
		width: 93.33333%;
	}
	.\31\/16--mobile-up {
		width: 6.25%;
	}
	.\33\/16--mobile-up {
		width: 18.75%;
	}
	.\35\/16--mobile-up {
		width: 31.25%;
	}
	.\37\/16--mobile-up {
		width: 43.75%;
	}
	.\39\/16--mobile-up {
		width: 56.25%;
	}
	.\31\31\/16--mobile-up {
		width: 68.75%;
	}
	.\31\33\/16--mobile-up {
		width: 81.25%;
	}
	.\31\35\/16--mobile-up {
		width: 93.75%;
	}
	.\31\/17--mobile-up {
		width: 5.88235%;
	}
	.\32\/17--mobile-up {
		width: 11.76471%;
	}
	.\33\/17--mobile-up {
		width: 17.64706%;
	}
	.\34\/17--mobile-up {
		width: 23.52941%;
	}
	.\35\/17--mobile-up {
		width: 29.41176%;
	}
	.\36\/17--mobile-up {
		width: 35.29412%;
	}
	.\37\/17--mobile-up {
		width: 41.17647%;
	}
	.\38\/17--mobile-up {
		width: 47.05882%;
	}
	.\39\/17--mobile-up {
		width: 52.94118%;
	}
	.\31\30\/17--mobile-up {
		width: 58.82353%;
	}
	.\31\31\/17--mobile-up {
		width: 64.70588%;
	}
	.\31\32\/17--mobile-up {
		width: 70.58824%;
	}
	.\31\33\/17--mobile-up {
		width: 76.47059%;
	}
	.\31\34\/17--mobile-up {
		width: 82.35294%;
	}
	.\31\35\/17--mobile-up {
		width: 88.23529%;
	}
	.\31\36\/17--mobile-up {
		width: 94.11765%;
	}
	.\31\/18--mobile-up {
		width: 5.55556%;
	}
	.\35\/18--mobile-up {
		width: 27.77778%;
	}
	.\37\/18--mobile-up {
		width: 38.88889%;
	}
	.\31\31\/18--mobile-up {
		width: 61.11111%;
	}
	.\31\33\/18--mobile-up {
		width: 72.22222%;
	}
	.\31\37\/18--mobile-up {
		width: 94.44444%;
	}
	.\31\/19--mobile-up {
		width: 5.26316%;
	}
	.\32\/19--mobile-up {
		width: 10.52632%;
	}
	.\33\/19--mobile-up {
		width: 15.78947%;
	}
	.\34\/19--mobile-up {
		width: 21.05263%;
	}
	.\35\/19--mobile-up {
		width: 26.31579%;
	}
	.\36\/19--mobile-up {
		width: 31.57895%;
	}
	.\37\/19--mobile-up {
		width: 36.84211%;
	}
	.\38\/19--mobile-up {
		width: 42.10526%;
	}
	.\39\/19--mobile-up {
		width: 47.36842%;
	}
	.\31\30\/19--mobile-up {
		width: 52.63158%;
	}
	.\31\31\/19--mobile-up {
		width: 57.89474%;
	}
	.\31\32\/19--mobile-up {
		width: 63.15789%;
	}
	.\31\33\/19--mobile-up {
		width: 68.42105%;
	}
	.\31\34\/19--mobile-up {
		width: 73.68421%;
	}
	.\31\35\/19--mobile-up {
		width: 78.94737%;
	}
	.\31\36\/19--mobile-up {
		width: 84.21053%;
	}
	.\31\37\/19--mobile-up {
		width: 89.47368%;
	}
	.\31\38\/19--mobile-up {
		width: 94.73684%;
	}
	.\31\/20--mobile-up,
	.\33\/60--mobile-up,
	.\36\/120--mobile-up {
		width: 5%;
	}
	.\31\38\/120--mobile-up,
	.\33\/20--mobile-up,
	.\39\/60--mobile-up {
		width: 15%;
	}
	.\32\31\/60--mobile-up,
	.\34\32\/120--mobile-up,
	.\37\/20--mobile-up {
		width: 35%;
	}
	.\32\37\/60--mobile-up,
	.\35\34\/120--mobile-up,
	.\39\/20--mobile-up {
		width: 45%;
	}
	.\31\31\/20--mobile-up,
	.\33\33\/60--mobile-up,
	.\36\36\/120--mobile-up {
		width: 55%;
	}
	.\31\33\/20--mobile-up,
	.\33\39\/60--mobile-up,
	.\37\38\/120--mobile-up {
		width: 65%;
	}
	.\31\30\32\/120--mobile-up,
	.\31\37\/20--mobile-up,
	.\35\31\/60--mobile-up {
		width: 85%;
	}
	.\31\31\34\/120--mobile-up,
	.\31\39\/20--mobile-up,
	.\35\37\/60--mobile-up {
		width: 95%;
	}
	.\31\/24--mobile-up,
	.\35\/120--mobile-up {
		width: 4.16667%;
	}
	.\32\35\/120--mobile-up,
	.\35\/24--mobile-up {
		width: 20.83333%;
	}
	.\33\35\/120--mobile-up,
	.\37\/24--mobile-up {
		width: 29.16667%;
	}
	.\31\31\/24--mobile-up,
	.\35\35\/120--mobile-up {
		width: 45.83333%;
	}
	.\31\33\/24--mobile-up,
	.\36\35\/120--mobile-up {
		width: 54.16667%;
	}
	.\31\37\/24--mobile-up,
	.\38\35\/120--mobile-up {
		width: 70.83333%;
	}
	.\31\39\/24--mobile-up,
	.\39\35\/120--mobile-up {
		width: 79.16667%;
	}
	.\31\31\35\/120--mobile-up,
	.\32\33\/24--mobile-up {
		width: 95.83333%;
	}
	.\31\/28--mobile-up {
		width: 3.57143%;
	}
	.\33\/28--mobile-up {
		width: 10.71429%;
	}
	.\35\/28--mobile-up {
		width: 17.85714%;
	}
	.\39\/28--mobile-up {
		width: 32.14286%;
	}
	.\31\31\/28--mobile-up {
		width: 39.28571%;
	}
	.\31\33\/28--mobile-up {
		width: 46.42857%;
	}
	.\31\35\/28--mobile-up {
		width: 53.57143%;
	}
	.\31\37\/28--mobile-up {
		width: 60.71429%;
	}
	.\31\39\/28--mobile-up {
		width: 67.85714%;
	}
	.\32\33\/28--mobile-up {
		width: 82.14286%;
	}
	.\32\35\/28--mobile-up {
		width: 89.28571%;
	}
	.\32\37\/28--mobile-up {
		width: 96.42857%;
	}
	.\31\/60--mobile-up,
	.\32\/120--mobile-up {
		width: 1.66667%;
	}
	.\32\/60--mobile-up,
	.\34\/120--mobile-up {
		width: 3.33333%;
	}
	.\31\34\/120--mobile-up,
	.\37\/60--mobile-up {
		width: 11.66667%;
	}
	.\31\31\/60--mobile-up,
	.\32\32\/120--mobile-up {
		width: 18.33333%;
	}
	.\31\33\/60--mobile-up,
	.\32\36\/120--mobile-up {
		width: 21.66667%;
	}
	.\31\34\/60--mobile-up,
	.\32\38\/120--mobile-up {
		width: 23.33333%;
	}
	.\31\37\/60--mobile-up,
	.\33\34\/120--mobile-up {
		width: 28.33333%;
	}
	.\31\39\/60--mobile-up,
	.\33\38\/120--mobile-up {
		width: 31.66667%;
	}
	.\32\32\/60--mobile-up,
	.\34\34\/120--mobile-up {
		width: 36.66667%;
	}
	.\32\33\/60--mobile-up,
	.\34\36\/120--mobile-up {
		width: 38.33333%;
	}
	.\32\36\/60--mobile-up,
	.\35\32\/120--mobile-up {
		width: 43.33333%;
	}
	.\32\39\/60--mobile-up,
	.\35\38\/120--mobile-up {
		width: 48.33333%;
	}
	.\33\31\/60--mobile-up,
	.\36\32\/120--mobile-up {
		width: 51.66667%;
	}
	.\33\34\/60--mobile-up,
	.\36\38\/120--mobile-up {
		width: 56.66667%;
	}
	.\33\37\/60--mobile-up,
	.\37\34\/120--mobile-up {
		width: 61.66667%;
	}
	.\33\38\/60--mobile-up,
	.\37\36\/120--mobile-up {
		width: 63.33333%;
	}
	.\34\31\/60--mobile-up,
	.\38\32\/120--mobile-up {
		width: 68.33333%;
	}
	.\34\33\/60--mobile-up,
	.\38\36\/120--mobile-up {
		width: 71.66667%;
	}
	.\34\36\/60--mobile-up,
	.\39\32\/120--mobile-up {
		width: 76.66667%;
	}
	.\34\37\/60--mobile-up,
	.\39\34\/120--mobile-up {
		width: 78.33333%;
	}
	.\34\39\/60--mobile-up,
	.\39\38\/120--mobile-up {
		width: 81.66667%;
	}
	.\31\30\36\/120--mobile-up,
	.\35\33\/60--mobile-up {
		width: 88.33333%;
	}
	.\31\31\36\/120--mobile-up,
	.\35\38\/60--mobile-up {
		width: 96.66667%;
	}
	.\31\31\38\/120--mobile-up,
	.\35\39\/60--mobile-up {
		width: 98.33333%;
	}
	.\31\/120--mobile-up {
		width: 0.83333%;
	}
	.\33\/120--mobile-up {
		width: 2.5%;
	}
	.\37\/120--mobile-up {
		width: 5.83333%;
	}
	.\39\/120--mobile-up {
		width: 7.5%;
	}
	.\31\31\/120--mobile-up {
		width: 9.16667%;
	}
	.\31\33\/120--mobile-up {
		width: 10.83333%;
	}
	.\31\37\/120--mobile-up {
		width: 14.16667%;
	}
	.\31\39\/120--mobile-up {
		width: 15.83333%;
	}
	.\32\31\/120--mobile-up {
		width: 17.5%;
	}
	.\32\33\/120--mobile-up {
		width: 19.16667%;
	}
	.\32\37\/120--mobile-up {
		width: 22.5%;
	}
	.\32\39\/120--mobile-up {
		width: 24.16667%;
	}
	.\33\31\/120--mobile-up {
		width: 25.83333%;
	}
	.\33\33\/120--mobile-up {
		width: 27.5%;
	}
	.\33\37\/120--mobile-up {
		width: 30.83333%;
	}
	.\33\39\/120--mobile-up {
		width: 32.5%;
	}
	.\34\31\/120--mobile-up {
		width: 34.16667%;
	}
	.\34\33\/120--mobile-up {
		width: 35.83333%;
	}
	.\34\37\/120--mobile-up {
		width: 39.16667%;
	}
	.\34\39\/120--mobile-up {
		width: 40.83333%;
	}
	.\35\31\/120--mobile-up {
		width: 42.5%;
	}
	.\35\33\/120--mobile-up {
		width: 44.16667%;
	}
	.\35\37\/120--mobile-up {
		width: 47.5%;
	}
	.\35\39\/120--mobile-up {
		width: 49.16667%;
	}
	.\36\31\/120--mobile-up {
		width: 50.83333%;
	}
	.\36\33\/120--mobile-up {
		width: 52.5%;
	}
	.\36\37\/120--mobile-up {
		width: 55.83333%;
	}
	.\36\39\/120--mobile-up {
		width: 57.5%;
	}
	.\37\31\/120--mobile-up {
		width: 59.16667%;
	}
	.\37\33\/120--mobile-up {
		width: 60.83333%;
	}
	.\37\37\/120--mobile-up {
		width: 64.16667%;
	}
	.\37\39\/120--mobile-up {
		width: 65.83333%;
	}
	.\38\31\/120--mobile-up {
		width: 67.5%;
	}
	.\38\33\/120--mobile-up {
		width: 69.16667%;
	}
	.\38\37\/120--mobile-up {
		width: 72.5%;
	}
	.\38\39\/120--mobile-up {
		width: 74.16667%;
	}
	.\39\31\/120--mobile-up {
		width: 75.83333%;
	}
	.\39\33\/120--mobile-up {
		width: 77.5%;
	}
	.\39\37\/120--mobile-up {
		width: 80.83333%;
	}
	.\39\39\/120--mobile-up {
		width: 82.5%;
	}
	.\31\30\31\/120--mobile-up {
		width: 84.16667%;
	}
	.\31\30\33\/120--mobile-up {
		width: 85.83333%;
	}
	.\31\30\37\/120--mobile-up {
		width: 89.16667%;
	}
	.\31\30\39\/120--mobile-up {
		width: 90.83333%;
	}
	.\31\31\31\/120--mobile-up {
		width: 92.5%;
	}
	.\31\31\33\/120--mobile-up {
		width: 94.16667%;
	}
	.\31\31\37\/120--mobile-up {
		width: 97.5%;
	}
	.\31\31\39\/120--mobile-up {
		width: 99.16667%;
	}
}
@media screen and (max-width: 767px) {
	.\31\/2--tablet-down,
	.\31\30\/20--tablet-down,
	.\31\32\/24--tablet-down,
	.\31\34\/28--tablet-down,
	.\32\/4--tablet-down,
	.\33\/6--tablet-down,
	.\33\30\/60--tablet-down,
	.\34\/8--tablet-down,
	.\35\/10--tablet-down,
	.\36\/12--tablet-down,
	.\36\30\/120--tablet-down,
	.\37\/14--tablet-down,
	.\38\/16--tablet-down,
	.\39\/18--tablet-down {
		width: 50%;
	}
	.\31\/3--tablet-down,
	.\32\/6--tablet-down,
	.\32\30\/60--tablet-down,
	.\33\/9--tablet-down,
	.\34\/12--tablet-down,
	.\34\30\/120--tablet-down,
	.\35\/15--tablet-down,
	.\36\/18--tablet-down,
	.\38\/24--tablet-down {
		width: 33.33333%;
	}
	.\31\30\/15--tablet-down,
	.\31\32\/18--tablet-down,
	.\31\36\/24--tablet-down,
	.\32\/3--tablet-down,
	.\34\/6--tablet-down,
	.\34\30\/60--tablet-down,
	.\36\/9--tablet-down,
	.\38\/12--tablet-down,
	.\38\30\/120--tablet-down {
		width: 66.66667%;
	}
	.\31\/4--tablet-down,
	.\31\35\/60--tablet-down,
	.\32\/8--tablet-down,
	.\33\/12--tablet-down,
	.\33\30\/120--tablet-down,
	.\34\/16--tablet-down,
	.\35\/20--tablet-down,
	.\36\/24--tablet-down,
	.\37\/28--tablet-down {
		width: 25%;
	}
	.\31\32\/16--tablet-down,
	.\31\35\/20--tablet-down,
	.\31\38\/24--tablet-down,
	.\32\31\/28--tablet-down,
	.\33\/4--tablet-down,
	.\34\35\/60--tablet-down,
	.\36\/8--tablet-down,
	.\39\/12--tablet-down,
	.\39\30\/120--tablet-down {
		width: 75%;
	}
	.\31\/5--tablet-down,
	.\31\32\/60--tablet-down,
	.\32\/10--tablet-down,
	.\32\34\/120--tablet-down,
	.\33\/15--tablet-down,
	.\34\/20--tablet-down {
		width: 20%;
	}
	.\32\/5--tablet-down,
	.\32\34\/60--tablet-down,
	.\34\/10--tablet-down,
	.\34\38\/120--tablet-down,
	.\36\/15--tablet-down,
	.\38\/20--tablet-down {
		width: 40%;
	}
	.\31\32\/20--tablet-down,
	.\33\/5--tablet-down,
	.\33\36\/60--tablet-down,
	.\36\/10--tablet-down,
	.\37\32\/120--tablet-down,
	.\39\/15--tablet-down {
		width: 60%;
	}
	.\31\32\/15--tablet-down,
	.\31\36\/20--tablet-down,
	.\34\/5--tablet-down,
	.\34\38\/60--tablet-down,
	.\38\/10--tablet-down,
	.\39\36\/120--tablet-down {
		width: 80%;
	}
	.\31\/6--tablet-down,
	.\31\30\/60--tablet-down,
	.\32\/12--tablet-down,
	.\32\30\/120--tablet-down,
	.\33\/18--tablet-down,
	.\34\/24--tablet-down {
		width: 16.66667%;
	}
	.\31\30\/12--tablet-down,
	.\31\30\30\/120--tablet-down,
	.\31\35\/18--tablet-down,
	.\32\30\/24--tablet-down,
	.\35\/6--tablet-down,
	.\35\30\/60--tablet-down {
		width: 83.33333%;
	}
	.\31\/7--tablet-down,
	.\32\/14--tablet-down,
	.\34\/28--tablet-down {
		width: 14.28571%;
	}
	.\32\/7--tablet-down,
	.\34\/14--tablet-down,
	.\38\/28--tablet-down {
		width: 28.57143%;
	}
	.\31\32\/28--tablet-down,
	.\33\/7--tablet-down,
	.\36\/14--tablet-down {
		width: 42.85714%;
	}
	.\31\36\/28--tablet-down,
	.\34\/7--tablet-down,
	.\38\/14--tablet-down {
		width: 57.14286%;
	}
	.\31\30\/14--tablet-down,
	.\32\30\/28--tablet-down,
	.\35\/7--tablet-down {
		width: 71.42857%;
	}
	.\31\32\/14--tablet-down,
	.\32\34\/28--tablet-down,
	.\36\/7--tablet-down {
		width: 85.71429%;
	}
	.\31\/8--tablet-down,
	.\31\35\/120--tablet-down,
	.\32\/16--tablet-down,
	.\33\/24--tablet-down {
		width: 12.5%;
	}
	.\33\/8--tablet-down,
	.\34\35\/120--tablet-down,
	.\36\/16--tablet-down,
	.\39\/24--tablet-down {
		width: 37.5%;
	}
	.\31\30\/16--tablet-down,
	.\31\35\/24--tablet-down,
	.\35\/8--tablet-down,
	.\37\35\/120--tablet-down {
		width: 62.5%;
	}
	.\31\30\35\/120--tablet-down,
	.\31\34\/16--tablet-down,
	.\32\31\/24--tablet-down,
	.\37\/8--tablet-down {
		width: 87.5%;
	}
	.\31\/9--tablet-down,
	.\32\/18--tablet-down {
		width: 11.11111%;
	}
	.\32\/9--tablet-down,
	.\34\/18--tablet-down {
		width: 22.22222%;
	}
	.\34\/9--tablet-down,
	.\38\/18--tablet-down {
		width: 44.44444%;
	}
	.\31\30\/18--tablet-down,
	.\35\/9--tablet-down {
		width: 55.55556%;
	}
	.\31\34\/18--tablet-down,
	.\37\/9--tablet-down {
		width: 77.77778%;
	}
	.\31\36\/18--tablet-down,
	.\38\/9--tablet-down {
		width: 88.88889%;
	}
	.\31\/10--tablet-down,
	.\31\32\/120--tablet-down,
	.\32\/20--tablet-down,
	.\36\/60--tablet-down {
		width: 10%;
	}
	.\31\38\/60--tablet-down,
	.\33\/10--tablet-down,
	.\33\36\/120--tablet-down,
	.\36\/20--tablet-down {
		width: 30%;
	}
	.\31\34\/20--tablet-down,
	.\34\32\/60--tablet-down,
	.\37\/10--tablet-down,
	.\38\34\/120--tablet-down {
		width: 70%;
	}
	.\31\30\38\/120--tablet-down,
	.\31\38\/20--tablet-down,
	.\35\34\/60--tablet-down,
	.\39\/10--tablet-down {
		width: 90%;
	}
	.\31\/11--tablet-down {
		width: 9.09091%;
	}
	.\32\/11--tablet-down {
		width: 18.18182%;
	}
	.\33\/11--tablet-down {
		width: 27.27273%;
	}
	.\34\/11--tablet-down {
		width: 36.36364%;
	}
	.\35\/11--tablet-down {
		width: 45.45455%;
	}
	.\36\/11--tablet-down {
		width: 54.54545%;
	}
	.\37\/11--tablet-down {
		width: 63.63636%;
	}
	.\38\/11--tablet-down {
		width: 72.72727%;
	}
	.\39\/11--tablet-down {
		width: 81.81818%;
	}
	.\31\30\/11--tablet-down {
		width: 90.90909%;
	}
	.\31\/12--tablet-down,
	.\31\30\/120--tablet-down,
	.\32\/24--tablet-down,
	.\35\/60--tablet-down {
		width: 8.33333%;
	}
	.\31\30\/24--tablet-down,
	.\32\35\/60--tablet-down,
	.\35\/12--tablet-down,
	.\35\30\/120--tablet-down {
		width: 41.66667%;
	}
	.\31\34\/24--tablet-down,
	.\33\35\/60--tablet-down,
	.\37\/12--tablet-down,
	.\37\30\/120--tablet-down {
		width: 58.33333%;
	}
	.\31\31\/12--tablet-down,
	.\31\31\30\/120--tablet-down,
	.\32\32\/24--tablet-down,
	.\35\35\/60--tablet-down {
		width: 91.66667%;
	}
	.\31\/13--tablet-down {
		width: 7.69231%;
	}
	.\32\/13--tablet-down {
		width: 15.38462%;
	}
	.\33\/13--tablet-down {
		width: 23.07692%;
	}
	.\34\/13--tablet-down {
		width: 30.76923%;
	}
	.\35\/13--tablet-down {
		width: 38.46154%;
	}
	.\36\/13--tablet-down {
		width: 46.15385%;
	}
	.\37\/13--tablet-down {
		width: 53.84615%;
	}
	.\38\/13--tablet-down {
		width: 61.53846%;
	}
	.\39\/13--tablet-down {
		width: 69.23077%;
	}
	.\31\30\/13--tablet-down {
		width: 76.92308%;
	}
	.\31\31\/13--tablet-down {
		width: 84.61538%;
	}
	.\31\32\/13--tablet-down {
		width: 92.30769%;
	}
	.\31\/14--tablet-down,
	.\32\/28--tablet-down {
		width: 7.14286%;
	}
	.\33\/14--tablet-down,
	.\36\/28--tablet-down {
		width: 21.42857%;
	}
	.\31\30\/28--tablet-down,
	.\35\/14--tablet-down {
		width: 35.71429%;
	}
	.\31\38\/28--tablet-down,
	.\39\/14--tablet-down {
		width: 64.28571%;
	}
	.\31\31\/14--tablet-down,
	.\32\32\/28--tablet-down {
		width: 78.57143%;
	}
	.\31\33\/14--tablet-down,
	.\32\36\/28--tablet-down {
		width: 92.85714%;
	}
	.\31\/15--tablet-down,
	.\34\/60--tablet-down,
	.\38\/120--tablet-down {
		width: 6.66667%;
	}
	.\31\36\/120--tablet-down,
	.\32\/15--tablet-down,
	.\38\/60--tablet-down {
		width: 13.33333%;
	}
	.\31\36\/60--tablet-down,
	.\33\32\/120--tablet-down,
	.\34\/15--tablet-down {
		width: 26.66667%;
	}
	.\32\38\/60--tablet-down,
	.\35\36\/120--tablet-down,
	.\37\/15--tablet-down {
		width: 46.66667%;
	}
	.\33\32\/60--tablet-down,
	.\36\34\/120--tablet-down,
	.\38\/15--tablet-down {
		width: 53.33333%;
	}
	.\31\31\/15--tablet-down,
	.\34\34\/60--tablet-down,
	.\38\38\/120--tablet-down {
		width: 73.33333%;
	}
	.\31\30\34\/120--tablet-down,
	.\31\33\/15--tablet-down,
	.\35\32\/60--tablet-down {
		width: 86.66667%;
	}
	.\31\31\32\/120--tablet-down,
	.\31\34\/15--tablet-down,
	.\35\36\/60--tablet-down {
		width: 93.33333%;
	}
	.\31\/16--tablet-down {
		width: 6.25%;
	}
	.\33\/16--tablet-down {
		width: 18.75%;
	}
	.\35\/16--tablet-down {
		width: 31.25%;
	}
	.\37\/16--tablet-down {
		width: 43.75%;
	}
	.\39\/16--tablet-down {
		width: 56.25%;
	}
	.\31\31\/16--tablet-down {
		width: 68.75%;
	}
	.\31\33\/16--tablet-down {
		width: 81.25%;
	}
	.\31\35\/16--tablet-down {
		width: 93.75%;
	}
	.\31\/17--tablet-down {
		width: 5.88235%;
	}
	.\32\/17--tablet-down {
		width: 11.76471%;
	}
	.\33\/17--tablet-down {
		width: 17.64706%;
	}
	.\34\/17--tablet-down {
		width: 23.52941%;
	}
	.\35\/17--tablet-down {
		width: 29.41176%;
	}
	.\36\/17--tablet-down {
		width: 35.29412%;
	}
	.\37\/17--tablet-down {
		width: 41.17647%;
	}
	.\38\/17--tablet-down {
		width: 47.05882%;
	}
	.\39\/17--tablet-down {
		width: 52.94118%;
	}
	.\31\30\/17--tablet-down {
		width: 58.82353%;
	}
	.\31\31\/17--tablet-down {
		width: 64.70588%;
	}
	.\31\32\/17--tablet-down {
		width: 70.58824%;
	}
	.\31\33\/17--tablet-down {
		width: 76.47059%;
	}
	.\31\34\/17--tablet-down {
		width: 82.35294%;
	}
	.\31\35\/17--tablet-down {
		width: 88.23529%;
	}
	.\31\36\/17--tablet-down {
		width: 94.11765%;
	}
	.\31\/18--tablet-down {
		width: 5.55556%;
	}
	.\35\/18--tablet-down {
		width: 27.77778%;
	}
	.\37\/18--tablet-down {
		width: 38.88889%;
	}
	.\31\31\/18--tablet-down {
		width: 61.11111%;
	}
	.\31\33\/18--tablet-down {
		width: 72.22222%;
	}
	.\31\37\/18--tablet-down {
		width: 94.44444%;
	}
	.\31\/19--tablet-down {
		width: 5.26316%;
	}
	.\32\/19--tablet-down {
		width: 10.52632%;
	}
	.\33\/19--tablet-down {
		width: 15.78947%;
	}
	.\34\/19--tablet-down {
		width: 21.05263%;
	}
	.\35\/19--tablet-down {
		width: 26.31579%;
	}
	.\36\/19--tablet-down {
		width: 31.57895%;
	}
	.\37\/19--tablet-down {
		width: 36.84211%;
	}
	.\38\/19--tablet-down {
		width: 42.10526%;
	}
	.\39\/19--tablet-down {
		width: 47.36842%;
	}
	.\31\30\/19--tablet-down {
		width: 52.63158%;
	}
	.\31\31\/19--tablet-down {
		width: 57.89474%;
	}
	.\31\32\/19--tablet-down {
		width: 63.15789%;
	}
	.\31\33\/19--tablet-down {
		width: 68.42105%;
	}
	.\31\34\/19--tablet-down {
		width: 73.68421%;
	}
	.\31\35\/19--tablet-down {
		width: 78.94737%;
	}
	.\31\36\/19--tablet-down {
		width: 84.21053%;
	}
	.\31\37\/19--tablet-down {
		width: 89.47368%;
	}
	.\31\38\/19--tablet-down {
		width: 94.73684%;
	}
	.\31\/20--tablet-down,
	.\33\/60--tablet-down,
	.\36\/120--tablet-down {
		width: 5%;
	}
	.\31\38\/120--tablet-down,
	.\33\/20--tablet-down,
	.\39\/60--tablet-down {
		width: 15%;
	}
	.\32\31\/60--tablet-down,
	.\34\32\/120--tablet-down,
	.\37\/20--tablet-down {
		width: 35%;
	}
	.\32\37\/60--tablet-down,
	.\35\34\/120--tablet-down,
	.\39\/20--tablet-down {
		width: 45%;
	}
	.\31\31\/20--tablet-down,
	.\33\33\/60--tablet-down,
	.\36\36\/120--tablet-down {
		width: 55%;
	}
	.\31\33\/20--tablet-down,
	.\33\39\/60--tablet-down,
	.\37\38\/120--tablet-down {
		width: 65%;
	}
	.\31\30\32\/120--tablet-down,
	.\31\37\/20--tablet-down,
	.\35\31\/60--tablet-down {
		width: 85%;
	}
	.\31\31\34\/120--tablet-down,
	.\31\39\/20--tablet-down,
	.\35\37\/60--tablet-down {
		width: 95%;
	}
	.\31\/24--tablet-down,
	.\35\/120--tablet-down {
		width: 4.16667%;
	}
	.\32\35\/120--tablet-down,
	.\35\/24--tablet-down {
		width: 20.83333%;
	}
	.\33\35\/120--tablet-down,
	.\37\/24--tablet-down {
		width: 29.16667%;
	}
	.\31\31\/24--tablet-down,
	.\35\35\/120--tablet-down {
		width: 45.83333%;
	}
	.\31\33\/24--tablet-down,
	.\36\35\/120--tablet-down {
		width: 54.16667%;
	}
	.\31\37\/24--tablet-down,
	.\38\35\/120--tablet-down {
		width: 70.83333%;
	}
	.\31\39\/24--tablet-down,
	.\39\35\/120--tablet-down {
		width: 79.16667%;
	}
	.\31\31\35\/120--tablet-down,
	.\32\33\/24--tablet-down {
		width: 95.83333%;
	}
	.\31\/28--tablet-down {
		width: 3.57143%;
	}
	.\33\/28--tablet-down {
		width: 10.71429%;
	}
	.\35\/28--tablet-down {
		width: 17.85714%;
	}
	.\39\/28--tablet-down {
		width: 32.14286%;
	}
	.\31\31\/28--tablet-down {
		width: 39.28571%;
	}
	.\31\33\/28--tablet-down {
		width: 46.42857%;
	}
	.\31\35\/28--tablet-down {
		width: 53.57143%;
	}
	.\31\37\/28--tablet-down {
		width: 60.71429%;
	}
	.\31\39\/28--tablet-down {
		width: 67.85714%;
	}
	.\32\33\/28--tablet-down {
		width: 82.14286%;
	}
	.\32\35\/28--tablet-down {
		width: 89.28571%;
	}
	.\32\37\/28--tablet-down {
		width: 96.42857%;
	}
	.\31\/60--tablet-down,
	.\32\/120--tablet-down {
		width: 1.66667%;
	}
	.\32\/60--tablet-down,
	.\34\/120--tablet-down {
		width: 3.33333%;
	}
	.\31\34\/120--tablet-down,
	.\37\/60--tablet-down {
		width: 11.66667%;
	}
	.\31\31\/60--tablet-down,
	.\32\32\/120--tablet-down {
		width: 18.33333%;
	}
	.\31\33\/60--tablet-down,
	.\32\36\/120--tablet-down {
		width: 21.66667%;
	}
	.\31\34\/60--tablet-down,
	.\32\38\/120--tablet-down {
		width: 23.33333%;
	}
	.\31\37\/60--tablet-down,
	.\33\34\/120--tablet-down {
		width: 28.33333%;
	}
	.\31\39\/60--tablet-down,
	.\33\38\/120--tablet-down {
		width: 31.66667%;
	}
	.\32\32\/60--tablet-down,
	.\34\34\/120--tablet-down {
		width: 36.66667%;
	}
	.\32\33\/60--tablet-down,
	.\34\36\/120--tablet-down {
		width: 38.33333%;
	}
	.\32\36\/60--tablet-down,
	.\35\32\/120--tablet-down {
		width: 43.33333%;
	}
	.\32\39\/60--tablet-down,
	.\35\38\/120--tablet-down {
		width: 48.33333%;
	}
	.\33\31\/60--tablet-down,
	.\36\32\/120--tablet-down {
		width: 51.66667%;
	}
	.\33\34\/60--tablet-down,
	.\36\38\/120--tablet-down {
		width: 56.66667%;
	}
	.\33\37\/60--tablet-down,
	.\37\34\/120--tablet-down {
		width: 61.66667%;
	}
	.\33\38\/60--tablet-down,
	.\37\36\/120--tablet-down {
		width: 63.33333%;
	}
	.\34\31\/60--tablet-down,
	.\38\32\/120--tablet-down {
		width: 68.33333%;
	}
	.\34\33\/60--tablet-down,
	.\38\36\/120--tablet-down {
		width: 71.66667%;
	}
	.\34\36\/60--tablet-down,
	.\39\32\/120--tablet-down {
		width: 76.66667%;
	}
	.\34\37\/60--tablet-down,
	.\39\34\/120--tablet-down {
		width: 78.33333%;
	}
	.\34\39\/60--tablet-down,
	.\39\38\/120--tablet-down {
		width: 81.66667%;
	}
	.\31\30\36\/120--tablet-down,
	.\35\33\/60--tablet-down {
		width: 88.33333%;
	}
	.\31\31\36\/120--tablet-down,
	.\35\38\/60--tablet-down {
		width: 96.66667%;
	}
	.\31\31\38\/120--tablet-down,
	.\35\39\/60--tablet-down {
		width: 98.33333%;
	}
	.\31\/120--tablet-down {
		width: 0.83333%;
	}
	.\33\/120--tablet-down {
		width: 2.5%;
	}
	.\37\/120--tablet-down {
		width: 5.83333%;
	}
	.\39\/120--tablet-down {
		width: 7.5%;
	}
	.\31\31\/120--tablet-down {
		width: 9.16667%;
	}
	.\31\33\/120--tablet-down {
		width: 10.83333%;
	}
	.\31\37\/120--tablet-down {
		width: 14.16667%;
	}
	.\31\39\/120--tablet-down {
		width: 15.83333%;
	}
	.\32\31\/120--tablet-down {
		width: 17.5%;
	}
	.\32\33\/120--tablet-down {
		width: 19.16667%;
	}
	.\32\37\/120--tablet-down {
		width: 22.5%;
	}
	.\32\39\/120--tablet-down {
		width: 24.16667%;
	}
	.\33\31\/120--tablet-down {
		width: 25.83333%;
	}
	.\33\33\/120--tablet-down {
		width: 27.5%;
	}
	.\33\37\/120--tablet-down {
		width: 30.83333%;
	}
	.\33\39\/120--tablet-down {
		width: 32.5%;
	}
	.\34\31\/120--tablet-down {
		width: 34.16667%;
	}
	.\34\33\/120--tablet-down {
		width: 35.83333%;
	}
	.\34\37\/120--tablet-down {
		width: 39.16667%;
	}
	.\34\39\/120--tablet-down {
		width: 40.83333%;
	}
	.\35\31\/120--tablet-down {
		width: 42.5%;
	}
	.\35\33\/120--tablet-down {
		width: 44.16667%;
	}
	.\35\37\/120--tablet-down {
		width: 47.5%;
	}
	.\35\39\/120--tablet-down {
		width: 49.16667%;
	}
	.\36\31\/120--tablet-down {
		width: 50.83333%;
	}
	.\36\33\/120--tablet-down {
		width: 52.5%;
	}
	.\36\37\/120--tablet-down {
		width: 55.83333%;
	}
	.\36\39\/120--tablet-down {
		width: 57.5%;
	}
	.\37\31\/120--tablet-down {
		width: 59.16667%;
	}
	.\37\33\/120--tablet-down {
		width: 60.83333%;
	}
	.\37\37\/120--tablet-down {
		width: 64.16667%;
	}
	.\37\39\/120--tablet-down {
		width: 65.83333%;
	}
	.\38\31\/120--tablet-down {
		width: 67.5%;
	}
	.\38\33\/120--tablet-down {
		width: 69.16667%;
	}
	.\38\37\/120--tablet-down {
		width: 72.5%;
	}
	.\38\39\/120--tablet-down {
		width: 74.16667%;
	}
	.\39\31\/120--tablet-down {
		width: 75.83333%;
	}
	.\39\33\/120--tablet-down {
		width: 77.5%;
	}
	.\39\37\/120--tablet-down {
		width: 80.83333%;
	}
	.\39\39\/120--tablet-down {
		width: 82.5%;
	}
	.\31\30\31\/120--tablet-down {
		width: 84.16667%;
	}
	.\31\30\33\/120--tablet-down {
		width: 85.83333%;
	}
	.\31\30\37\/120--tablet-down {
		width: 89.16667%;
	}
	.\31\30\39\/120--tablet-down {
		width: 90.83333%;
	}
	.\31\31\31\/120--tablet-down {
		width: 92.5%;
	}
	.\31\31\33\/120--tablet-down {
		width: 94.16667%;
	}
	.\31\31\37\/120--tablet-down {
		width: 97.5%;
	}
	.\31\31\39\/120--tablet-down {
		width: 99.16667%;
	}
}
@media screen and (min-width: 768px) {
	.\31\/2--tablet-up,
	.\31\30\/20--tablet-up,
	.\31\32\/24--tablet-up,
	.\31\34\/28--tablet-up,
	.\32\/4--tablet-up,
	.\33\/6--tablet-up,
	.\33\30\/60--tablet-up,
	.\34\/8--tablet-up,
	.\35\/10--tablet-up,
	.\36\/12--tablet-up,
	.\36\30\/120--tablet-up,
	.\37\/14--tablet-up,
	.\38\/16--tablet-up,
	.\39\/18--tablet-up {
		width: 50%;
	}
	.\31\/3--tablet-up,
	.\32\/6--tablet-up,
	.\32\30\/60--tablet-up,
	.\33\/9--tablet-up,
	.\34\/12--tablet-up,
	.\34\30\/120--tablet-up,
	.\35\/15--tablet-up,
	.\36\/18--tablet-up,
	.\38\/24--tablet-up {
		width: 33.33333%;
	}
	.\31\30\/15--tablet-up,
	.\31\32\/18--tablet-up,
	.\31\36\/24--tablet-up,
	.\32\/3--tablet-up,
	.\34\/6--tablet-up,
	.\34\30\/60--tablet-up,
	.\36\/9--tablet-up,
	.\38\/12--tablet-up,
	.\38\30\/120--tablet-up {
		width: 66.66667%;
	}
	.\31\/4--tablet-up,
	.\31\35\/60--tablet-up,
	.\32\/8--tablet-up,
	.\33\/12--tablet-up,
	.\33\30\/120--tablet-up,
	.\34\/16--tablet-up,
	.\35\/20--tablet-up,
	.\36\/24--tablet-up,
	.\37\/28--tablet-up {
		width: 25%;
	}
	.\31\32\/16--tablet-up,
	.\31\35\/20--tablet-up,
	.\31\38\/24--tablet-up,
	.\32\31\/28--tablet-up,
	.\33\/4--tablet-up,
	.\34\35\/60--tablet-up,
	.\36\/8--tablet-up,
	.\39\/12--tablet-up,
	.\39\30\/120--tablet-up {
		width: 75%;
	}
	.\31\/5--tablet-up,
	.\31\32\/60--tablet-up,
	.\32\/10--tablet-up,
	.\32\34\/120--tablet-up,
	.\33\/15--tablet-up,
	.\34\/20--tablet-up {
		width: 20%;
	}
	.\32\/5--tablet-up,
	.\32\34\/60--tablet-up,
	.\34\/10--tablet-up,
	.\34\38\/120--tablet-up,
	.\36\/15--tablet-up,
	.\38\/20--tablet-up {
		width: 40%;
	}
	.\31\32\/20--tablet-up,
	.\33\/5--tablet-up,
	.\33\36\/60--tablet-up,
	.\36\/10--tablet-up,
	.\37\32\/120--tablet-up,
	.\39\/15--tablet-up {
		width: 60%;
	}
	.\31\32\/15--tablet-up,
	.\31\36\/20--tablet-up,
	.\34\/5--tablet-up,
	.\34\38\/60--tablet-up,
	.\38\/10--tablet-up,
	.\39\36\/120--tablet-up {
		width: 80%;
	}
	.\31\/6--tablet-up,
	.\31\30\/60--tablet-up,
	.\32\/12--tablet-up,
	.\32\30\/120--tablet-up,
	.\33\/18--tablet-up,
	.\34\/24--tablet-up {
		width: 16.66667%;
	}
	.\31\30\/12--tablet-up,
	.\31\30\30\/120--tablet-up,
	.\31\35\/18--tablet-up,
	.\32\30\/24--tablet-up,
	.\35\/6--tablet-up,
	.\35\30\/60--tablet-up {
		width: 83.33333%;
	}
	.\31\/7--tablet-up,
	.\32\/14--tablet-up,
	.\34\/28--tablet-up {
		width: 14.28571%;
	}
	.\32\/7--tablet-up,
	.\34\/14--tablet-up,
	.\38\/28--tablet-up {
		width: 28.57143%;
	}
	.\31\32\/28--tablet-up,
	.\33\/7--tablet-up,
	.\36\/14--tablet-up {
		width: 42.85714%;
	}
	.\31\36\/28--tablet-up,
	.\34\/7--tablet-up,
	.\38\/14--tablet-up {
		width: 57.14286%;
	}
	.\31\30\/14--tablet-up,
	.\32\30\/28--tablet-up,
	.\35\/7--tablet-up {
		width: 71.42857%;
	}
	.\31\32\/14--tablet-up,
	.\32\34\/28--tablet-up,
	.\36\/7--tablet-up {
		width: 85.71429%;
	}
	.\31\/8--tablet-up,
	.\31\35\/120--tablet-up,
	.\32\/16--tablet-up,
	.\33\/24--tablet-up {
		width: 12.5%;
	}
	.\33\/8--tablet-up,
	.\34\35\/120--tablet-up,
	.\36\/16--tablet-up,
	.\39\/24--tablet-up {
		width: 37.5%;
	}
	.\31\30\/16--tablet-up,
	.\31\35\/24--tablet-up,
	.\35\/8--tablet-up,
	.\37\35\/120--tablet-up {
		width: 62.5%;
	}
	.\31\30\35\/120--tablet-up,
	.\31\34\/16--tablet-up,
	.\32\31\/24--tablet-up,
	.\37\/8--tablet-up {
		width: 87.5%;
	}
	.\31\/9--tablet-up,
	.\32\/18--tablet-up {
		width: 11.11111%;
	}
	.\32\/9--tablet-up,
	.\34\/18--tablet-up {
		width: 22.22222%;
	}
	.\34\/9--tablet-up,
	.\38\/18--tablet-up {
		width: 44.44444%;
	}
	.\31\30\/18--tablet-up,
	.\35\/9--tablet-up {
		width: 55.55556%;
	}
	.\31\34\/18--tablet-up,
	.\37\/9--tablet-up {
		width: 77.77778%;
	}
	.\31\36\/18--tablet-up,
	.\38\/9--tablet-up {
		width: 88.88889%;
	}
	.\31\/10--tablet-up,
	.\31\32\/120--tablet-up,
	.\32\/20--tablet-up,
	.\36\/60--tablet-up {
		width: 10%;
	}
	.\31\38\/60--tablet-up,
	.\33\/10--tablet-up,
	.\33\36\/120--tablet-up,
	.\36\/20--tablet-up {
		width: 30%;
	}
	.\31\34\/20--tablet-up,
	.\34\32\/60--tablet-up,
	.\37\/10--tablet-up,
	.\38\34\/120--tablet-up {
		width: 70%;
	}
	.\31\30\38\/120--tablet-up,
	.\31\38\/20--tablet-up,
	.\35\34\/60--tablet-up,
	.\39\/10--tablet-up {
		width: 90%;
	}
	.\31\/11--tablet-up {
		width: 9.09091%;
	}
	.\32\/11--tablet-up {
		width: 18.18182%;
	}
	.\33\/11--tablet-up {
		width: 27.27273%;
	}
	.\34\/11--tablet-up {
		width: 36.36364%;
	}
	.\35\/11--tablet-up {
		width: 45.45455%;
	}
	.\36\/11--tablet-up {
		width: 54.54545%;
	}
	.\37\/11--tablet-up {
		width: 63.63636%;
	}
	.\38\/11--tablet-up {
		width: 72.72727%;
	}
	.\39\/11--tablet-up {
		width: 81.81818%;
	}
	.\31\30\/11--tablet-up {
		width: 90.90909%;
	}
	.\31\/12--tablet-up,
	.\31\30\/120--tablet-up,
	.\32\/24--tablet-up,
	.\35\/60--tablet-up {
		width: 8.33333%;
	}
	.\31\30\/24--tablet-up,
	.\32\35\/60--tablet-up,
	.\35\/12--tablet-up,
	.\35\30\/120--tablet-up {
		width: 41.66667%;
	}
	.\31\34\/24--tablet-up,
	.\33\35\/60--tablet-up,
	.\37\/12--tablet-up,
	.\37\30\/120--tablet-up {
		width: 58.33333%;
	}
	.\31\31\/12--tablet-up,
	.\31\31\30\/120--tablet-up,
	.\32\32\/24--tablet-up,
	.\35\35\/60--tablet-up {
		width: 91.66667%;
	}
	.\31\/13--tablet-up {
		width: 7.69231%;
	}
	.\32\/13--tablet-up {
		width: 15.38462%;
	}
	.\33\/13--tablet-up {
		width: 23.07692%;
	}
	.\34\/13--tablet-up {
		width: 30.76923%;
	}
	.\35\/13--tablet-up {
		width: 38.46154%;
	}
	.\36\/13--tablet-up {
		width: 46.15385%;
	}
	.\37\/13--tablet-up {
		width: 53.84615%;
	}
	.\38\/13--tablet-up {
		width: 61.53846%;
	}
	.\39\/13--tablet-up {
		width: 69.23077%;
	}
	.\31\30\/13--tablet-up {
		width: 76.92308%;
	}
	.\31\31\/13--tablet-up {
		width: 84.61538%;
	}
	.\31\32\/13--tablet-up {
		width: 92.30769%;
	}
	.\31\/14--tablet-up,
	.\32\/28--tablet-up {
		width: 7.14286%;
	}
	.\33\/14--tablet-up,
	.\36\/28--tablet-up {
		width: 21.42857%;
	}
	.\31\30\/28--tablet-up,
	.\35\/14--tablet-up {
		width: 35.71429%;
	}
	.\31\38\/28--tablet-up,
	.\39\/14--tablet-up {
		width: 64.28571%;
	}
	.\31\31\/14--tablet-up,
	.\32\32\/28--tablet-up {
		width: 78.57143%;
	}
	.\31\33\/14--tablet-up,
	.\32\36\/28--tablet-up {
		width: 92.85714%;
	}
	.\31\/15--tablet-up,
	.\34\/60--tablet-up,
	.\38\/120--tablet-up {
		width: 6.66667%;
	}
	.\31\36\/120--tablet-up,
	.\32\/15--tablet-up,
	.\38\/60--tablet-up {
		width: 13.33333%;
	}
	.\31\36\/60--tablet-up,
	.\33\32\/120--tablet-up,
	.\34\/15--tablet-up {
		width: 26.66667%;
	}
	.\32\38\/60--tablet-up,
	.\35\36\/120--tablet-up,
	.\37\/15--tablet-up {
		width: 46.66667%;
	}
	.\33\32\/60--tablet-up,
	.\36\34\/120--tablet-up,
	.\38\/15--tablet-up {
		width: 53.33333%;
	}
	.\31\31\/15--tablet-up,
	.\34\34\/60--tablet-up,
	.\38\38\/120--tablet-up {
		width: 73.33333%;
	}
	.\31\30\34\/120--tablet-up,
	.\31\33\/15--tablet-up,
	.\35\32\/60--tablet-up {
		width: 86.66667%;
	}
	.\31\31\32\/120--tablet-up,
	.\31\34\/15--tablet-up,
	.\35\36\/60--tablet-up {
		width: 93.33333%;
	}
	.\31\/16--tablet-up {
		width: 6.25%;
	}
	.\33\/16--tablet-up {
		width: 18.75%;
	}
	.\35\/16--tablet-up {
		width: 31.25%;
	}
	.\37\/16--tablet-up {
		width: 43.75%;
	}
	.\39\/16--tablet-up {
		width: 56.25%;
	}
	.\31\31\/16--tablet-up {
		width: 68.75%;
	}
	.\31\33\/16--tablet-up {
		width: 81.25%;
	}
	.\31\35\/16--tablet-up {
		width: 93.75%;
	}
	.\31\/17--tablet-up {
		width: 5.88235%;
	}
	.\32\/17--tablet-up {
		width: 11.76471%;
	}
	.\33\/17--tablet-up {
		width: 17.64706%;
	}
	.\34\/17--tablet-up {
		width: 23.52941%;
	}
	.\35\/17--tablet-up {
		width: 29.41176%;
	}
	.\36\/17--tablet-up {
		width: 35.29412%;
	}
	.\37\/17--tablet-up {
		width: 41.17647%;
	}
	.\38\/17--tablet-up {
		width: 47.05882%;
	}
	.\39\/17--tablet-up {
		width: 52.94118%;
	}
	.\31\30\/17--tablet-up {
		width: 58.82353%;
	}
	.\31\31\/17--tablet-up {
		width: 64.70588%;
	}
	.\31\32\/17--tablet-up {
		width: 70.58824%;
	}
	.\31\33\/17--tablet-up {
		width: 76.47059%;
	}
	.\31\34\/17--tablet-up {
		width: 82.35294%;
	}
	.\31\35\/17--tablet-up {
		width: 88.23529%;
	}
	.\31\36\/17--tablet-up {
		width: 94.11765%;
	}
	.\31\/18--tablet-up {
		width: 5.55556%;
	}
	.\35\/18--tablet-up {
		width: 27.77778%;
	}
	.\37\/18--tablet-up {
		width: 38.88889%;
	}
	.\31\31\/18--tablet-up {
		width: 61.11111%;
	}
	.\31\33\/18--tablet-up {
		width: 72.22222%;
	}
	.\31\37\/18--tablet-up {
		width: 94.44444%;
	}
	.\31\/19--tablet-up {
		width: 5.26316%;
	}
	.\32\/19--tablet-up {
		width: 10.52632%;
	}
	.\33\/19--tablet-up {
		width: 15.78947%;
	}
	.\34\/19--tablet-up {
		width: 21.05263%;
	}
	.\35\/19--tablet-up {
		width: 26.31579%;
	}
	.\36\/19--tablet-up {
		width: 31.57895%;
	}
	.\37\/19--tablet-up {
		width: 36.84211%;
	}
	.\38\/19--tablet-up {
		width: 42.10526%;
	}
	.\39\/19--tablet-up {
		width: 47.36842%;
	}
	.\31\30\/19--tablet-up {
		width: 52.63158%;
	}
	.\31\31\/19--tablet-up {
		width: 57.89474%;
	}
	.\31\32\/19--tablet-up {
		width: 63.15789%;
	}
	.\31\33\/19--tablet-up {
		width: 68.42105%;
	}
	.\31\34\/19--tablet-up {
		width: 73.68421%;
	}
	.\31\35\/19--tablet-up {
		width: 78.94737%;
	}
	.\31\36\/19--tablet-up {
		width: 84.21053%;
	}
	.\31\37\/19--tablet-up {
		width: 89.47368%;
	}
	.\31\38\/19--tablet-up {
		width: 94.73684%;
	}
	.\31\/20--tablet-up,
	.\33\/60--tablet-up,
	.\36\/120--tablet-up {
		width: 5%;
	}
	.\31\38\/120--tablet-up,
	.\33\/20--tablet-up,
	.\39\/60--tablet-up {
		width: 15%;
	}
	.\32\31\/60--tablet-up,
	.\34\32\/120--tablet-up,
	.\37\/20--tablet-up {
		width: 35%;
	}
	.\32\37\/60--tablet-up,
	.\35\34\/120--tablet-up,
	.\39\/20--tablet-up {
		width: 45%;
	}
	.\31\31\/20--tablet-up,
	.\33\33\/60--tablet-up,
	.\36\36\/120--tablet-up {
		width: 55%;
	}
	.\31\33\/20--tablet-up,
	.\33\39\/60--tablet-up,
	.\37\38\/120--tablet-up {
		width: 65%;
	}
	.\31\30\32\/120--tablet-up,
	.\31\37\/20--tablet-up,
	.\35\31\/60--tablet-up {
		width: 85%;
	}
	.\31\31\34\/120--tablet-up,
	.\31\39\/20--tablet-up,
	.\35\37\/60--tablet-up {
		width: 95%;
	}
	.\31\/24--tablet-up,
	.\35\/120--tablet-up {
		width: 4.16667%;
	}
	.\32\35\/120--tablet-up,
	.\35\/24--tablet-up {
		width: 20.83333%;
	}
	.\33\35\/120--tablet-up,
	.\37\/24--tablet-up {
		width: 29.16667%;
	}
	.\31\31\/24--tablet-up,
	.\35\35\/120--tablet-up {
		width: 45.83333%;
	}
	.\31\33\/24--tablet-up,
	.\36\35\/120--tablet-up {
		width: 54.16667%;
	}
	.\31\37\/24--tablet-up,
	.\38\35\/120--tablet-up {
		width: 70.83333%;
	}
	.\31\39\/24--tablet-up,
	.\39\35\/120--tablet-up {
		width: 79.16667%;
	}
	.\31\31\35\/120--tablet-up,
	.\32\33\/24--tablet-up {
		width: 95.83333%;
	}
	.\31\/28--tablet-up {
		width: 3.57143%;
	}
	.\33\/28--tablet-up {
		width: 10.71429%;
	}
	.\35\/28--tablet-up {
		width: 17.85714%;
	}
	.\39\/28--tablet-up {
		width: 32.14286%;
	}
	.\31\31\/28--tablet-up {
		width: 39.28571%;
	}
	.\31\33\/28--tablet-up {
		width: 46.42857%;
	}
	.\31\35\/28--tablet-up {
		width: 53.57143%;
	}
	.\31\37\/28--tablet-up {
		width: 60.71429%;
	}
	.\31\39\/28--tablet-up {
		width: 67.85714%;
	}
	.\32\33\/28--tablet-up {
		width: 82.14286%;
	}
	.\32\35\/28--tablet-up {
		width: 89.28571%;
	}
	.\32\37\/28--tablet-up {
		width: 96.42857%;
	}
	.\31\/60--tablet-up,
	.\32\/120--tablet-up {
		width: 1.66667%;
	}
	.\32\/60--tablet-up,
	.\34\/120--tablet-up {
		width: 3.33333%;
	}
	.\31\34\/120--tablet-up,
	.\37\/60--tablet-up {
		width: 11.66667%;
	}
	.\31\31\/60--tablet-up,
	.\32\32\/120--tablet-up {
		width: 18.33333%;
	}
	.\31\33\/60--tablet-up,
	.\32\36\/120--tablet-up {
		width: 21.66667%;
	}
	.\31\34\/60--tablet-up,
	.\32\38\/120--tablet-up {
		width: 23.33333%;
	}
	.\31\37\/60--tablet-up,
	.\33\34\/120--tablet-up {
		width: 28.33333%;
	}
	.\31\39\/60--tablet-up,
	.\33\38\/120--tablet-up {
		width: 31.66667%;
	}
	.\32\32\/60--tablet-up,
	.\34\34\/120--tablet-up {
		width: 36.66667%;
	}
	.\32\33\/60--tablet-up,
	.\34\36\/120--tablet-up {
		width: 38.33333%;
	}
	.\32\36\/60--tablet-up,
	.\35\32\/120--tablet-up {
		width: 43.33333%;
	}
	.\32\39\/60--tablet-up,
	.\35\38\/120--tablet-up {
		width: 48.33333%;
	}
	.\33\31\/60--tablet-up,
	.\36\32\/120--tablet-up {
		width: 51.66667%;
	}
	.\33\34\/60--tablet-up,
	.\36\38\/120--tablet-up {
		width: 56.66667%;
	}
	.\33\37\/60--tablet-up,
	.\37\34\/120--tablet-up {
		width: 61.66667%;
	}
	.\33\38\/60--tablet-up,
	.\37\36\/120--tablet-up {
		width: 63.33333%;
	}
	.\34\31\/60--tablet-up,
	.\38\32\/120--tablet-up {
		width: 68.33333%;
	}
	.\34\33\/60--tablet-up,
	.\38\36\/120--tablet-up {
		width: 71.66667%;
	}
	.\34\36\/60--tablet-up,
	.\39\32\/120--tablet-up {
		width: 76.66667%;
	}
	.\34\37\/60--tablet-up,
	.\39\34\/120--tablet-up {
		width: 78.33333%;
	}
	.\34\39\/60--tablet-up,
	.\39\38\/120--tablet-up {
		width: 81.66667%;
	}
	.\31\30\36\/120--tablet-up,
	.\35\33\/60--tablet-up {
		width: 88.33333%;
	}
	.\31\31\36\/120--tablet-up,
	.\35\38\/60--tablet-up {
		width: 96.66667%;
	}
	.\31\31\38\/120--tablet-up,
	.\35\39\/60--tablet-up {
		width: 98.33333%;
	}
	.\31\/120--tablet-up {
		width: 0.83333%;
	}
	.\33\/120--tablet-up {
		width: 2.5%;
	}
	.\37\/120--tablet-up {
		width: 5.83333%;
	}
	.\39\/120--tablet-up {
		width: 7.5%;
	}
	.\31\31\/120--tablet-up {
		width: 9.16667%;
	}
	.\31\33\/120--tablet-up {
		width: 10.83333%;
	}
	.\31\37\/120--tablet-up {
		width: 14.16667%;
	}
	.\31\39\/120--tablet-up {
		width: 15.83333%;
	}
	.\32\31\/120--tablet-up {
		width: 17.5%;
	}
	.\32\33\/120--tablet-up {
		width: 19.16667%;
	}
	.\32\37\/120--tablet-up {
		width: 22.5%;
	}
	.\32\39\/120--tablet-up {
		width: 24.16667%;
	}
	.\33\31\/120--tablet-up {
		width: 25.83333%;
	}
	.\33\33\/120--tablet-up {
		width: 27.5%;
	}
	.\33\37\/120--tablet-up {
		width: 30.83333%;
	}
	.\33\39\/120--tablet-up {
		width: 32.5%;
	}
	.\34\31\/120--tablet-up {
		width: 34.16667%;
	}
	.\34\33\/120--tablet-up {
		width: 35.83333%;
	}
	.\34\37\/120--tablet-up {
		width: 39.16667%;
	}
	.\34\39\/120--tablet-up {
		width: 40.83333%;
	}
	.\35\31\/120--tablet-up {
		width: 42.5%;
	}
	.\35\33\/120--tablet-up {
		width: 44.16667%;
	}
	.\35\37\/120--tablet-up {
		width: 47.5%;
	}
	.\35\39\/120--tablet-up {
		width: 49.16667%;
	}
	.\36\31\/120--tablet-up {
		width: 50.83333%;
	}
	.\36\33\/120--tablet-up {
		width: 52.5%;
	}
	.\36\37\/120--tablet-up {
		width: 55.83333%;
	}
	.\36\39\/120--tablet-up {
		width: 57.5%;
	}
	.\37\31\/120--tablet-up {
		width: 59.16667%;
	}
	.\37\33\/120--tablet-up {
		width: 60.83333%;
	}
	.\37\37\/120--tablet-up {
		width: 64.16667%;
	}
	.\37\39\/120--tablet-up {
		width: 65.83333%;
	}
	.\38\31\/120--tablet-up {
		width: 67.5%;
	}
	.\38\33\/120--tablet-up {
		width: 69.16667%;
	}
	.\38\37\/120--tablet-up {
		width: 72.5%;
	}
	.\38\39\/120--tablet-up {
		width: 74.16667%;
	}
	.\39\31\/120--tablet-up {
		width: 75.83333%;
	}
	.\39\33\/120--tablet-up {
		width: 77.5%;
	}
	.\39\37\/120--tablet-up {
		width: 80.83333%;
	}
	.\39\39\/120--tablet-up {
		width: 82.5%;
	}
	.\31\30\31\/120--tablet-up {
		width: 84.16667%;
	}
	.\31\30\33\/120--tablet-up {
		width: 85.83333%;
	}
	.\31\30\37\/120--tablet-up {
		width: 89.16667%;
	}
	.\31\30\39\/120--tablet-up {
		width: 90.83333%;
	}
	.\31\31\31\/120--tablet-up {
		width: 92.5%;
	}
	.\31\31\33\/120--tablet-up {
		width: 94.16667%;
	}
	.\31\31\37\/120--tablet-up {
		width: 97.5%;
	}
	.\31\31\39\/120--tablet-up {
		width: 99.16667%;
	}
}
@media screen and (min-width: 992px) {
	.\31\/2--sm-desk-up,
	.\31\30\/20--sm-desk-up,
	.\31\32\/24--sm-desk-up,
	.\31\34\/28--sm-desk-up,
	.\32\/4--sm-desk-up,
	.\33\/6--sm-desk-up,
	.\33\30\/60--sm-desk-up,
	.\34\/8--sm-desk-up,
	.\35\/10--sm-desk-up,
	.\36\/12--sm-desk-up,
	.\36\30\/120--sm-desk-up,
	.\37\/14--sm-desk-up,
	.\38\/16--sm-desk-up,
	.\39\/18--sm-desk-up {
		width: 50%;
	}
	.\31\/3--sm-desk-up,
	.\32\/6--sm-desk-up,
	.\32\30\/60--sm-desk-up,
	.\33\/9--sm-desk-up,
	.\34\/12--sm-desk-up,
	.\34\30\/120--sm-desk-up,
	.\35\/15--sm-desk-up,
	.\36\/18--sm-desk-up,
	.\38\/24--sm-desk-up {
		width: 33.33333%;
	}
	.\31\30\/15--sm-desk-up,
	.\31\32\/18--sm-desk-up,
	.\31\36\/24--sm-desk-up,
	.\32\/3--sm-desk-up,
	.\34\/6--sm-desk-up,
	.\34\30\/60--sm-desk-up,
	.\36\/9--sm-desk-up,
	.\38\/12--sm-desk-up,
	.\38\30\/120--sm-desk-up {
		width: 66.66667%;
	}
	.\31\/4--sm-desk-up,
	.\31\35\/60--sm-desk-up,
	.\32\/8--sm-desk-up,
	.\33\/12--sm-desk-up,
	.\33\30\/120--sm-desk-up,
	.\34\/16--sm-desk-up,
	.\35\/20--sm-desk-up,
	.\36\/24--sm-desk-up,
	.\37\/28--sm-desk-up {
		width: 25%;
	}
	.\31\32\/16--sm-desk-up,
	.\31\35\/20--sm-desk-up,
	.\31\38\/24--sm-desk-up,
	.\32\31\/28--sm-desk-up,
	.\33\/4--sm-desk-up,
	.\34\35\/60--sm-desk-up,
	.\36\/8--sm-desk-up,
	.\39\/12--sm-desk-up,
	.\39\30\/120--sm-desk-up {
		width: 75%;
	}
	.\31\/5--sm-desk-up,
	.\31\32\/60--sm-desk-up,
	.\32\/10--sm-desk-up,
	.\32\34\/120--sm-desk-up,
	.\33\/15--sm-desk-up,
	.\34\/20--sm-desk-up {
		width: 20%;
	}
	.\32\/5--sm-desk-up,
	.\32\34\/60--sm-desk-up,
	.\34\/10--sm-desk-up,
	.\34\38\/120--sm-desk-up,
	.\36\/15--sm-desk-up,
	.\38\/20--sm-desk-up {
		width: 40%;
	}
	.\31\32\/20--sm-desk-up,
	.\33\/5--sm-desk-up,
	.\33\36\/60--sm-desk-up,
	.\36\/10--sm-desk-up,
	.\37\32\/120--sm-desk-up,
	.\39\/15--sm-desk-up {
		width: 60%;
	}
	.\31\32\/15--sm-desk-up,
	.\31\36\/20--sm-desk-up,
	.\34\/5--sm-desk-up,
	.\34\38\/60--sm-desk-up,
	.\38\/10--sm-desk-up,
	.\39\36\/120--sm-desk-up {
		width: 80%;
	}
	.\31\/6--sm-desk-up,
	.\31\30\/60--sm-desk-up,
	.\32\/12--sm-desk-up,
	.\32\30\/120--sm-desk-up,
	.\33\/18--sm-desk-up,
	.\34\/24--sm-desk-up {
		width: 16.66667%;
	}
	.\31\30\/12--sm-desk-up,
	.\31\30\30\/120--sm-desk-up,
	.\31\35\/18--sm-desk-up,
	.\32\30\/24--sm-desk-up,
	.\35\/6--sm-desk-up,
	.\35\30\/60--sm-desk-up {
		width: 83.33333%;
	}
	.\31\/7--sm-desk-up,
	.\32\/14--sm-desk-up,
	.\34\/28--sm-desk-up {
		width: 14.28571%;
	}
	.\32\/7--sm-desk-up,
	.\34\/14--sm-desk-up,
	.\38\/28--sm-desk-up {
		width: 28.57143%;
	}
	.\31\32\/28--sm-desk-up,
	.\33\/7--sm-desk-up,
	.\36\/14--sm-desk-up {
		width: 42.85714%;
	}
	.\31\36\/28--sm-desk-up,
	.\34\/7--sm-desk-up,
	.\38\/14--sm-desk-up {
		width: 57.14286%;
	}
	.\31\30\/14--sm-desk-up,
	.\32\30\/28--sm-desk-up,
	.\35\/7--sm-desk-up {
		width: 71.42857%;
	}
	.\31\32\/14--sm-desk-up,
	.\32\34\/28--sm-desk-up,
	.\36\/7--sm-desk-up {
		width: 85.71429%;
	}
	.\31\/8--sm-desk-up,
	.\31\35\/120--sm-desk-up,
	.\32\/16--sm-desk-up,
	.\33\/24--sm-desk-up {
		width: 12.5%;
	}
	.\33\/8--sm-desk-up,
	.\34\35\/120--sm-desk-up,
	.\36\/16--sm-desk-up,
	.\39\/24--sm-desk-up {
		width: 37.5%;
	}
	.\31\30\/16--sm-desk-up,
	.\31\35\/24--sm-desk-up,
	.\35\/8--sm-desk-up,
	.\37\35\/120--sm-desk-up {
		width: 62.5%;
	}
	.\31\30\35\/120--sm-desk-up,
	.\31\34\/16--sm-desk-up,
	.\32\31\/24--sm-desk-up,
	.\37\/8--sm-desk-up {
		width: 87.5%;
	}
	.\31\/9--sm-desk-up,
	.\32\/18--sm-desk-up {
		width: 11.11111%;
	}
	.\32\/9--sm-desk-up,
	.\34\/18--sm-desk-up {
		width: 22.22222%;
	}
	.\34\/9--sm-desk-up,
	.\38\/18--sm-desk-up {
		width: 44.44444%;
	}
	.\31\30\/18--sm-desk-up,
	.\35\/9--sm-desk-up {
		width: 55.55556%;
	}
	.\31\34\/18--sm-desk-up,
	.\37\/9--sm-desk-up {
		width: 77.77778%;
	}
	.\31\36\/18--sm-desk-up,
	.\38\/9--sm-desk-up {
		width: 88.88889%;
	}
	.\31\/10--sm-desk-up,
	.\31\32\/120--sm-desk-up,
	.\32\/20--sm-desk-up,
	.\36\/60--sm-desk-up {
		width: 10%;
	}
	.\31\38\/60--sm-desk-up,
	.\33\/10--sm-desk-up,
	.\33\36\/120--sm-desk-up,
	.\36\/20--sm-desk-up {
		width: 30%;
	}
	.\31\34\/20--sm-desk-up,
	.\34\32\/60--sm-desk-up,
	.\37\/10--sm-desk-up,
	.\38\34\/120--sm-desk-up {
		width: 70%;
	}
	.\31\30\38\/120--sm-desk-up,
	.\31\38\/20--sm-desk-up,
	.\35\34\/60--sm-desk-up,
	.\39\/10--sm-desk-up {
		width: 90%;
	}
	.\31\/11--sm-desk-up {
		width: 9.09091%;
	}
	.\32\/11--sm-desk-up {
		width: 18.18182%;
	}
	.\33\/11--sm-desk-up {
		width: 27.27273%;
	}
	.\34\/11--sm-desk-up {
		width: 36.36364%;
	}
	.\35\/11--sm-desk-up {
		width: 45.45455%;
	}
	.\36\/11--sm-desk-up {
		width: 54.54545%;
	}
	.\37\/11--sm-desk-up {
		width: 63.63636%;
	}
	.\38\/11--sm-desk-up {
		width: 72.72727%;
	}
	.\39\/11--sm-desk-up {
		width: 81.81818%;
	}
	.\31\30\/11--sm-desk-up {
		width: 90.90909%;
	}
	.\31\/12--sm-desk-up,
	.\31\30\/120--sm-desk-up,
	.\32\/24--sm-desk-up,
	.\35\/60--sm-desk-up {
		width: 8.33333%;
	}
	.\31\30\/24--sm-desk-up,
	.\32\35\/60--sm-desk-up,
	.\35\/12--sm-desk-up,
	.\35\30\/120--sm-desk-up {
		width: 41.66667%;
	}
	.\31\34\/24--sm-desk-up,
	.\33\35\/60--sm-desk-up,
	.\37\/12--sm-desk-up,
	.\37\30\/120--sm-desk-up {
		width: 58.33333%;
	}
	.\31\31\/12--sm-desk-up,
	.\31\31\30\/120--sm-desk-up,
	.\32\32\/24--sm-desk-up,
	.\35\35\/60--sm-desk-up {
		width: 91.66667%;
	}
	.\31\/13--sm-desk-up {
		width: 7.69231%;
	}
	.\32\/13--sm-desk-up {
		width: 15.38462%;
	}
	.\33\/13--sm-desk-up {
		width: 23.07692%;
	}
	.\34\/13--sm-desk-up {
		width: 30.76923%;
	}
	.\35\/13--sm-desk-up {
		width: 38.46154%;
	}
	.\36\/13--sm-desk-up {
		width: 46.15385%;
	}
	.\37\/13--sm-desk-up {
		width: 53.84615%;
	}
	.\38\/13--sm-desk-up {
		width: 61.53846%;
	}
	.\39\/13--sm-desk-up {
		width: 69.23077%;
	}
	.\31\30\/13--sm-desk-up {
		width: 76.92308%;
	}
	.\31\31\/13--sm-desk-up {
		width: 84.61538%;
	}
	.\31\32\/13--sm-desk-up {
		width: 92.30769%;
	}
	.\31\/14--sm-desk-up,
	.\32\/28--sm-desk-up {
		width: 7.14286%;
	}
	.\33\/14--sm-desk-up,
	.\36\/28--sm-desk-up {
		width: 21.42857%;
	}
	.\31\30\/28--sm-desk-up,
	.\35\/14--sm-desk-up {
		width: 35.71429%;
	}
	.\31\38\/28--sm-desk-up,
	.\39\/14--sm-desk-up {
		width: 64.28571%;
	}
	.\31\31\/14--sm-desk-up,
	.\32\32\/28--sm-desk-up {
		width: 78.57143%;
	}
	.\31\33\/14--sm-desk-up,
	.\32\36\/28--sm-desk-up {
		width: 92.85714%;
	}
	.\31\/15--sm-desk-up,
	.\34\/60--sm-desk-up,
	.\38\/120--sm-desk-up {
		width: 6.66667%;
	}
	.\31\36\/120--sm-desk-up,
	.\32\/15--sm-desk-up,
	.\38\/60--sm-desk-up {
		width: 13.33333%;
	}
	.\31\36\/60--sm-desk-up,
	.\33\32\/120--sm-desk-up,
	.\34\/15--sm-desk-up {
		width: 26.66667%;
	}
	.\32\38\/60--sm-desk-up,
	.\35\36\/120--sm-desk-up,
	.\37\/15--sm-desk-up {
		width: 46.66667%;
	}
	.\33\32\/60--sm-desk-up,
	.\36\34\/120--sm-desk-up,
	.\38\/15--sm-desk-up {
		width: 53.33333%;
	}
	.\31\31\/15--sm-desk-up,
	.\34\34\/60--sm-desk-up,
	.\38\38\/120--sm-desk-up {
		width: 73.33333%;
	}
	.\31\30\34\/120--sm-desk-up,
	.\31\33\/15--sm-desk-up,
	.\35\32\/60--sm-desk-up {
		width: 86.66667%;
	}
	.\31\31\32\/120--sm-desk-up,
	.\31\34\/15--sm-desk-up,
	.\35\36\/60--sm-desk-up {
		width: 93.33333%;
	}
	.\31\/16--sm-desk-up {
		width: 6.25%;
	}
	.\33\/16--sm-desk-up {
		width: 18.75%;
	}
	.\35\/16--sm-desk-up {
		width: 31.25%;
	}
	.\37\/16--sm-desk-up {
		width: 43.75%;
	}
	.\39\/16--sm-desk-up {
		width: 56.25%;
	}
	.\31\31\/16--sm-desk-up {
		width: 68.75%;
	}
	.\31\33\/16--sm-desk-up {
		width: 81.25%;
	}
	.\31\35\/16--sm-desk-up {
		width: 93.75%;
	}
	.\31\/17--sm-desk-up {
		width: 5.88235%;
	}
	.\32\/17--sm-desk-up {
		width: 11.76471%;
	}
	.\33\/17--sm-desk-up {
		width: 17.64706%;
	}
	.\34\/17--sm-desk-up {
		width: 23.52941%;
	}
	.\35\/17--sm-desk-up {
		width: 29.41176%;
	}
	.\36\/17--sm-desk-up {
		width: 35.29412%;
	}
	.\37\/17--sm-desk-up {
		width: 41.17647%;
	}
	.\38\/17--sm-desk-up {
		width: 47.05882%;
	}
	.\39\/17--sm-desk-up {
		width: 52.94118%;
	}
	.\31\30\/17--sm-desk-up {
		width: 58.82353%;
	}
	.\31\31\/17--sm-desk-up {
		width: 64.70588%;
	}
	.\31\32\/17--sm-desk-up {
		width: 70.58824%;
	}
	.\31\33\/17--sm-desk-up {
		width: 76.47059%;
	}
	.\31\34\/17--sm-desk-up {
		width: 82.35294%;
	}
	.\31\35\/17--sm-desk-up {
		width: 88.23529%;
	}
	.\31\36\/17--sm-desk-up {
		width: 94.11765%;
	}
	.\31\/18--sm-desk-up {
		width: 5.55556%;
	}
	.\35\/18--sm-desk-up {
		width: 27.77778%;
	}
	.\37\/18--sm-desk-up {
		width: 38.88889%;
	}
	.\31\31\/18--sm-desk-up {
		width: 61.11111%;
	}
	.\31\33\/18--sm-desk-up {
		width: 72.22222%;
	}
	.\31\37\/18--sm-desk-up {
		width: 94.44444%;
	}
	.\31\/19--sm-desk-up {
		width: 5.26316%;
	}
	.\32\/19--sm-desk-up {
		width: 10.52632%;
	}
	.\33\/19--sm-desk-up {
		width: 15.78947%;
	}
	.\34\/19--sm-desk-up {
		width: 21.05263%;
	}
	.\35\/19--sm-desk-up {
		width: 26.31579%;
	}
	.\36\/19--sm-desk-up {
		width: 31.57895%;
	}
	.\37\/19--sm-desk-up {
		width: 36.84211%;
	}
	.\38\/19--sm-desk-up {
		width: 42.10526%;
	}
	.\39\/19--sm-desk-up {
		width: 47.36842%;
	}
	.\31\30\/19--sm-desk-up {
		width: 52.63158%;
	}
	.\31\31\/19--sm-desk-up {
		width: 57.89474%;
	}
	.\31\32\/19--sm-desk-up {
		width: 63.15789%;
	}
	.\31\33\/19--sm-desk-up {
		width: 68.42105%;
	}
	.\31\34\/19--sm-desk-up {
		width: 73.68421%;
	}
	.\31\35\/19--sm-desk-up {
		width: 78.94737%;
	}
	.\31\36\/19--sm-desk-up {
		width: 84.21053%;
	}
	.\31\37\/19--sm-desk-up {
		width: 89.47368%;
	}
	.\31\38\/19--sm-desk-up {
		width: 94.73684%;
	}
	.\31\/20--sm-desk-up,
	.\33\/60--sm-desk-up,
	.\36\/120--sm-desk-up {
		width: 5%;
	}
	.\31\38\/120--sm-desk-up,
	.\33\/20--sm-desk-up,
	.\39\/60--sm-desk-up {
		width: 15%;
	}
	.\32\31\/60--sm-desk-up,
	.\34\32\/120--sm-desk-up,
	.\37\/20--sm-desk-up {
		width: 35%;
	}
	.\32\37\/60--sm-desk-up,
	.\35\34\/120--sm-desk-up,
	.\39\/20--sm-desk-up {
		width: 45%;
	}
	.\31\31\/20--sm-desk-up,
	.\33\33\/60--sm-desk-up,
	.\36\36\/120--sm-desk-up {
		width: 55%;
	}
	.\31\33\/20--sm-desk-up,
	.\33\39\/60--sm-desk-up,
	.\37\38\/120--sm-desk-up {
		width: 65%;
	}
	.\31\30\32\/120--sm-desk-up,
	.\31\37\/20--sm-desk-up,
	.\35\31\/60--sm-desk-up {
		width: 85%;
	}
	.\31\31\34\/120--sm-desk-up,
	.\31\39\/20--sm-desk-up,
	.\35\37\/60--sm-desk-up {
		width: 95%;
	}
	.\31\/24--sm-desk-up,
	.\35\/120--sm-desk-up {
		width: 4.16667%;
	}
	.\32\35\/120--sm-desk-up,
	.\35\/24--sm-desk-up {
		width: 20.83333%;
	}
	.\33\35\/120--sm-desk-up,
	.\37\/24--sm-desk-up {
		width: 29.16667%;
	}
	.\31\31\/24--sm-desk-up,
	.\35\35\/120--sm-desk-up {
		width: 45.83333%;
	}
	.\31\33\/24--sm-desk-up,
	.\36\35\/120--sm-desk-up {
		width: 54.16667%;
	}
	.\31\37\/24--sm-desk-up,
	.\38\35\/120--sm-desk-up {
		width: 70.83333%;
	}
	.\31\39\/24--sm-desk-up,
	.\39\35\/120--sm-desk-up {
		width: 79.16667%;
	}
	.\31\31\35\/120--sm-desk-up,
	.\32\33\/24--sm-desk-up {
		width: 95.83333%;
	}
	.\31\/28--sm-desk-up {
		width: 3.57143%;
	}
	.\33\/28--sm-desk-up {
		width: 10.71429%;
	}
	.\35\/28--sm-desk-up {
		width: 17.85714%;
	}
	.\39\/28--sm-desk-up {
		width: 32.14286%;
	}
	.\31\31\/28--sm-desk-up {
		width: 39.28571%;
	}
	.\31\33\/28--sm-desk-up {
		width: 46.42857%;
	}
	.\31\35\/28--sm-desk-up {
		width: 53.57143%;
	}
	.\31\37\/28--sm-desk-up {
		width: 60.71429%;
	}
	.\31\39\/28--sm-desk-up {
		width: 67.85714%;
	}
	.\32\33\/28--sm-desk-up {
		width: 82.14286%;
	}
	.\32\35\/28--sm-desk-up {
		width: 89.28571%;
	}
	.\32\37\/28--sm-desk-up {
		width: 96.42857%;
	}
	.\31\/60--sm-desk-up,
	.\32\/120--sm-desk-up {
		width: 1.66667%;
	}
	.\32\/60--sm-desk-up,
	.\34\/120--sm-desk-up {
		width: 3.33333%;
	}
	.\31\34\/120--sm-desk-up,
	.\37\/60--sm-desk-up {
		width: 11.66667%;
	}
	.\31\31\/60--sm-desk-up,
	.\32\32\/120--sm-desk-up {
		width: 18.33333%;
	}
	.\31\33\/60--sm-desk-up,
	.\32\36\/120--sm-desk-up {
		width: 21.66667%;
	}
	.\31\34\/60--sm-desk-up,
	.\32\38\/120--sm-desk-up {
		width: 23.33333%;
	}
	.\31\37\/60--sm-desk-up,
	.\33\34\/120--sm-desk-up {
		width: 28.33333%;
	}
	.\31\39\/60--sm-desk-up,
	.\33\38\/120--sm-desk-up {
		width: 31.66667%;
	}
	.\32\32\/60--sm-desk-up,
	.\34\34\/120--sm-desk-up {
		width: 36.66667%;
	}
	.\32\33\/60--sm-desk-up,
	.\34\36\/120--sm-desk-up {
		width: 38.33333%;
	}
	.\32\36\/60--sm-desk-up,
	.\35\32\/120--sm-desk-up {
		width: 43.33333%;
	}
	.\32\39\/60--sm-desk-up,
	.\35\38\/120--sm-desk-up {
		width: 48.33333%;
	}
	.\33\31\/60--sm-desk-up,
	.\36\32\/120--sm-desk-up {
		width: 51.66667%;
	}
	.\33\34\/60--sm-desk-up,
	.\36\38\/120--sm-desk-up {
		width: 56.66667%;
	}
	.\33\37\/60--sm-desk-up,
	.\37\34\/120--sm-desk-up {
		width: 61.66667%;
	}
	.\33\38\/60--sm-desk-up,
	.\37\36\/120--sm-desk-up {
		width: 63.33333%;
	}
	.\34\31\/60--sm-desk-up,
	.\38\32\/120--sm-desk-up {
		width: 68.33333%;
	}
	.\34\33\/60--sm-desk-up,
	.\38\36\/120--sm-desk-up {
		width: 71.66667%;
	}
	.\34\36\/60--sm-desk-up,
	.\39\32\/120--sm-desk-up {
		width: 76.66667%;
	}
	.\34\37\/60--sm-desk-up,
	.\39\34\/120--sm-desk-up {
		width: 78.33333%;
	}
	.\34\39\/60--sm-desk-up,
	.\39\38\/120--sm-desk-up {
		width: 81.66667%;
	}
	.\31\30\36\/120--sm-desk-up,
	.\35\33\/60--sm-desk-up {
		width: 88.33333%;
	}
	.\31\31\36\/120--sm-desk-up,
	.\35\38\/60--sm-desk-up {
		width: 96.66667%;
	}
	.\31\31\38\/120--sm-desk-up,
	.\35\39\/60--sm-desk-up {
		width: 98.33333%;
	}
	.\31\/120--sm-desk-up {
		width: 0.83333%;
	}
	.\33\/120--sm-desk-up {
		width: 2.5%;
	}
	.\37\/120--sm-desk-up {
		width: 5.83333%;
	}
	.\39\/120--sm-desk-up {
		width: 7.5%;
	}
	.\31\31\/120--sm-desk-up {
		width: 9.16667%;
	}
	.\31\33\/120--sm-desk-up {
		width: 10.83333%;
	}
	.\31\37\/120--sm-desk-up {
		width: 14.16667%;
	}
	.\31\39\/120--sm-desk-up {
		width: 15.83333%;
	}
	.\32\31\/120--sm-desk-up {
		width: 17.5%;
	}
	.\32\33\/120--sm-desk-up {
		width: 19.16667%;
	}
	.\32\37\/120--sm-desk-up {
		width: 22.5%;
	}
	.\32\39\/120--sm-desk-up {
		width: 24.16667%;
	}
	.\33\31\/120--sm-desk-up {
		width: 25.83333%;
	}
	.\33\33\/120--sm-desk-up {
		width: 27.5%;
	}
	.\33\37\/120--sm-desk-up {
		width: 30.83333%;
	}
	.\33\39\/120--sm-desk-up {
		width: 32.5%;
	}
	.\34\31\/120--sm-desk-up {
		width: 34.16667%;
	}
	.\34\33\/120--sm-desk-up {
		width: 35.83333%;
	}
	.\34\37\/120--sm-desk-up {
		width: 39.16667%;
	}
	.\34\39\/120--sm-desk-up {
		width: 40.83333%;
	}
	.\35\31\/120--sm-desk-up {
		width: 42.5%;
	}
	.\35\33\/120--sm-desk-up {
		width: 44.16667%;
	}
	.\35\37\/120--sm-desk-up {
		width: 47.5%;
	}
	.\35\39\/120--sm-desk-up {
		width: 49.16667%;
	}
	.\36\31\/120--sm-desk-up {
		width: 50.83333%;
	}
	.\36\33\/120--sm-desk-up {
		width: 52.5%;
	}
	.\36\37\/120--sm-desk-up {
		width: 55.83333%;
	}
	.\36\39\/120--sm-desk-up {
		width: 57.5%;
	}
	.\37\31\/120--sm-desk-up {
		width: 59.16667%;
	}
	.\37\33\/120--sm-desk-up {
		width: 60.83333%;
	}
	.\37\37\/120--sm-desk-up {
		width: 64.16667%;
	}
	.\37\39\/120--sm-desk-up {
		width: 65.83333%;
	}
	.\38\31\/120--sm-desk-up {
		width: 67.5%;
	}
	.\38\33\/120--sm-desk-up {
		width: 69.16667%;
	}
	.\38\37\/120--sm-desk-up {
		width: 72.5%;
	}
	.\38\39\/120--sm-desk-up {
		width: 74.16667%;
	}
	.\39\31\/120--sm-desk-up {
		width: 75.83333%;
	}
	.\39\33\/120--sm-desk-up {
		width: 77.5%;
	}
	.\39\37\/120--sm-desk-up {
		width: 80.83333%;
	}
	.\39\39\/120--sm-desk-up {
		width: 82.5%;
	}
	.\31\30\31\/120--sm-desk-up {
		width: 84.16667%;
	}
	.\31\30\33\/120--sm-desk-up {
		width: 85.83333%;
	}
	.\31\30\37\/120--sm-desk-up {
		width: 89.16667%;
	}
	.\31\30\39\/120--sm-desk-up {
		width: 90.83333%;
	}
	.\31\31\31\/120--sm-desk-up {
		width: 92.5%;
	}
	.\31\31\33\/120--sm-desk-up {
		width: 94.16667%;
	}
	.\31\31\37\/120--sm-desk-up {
		width: 97.5%;
	}
	.\31\31\39\/120--sm-desk-up {
		width: 99.16667%;
	}
}
@media screen and (max-width: 991px) {
	.\31\/2--sm-desk-down,
	.\31\30\/20--sm-desk-down,
	.\31\32\/24--sm-desk-down,
	.\31\34\/28--sm-desk-down,
	.\32\/4--sm-desk-down,
	.\33\/6--sm-desk-down,
	.\33\30\/60--sm-desk-down,
	.\34\/8--sm-desk-down,
	.\35\/10--sm-desk-down,
	.\36\/12--sm-desk-down,
	.\36\30\/120--sm-desk-down,
	.\37\/14--sm-desk-down,
	.\38\/16--sm-desk-down,
	.\39\/18--sm-desk-down {
		width: 50%;
	}
	.\31\/3--sm-desk-down,
	.\32\/6--sm-desk-down,
	.\32\30\/60--sm-desk-down,
	.\33\/9--sm-desk-down,
	.\34\/12--sm-desk-down,
	.\34\30\/120--sm-desk-down,
	.\35\/15--sm-desk-down,
	.\36\/18--sm-desk-down,
	.\38\/24--sm-desk-down {
		width: 33.33333%;
	}
	.\31\30\/15--sm-desk-down,
	.\31\32\/18--sm-desk-down,
	.\31\36\/24--sm-desk-down,
	.\32\/3--sm-desk-down,
	.\34\/6--sm-desk-down,
	.\34\30\/60--sm-desk-down,
	.\36\/9--sm-desk-down,
	.\38\/12--sm-desk-down,
	.\38\30\/120--sm-desk-down {
		width: 66.66667%;
	}
	.\31\/4--sm-desk-down,
	.\31\35\/60--sm-desk-down,
	.\32\/8--sm-desk-down,
	.\33\/12--sm-desk-down,
	.\33\30\/120--sm-desk-down,
	.\34\/16--sm-desk-down,
	.\35\/20--sm-desk-down,
	.\36\/24--sm-desk-down,
	.\37\/28--sm-desk-down {
		width: 25%;
	}
	.\31\32\/16--sm-desk-down,
	.\31\35\/20--sm-desk-down,
	.\31\38\/24--sm-desk-down,
	.\32\31\/28--sm-desk-down,
	.\33\/4--sm-desk-down,
	.\34\35\/60--sm-desk-down,
	.\36\/8--sm-desk-down,
	.\39\/12--sm-desk-down,
	.\39\30\/120--sm-desk-down {
		width: 75%;
	}
	.\31\/5--sm-desk-down,
	.\31\32\/60--sm-desk-down,
	.\32\/10--sm-desk-down,
	.\32\34\/120--sm-desk-down,
	.\33\/15--sm-desk-down,
	.\34\/20--sm-desk-down {
		width: 20%;
	}
	.\32\/5--sm-desk-down,
	.\32\34\/60--sm-desk-down,
	.\34\/10--sm-desk-down,
	.\34\38\/120--sm-desk-down,
	.\36\/15--sm-desk-down,
	.\38\/20--sm-desk-down {
		width: 40%;
	}
	.\31\32\/20--sm-desk-down,
	.\33\/5--sm-desk-down,
	.\33\36\/60--sm-desk-down,
	.\36\/10--sm-desk-down,
	.\37\32\/120--sm-desk-down,
	.\39\/15--sm-desk-down {
		width: 60%;
	}
	.\31\32\/15--sm-desk-down,
	.\31\36\/20--sm-desk-down,
	.\34\/5--sm-desk-down,
	.\34\38\/60--sm-desk-down,
	.\38\/10--sm-desk-down,
	.\39\36\/120--sm-desk-down {
		width: 80%;
	}
	.\31\/6--sm-desk-down,
	.\31\30\/60--sm-desk-down,
	.\32\/12--sm-desk-down,
	.\32\30\/120--sm-desk-down,
	.\33\/18--sm-desk-down,
	.\34\/24--sm-desk-down {
		width: 16.66667%;
	}
	.\31\30\/12--sm-desk-down,
	.\31\30\30\/120--sm-desk-down,
	.\31\35\/18--sm-desk-down,
	.\32\30\/24--sm-desk-down,
	.\35\/6--sm-desk-down,
	.\35\30\/60--sm-desk-down {
		width: 83.33333%;
	}
	.\31\/7--sm-desk-down,
	.\32\/14--sm-desk-down,
	.\34\/28--sm-desk-down {
		width: 14.28571%;
	}
	.\32\/7--sm-desk-down,
	.\34\/14--sm-desk-down,
	.\38\/28--sm-desk-down {
		width: 28.57143%;
	}
	.\31\32\/28--sm-desk-down,
	.\33\/7--sm-desk-down,
	.\36\/14--sm-desk-down {
		width: 42.85714%;
	}
	.\31\36\/28--sm-desk-down,
	.\34\/7--sm-desk-down,
	.\38\/14--sm-desk-down {
		width: 57.14286%;
	}
	.\31\30\/14--sm-desk-down,
	.\32\30\/28--sm-desk-down,
	.\35\/7--sm-desk-down {
		width: 71.42857%;
	}
	.\31\32\/14--sm-desk-down,
	.\32\34\/28--sm-desk-down,
	.\36\/7--sm-desk-down {
		width: 85.71429%;
	}
	.\31\/8--sm-desk-down,
	.\31\35\/120--sm-desk-down,
	.\32\/16--sm-desk-down,
	.\33\/24--sm-desk-down {
		width: 12.5%;
	}
	.\33\/8--sm-desk-down,
	.\34\35\/120--sm-desk-down,
	.\36\/16--sm-desk-down,
	.\39\/24--sm-desk-down {
		width: 37.5%;
	}
	.\31\30\/16--sm-desk-down,
	.\31\35\/24--sm-desk-down,
	.\35\/8--sm-desk-down,
	.\37\35\/120--sm-desk-down {
		width: 62.5%;
	}
	.\31\30\35\/120--sm-desk-down,
	.\31\34\/16--sm-desk-down,
	.\32\31\/24--sm-desk-down,
	.\37\/8--sm-desk-down {
		width: 87.5%;
	}
	.\31\/9--sm-desk-down,
	.\32\/18--sm-desk-down {
		width: 11.11111%;
	}
	.\32\/9--sm-desk-down,
	.\34\/18--sm-desk-down {
		width: 22.22222%;
	}
	.\34\/9--sm-desk-down,
	.\38\/18--sm-desk-down {
		width: 44.44444%;
	}
	.\31\30\/18--sm-desk-down,
	.\35\/9--sm-desk-down {
		width: 55.55556%;
	}
	.\31\34\/18--sm-desk-down,
	.\37\/9--sm-desk-down {
		width: 77.77778%;
	}
	.\31\36\/18--sm-desk-down,
	.\38\/9--sm-desk-down {
		width: 88.88889%;
	}
	.\31\/10--sm-desk-down,
	.\31\32\/120--sm-desk-down,
	.\32\/20--sm-desk-down,
	.\36\/60--sm-desk-down {
		width: 10%;
	}
	.\31\38\/60--sm-desk-down,
	.\33\/10--sm-desk-down,
	.\33\36\/120--sm-desk-down,
	.\36\/20--sm-desk-down {
		width: 30%;
	}
	.\31\34\/20--sm-desk-down,
	.\34\32\/60--sm-desk-down,
	.\37\/10--sm-desk-down,
	.\38\34\/120--sm-desk-down {
		width: 70%;
	}
	.\31\30\38\/120--sm-desk-down,
	.\31\38\/20--sm-desk-down,
	.\35\34\/60--sm-desk-down,
	.\39\/10--sm-desk-down {
		width: 90%;
	}
	.\31\/11--sm-desk-down {
		width: 9.09091%;
	}
	.\32\/11--sm-desk-down {
		width: 18.18182%;
	}
	.\33\/11--sm-desk-down {
		width: 27.27273%;
	}
	.\34\/11--sm-desk-down {
		width: 36.36364%;
	}
	.\35\/11--sm-desk-down {
		width: 45.45455%;
	}
	.\36\/11--sm-desk-down {
		width: 54.54545%;
	}
	.\37\/11--sm-desk-down {
		width: 63.63636%;
	}
	.\38\/11--sm-desk-down {
		width: 72.72727%;
	}
	.\39\/11--sm-desk-down {
		width: 81.81818%;
	}
	.\31\30\/11--sm-desk-down {
		width: 90.90909%;
	}
	.\31\/12--sm-desk-down,
	.\31\30\/120--sm-desk-down,
	.\32\/24--sm-desk-down,
	.\35\/60--sm-desk-down {
		width: 8.33333%;
	}
	.\31\30\/24--sm-desk-down,
	.\32\35\/60--sm-desk-down,
	.\35\/12--sm-desk-down,
	.\35\30\/120--sm-desk-down {
		width: 41.66667%;
	}
	.\31\34\/24--sm-desk-down,
	.\33\35\/60--sm-desk-down,
	.\37\/12--sm-desk-down,
	.\37\30\/120--sm-desk-down {
		width: 58.33333%;
	}
	.\31\31\/12--sm-desk-down,
	.\31\31\30\/120--sm-desk-down,
	.\32\32\/24--sm-desk-down,
	.\35\35\/60--sm-desk-down {
		width: 91.66667%;
	}
	.\31\/13--sm-desk-down {
		width: 7.69231%;
	}
	.\32\/13--sm-desk-down {
		width: 15.38462%;
	}
	.\33\/13--sm-desk-down {
		width: 23.07692%;
	}
	.\34\/13--sm-desk-down {
		width: 30.76923%;
	}
	.\35\/13--sm-desk-down {
		width: 38.46154%;
	}
	.\36\/13--sm-desk-down {
		width: 46.15385%;
	}
	.\37\/13--sm-desk-down {
		width: 53.84615%;
	}
	.\38\/13--sm-desk-down {
		width: 61.53846%;
	}
	.\39\/13--sm-desk-down {
		width: 69.23077%;
	}
	.\31\30\/13--sm-desk-down {
		width: 76.92308%;
	}
	.\31\31\/13--sm-desk-down {
		width: 84.61538%;
	}
	.\31\32\/13--sm-desk-down {
		width: 92.30769%;
	}
	.\31\/14--sm-desk-down,
	.\32\/28--sm-desk-down {
		width: 7.14286%;
	}
	.\33\/14--sm-desk-down,
	.\36\/28--sm-desk-down {
		width: 21.42857%;
	}
	.\31\30\/28--sm-desk-down,
	.\35\/14--sm-desk-down {
		width: 35.71429%;
	}
	.\31\38\/28--sm-desk-down,
	.\39\/14--sm-desk-down {
		width: 64.28571%;
	}
	.\31\31\/14--sm-desk-down,
	.\32\32\/28--sm-desk-down {
		width: 78.57143%;
	}
	.\31\33\/14--sm-desk-down,
	.\32\36\/28--sm-desk-down {
		width: 92.85714%;
	}
	.\31\/15--sm-desk-down,
	.\34\/60--sm-desk-down,
	.\38\/120--sm-desk-down {
		width: 6.66667%;
	}
	.\31\36\/120--sm-desk-down,
	.\32\/15--sm-desk-down,
	.\38\/60--sm-desk-down {
		width: 13.33333%;
	}
	.\31\36\/60--sm-desk-down,
	.\33\32\/120--sm-desk-down,
	.\34\/15--sm-desk-down {
		width: 26.66667%;
	}
	.\32\38\/60--sm-desk-down,
	.\35\36\/120--sm-desk-down,
	.\37\/15--sm-desk-down {
		width: 46.66667%;
	}
	.\33\32\/60--sm-desk-down,
	.\36\34\/120--sm-desk-down,
	.\38\/15--sm-desk-down {
		width: 53.33333%;
	}
	.\31\31\/15--sm-desk-down,
	.\34\34\/60--sm-desk-down,
	.\38\38\/120--sm-desk-down {
		width: 73.33333%;
	}
	.\31\30\34\/120--sm-desk-down,
	.\31\33\/15--sm-desk-down,
	.\35\32\/60--sm-desk-down {
		width: 86.66667%;
	}
	.\31\31\32\/120--sm-desk-down,
	.\31\34\/15--sm-desk-down,
	.\35\36\/60--sm-desk-down {
		width: 93.33333%;
	}
	.\31\/16--sm-desk-down {
		width: 6.25%;
	}
	.\33\/16--sm-desk-down {
		width: 18.75%;
	}
	.\35\/16--sm-desk-down {
		width: 31.25%;
	}
	.\37\/16--sm-desk-down {
		width: 43.75%;
	}
	.\39\/16--sm-desk-down {
		width: 56.25%;
	}
	.\31\31\/16--sm-desk-down {
		width: 68.75%;
	}
	.\31\33\/16--sm-desk-down {
		width: 81.25%;
	}
	.\31\35\/16--sm-desk-down {
		width: 93.75%;
	}
	.\31\/17--sm-desk-down {
		width: 5.88235%;
	}
	.\32\/17--sm-desk-down {
		width: 11.76471%;
	}
	.\33\/17--sm-desk-down {
		width: 17.64706%;
	}
	.\34\/17--sm-desk-down {
		width: 23.52941%;
	}
	.\35\/17--sm-desk-down {
		width: 29.41176%;
	}
	.\36\/17--sm-desk-down {
		width: 35.29412%;
	}
	.\37\/17--sm-desk-down {
		width: 41.17647%;
	}
	.\38\/17--sm-desk-down {
		width: 47.05882%;
	}
	.\39\/17--sm-desk-down {
		width: 52.94118%;
	}
	.\31\30\/17--sm-desk-down {
		width: 58.82353%;
	}
	.\31\31\/17--sm-desk-down {
		width: 64.70588%;
	}
	.\31\32\/17--sm-desk-down {
		width: 70.58824%;
	}
	.\31\33\/17--sm-desk-down {
		width: 76.47059%;
	}
	.\31\34\/17--sm-desk-down {
		width: 82.35294%;
	}
	.\31\35\/17--sm-desk-down {
		width: 88.23529%;
	}
	.\31\36\/17--sm-desk-down {
		width: 94.11765%;
	}
	.\31\/18--sm-desk-down {
		width: 5.55556%;
	}
	.\35\/18--sm-desk-down {
		width: 27.77778%;
	}
	.\37\/18--sm-desk-down {
		width: 38.88889%;
	}
	.\31\31\/18--sm-desk-down {
		width: 61.11111%;
	}
	.\31\33\/18--sm-desk-down {
		width: 72.22222%;
	}
	.\31\37\/18--sm-desk-down {
		width: 94.44444%;
	}
	.\31\/19--sm-desk-down {
		width: 5.26316%;
	}
	.\32\/19--sm-desk-down {
		width: 10.52632%;
	}
	.\33\/19--sm-desk-down {
		width: 15.78947%;
	}
	.\34\/19--sm-desk-down {
		width: 21.05263%;
	}
	.\35\/19--sm-desk-down {
		width: 26.31579%;
	}
	.\36\/19--sm-desk-down {
		width: 31.57895%;
	}
	.\37\/19--sm-desk-down {
		width: 36.84211%;
	}
	.\38\/19--sm-desk-down {
		width: 42.10526%;
	}
	.\39\/19--sm-desk-down {
		width: 47.36842%;
	}
	.\31\30\/19--sm-desk-down {
		width: 52.63158%;
	}
	.\31\31\/19--sm-desk-down {
		width: 57.89474%;
	}
	.\31\32\/19--sm-desk-down {
		width: 63.15789%;
	}
	.\31\33\/19--sm-desk-down {
		width: 68.42105%;
	}
	.\31\34\/19--sm-desk-down {
		width: 73.68421%;
	}
	.\31\35\/19--sm-desk-down {
		width: 78.94737%;
	}
	.\31\36\/19--sm-desk-down {
		width: 84.21053%;
	}
	.\31\37\/19--sm-desk-down {
		width: 89.47368%;
	}
	.\31\38\/19--sm-desk-down {
		width: 94.73684%;
	}
	.\31\/20--sm-desk-down,
	.\33\/60--sm-desk-down,
	.\36\/120--sm-desk-down {
		width: 5%;
	}
	.\31\38\/120--sm-desk-down,
	.\33\/20--sm-desk-down,
	.\39\/60--sm-desk-down {
		width: 15%;
	}
	.\32\31\/60--sm-desk-down,
	.\34\32\/120--sm-desk-down,
	.\37\/20--sm-desk-down {
		width: 35%;
	}
	.\32\37\/60--sm-desk-down,
	.\35\34\/120--sm-desk-down,
	.\39\/20--sm-desk-down {
		width: 45%;
	}
	.\31\31\/20--sm-desk-down,
	.\33\33\/60--sm-desk-down,
	.\36\36\/120--sm-desk-down {
		width: 55%;
	}
	.\31\33\/20--sm-desk-down,
	.\33\39\/60--sm-desk-down,
	.\37\38\/120--sm-desk-down {
		width: 65%;
	}
	.\31\30\32\/120--sm-desk-down,
	.\31\37\/20--sm-desk-down,
	.\35\31\/60--sm-desk-down {
		width: 85%;
	}
	.\31\31\34\/120--sm-desk-down,
	.\31\39\/20--sm-desk-down,
	.\35\37\/60--sm-desk-down {
		width: 95%;
	}
	.\31\/24--sm-desk-down,
	.\35\/120--sm-desk-down {
		width: 4.16667%;
	}
	.\32\35\/120--sm-desk-down,
	.\35\/24--sm-desk-down {
		width: 20.83333%;
	}
	.\33\35\/120--sm-desk-down,
	.\37\/24--sm-desk-down {
		width: 29.16667%;
	}
	.\31\31\/24--sm-desk-down,
	.\35\35\/120--sm-desk-down {
		width: 45.83333%;
	}
	.\31\33\/24--sm-desk-down,
	.\36\35\/120--sm-desk-down {
		width: 54.16667%;
	}
	.\31\37\/24--sm-desk-down,
	.\38\35\/120--sm-desk-down {
		width: 70.83333%;
	}
	.\31\39\/24--sm-desk-down,
	.\39\35\/120--sm-desk-down {
		width: 79.16667%;
	}
	.\31\31\35\/120--sm-desk-down,
	.\32\33\/24--sm-desk-down {
		width: 95.83333%;
	}
	.\31\/28--sm-desk-down {
		width: 3.57143%;
	}
	.\33\/28--sm-desk-down {
		width: 10.71429%;
	}
	.\35\/28--sm-desk-down {
		width: 17.85714%;
	}
	.\39\/28--sm-desk-down {
		width: 32.14286%;
	}
	.\31\31\/28--sm-desk-down {
		width: 39.28571%;
	}
	.\31\33\/28--sm-desk-down {
		width: 46.42857%;
	}
	.\31\35\/28--sm-desk-down {
		width: 53.57143%;
	}
	.\31\37\/28--sm-desk-down {
		width: 60.71429%;
	}
	.\31\39\/28--sm-desk-down {
		width: 67.85714%;
	}
	.\32\33\/28--sm-desk-down {
		width: 82.14286%;
	}
	.\32\35\/28--sm-desk-down {
		width: 89.28571%;
	}
	.\32\37\/28--sm-desk-down {
		width: 96.42857%;
	}
	.\31\/60--sm-desk-down,
	.\32\/120--sm-desk-down {
		width: 1.66667%;
	}
	.\32\/60--sm-desk-down,
	.\34\/120--sm-desk-down {
		width: 3.33333%;
	}
	.\31\34\/120--sm-desk-down,
	.\37\/60--sm-desk-down {
		width: 11.66667%;
	}
	.\31\31\/60--sm-desk-down,
	.\32\32\/120--sm-desk-down {
		width: 18.33333%;
	}
	.\31\33\/60--sm-desk-down,
	.\32\36\/120--sm-desk-down {
		width: 21.66667%;
	}
	.\31\34\/60--sm-desk-down,
	.\32\38\/120--sm-desk-down {
		width: 23.33333%;
	}
	.\31\37\/60--sm-desk-down,
	.\33\34\/120--sm-desk-down {
		width: 28.33333%;
	}
	.\31\39\/60--sm-desk-down,
	.\33\38\/120--sm-desk-down {
		width: 31.66667%;
	}
	.\32\32\/60--sm-desk-down,
	.\34\34\/120--sm-desk-down {
		width: 36.66667%;
	}
	.\32\33\/60--sm-desk-down,
	.\34\36\/120--sm-desk-down {
		width: 38.33333%;
	}
	.\32\36\/60--sm-desk-down,
	.\35\32\/120--sm-desk-down {
		width: 43.33333%;
	}
	.\32\39\/60--sm-desk-down,
	.\35\38\/120--sm-desk-down {
		width: 48.33333%;
	}
	.\33\31\/60--sm-desk-down,
	.\36\32\/120--sm-desk-down {
		width: 51.66667%;
	}
	.\33\34\/60--sm-desk-down,
	.\36\38\/120--sm-desk-down {
		width: 56.66667%;
	}
	.\33\37\/60--sm-desk-down,
	.\37\34\/120--sm-desk-down {
		width: 61.66667%;
	}
	.\33\38\/60--sm-desk-down,
	.\37\36\/120--sm-desk-down {
		width: 63.33333%;
	}
	.\34\31\/60--sm-desk-down,
	.\38\32\/120--sm-desk-down {
		width: 68.33333%;
	}
	.\34\33\/60--sm-desk-down,
	.\38\36\/120--sm-desk-down {
		width: 71.66667%;
	}
	.\34\36\/60--sm-desk-down,
	.\39\32\/120--sm-desk-down {
		width: 76.66667%;
	}
	.\34\37\/60--sm-desk-down,
	.\39\34\/120--sm-desk-down {
		width: 78.33333%;
	}
	.\34\39\/60--sm-desk-down,
	.\39\38\/120--sm-desk-down {
		width: 81.66667%;
	}
	.\31\30\36\/120--sm-desk-down,
	.\35\33\/60--sm-desk-down {
		width: 88.33333%;
	}
	.\31\31\36\/120--sm-desk-down,
	.\35\38\/60--sm-desk-down {
		width: 96.66667%;
	}
	.\31\31\38\/120--sm-desk-down,
	.\35\39\/60--sm-desk-down {
		width: 98.33333%;
	}
	.\31\/120--sm-desk-down {
		width: 0.83333%;
	}
	.\33\/120--sm-desk-down {
		width: 2.5%;
	}
	.\37\/120--sm-desk-down {
		width: 5.83333%;
	}
	.\39\/120--sm-desk-down {
		width: 7.5%;
	}
	.\31\31\/120--sm-desk-down {
		width: 9.16667%;
	}
	.\31\33\/120--sm-desk-down {
		width: 10.83333%;
	}
	.\31\37\/120--sm-desk-down {
		width: 14.16667%;
	}
	.\31\39\/120--sm-desk-down {
		width: 15.83333%;
	}
	.\32\31\/120--sm-desk-down {
		width: 17.5%;
	}
	.\32\33\/120--sm-desk-down {
		width: 19.16667%;
	}
	.\32\37\/120--sm-desk-down {
		width: 22.5%;
	}
	.\32\39\/120--sm-desk-down {
		width: 24.16667%;
	}
	.\33\31\/120--sm-desk-down {
		width: 25.83333%;
	}
	.\33\33\/120--sm-desk-down {
		width: 27.5%;
	}
	.\33\37\/120--sm-desk-down {
		width: 30.83333%;
	}
	.\33\39\/120--sm-desk-down {
		width: 32.5%;
	}
	.\34\31\/120--sm-desk-down {
		width: 34.16667%;
	}
	.\34\33\/120--sm-desk-down {
		width: 35.83333%;
	}
	.\34\37\/120--sm-desk-down {
		width: 39.16667%;
	}
	.\34\39\/120--sm-desk-down {
		width: 40.83333%;
	}
	.\35\31\/120--sm-desk-down {
		width: 42.5%;
	}
	.\35\33\/120--sm-desk-down {
		width: 44.16667%;
	}
	.\35\37\/120--sm-desk-down {
		width: 47.5%;
	}
	.\35\39\/120--sm-desk-down {
		width: 49.16667%;
	}
	.\36\31\/120--sm-desk-down {
		width: 50.83333%;
	}
	.\36\33\/120--sm-desk-down {
		width: 52.5%;
	}
	.\36\37\/120--sm-desk-down {
		width: 55.83333%;
	}
	.\36\39\/120--sm-desk-down {
		width: 57.5%;
	}
	.\37\31\/120--sm-desk-down {
		width: 59.16667%;
	}
	.\37\33\/120--sm-desk-down {
		width: 60.83333%;
	}
	.\37\37\/120--sm-desk-down {
		width: 64.16667%;
	}
	.\37\39\/120--sm-desk-down {
		width: 65.83333%;
	}
	.\38\31\/120--sm-desk-down {
		width: 67.5%;
	}
	.\38\33\/120--sm-desk-down {
		width: 69.16667%;
	}
	.\38\37\/120--sm-desk-down {
		width: 72.5%;
	}
	.\38\39\/120--sm-desk-down {
		width: 74.16667%;
	}
	.\39\31\/120--sm-desk-down {
		width: 75.83333%;
	}
	.\39\33\/120--sm-desk-down {
		width: 77.5%;
	}
	.\39\37\/120--sm-desk-down {
		width: 80.83333%;
	}
	.\39\39\/120--sm-desk-down {
		width: 82.5%;
	}
	.\31\30\31\/120--sm-desk-down {
		width: 84.16667%;
	}
	.\31\30\33\/120--sm-desk-down {
		width: 85.83333%;
	}
	.\31\30\37\/120--sm-desk-down {
		width: 89.16667%;
	}
	.\31\30\39\/120--sm-desk-down {
		width: 90.83333%;
	}
	.\31\31\31\/120--sm-desk-down {
		width: 92.5%;
	}
	.\31\31\33\/120--sm-desk-down {
		width: 94.16667%;
	}
	.\31\31\37\/120--sm-desk-down {
		width: 97.5%;
	}
	.\31\31\39\/120--sm-desk-down {
		width: 99.16667%;
	}
}
@media screen and (min-width: 1200px) {
	.\31\/2--lg-desk-up,
	.\31\30\/20--lg-desk-up,
	.\31\32\/24--lg-desk-up,
	.\31\34\/28--lg-desk-up,
	.\32\/4--lg-desk-up,
	.\33\/6--lg-desk-up,
	.\33\30\/60--lg-desk-up,
	.\34\/8--lg-desk-up,
	.\35\/10--lg-desk-up,
	.\36\/12--lg-desk-up,
	.\36\30\/120--lg-desk-up,
	.\37\/14--lg-desk-up,
	.\38\/16--lg-desk-up,
	.\39\/18--lg-desk-up {
		width: 50%;
	}
	.\31\/3--lg-desk-up,
	.\32\/6--lg-desk-up,
	.\32\30\/60--lg-desk-up,
	.\33\/9--lg-desk-up,
	.\34\/12--lg-desk-up,
	.\34\30\/120--lg-desk-up,
	.\35\/15--lg-desk-up,
	.\36\/18--lg-desk-up,
	.\38\/24--lg-desk-up {
		width: 33.33333%;
	}
	.\31\30\/15--lg-desk-up,
	.\31\32\/18--lg-desk-up,
	.\31\36\/24--lg-desk-up,
	.\32\/3--lg-desk-up,
	.\34\/6--lg-desk-up,
	.\34\30\/60--lg-desk-up,
	.\36\/9--lg-desk-up,
	.\38\/12--lg-desk-up,
	.\38\30\/120--lg-desk-up {
		width: 66.66667%;
	}
	.\31\/4--lg-desk-up,
	.\31\35\/60--lg-desk-up,
	.\32\/8--lg-desk-up,
	.\33\/12--lg-desk-up,
	.\33\30\/120--lg-desk-up,
	.\34\/16--lg-desk-up,
	.\35\/20--lg-desk-up,
	.\36\/24--lg-desk-up,
	.\37\/28--lg-desk-up {
		width: 25%;
	}
	.\31\32\/16--lg-desk-up,
	.\31\35\/20--lg-desk-up,
	.\31\38\/24--lg-desk-up,
	.\32\31\/28--lg-desk-up,
	.\33\/4--lg-desk-up,
	.\34\35\/60--lg-desk-up,
	.\36\/8--lg-desk-up,
	.\39\/12--lg-desk-up,
	.\39\30\/120--lg-desk-up {
		width: 75%;
	}
	.\31\/5--lg-desk-up,
	.\31\32\/60--lg-desk-up,
	.\32\/10--lg-desk-up,
	.\32\34\/120--lg-desk-up,
	.\33\/15--lg-desk-up,
	.\34\/20--lg-desk-up {
		width: 20%;
	}
	.\32\/5--lg-desk-up,
	.\32\34\/60--lg-desk-up,
	.\34\/10--lg-desk-up,
	.\34\38\/120--lg-desk-up,
	.\36\/15--lg-desk-up,
	.\38\/20--lg-desk-up {
		width: 40%;
	}
	.\31\32\/20--lg-desk-up,
	.\33\/5--lg-desk-up,
	.\33\36\/60--lg-desk-up,
	.\36\/10--lg-desk-up,
	.\37\32\/120--lg-desk-up,
	.\39\/15--lg-desk-up {
		width: 60%;
	}
	.\31\32\/15--lg-desk-up,
	.\31\36\/20--lg-desk-up,
	.\34\/5--lg-desk-up,
	.\34\38\/60--lg-desk-up,
	.\38\/10--lg-desk-up,
	.\39\36\/120--lg-desk-up {
		width: 80%;
	}
	.\31\/6--lg-desk-up,
	.\31\30\/60--lg-desk-up,
	.\32\/12--lg-desk-up,
	.\32\30\/120--lg-desk-up,
	.\33\/18--lg-desk-up,
	.\34\/24--lg-desk-up {
		width: 16.66667%;
	}
	.\31\30\/12--lg-desk-up,
	.\31\30\30\/120--lg-desk-up,
	.\31\35\/18--lg-desk-up,
	.\32\30\/24--lg-desk-up,
	.\35\/6--lg-desk-up,
	.\35\30\/60--lg-desk-up {
		width: 83.33333%;
	}
	.\31\/7--lg-desk-up,
	.\32\/14--lg-desk-up,
	.\34\/28--lg-desk-up {
		width: 14.28571%;
	}
	.\32\/7--lg-desk-up,
	.\34\/14--lg-desk-up,
	.\38\/28--lg-desk-up {
		width: 28.57143%;
	}
	.\31\32\/28--lg-desk-up,
	.\33\/7--lg-desk-up,
	.\36\/14--lg-desk-up {
		width: 42.85714%;
	}
	.\31\36\/28--lg-desk-up,
	.\34\/7--lg-desk-up,
	.\38\/14--lg-desk-up {
		width: 57.14286%;
	}
	.\31\30\/14--lg-desk-up,
	.\32\30\/28--lg-desk-up,
	.\35\/7--lg-desk-up {
		width: 71.42857%;
	}
	.\31\32\/14--lg-desk-up,
	.\32\34\/28--lg-desk-up,
	.\36\/7--lg-desk-up {
		width: 85.71429%;
	}
	.\31\/8--lg-desk-up,
	.\31\35\/120--lg-desk-up,
	.\32\/16--lg-desk-up,
	.\33\/24--lg-desk-up {
		width: 12.5%;
	}
	.\33\/8--lg-desk-up,
	.\34\35\/120--lg-desk-up,
	.\36\/16--lg-desk-up,
	.\39\/24--lg-desk-up {
		width: 37.5%;
	}
	.\31\30\/16--lg-desk-up,
	.\31\35\/24--lg-desk-up,
	.\35\/8--lg-desk-up,
	.\37\35\/120--lg-desk-up {
		width: 62.5%;
	}
	.\31\30\35\/120--lg-desk-up,
	.\31\34\/16--lg-desk-up,
	.\32\31\/24--lg-desk-up,
	.\37\/8--lg-desk-up {
		width: 87.5%;
	}
	.\31\/9--lg-desk-up,
	.\32\/18--lg-desk-up {
		width: 11.11111%;
	}
	.\32\/9--lg-desk-up,
	.\34\/18--lg-desk-up {
		width: 22.22222%;
	}
	.\34\/9--lg-desk-up,
	.\38\/18--lg-desk-up {
		width: 44.44444%;
	}
	.\31\30\/18--lg-desk-up,
	.\35\/9--lg-desk-up {
		width: 55.55556%;
	}
	.\31\34\/18--lg-desk-up,
	.\37\/9--lg-desk-up {
		width: 77.77778%;
	}
	.\31\36\/18--lg-desk-up,
	.\38\/9--lg-desk-up {
		width: 88.88889%;
	}
	.\31\/10--lg-desk-up,
	.\31\32\/120--lg-desk-up,
	.\32\/20--lg-desk-up,
	.\36\/60--lg-desk-up {
		width: 10%;
	}
	.\31\38\/60--lg-desk-up,
	.\33\/10--lg-desk-up,
	.\33\36\/120--lg-desk-up,
	.\36\/20--lg-desk-up {
		width: 30%;
	}
	.\31\34\/20--lg-desk-up,
	.\34\32\/60--lg-desk-up,
	.\37\/10--lg-desk-up,
	.\38\34\/120--lg-desk-up {
		width: 70%;
	}
	.\31\30\38\/120--lg-desk-up,
	.\31\38\/20--lg-desk-up,
	.\35\34\/60--lg-desk-up,
	.\39\/10--lg-desk-up {
		width: 90%;
	}
	.\31\/11--lg-desk-up {
		width: 9.09091%;
	}
	.\32\/11--lg-desk-up {
		width: 18.18182%;
	}
	.\33\/11--lg-desk-up {
		width: 27.27273%;
	}
	.\34\/11--lg-desk-up {
		width: 36.36364%;
	}
	.\35\/11--lg-desk-up {
		width: 45.45455%;
	}
	.\36\/11--lg-desk-up {
		width: 54.54545%;
	}
	.\37\/11--lg-desk-up {
		width: 63.63636%;
	}
	.\38\/11--lg-desk-up {
		width: 72.72727%;
	}
	.\39\/11--lg-desk-up {
		width: 81.81818%;
	}
	.\31\30\/11--lg-desk-up {
		width: 90.90909%;
	}
	.\31\/12--lg-desk-up,
	.\31\30\/120--lg-desk-up,
	.\32\/24--lg-desk-up,
	.\35\/60--lg-desk-up {
		width: 8.33333%;
	}
	.\31\30\/24--lg-desk-up,
	.\32\35\/60--lg-desk-up,
	.\35\/12--lg-desk-up,
	.\35\30\/120--lg-desk-up {
		width: 41.66667%;
	}
	.\31\34\/24--lg-desk-up,
	.\33\35\/60--lg-desk-up,
	.\37\/12--lg-desk-up,
	.\37\30\/120--lg-desk-up {
		width: 58.33333%;
	}
	.\31\31\/12--lg-desk-up,
	.\31\31\30\/120--lg-desk-up,
	.\32\32\/24--lg-desk-up,
	.\35\35\/60--lg-desk-up {
		width: 91.66667%;
	}
	.\31\/13--lg-desk-up {
		width: 7.69231%;
	}
	.\32\/13--lg-desk-up {
		width: 15.38462%;
	}
	.\33\/13--lg-desk-up {
		width: 23.07692%;
	}
	.\34\/13--lg-desk-up {
		width: 30.76923%;
	}
	.\35\/13--lg-desk-up {
		width: 38.46154%;
	}
	.\36\/13--lg-desk-up {
		width: 46.15385%;
	}
	.\37\/13--lg-desk-up {
		width: 53.84615%;
	}
	.\38\/13--lg-desk-up {
		width: 61.53846%;
	}
	.\39\/13--lg-desk-up {
		width: 69.23077%;
	}
	.\31\30\/13--lg-desk-up {
		width: 76.92308%;
	}
	.\31\31\/13--lg-desk-up {
		width: 84.61538%;
	}
	.\31\32\/13--lg-desk-up {
		width: 92.30769%;
	}
	.\31\/14--lg-desk-up,
	.\32\/28--lg-desk-up {
		width: 7.14286%;
	}
	.\33\/14--lg-desk-up,
	.\36\/28--lg-desk-up {
		width: 21.42857%;
	}
	.\31\30\/28--lg-desk-up,
	.\35\/14--lg-desk-up {
		width: 35.71429%;
	}
	.\31\38\/28--lg-desk-up,
	.\39\/14--lg-desk-up {
		width: 64.28571%;
	}
	.\31\31\/14--lg-desk-up,
	.\32\32\/28--lg-desk-up {
		width: 78.57143%;
	}
	.\31\33\/14--lg-desk-up,
	.\32\36\/28--lg-desk-up {
		width: 92.85714%;
	}
	.\31\/15--lg-desk-up,
	.\34\/60--lg-desk-up,
	.\38\/120--lg-desk-up {
		width: 6.66667%;
	}
	.\31\36\/120--lg-desk-up,
	.\32\/15--lg-desk-up,
	.\38\/60--lg-desk-up {
		width: 13.33333%;
	}
	.\31\36\/60--lg-desk-up,
	.\33\32\/120--lg-desk-up,
	.\34\/15--lg-desk-up {
		width: 26.66667%;
	}
	.\32\38\/60--lg-desk-up,
	.\35\36\/120--lg-desk-up,
	.\37\/15--lg-desk-up {
		width: 46.66667%;
	}
	.\33\32\/60--lg-desk-up,
	.\36\34\/120--lg-desk-up,
	.\38\/15--lg-desk-up {
		width: 53.33333%;
	}
	.\31\31\/15--lg-desk-up,
	.\34\34\/60--lg-desk-up,
	.\38\38\/120--lg-desk-up {
		width: 73.33333%;
	}
	.\31\30\34\/120--lg-desk-up,
	.\31\33\/15--lg-desk-up,
	.\35\32\/60--lg-desk-up {
		width: 86.66667%;
	}
	.\31\31\32\/120--lg-desk-up,
	.\31\34\/15--lg-desk-up,
	.\35\36\/60--lg-desk-up {
		width: 93.33333%;
	}
	.\31\/16--lg-desk-up {
		width: 6.25%;
	}
	.\33\/16--lg-desk-up {
		width: 18.75%;
	}
	.\35\/16--lg-desk-up {
		width: 31.25%;
	}
	.\37\/16--lg-desk-up {
		width: 43.75%;
	}
	.\39\/16--lg-desk-up {
		width: 56.25%;
	}
	.\31\31\/16--lg-desk-up {
		width: 68.75%;
	}
	.\31\33\/16--lg-desk-up {
		width: 81.25%;
	}
	.\31\35\/16--lg-desk-up {
		width: 93.75%;
	}
	.\31\/17--lg-desk-up {
		width: 5.88235%;
	}
	.\32\/17--lg-desk-up {
		width: 11.76471%;
	}
	.\33\/17--lg-desk-up {
		width: 17.64706%;
	}
	.\34\/17--lg-desk-up {
		width: 23.52941%;
	}
	.\35\/17--lg-desk-up {
		width: 29.41176%;
	}
	.\36\/17--lg-desk-up {
		width: 35.29412%;
	}
	.\37\/17--lg-desk-up {
		width: 41.17647%;
	}
	.\38\/17--lg-desk-up {
		width: 47.05882%;
	}
	.\39\/17--lg-desk-up {
		width: 52.94118%;
	}
	.\31\30\/17--lg-desk-up {
		width: 58.82353%;
	}
	.\31\31\/17--lg-desk-up {
		width: 64.70588%;
	}
	.\31\32\/17--lg-desk-up {
		width: 70.58824%;
	}
	.\31\33\/17--lg-desk-up {
		width: 76.47059%;
	}
	.\31\34\/17--lg-desk-up {
		width: 82.35294%;
	}
	.\31\35\/17--lg-desk-up {
		width: 88.23529%;
	}
	.\31\36\/17--lg-desk-up {
		width: 94.11765%;
	}
	.\31\/18--lg-desk-up {
		width: 5.55556%;
	}
	.\35\/18--lg-desk-up {
		width: 27.77778%;
	}
	.\37\/18--lg-desk-up {
		width: 38.88889%;
	}
	.\31\31\/18--lg-desk-up {
		width: 61.11111%;
	}
	.\31\33\/18--lg-desk-up {
		width: 72.22222%;
	}
	.\31\37\/18--lg-desk-up {
		width: 94.44444%;
	}
	.\31\/19--lg-desk-up {
		width: 5.26316%;
	}
	.\32\/19--lg-desk-up {
		width: 10.52632%;
	}
	.\33\/19--lg-desk-up {
		width: 15.78947%;
	}
	.\34\/19--lg-desk-up {
		width: 21.05263%;
	}
	.\35\/19--lg-desk-up {
		width: 26.31579%;
	}
	.\36\/19--lg-desk-up {
		width: 31.57895%;
	}
	.\37\/19--lg-desk-up {
		width: 36.84211%;
	}
	.\38\/19--lg-desk-up {
		width: 42.10526%;
	}
	.\39\/19--lg-desk-up {
		width: 47.36842%;
	}
	.\31\30\/19--lg-desk-up {
		width: 52.63158%;
	}
	.\31\31\/19--lg-desk-up {
		width: 57.89474%;
	}
	.\31\32\/19--lg-desk-up {
		width: 63.15789%;
	}
	.\31\33\/19--lg-desk-up {
		width: 68.42105%;
	}
	.\31\34\/19--lg-desk-up {
		width: 73.68421%;
	}
	.\31\35\/19--lg-desk-up {
		width: 78.94737%;
	}
	.\31\36\/19--lg-desk-up {
		width: 84.21053%;
	}
	.\31\37\/19--lg-desk-up {
		width: 89.47368%;
	}
	.\31\38\/19--lg-desk-up {
		width: 94.73684%;
	}
	.\31\/20--lg-desk-up,
	.\33\/60--lg-desk-up,
	.\36\/120--lg-desk-up {
		width: 5%;
	}
	.\31\38\/120--lg-desk-up,
	.\33\/20--lg-desk-up,
	.\39\/60--lg-desk-up {
		width: 15%;
	}
	.\32\31\/60--lg-desk-up,
	.\34\32\/120--lg-desk-up,
	.\37\/20--lg-desk-up {
		width: 35%;
	}
	.\32\37\/60--lg-desk-up,
	.\35\34\/120--lg-desk-up,
	.\39\/20--lg-desk-up {
		width: 45%;
	}
	.\31\31\/20--lg-desk-up,
	.\33\33\/60--lg-desk-up,
	.\36\36\/120--lg-desk-up {
		width: 55%;
	}
	.\31\33\/20--lg-desk-up,
	.\33\39\/60--lg-desk-up,
	.\37\38\/120--lg-desk-up {
		width: 65%;
	}
	.\31\30\32\/120--lg-desk-up,
	.\31\37\/20--lg-desk-up,
	.\35\31\/60--lg-desk-up {
		width: 85%;
	}
	.\31\31\34\/120--lg-desk-up,
	.\31\39\/20--lg-desk-up,
	.\35\37\/60--lg-desk-up {
		width: 95%;
	}
	.\31\/24--lg-desk-up,
	.\35\/120--lg-desk-up {
		width: 4.16667%;
	}
	.\32\35\/120--lg-desk-up,
	.\35\/24--lg-desk-up {
		width: 20.83333%;
	}
	.\33\35\/120--lg-desk-up,
	.\37\/24--lg-desk-up {
		width: 29.16667%;
	}
	.\31\31\/24--lg-desk-up,
	.\35\35\/120--lg-desk-up {
		width: 45.83333%;
	}
	.\31\33\/24--lg-desk-up,
	.\36\35\/120--lg-desk-up {
		width: 54.16667%;
	}
	.\31\37\/24--lg-desk-up,
	.\38\35\/120--lg-desk-up {
		width: 70.83333%;
	}
	.\31\39\/24--lg-desk-up,
	.\39\35\/120--lg-desk-up {
		width: 79.16667%;
	}
	.\31\31\35\/120--lg-desk-up,
	.\32\33\/24--lg-desk-up {
		width: 95.83333%;
	}
	.\31\/28--lg-desk-up {
		width: 3.57143%;
	}
	.\33\/28--lg-desk-up {
		width: 10.71429%;
	}
	.\35\/28--lg-desk-up {
		width: 17.85714%;
	}
	.\39\/28--lg-desk-up {
		width: 32.14286%;
	}
	.\31\31\/28--lg-desk-up {
		width: 39.28571%;
	}
	.\31\33\/28--lg-desk-up {
		width: 46.42857%;
	}
	.\31\35\/28--lg-desk-up {
		width: 53.57143%;
	}
	.\31\37\/28--lg-desk-up {
		width: 60.71429%;
	}
	.\31\39\/28--lg-desk-up {
		width: 67.85714%;
	}
	.\32\33\/28--lg-desk-up {
		width: 82.14286%;
	}
	.\32\35\/28--lg-desk-up {
		width: 89.28571%;
	}
	.\32\37\/28--lg-desk-up {
		width: 96.42857%;
	}
	.\31\/60--lg-desk-up,
	.\32\/120--lg-desk-up {
		width: 1.66667%;
	}
	.\32\/60--lg-desk-up,
	.\34\/120--lg-desk-up {
		width: 3.33333%;
	}
	.\31\34\/120--lg-desk-up,
	.\37\/60--lg-desk-up {
		width: 11.66667%;
	}
	.\31\31\/60--lg-desk-up,
	.\32\32\/120--lg-desk-up {
		width: 18.33333%;
	}
	.\31\33\/60--lg-desk-up,
	.\32\36\/120--lg-desk-up {
		width: 21.66667%;
	}
	.\31\34\/60--lg-desk-up,
	.\32\38\/120--lg-desk-up {
		width: 23.33333%;
	}
	.\31\37\/60--lg-desk-up,
	.\33\34\/120--lg-desk-up {
		width: 28.33333%;
	}
	.\31\39\/60--lg-desk-up,
	.\33\38\/120--lg-desk-up {
		width: 31.66667%;
	}
	.\32\32\/60--lg-desk-up,
	.\34\34\/120--lg-desk-up {
		width: 36.66667%;
	}
	.\32\33\/60--lg-desk-up,
	.\34\36\/120--lg-desk-up {
		width: 38.33333%;
	}
	.\32\36\/60--lg-desk-up,
	.\35\32\/120--lg-desk-up {
		width: 43.33333%;
	}
	.\32\39\/60--lg-desk-up,
	.\35\38\/120--lg-desk-up {
		width: 48.33333%;
	}
	.\33\31\/60--lg-desk-up,
	.\36\32\/120--lg-desk-up {
		width: 51.66667%;
	}
	.\33\34\/60--lg-desk-up,
	.\36\38\/120--lg-desk-up {
		width: 56.66667%;
	}
	.\33\37\/60--lg-desk-up,
	.\37\34\/120--lg-desk-up {
		width: 61.66667%;
	}
	.\33\38\/60--lg-desk-up,
	.\37\36\/120--lg-desk-up {
		width: 63.33333%;
	}
	.\34\31\/60--lg-desk-up,
	.\38\32\/120--lg-desk-up {
		width: 68.33333%;
	}
	.\34\33\/60--lg-desk-up,
	.\38\36\/120--lg-desk-up {
		width: 71.66667%;
	}
	.\34\36\/60--lg-desk-up,
	.\39\32\/120--lg-desk-up {
		width: 76.66667%;
	}
	.\34\37\/60--lg-desk-up,
	.\39\34\/120--lg-desk-up {
		width: 78.33333%;
	}
	.\34\39\/60--lg-desk-up,
	.\39\38\/120--lg-desk-up {
		width: 81.66667%;
	}
	.\31\30\36\/120--lg-desk-up,
	.\35\33\/60--lg-desk-up {
		width: 88.33333%;
	}
	.\31\31\36\/120--lg-desk-up,
	.\35\38\/60--lg-desk-up {
		width: 96.66667%;
	}
	.\31\31\38\/120--lg-desk-up,
	.\35\39\/60--lg-desk-up {
		width: 98.33333%;
	}
	.\31\/120--lg-desk-up {
		width: 0.83333%;
	}
	.\33\/120--lg-desk-up {
		width: 2.5%;
	}
	.\37\/120--lg-desk-up {
		width: 5.83333%;
	}
	.\39\/120--lg-desk-up {
		width: 7.5%;
	}
	.\31\31\/120--lg-desk-up {
		width: 9.16667%;
	}
	.\31\33\/120--lg-desk-up {
		width: 10.83333%;
	}
	.\31\37\/120--lg-desk-up {
		width: 14.16667%;
	}
	.\31\39\/120--lg-desk-up {
		width: 15.83333%;
	}
	.\32\31\/120--lg-desk-up {
		width: 17.5%;
	}
	.\32\33\/120--lg-desk-up {
		width: 19.16667%;
	}
	.\32\37\/120--lg-desk-up {
		width: 22.5%;
	}
	.\32\39\/120--lg-desk-up {
		width: 24.16667%;
	}
	.\33\31\/120--lg-desk-up {
		width: 25.83333%;
	}
	.\33\33\/120--lg-desk-up {
		width: 27.5%;
	}
	.\33\37\/120--lg-desk-up {
		width: 30.83333%;
	}
	.\33\39\/120--lg-desk-up {
		width: 32.5%;
	}
	.\34\31\/120--lg-desk-up {
		width: 34.16667%;
	}
	.\34\33\/120--lg-desk-up {
		width: 35.83333%;
	}
	.\34\37\/120--lg-desk-up {
		width: 39.16667%;
	}
	.\34\39\/120--lg-desk-up {
		width: 40.83333%;
	}
	.\35\31\/120--lg-desk-up {
		width: 42.5%;
	}
	.\35\33\/120--lg-desk-up {
		width: 44.16667%;
	}
	.\35\37\/120--lg-desk-up {
		width: 47.5%;
	}
	.\35\39\/120--lg-desk-up {
		width: 49.16667%;
	}
	.\36\31\/120--lg-desk-up {
		width: 50.83333%;
	}
	.\36\33\/120--lg-desk-up {
		width: 52.5%;
	}
	.\36\37\/120--lg-desk-up {
		width: 55.83333%;
	}
	.\36\39\/120--lg-desk-up {
		width: 57.5%;
	}
	.\37\31\/120--lg-desk-up {
		width: 59.16667%;
	}
	.\37\33\/120--lg-desk-up {
		width: 60.83333%;
	}
	.\37\37\/120--lg-desk-up {
		width: 64.16667%;
	}
	.\37\39\/120--lg-desk-up {
		width: 65.83333%;
	}
	.\38\31\/120--lg-desk-up {
		width: 67.5%;
	}
	.\38\33\/120--lg-desk-up {
		width: 69.16667%;
	}
	.\38\37\/120--lg-desk-up {
		width: 72.5%;
	}
	.\38\39\/120--lg-desk-up {
		width: 74.16667%;
	}
	.\39\31\/120--lg-desk-up {
		width: 75.83333%;
	}
	.\39\33\/120--lg-desk-up {
		width: 77.5%;
	}
	.\39\37\/120--lg-desk-up {
		width: 80.83333%;
	}
	.\39\39\/120--lg-desk-up {
		width: 82.5%;
	}
	.\31\30\31\/120--lg-desk-up {
		width: 84.16667%;
	}
	.\31\30\33\/120--lg-desk-up {
		width: 85.83333%;
	}
	.\31\30\37\/120--lg-desk-up {
		width: 89.16667%;
	}
	.\31\30\39\/120--lg-desk-up {
		width: 90.83333%;
	}
	.\31\31\31\/120--lg-desk-up {
		width: 92.5%;
	}
	.\31\31\33\/120--lg-desk-up {
		width: 94.16667%;
	}
	.\31\31\37\/120--lg-desk-up {
		width: 97.5%;
	}
	.\31\31\39\/120--lg-desk-up {
		width: 99.16667%;
	}
}
@media screen and (max-width: 1199px) {
	.\31\/2--lg-desk-down,
	.\31\30\/20--lg-desk-down,
	.\31\32\/24--lg-desk-down,
	.\31\34\/28--lg-desk-down,
	.\32\/4--lg-desk-down,
	.\33\/6--lg-desk-down,
	.\33\30\/60--lg-desk-down,
	.\34\/8--lg-desk-down,
	.\35\/10--lg-desk-down,
	.\36\/12--lg-desk-down,
	.\36\30\/120--lg-desk-down,
	.\37\/14--lg-desk-down,
	.\38\/16--lg-desk-down,
	.\39\/18--lg-desk-down {
		width: 50%;
	}
	.\31\/3--lg-desk-down,
	.\32\/6--lg-desk-down,
	.\32\30\/60--lg-desk-down,
	.\33\/9--lg-desk-down,
	.\34\/12--lg-desk-down,
	.\34\30\/120--lg-desk-down,
	.\35\/15--lg-desk-down,
	.\36\/18--lg-desk-down,
	.\38\/24--lg-desk-down {
		width: 33.33333%;
	}
	.\31\30\/15--lg-desk-down,
	.\31\32\/18--lg-desk-down,
	.\31\36\/24--lg-desk-down,
	.\32\/3--lg-desk-down,
	.\34\/6--lg-desk-down,
	.\34\30\/60--lg-desk-down,
	.\36\/9--lg-desk-down,
	.\38\/12--lg-desk-down,
	.\38\30\/120--lg-desk-down {
		width: 66.66667%;
	}
	.\31\/4--lg-desk-down,
	.\31\35\/60--lg-desk-down,
	.\32\/8--lg-desk-down,
	.\33\/12--lg-desk-down,
	.\33\30\/120--lg-desk-down,
	.\34\/16--lg-desk-down,
	.\35\/20--lg-desk-down,
	.\36\/24--lg-desk-down,
	.\37\/28--lg-desk-down {
		width: 25%;
	}
	.\31\32\/16--lg-desk-down,
	.\31\35\/20--lg-desk-down,
	.\31\38\/24--lg-desk-down,
	.\32\31\/28--lg-desk-down,
	.\33\/4--lg-desk-down,
	.\34\35\/60--lg-desk-down,
	.\36\/8--lg-desk-down,
	.\39\/12--lg-desk-down,
	.\39\30\/120--lg-desk-down {
		width: 75%;
	}
	.\31\/5--lg-desk-down,
	.\31\32\/60--lg-desk-down,
	.\32\/10--lg-desk-down,
	.\32\34\/120--lg-desk-down,
	.\33\/15--lg-desk-down,
	.\34\/20--lg-desk-down {
		width: 20%;
	}
	.\32\/5--lg-desk-down,
	.\32\34\/60--lg-desk-down,
	.\34\/10--lg-desk-down,
	.\34\38\/120--lg-desk-down,
	.\36\/15--lg-desk-down,
	.\38\/20--lg-desk-down {
		width: 40%;
	}
	.\31\32\/20--lg-desk-down,
	.\33\/5--lg-desk-down,
	.\33\36\/60--lg-desk-down,
	.\36\/10--lg-desk-down,
	.\37\32\/120--lg-desk-down,
	.\39\/15--lg-desk-down {
		width: 60%;
	}
	.\31\32\/15--lg-desk-down,
	.\31\36\/20--lg-desk-down,
	.\34\/5--lg-desk-down,
	.\34\38\/60--lg-desk-down,
	.\38\/10--lg-desk-down,
	.\39\36\/120--lg-desk-down {
		width: 80%;
	}
	.\31\/6--lg-desk-down,
	.\31\30\/60--lg-desk-down,
	.\32\/12--lg-desk-down,
	.\32\30\/120--lg-desk-down,
	.\33\/18--lg-desk-down,
	.\34\/24--lg-desk-down {
		width: 16.66667%;
	}
	.\31\30\/12--lg-desk-down,
	.\31\30\30\/120--lg-desk-down,
	.\31\35\/18--lg-desk-down,
	.\32\30\/24--lg-desk-down,
	.\35\/6--lg-desk-down,
	.\35\30\/60--lg-desk-down {
		width: 83.33333%;
	}
	.\31\/7--lg-desk-down,
	.\32\/14--lg-desk-down,
	.\34\/28--lg-desk-down {
		width: 14.28571%;
	}
	.\32\/7--lg-desk-down,
	.\34\/14--lg-desk-down,
	.\38\/28--lg-desk-down {
		width: 28.57143%;
	}
	.\31\32\/28--lg-desk-down,
	.\33\/7--lg-desk-down,
	.\36\/14--lg-desk-down {
		width: 42.85714%;
	}
	.\31\36\/28--lg-desk-down,
	.\34\/7--lg-desk-down,
	.\38\/14--lg-desk-down {
		width: 57.14286%;
	}
	.\31\30\/14--lg-desk-down,
	.\32\30\/28--lg-desk-down,
	.\35\/7--lg-desk-down {
		width: 71.42857%;
	}
	.\31\32\/14--lg-desk-down,
	.\32\34\/28--lg-desk-down,
	.\36\/7--lg-desk-down {
		width: 85.71429%;
	}
	.\31\/8--lg-desk-down,
	.\31\35\/120--lg-desk-down,
	.\32\/16--lg-desk-down,
	.\33\/24--lg-desk-down {
		width: 12.5%;
	}
	.\33\/8--lg-desk-down,
	.\34\35\/120--lg-desk-down,
	.\36\/16--lg-desk-down,
	.\39\/24--lg-desk-down {
		width: 37.5%;
	}
	.\31\30\/16--lg-desk-down,
	.\31\35\/24--lg-desk-down,
	.\35\/8--lg-desk-down,
	.\37\35\/120--lg-desk-down {
		width: 62.5%;
	}
	.\31\30\35\/120--lg-desk-down,
	.\31\34\/16--lg-desk-down,
	.\32\31\/24--lg-desk-down,
	.\37\/8--lg-desk-down {
		width: 87.5%;
	}
	.\31\/9--lg-desk-down,
	.\32\/18--lg-desk-down {
		width: 11.11111%;
	}
	.\32\/9--lg-desk-down,
	.\34\/18--lg-desk-down {
		width: 22.22222%;
	}
	.\34\/9--lg-desk-down,
	.\38\/18--lg-desk-down {
		width: 44.44444%;
	}
	.\31\30\/18--lg-desk-down,
	.\35\/9--lg-desk-down {
		width: 55.55556%;
	}
	.\31\34\/18--lg-desk-down,
	.\37\/9--lg-desk-down {
		width: 77.77778%;
	}
	.\31\36\/18--lg-desk-down,
	.\38\/9--lg-desk-down {
		width: 88.88889%;
	}
	.\31\/10--lg-desk-down,
	.\31\32\/120--lg-desk-down,
	.\32\/20--lg-desk-down,
	.\36\/60--lg-desk-down {
		width: 10%;
	}
	.\31\38\/60--lg-desk-down,
	.\33\/10--lg-desk-down,
	.\33\36\/120--lg-desk-down,
	.\36\/20--lg-desk-down {
		width: 30%;
	}
	.\31\34\/20--lg-desk-down,
	.\34\32\/60--lg-desk-down,
	.\37\/10--lg-desk-down,
	.\38\34\/120--lg-desk-down {
		width: 70%;
	}
	.\31\30\38\/120--lg-desk-down,
	.\31\38\/20--lg-desk-down,
	.\35\34\/60--lg-desk-down,
	.\39\/10--lg-desk-down {
		width: 90%;
	}
	.\31\/11--lg-desk-down {
		width: 9.09091%;
	}
	.\32\/11--lg-desk-down {
		width: 18.18182%;
	}
	.\33\/11--lg-desk-down {
		width: 27.27273%;
	}
	.\34\/11--lg-desk-down {
		width: 36.36364%;
	}
	.\35\/11--lg-desk-down {
		width: 45.45455%;
	}
	.\36\/11--lg-desk-down {
		width: 54.54545%;
	}
	.\37\/11--lg-desk-down {
		width: 63.63636%;
	}
	.\38\/11--lg-desk-down {
		width: 72.72727%;
	}
	.\39\/11--lg-desk-down {
		width: 81.81818%;
	}
	.\31\30\/11--lg-desk-down {
		width: 90.90909%;
	}
	.\31\/12--lg-desk-down,
	.\31\30\/120--lg-desk-down,
	.\32\/24--lg-desk-down,
	.\35\/60--lg-desk-down {
		width: 8.33333%;
	}
	.\31\30\/24--lg-desk-down,
	.\32\35\/60--lg-desk-down,
	.\35\/12--lg-desk-down,
	.\35\30\/120--lg-desk-down {
		width: 41.66667%;
	}
	.\31\34\/24--lg-desk-down,
	.\33\35\/60--lg-desk-down,
	.\37\/12--lg-desk-down,
	.\37\30\/120--lg-desk-down {
		width: 58.33333%;
	}
	.\31\31\/12--lg-desk-down,
	.\31\31\30\/120--lg-desk-down,
	.\32\32\/24--lg-desk-down,
	.\35\35\/60--lg-desk-down {
		width: 91.66667%;
	}
	.\31\/13--lg-desk-down {
		width: 7.69231%;
	}
	.\32\/13--lg-desk-down {
		width: 15.38462%;
	}
	.\33\/13--lg-desk-down {
		width: 23.07692%;
	}
	.\34\/13--lg-desk-down {
		width: 30.76923%;
	}
	.\35\/13--lg-desk-down {
		width: 38.46154%;
	}
	.\36\/13--lg-desk-down {
		width: 46.15385%;
	}
	.\37\/13--lg-desk-down {
		width: 53.84615%;
	}
	.\38\/13--lg-desk-down {
		width: 61.53846%;
	}
	.\39\/13--lg-desk-down {
		width: 69.23077%;
	}
	.\31\30\/13--lg-desk-down {
		width: 76.92308%;
	}
	.\31\31\/13--lg-desk-down {
		width: 84.61538%;
	}
	.\31\32\/13--lg-desk-down {
		width: 92.30769%;
	}
	.\31\/14--lg-desk-down,
	.\32\/28--lg-desk-down {
		width: 7.14286%;
	}
	.\33\/14--lg-desk-down,
	.\36\/28--lg-desk-down {
		width: 21.42857%;
	}
	.\31\30\/28--lg-desk-down,
	.\35\/14--lg-desk-down {
		width: 35.71429%;
	}
	.\31\38\/28--lg-desk-down,
	.\39\/14--lg-desk-down {
		width: 64.28571%;
	}
	.\31\31\/14--lg-desk-down,
	.\32\32\/28--lg-desk-down {
		width: 78.57143%;
	}
	.\31\33\/14--lg-desk-down,
	.\32\36\/28--lg-desk-down {
		width: 92.85714%;
	}
	.\31\/15--lg-desk-down,
	.\34\/60--lg-desk-down,
	.\38\/120--lg-desk-down {
		width: 6.66667%;
	}
	.\31\36\/120--lg-desk-down,
	.\32\/15--lg-desk-down,
	.\38\/60--lg-desk-down {
		width: 13.33333%;
	}
	.\31\36\/60--lg-desk-down,
	.\33\32\/120--lg-desk-down,
	.\34\/15--lg-desk-down {
		width: 26.66667%;
	}
	.\32\38\/60--lg-desk-down,
	.\35\36\/120--lg-desk-down,
	.\37\/15--lg-desk-down {
		width: 46.66667%;
	}
	.\33\32\/60--lg-desk-down,
	.\36\34\/120--lg-desk-down,
	.\38\/15--lg-desk-down {
		width: 53.33333%;
	}
	.\31\31\/15--lg-desk-down,
	.\34\34\/60--lg-desk-down,
	.\38\38\/120--lg-desk-down {
		width: 73.33333%;
	}
	.\31\30\34\/120--lg-desk-down,
	.\31\33\/15--lg-desk-down,
	.\35\32\/60--lg-desk-down {
		width: 86.66667%;
	}
	.\31\31\32\/120--lg-desk-down,
	.\31\34\/15--lg-desk-down,
	.\35\36\/60--lg-desk-down {
		width: 93.33333%;
	}
	.\31\/16--lg-desk-down {
		width: 6.25%;
	}
	.\33\/16--lg-desk-down {
		width: 18.75%;
	}
	.\35\/16--lg-desk-down {
		width: 31.25%;
	}
	.\37\/16--lg-desk-down {
		width: 43.75%;
	}
	.\39\/16--lg-desk-down {
		width: 56.25%;
	}
	.\31\31\/16--lg-desk-down {
		width: 68.75%;
	}
	.\31\33\/16--lg-desk-down {
		width: 81.25%;
	}
	.\31\35\/16--lg-desk-down {
		width: 93.75%;
	}
	.\31\/17--lg-desk-down {
		width: 5.88235%;
	}
	.\32\/17--lg-desk-down {
		width: 11.76471%;
	}
	.\33\/17--lg-desk-down {
		width: 17.64706%;
	}
	.\34\/17--lg-desk-down {
		width: 23.52941%;
	}
	.\35\/17--lg-desk-down {
		width: 29.41176%;
	}
	.\36\/17--lg-desk-down {
		width: 35.29412%;
	}
	.\37\/17--lg-desk-down {
		width: 41.17647%;
	}
	.\38\/17--lg-desk-down {
		width: 47.05882%;
	}
	.\39\/17--lg-desk-down {
		width: 52.94118%;
	}
	.\31\30\/17--lg-desk-down {
		width: 58.82353%;
	}
	.\31\31\/17--lg-desk-down {
		width: 64.70588%;
	}
	.\31\32\/17--lg-desk-down {
		width: 70.58824%;
	}
	.\31\33\/17--lg-desk-down {
		width: 76.47059%;
	}
	.\31\34\/17--lg-desk-down {
		width: 82.35294%;
	}
	.\31\35\/17--lg-desk-down {
		width: 88.23529%;
	}
	.\31\36\/17--lg-desk-down {
		width: 94.11765%;
	}
	.\31\/18--lg-desk-down {
		width: 5.55556%;
	}
	.\35\/18--lg-desk-down {
		width: 27.77778%;
	}
	.\37\/18--lg-desk-down {
		width: 38.88889%;
	}
	.\31\31\/18--lg-desk-down {
		width: 61.11111%;
	}
	.\31\33\/18--lg-desk-down {
		width: 72.22222%;
	}
	.\31\37\/18--lg-desk-down {
		width: 94.44444%;
	}
	.\31\/19--lg-desk-down {
		width: 5.26316%;
	}
	.\32\/19--lg-desk-down {
		width: 10.52632%;
	}
	.\33\/19--lg-desk-down {
		width: 15.78947%;
	}
	.\34\/19--lg-desk-down {
		width: 21.05263%;
	}
	.\35\/19--lg-desk-down {
		width: 26.31579%;
	}
	.\36\/19--lg-desk-down {
		width: 31.57895%;
	}
	.\37\/19--lg-desk-down {
		width: 36.84211%;
	}
	.\38\/19--lg-desk-down {
		width: 42.10526%;
	}
	.\39\/19--lg-desk-down {
		width: 47.36842%;
	}
	.\31\30\/19--lg-desk-down {
		width: 52.63158%;
	}
	.\31\31\/19--lg-desk-down {
		width: 57.89474%;
	}
	.\31\32\/19--lg-desk-down {
		width: 63.15789%;
	}
	.\31\33\/19--lg-desk-down {
		width: 68.42105%;
	}
	.\31\34\/19--lg-desk-down {
		width: 73.68421%;
	}
	.\31\35\/19--lg-desk-down {
		width: 78.94737%;
	}
	.\31\36\/19--lg-desk-down {
		width: 84.21053%;
	}
	.\31\37\/19--lg-desk-down {
		width: 89.47368%;
	}
	.\31\38\/19--lg-desk-down {
		width: 94.73684%;
	}
	.\31\/20--lg-desk-down,
	.\33\/60--lg-desk-down,
	.\36\/120--lg-desk-down {
		width: 5%;
	}
	.\31\38\/120--lg-desk-down,
	.\33\/20--lg-desk-down,
	.\39\/60--lg-desk-down {
		width: 15%;
	}
	.\32\31\/60--lg-desk-down,
	.\34\32\/120--lg-desk-down,
	.\37\/20--lg-desk-down {
		width: 35%;
	}
	.\32\37\/60--lg-desk-down,
	.\35\34\/120--lg-desk-down,
	.\39\/20--lg-desk-down {
		width: 45%;
	}
	.\31\31\/20--lg-desk-down,
	.\33\33\/60--lg-desk-down,
	.\36\36\/120--lg-desk-down {
		width: 55%;
	}
	.\31\33\/20--lg-desk-down,
	.\33\39\/60--lg-desk-down,
	.\37\38\/120--lg-desk-down {
		width: 65%;
	}
	.\31\30\32\/120--lg-desk-down,
	.\31\37\/20--lg-desk-down,
	.\35\31\/60--lg-desk-down {
		width: 85%;
	}
	.\31\31\34\/120--lg-desk-down,
	.\31\39\/20--lg-desk-down,
	.\35\37\/60--lg-desk-down {
		width: 95%;
	}
	.\31\/24--lg-desk-down,
	.\35\/120--lg-desk-down {
		width: 4.16667%;
	}
	.\32\35\/120--lg-desk-down,
	.\35\/24--lg-desk-down {
		width: 20.83333%;
	}
	.\33\35\/120--lg-desk-down,
	.\37\/24--lg-desk-down {
		width: 29.16667%;
	}
	.\31\31\/24--lg-desk-down,
	.\35\35\/120--lg-desk-down {
		width: 45.83333%;
	}
	.\31\33\/24--lg-desk-down,
	.\36\35\/120--lg-desk-down {
		width: 54.16667%;
	}
	.\31\37\/24--lg-desk-down,
	.\38\35\/120--lg-desk-down {
		width: 70.83333%;
	}
	.\31\39\/24--lg-desk-down,
	.\39\35\/120--lg-desk-down {
		width: 79.16667%;
	}
	.\31\31\35\/120--lg-desk-down,
	.\32\33\/24--lg-desk-down {
		width: 95.83333%;
	}
	.\31\/28--lg-desk-down {
		width: 3.57143%;
	}
	.\33\/28--lg-desk-down {
		width: 10.71429%;
	}
	.\35\/28--lg-desk-down {
		width: 17.85714%;
	}
	.\39\/28--lg-desk-down {
		width: 32.14286%;
	}
	.\31\31\/28--lg-desk-down {
		width: 39.28571%;
	}
	.\31\33\/28--lg-desk-down {
		width: 46.42857%;
	}
	.\31\35\/28--lg-desk-down {
		width: 53.57143%;
	}
	.\31\37\/28--lg-desk-down {
		width: 60.71429%;
	}
	.\31\39\/28--lg-desk-down {
		width: 67.85714%;
	}
	.\32\33\/28--lg-desk-down {
		width: 82.14286%;
	}
	.\32\35\/28--lg-desk-down {
		width: 89.28571%;
	}
	.\32\37\/28--lg-desk-down {
		width: 96.42857%;
	}
	.\31\/60--lg-desk-down,
	.\32\/120--lg-desk-down {
		width: 1.66667%;
	}
	.\32\/60--lg-desk-down,
	.\34\/120--lg-desk-down {
		width: 3.33333%;
	}
	.\31\34\/120--lg-desk-down,
	.\37\/60--lg-desk-down {
		width: 11.66667%;
	}
	.\31\31\/60--lg-desk-down,
	.\32\32\/120--lg-desk-down {
		width: 18.33333%;
	}
	.\31\33\/60--lg-desk-down,
	.\32\36\/120--lg-desk-down {
		width: 21.66667%;
	}
	.\31\34\/60--lg-desk-down,
	.\32\38\/120--lg-desk-down {
		width: 23.33333%;
	}
	.\31\37\/60--lg-desk-down,
	.\33\34\/120--lg-desk-down {
		width: 28.33333%;
	}
	.\31\39\/60--lg-desk-down,
	.\33\38\/120--lg-desk-down {
		width: 31.66667%;
	}
	.\32\32\/60--lg-desk-down,
	.\34\34\/120--lg-desk-down {
		width: 36.66667%;
	}
	.\32\33\/60--lg-desk-down,
	.\34\36\/120--lg-desk-down {
		width: 38.33333%;
	}
	.\32\36\/60--lg-desk-down,
	.\35\32\/120--lg-desk-down {
		width: 43.33333%;
	}
	.\32\39\/60--lg-desk-down,
	.\35\38\/120--lg-desk-down {
		width: 48.33333%;
	}
	.\33\31\/60--lg-desk-down,
	.\36\32\/120--lg-desk-down {
		width: 51.66667%;
	}
	.\33\34\/60--lg-desk-down,
	.\36\38\/120--lg-desk-down {
		width: 56.66667%;
	}
	.\33\37\/60--lg-desk-down,
	.\37\34\/120--lg-desk-down {
		width: 61.66667%;
	}
	.\33\38\/60--lg-desk-down,
	.\37\36\/120--lg-desk-down {
		width: 63.33333%;
	}
	.\34\31\/60--lg-desk-down,
	.\38\32\/120--lg-desk-down {
		width: 68.33333%;
	}
	.\34\33\/60--lg-desk-down,
	.\38\36\/120--lg-desk-down {
		width: 71.66667%;
	}
	.\34\36\/60--lg-desk-down,
	.\39\32\/120--lg-desk-down {
		width: 76.66667%;
	}
	.\34\37\/60--lg-desk-down,
	.\39\34\/120--lg-desk-down {
		width: 78.33333%;
	}
	.\34\39\/60--lg-desk-down,
	.\39\38\/120--lg-desk-down {
		width: 81.66667%;
	}
	.\31\30\36\/120--lg-desk-down,
	.\35\33\/60--lg-desk-down {
		width: 88.33333%;
	}
	.\31\31\36\/120--lg-desk-down,
	.\35\38\/60--lg-desk-down {
		width: 96.66667%;
	}
	.\31\31\38\/120--lg-desk-down,
	.\35\39\/60--lg-desk-down {
		width: 98.33333%;
	}
	.\31\/120--lg-desk-down {
		width: 0.83333%;
	}
	.\33\/120--lg-desk-down {
		width: 2.5%;
	}
	.\37\/120--lg-desk-down {
		width: 5.83333%;
	}
	.\39\/120--lg-desk-down {
		width: 7.5%;
	}
	.\31\31\/120--lg-desk-down {
		width: 9.16667%;
	}
	.\31\33\/120--lg-desk-down {
		width: 10.83333%;
	}
	.\31\37\/120--lg-desk-down {
		width: 14.16667%;
	}
	.\31\39\/120--lg-desk-down {
		width: 15.83333%;
	}
	.\32\31\/120--lg-desk-down {
		width: 17.5%;
	}
	.\32\33\/120--lg-desk-down {
		width: 19.16667%;
	}
	.\32\37\/120--lg-desk-down {
		width: 22.5%;
	}
	.\32\39\/120--lg-desk-down {
		width: 24.16667%;
	}
	.\33\31\/120--lg-desk-down {
		width: 25.83333%;
	}
	.\33\33\/120--lg-desk-down {
		width: 27.5%;
	}
	.\33\37\/120--lg-desk-down {
		width: 30.83333%;
	}
	.\33\39\/120--lg-desk-down {
		width: 32.5%;
	}
	.\34\31\/120--lg-desk-down {
		width: 34.16667%;
	}
	.\34\33\/120--lg-desk-down {
		width: 35.83333%;
	}
	.\34\37\/120--lg-desk-down {
		width: 39.16667%;
	}
	.\34\39\/120--lg-desk-down {
		width: 40.83333%;
	}
	.\35\31\/120--lg-desk-down {
		width: 42.5%;
	}
	.\35\33\/120--lg-desk-down {
		width: 44.16667%;
	}
	.\35\37\/120--lg-desk-down {
		width: 47.5%;
	}
	.\35\39\/120--lg-desk-down {
		width: 49.16667%;
	}
	.\36\31\/120--lg-desk-down {
		width: 50.83333%;
	}
	.\36\33\/120--lg-desk-down {
		width: 52.5%;
	}
	.\36\37\/120--lg-desk-down {
		width: 55.83333%;
	}
	.\36\39\/120--lg-desk-down {
		width: 57.5%;
	}
	.\37\31\/120--lg-desk-down {
		width: 59.16667%;
	}
	.\37\33\/120--lg-desk-down {
		width: 60.83333%;
	}
	.\37\37\/120--lg-desk-down {
		width: 64.16667%;
	}
	.\37\39\/120--lg-desk-down {
		width: 65.83333%;
	}
	.\38\31\/120--lg-desk-down {
		width: 67.5%;
	}
	.\38\33\/120--lg-desk-down {
		width: 69.16667%;
	}
	.\38\37\/120--lg-desk-down {
		width: 72.5%;
	}
	.\38\39\/120--lg-desk-down {
		width: 74.16667%;
	}
	.\39\31\/120--lg-desk-down {
		width: 75.83333%;
	}
	.\39\33\/120--lg-desk-down {
		width: 77.5%;
	}
	.\39\37\/120--lg-desk-down {
		width: 80.83333%;
	}
	.\39\39\/120--lg-desk-down {
		width: 82.5%;
	}
	.\31\30\31\/120--lg-desk-down {
		width: 84.16667%;
	}
	.\31\30\33\/120--lg-desk-down {
		width: 85.83333%;
	}
	.\31\30\37\/120--lg-desk-down {
		width: 89.16667%;
	}
	.\31\30\39\/120--lg-desk-down {
		width: 90.83333%;
	}
	.\31\31\31\/120--lg-desk-down {
		width: 92.5%;
	}
	.\31\31\33\/120--lg-desk-down {
		width: 94.16667%;
	}
	.\31\31\37\/120--lg-desk-down {
		width: 97.5%;
	}
	.\31\31\39\/120--lg-desk-down {
		width: 99.16667%;
	}
}


.row {
	margin-right: -15px;
	margin-left: -15px;
  }
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
  }
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9{
	float: left;
  }
  .col-xs-12 {
	width: 100%;
  }
  .col-xs-11 {
	width: 91.66666667%;
  }
  .col-xs-10 {
	width: 83.33333333%;
  }
  .col-xs-9 {
	width: 75%;
  }
  .col-xs-8 {
	width: 66.66666667%;
  }
  .col-xs-7 {
	width: 58.33333333%;
  }
  .col-xs-6 {
	width: 50%;
  }
  .col-xs-5 {
	width: 41.66666667%;
  }
  .col-xs-4 {
	width: 33.33333333%;
  }
  .col-xs-3 {
	width: 25%;
  }
  .col-xs-2 {
	width: 16.66666667%;
  }
  .col-xs-1 {
	width: 8.33333333%;
  }
  .col-xs-pull-12 {
	right: 100%;
  }
  .col-xs-pull-11 {
	right: 91.66666667%;
  }
  .col-xs-pull-10 {
	right: 83.33333333%;
  }
  .col-xs-pull-9 {
	right: 75%;
  }
  .col-xs-pull-8 {
	right: 66.66666667%;
  }
  .col-xs-pull-7 {
	right: 58.33333333%;
  }
  .col-xs-pull-6 {
	right: 50%;
  }
  .col-xs-pull-5 {
	right: 41.66666667%;
  }
  .col-xs-pull-4 {
	right: 33.33333333%;
  }
  .col-xs-pull-3 {
	right: 25%;
  }
  .col-xs-pull-2 {
	right: 16.66666667%;
  }
  .col-xs-pull-1 {
	right: 8.33333333%;
  }
  .col-xs-pull-0 {
	right: auto;
  }
  .col-xs-push-12 {
	left: 100%;
  }
  .col-xs-push-11 {
	left: 91.66666667%;
  }
  .col-xs-push-10 {
	left: 83.33333333%;
  }
  .col-xs-push-9 {
	left: 75%;
  }
  .col-xs-push-8 {
	left: 66.66666667%;
  }
  .col-xs-push-7 {
	left: 58.33333333%;
  }
  .col-xs-push-6 {
	left: 50%;
  }
  .col-xs-push-5 {
	left: 41.66666667%;
  }
  .col-xs-push-4 {
	left: 33.33333333%;
  }
  .col-xs-push-3 {
	left: 25%;
  }
  .col-xs-push-2 {
	left: 16.66666667%;
  }
  .col-xs-push-1 {
	left: 8.33333333%;
  }
  .col-xs-push-0 {
	left: auto;
  }
  .col-xs-offset-12 {
	margin-left: 100%;
  }
  .col-xs-offset-11 {
	margin-left: 91.66666667%;
  }
  .col-xs-offset-10 {
	margin-left: 83.33333333%;
  }
  .col-xs-offset-9 {
	margin-left: 75%;
  }
  .col-xs-offset-8 {
	margin-left: 66.66666667%;
  }
  .col-xs-offset-7 {
	margin-left: 58.33333333%;
  }
  .col-xs-offset-6 {
	margin-left: 50%;
  }
  .col-xs-offset-5 {
	margin-left: 41.66666667%;
  }
  .col-xs-offset-4 {
	margin-left: 33.33333333%;
  }
  .col-xs-offset-3 {
	margin-left: 25%;
  }
  .col-xs-offset-2 {
	margin-left: 16.66666667%;
  }
  .col-xs-offset-1 {
	margin-left: 8.33333333%;
  }
  .col-xs-offset-0 {
	margin-left: 0;
  }
  @media (min-width: 768px) {
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
 {
	  float: left;
	}
	.col-sm-12 {
	  width: 100%;
	}
	.col-sm-11 {
	  width: 91.66666667%;
	}
	.col-sm-10 {
	  width: 83.33333333%;
	}
	.col-sm-9 {
	  width: 75%;
	}
	.col-sm-8 {
	  width: 66.66666667%;
	}
	.col-sm-7 {
	  width: 58.33333333%;
	}
	.col-sm-6 {
	  width: 50%;
	}
	.col-sm-5 {
	  width: 41.66666667%;
	}
	.col-sm-4 {
	  width: 33.33333333%;
	}
	.col-sm-3 {
	  width: 25%;
	}
	.col-sm-2 {
	  width: 16.66666667%;
	}
	.col-sm-1 {
	  width: 8.33333333%;
	}
	.col-sm-pull-12 {
	  right: 100%;
	}
	.col-sm-pull-11 {
	  right: 91.66666667%;
	}
	.col-sm-pull-10 {
	  right: 83.33333333%;
	}
	.col-sm-pull-9 {
	  right: 75%;
	}
	.col-sm-pull-8 {
	  right: 66.66666667%;
	}
	.col-sm-pull-7 {
	  right: 58.33333333%;
	}
	.col-sm-pull-6 {
	  right: 50%;
	}
	.col-sm-pull-5 {
	  right: 41.66666667%;
	}
	.col-sm-pull-4 {
	  right: 33.33333333%;
	}
	.col-sm-pull-3 {
	  right: 25%;
	}
	.col-sm-pull-2 {
	  right: 16.66666667%;
	}
	.col-sm-pull-1 {
	  right: 8.33333333%;
	}
	.col-sm-pull-0 {
	  right: auto;
	}
	.col-sm-push-12 {
	  left: 100%;
	}
	.col-sm-push-11 {
	  left: 91.66666667%;
	}
	.col-sm-push-10 {
	  left: 83.33333333%;
	}
	.col-sm-push-9 {
	  left: 75%;
	}
	.col-sm-push-8 {
	  left: 66.66666667%;
	}
	.col-sm-push-7 {
	  left: 58.33333333%;
	}
	.col-sm-push-6 {
	  left: 50%;
	}
	.col-sm-push-5 {
	  left: 41.66666667%;
	}
	.col-sm-push-4 {
	  left: 33.33333333%;
	}
	.col-sm-push-3 {
	  left: 25%;
	}
	.col-sm-push-2 {
	  left: 16.66666667%;
	}
	.col-sm-push-1 {
	  left: 8.33333333%;
	}
	.col-sm-push-0 {
	  left: auto;
	}
	.col-sm-offset-12 {
	  margin-left: 100%;
	}
	.col-sm-offset-11 {
	  margin-left: 91.66666667%;
	}
	.col-sm-offset-10 {
	  margin-left: 83.33333333%;
	}
	.col-sm-offset-9 {
	  margin-left: 75%;
	}
	.col-sm-offset-8 {
	  margin-left: 66.66666667%;
	}
	.col-sm-offset-7 {
	  margin-left: 58.33333333%;
	}
	.col-sm-offset-6 {
	  margin-left: 50%;
	}
	.col-sm-offset-5 {
	  margin-left: 41.66666667%;
	}
	.col-sm-offset-4 {
	  margin-left: 33.33333333%;
	}
	.col-sm-offset-3 {
	  margin-left: 25%;
	}
	.col-sm-offset-2 {
	  margin-left: 16.66666667%;
	}
	.col-sm-offset-1 {
	  margin-left: 8.33333333%;
	}
	.col-sm-offset-0 {
	  margin-left: 0;
	}
  }
  @media (min-width: 992px) {
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9 {
	  float: left;
	}
	.col-md-12 {
	  width: 100%;
	}
	.col-md-11 {
	  width: 91.66666667%;
	}
	.col-md-10 {
	  width: 83.33333333%;
	}
	.col-md-9 {
	  width: 75%;
	}
	.col-md-8 {
	  width: 66.66666667%;
	}
	.col-md-7 {
	  width: 58.33333333%;
	}
	.col-md-6 {
	  width: 50%;
	}
	.col-md-5 {
	  width: 41.66666667%;
	}
	.col-md-4 {
	  width: 33.33333333%;
	}
	.col-md-3 {
	  width: 25%;
	}
	.col-md-2 {
	  width: 16.66666667%;
	}
	.col-md-1 {
	  width: 8.33333333%;
	}
	.col-md-pull-12 {
	  right: 100%;
	}
	.col-md-pull-11 {
	  right: 91.66666667%;
	}
	.col-md-pull-10 {
	  right: 83.33333333%;
	}
	.col-md-pull-9 {
	  right: 75%;
	}
	.col-md-pull-8 {
	  right: 66.66666667%;
	}
	.col-md-pull-7 {
	  right: 58.33333333%;
	}
	.col-md-pull-6 {
	  right: 50%;
	}
	.col-md-pull-5 {
	  right: 41.66666667%;
	}
	.col-md-pull-4 {
	  right: 33.33333333%;
	}
	.col-md-pull-3 {
	  right: 25%;
	}
	.col-md-pull-2 {
	  right: 16.66666667%;
	}
	.col-md-pull-1 {
	  right: 8.33333333%;
	}
	.col-md-pull-0 {
	  right: auto;
	}
	.col-md-push-12 {
	  left: 100%;
	}
	.col-md-push-11 {
	  left: 91.66666667%;
	}
	.col-md-push-10 {
	  left: 83.33333333%;
	}
	.col-md-push-9 {
	  left: 75%;
	}
	.col-md-push-8 {
	  left: 66.66666667%;
	}
	.col-md-push-7 {
	  left: 58.33333333%;
	}
	.col-md-push-6 {
	  left: 50%;
	}
	.col-md-push-5 {
	  left: 41.66666667%;
	}
	.col-md-push-4 {
	  left: 33.33333333%;
	}
	.col-md-push-3 {
	  left: 25%;
	}
	.col-md-push-2 {
	  left: 16.66666667%;
	}
	.col-md-push-1 {
	  left: 8.33333333%;
	}
	.col-md-push-0 {
	  left: auto;
	}
	.col-md-offset-12 {
	  margin-left: 100%;
	}
	.col-md-offset-11 {
	  margin-left: 91.66666667%;
	}
	.col-md-offset-10 {
	  margin-left: 83.33333333%;
	}
	.col-md-offset-9 {
	  margin-left: 75%;
	}
	.col-md-offset-8 {
	  margin-left: 66.66666667%;
	}
	.col-md-offset-7 {
	  margin-left: 58.33333333%;
	}
	.col-md-offset-6 {
	  margin-left: 50%;
	}
	.col-md-offset-5 {
	  margin-left: 41.66666667%;
	}
	.col-md-offset-4 {
	  margin-left: 33.33333333%;
	}
	.col-md-offset-3 {
	  margin-left: 25%;
	}
	.col-md-offset-2 {
	  margin-left: 16.66666667%;
	}
	.col-md-offset-1 {
	  margin-left: 8.33333333%;
	}
	.col-md-offset-0 {
	  margin-left: 0;
	}
  }
  @media (min-width: 1200px) {
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9 {
	  float: left;
	}
	.col-lg-12 {
	  width: 100%;
	}
	.col-lg-11 {
	  width: 91.66666667%;
	}
	.col-lg-10 {
	  width: 83.33333333%;
	}
	.col-lg-9 {
	  width: 75%;
	}
	.col-lg-8 {
	  width: 66.66666667%;
	}
	.col-lg-7 {
	  width: 58.33333333%;
	}
	.col-lg-6 {
	  width: 50%;
	}
	.col-lg-5 {
	  width: 41.66666667%;
	}
	.col-lg-4 {
	  width: 33.33333333%;
	}
	.col-lg-3 {
	  width: 25%;
	}
	.col-lg-2 {
	  width: 16.66666667%;
	}
	.col-lg-1 {
	  width: 8.33333333%;
	}
	.col-lg-pull-12 {
	  right: 100%;
	}
	.col-lg-pull-11 {
	  right: 91.66666667%;
	}
	.col-lg-pull-10 {
	  right: 83.33333333%;
	}
	.col-lg-pull-9 {
	  right: 75%;
	}
	.col-lg-pull-8 {
	  right: 66.66666667%;
	}
	.col-lg-pull-7 {
	  right: 58.33333333%;
	}
	.col-lg-pull-6 {
	  right: 50%;
	}
	.col-lg-pull-5 {
	  right: 41.66666667%;
	}
	.col-lg-pull-4 {
	  right: 33.33333333%;
	}
	.col-lg-pull-3 {
	  right: 25%;
	}
	.col-lg-pull-2 {
	  right: 16.66666667%;
	}
	.col-lg-pull-1 {
	  right: 8.33333333%;
	}
	.col-lg-pull-0 {
	  right: auto;
	}
	.col-lg-push-12 {
	  left: 100%;
	}
	.col-lg-push-11 {
	  left: 91.66666667%;
	}
	.col-lg-push-10 {
	  left: 83.33333333%;
	}
	.col-lg-push-9 {
	  left: 75%;
	}
	.col-lg-push-8 {
	  left: 66.66666667%;
	}
	.col-lg-push-7 {
	  left: 58.33333333%;
	}
	.col-lg-push-6 {
	  left: 50%;
	}
	.col-lg-push-5 {
	  left: 41.66666667%;
	}
	.col-lg-push-4 {
	  left: 33.33333333%;
	}
	.col-lg-push-3 {
	  left: 25%;
	}
	.col-lg-push-2 {
	  left: 16.66666667%;
	}
	.col-lg-push-1 {
	  left: 8.33333333%;
	}
	.col-lg-push-0 {
	  left: auto;
	}
	.col-lg-offset-12 {
	  margin-left: 100%;
	}
	.col-lg-offset-11 {
	  margin-left: 91.66666667%;
	}
	.col-lg-offset-10 {
	  margin-left: 83.33333333%;
	}
	.col-lg-offset-9 {
	  margin-left: 75%;
	}
	.col-lg-offset-8 {
	  margin-left: 66.66666667%;
	}
	.col-lg-offset-7 {
	  margin-left: 58.33333333%;
	}
	.col-lg-offset-6 {
	  margin-left: 50%;
	}
	.col-lg-offset-5 {
	  margin-left: 41.66666667%;
	}
	.col-lg-offset-4 {
	  margin-left: 33.33333333%;
	}
	.col-lg-offset-3 {
	  margin-left: 25%;
	}
	.col-lg-offset-2 {
	  margin-left: 16.66666667%;
	}
	.col-lg-offset-1 {
	  margin-left: 8.33333333%;
	}
	.col-lg-offset-0 {
	  margin-left: 0;
	}
  }
