

.contact-information {
	font-family: Apercu,Helvetica,sans-serif;
	display: flex;
	flex-wrap: wrap;
	margin: rem(-5);
	.contact-information__column {
		padding: rem(5);
	}
}
.contact-information__column--director {
	width: rem(196);
}
.contact-information__column--summary {
	flex: 0 0 100%;
}
.contact-information__wrapper {
	padding-bottom: 18px;
}
.contact-information__title {
	line-height: 29px;
	color: #000;
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 15px;
}
@media (min-width: 768px) {
	.contact-information__title {
		font-size: 20px;
	}
}
.contact-information__strapline {
	line-height: 22.85px;
}
.contact-information__wrapper {
	padding-bottom: 8px;
}
.contact-information__text {
	color: #6e6e6e;
	font-weight: 400;
}
.contact-information__link {
	color: #e64626;
	text-decoration: underline;
}
.contact-information__link:hover {
	color: #ce3d20;
	text-decoration: underline;
}
.contact-information__title {
	font-size: 20px;
}
@media (min-width: 768px) {
	.contact-information__title {
		font-size: 17px;
	}
}
@media (min-width: 1200px) {
	.contact-information__title {
		font-size: 20px;
	}
}

