.accordion {
	border-bottom: rem(1px) solid $shade-03;

	.course-description & {
		border-bottom: none;
	}
}

.accordion__trigger {
	cursor: pointer;
	transition: all 0.2s ease 0s;
	font-size: rem(16px) !important;
	color: black;
	position: relative;
	padding: rem(15px) rem(20px) rem(15px) 0;
	margin: 0 !important;

	@media (min-width: $break-grid-lg) {
		padding: rem(30px) rem(40px) rem(30px) 0;
	}

	&:hover {
		color: $color-brand-01;
	}

	.course-description & {
		border-top: rem(1px) solid rgba(black, .08);
		font-size: rem(14px) !important;

		@media (min-width: $break-grid-lg) {
			padding: rem(25px) 0;
		}

		@media (min-width: $break-grid-xl) {
			font-size: rem(19px) !important;
		}
	}

	&:before,
	&:after {
		content: "";
		height: rem(2px);
		width: rem(12px);
		background: $color-brand-01;
		position: absolute;
		right: 0;
		top: 50%;
		transition: all 0.2s ease 0s;

		@media (min-width: $break-grid-lg) {
			right: rem(13px);
		}
	}

	&:after {
		transform: rotate(90deg);
	}

	&.is-active {
		color: $color-brand-01;
		border-bottom: none;

		&:after {
			transform: rotate(0deg);
		}
	}
}

.accordion__panel {
	display: none;
	font-size: rem(16px);
	padding-bottom: rem(20px);

	&.is-active {
		display: block;
	}

	.course-description div[class*=' taggroup-'] & .courses-list-sub,
	.course-description div[class^='taggroup-'] & .courses-list-sub {
		margin-bottom: 0 !important;
	}
}

div[class*=' block-FrequentlyAskedQuestions'],div[class^='block-FrequentlyAskedQuestions'] {
	h3:not(.accordion__trigger) {
		margin-top: rem(30px);
	}

	a {
		@extend .text-link;
	}
}