.our-clients-menu__heading-wrap {
	display: flex;
	align-items: baseline;
}

.our-clients-menu__heading-arrow {
	margin-right: rem(10px);
}

.our-clients-menu__heading {
	font-size: rem(16px);
	color: $color-brand-01;
	margin-bottom: rem(20px);
}

.our-clients-menu__heading--black {
	color: black;
}

.our-clients-menu__list {
	border-top: rem(3px) solid black;
}

.our-clients-menu__list__item {
	font-weight: bold;
	border-bottom: rem(1px) solid $shade-07;
	line-height: 1.1;
}

.our-clients-menu__list__item__link {
	display: block;
	padding: rem(20px) rem(25px);
	color: black;
	transition: $transition-default;

	&:hover {
		color: $color-brand-01;
	}
}

.our-clients-menu__list__item__link--active {
	color: $color-brand-01;
}