.site-main,
.site-header,
.site-footer {
	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]):not(.hero-search-field):not(.header-search-field):not(.filter-search-field),
	textarea,
	select:not(.filter__applied__refine-dropdown),
	.block-Checkout #oncourse-checkout input[type="text"],
	.block-Checkout #oncourse-checkout textarea,
	.block-Checkout #oncourse-checkout .Select .Select-control {
		@include type-style-10;
		border: rem(2px) solid black;
		border-radius: 0;
		width: 100%;
		height: auto;
		color: black;
		margin: 0;
		padding: rem(16px) rem(25px);
		background-color: transparent;

		&::placeholder {
			color: #7b7b7b;
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		position: relative;
		padding-right: rem(35px);
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAzElEQVQYlWP4/7B65mVbl/9txa9C/v//zwDFzFAM4b9PNbhTof5/T+2uXWCBj6frPl2wdfnfV3I9iuH/f6b////L/f//XwWs6Xau/e0K9f/n6jb893n8XxpmIoqmH///l/7//7/5/+1cO2TFIHVwDSD8+UzdkwtuLv/P7Xl2/c/Xcw9vZkMU17z6rwpTg6IBoqkerOlCkvr/M/Wb3sU8BjsPLo+hAey8M3XHz9WtewRzBjJmBOvCDtgZGBh+oksx4VLNwMDAgiHCwMAAAOIoz3Gx+PZWAAAAAElFTkSuQmCC);
		background-repeat: no-repeat;
		background-position: right rem(15px) center;

		&:hover {
			cursor: pointer;
		}

		@include ie-only {
			padding-right: 0;
			background: none;
		}
	}

	//input[type="radio"],
	input[type="checkbox"] {
		display: none;
	}

	.form__checkbox-wrap,
	.faux-checkbox {
		label {
			display: flex;
			align-items: flex-start;
			position: relative;
			padding: 0;

			&:hover {
				cursor: pointer;
			}

			&:before {
				content: '';
				display: block;
				width: rem(21px);
				height: rem(21px);
				border: rem(2px) solid #a0a0a0;
				margin-right: rem(15px);
				flex-shrink: 0;
			}
		}
	}

	input[type="checkbox"] + label,
	.opt-marketing input[type="checkbox"],
	.payment-conditions input[type="checkbox"],
	.enrolmentSelect {
		&::after {
			content: '';
			display: block;
			width: rem(13px);
			height: rem(13px);
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCI+CiAgPGltYWdlIGlkPSJjaGVja2JveGVzIiB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHhsaW5rOmhyZWY9ImRhdGE6aW1nL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUEwQUFBQUtDQVlBQUFCdjd0VEVBQUFBdmtsRVFWUW9rWTNRTVV0Q1VSUUg4Ri9wNHBCRDRDYmsyTnl1cllFb05BWU5RUTN0QllMNkFVeHdjM0JyYU5ESnBpQndFUHdBZm9XK1FnalNFRlJjZUJmZThCNjlzOXdMZjM3MzNITU90czJHZ25XQ1o5eVZDNEpUckZESDAyRUJjSVpOQXNKNSt4OXFZWTBhM25DQlhVVEhHYUNOZDFReHh5VytRaEJRRngvb3BNQVZYbEhCRE5mNGptRll4SG55MmhJM09NSVVKWXpReDIvNkN3RTlKRzBIZUVsbFBZeXpCbzB6RGZHWTNIOXdud2RpcDFnVGZHS1BSZTQrOFFja0hCNDJRajhWTmdBQUFBQkpSVTVFcmtKZ2dnPT0iLz4KPC9zdmc+Cg==);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 0;
			position: absolute;
			top: rem(4px);
			left: rem(4px);
			z-index: 1;
		}
	}

	input[type="checkbox"]:checked + label,
	.opt-marketing input[type="checkbox"]:checked,
	.payment-conditions input[type="checkbox"]:checked,
	.enrolmentSelect:checked {
		color: black;

		&::before {
			border-color: black !important;
			border-radius: rem(4px);
		}

		&::after {
			background-size: auto;
		}
	}

	input[type="checkbox"]:disabled + label {
		color: #c3c3c3;

		&::before {
			border: rem(2px) solid #d2d2d2;
		}
	}

	.form__radio-wrap,
	.radio-list {
		label,
		input[type="radio"] + span {
			display: flex !important;
			align-items: center;
			position: relative;

			@include ie-only {
				display: inline-block !important;
			}

			&:before {
				content: '';
				display: block;
				width: rem(21px);
				height: rem(21px);
				border: rem(2px) solid black;
				border-radius: 50%;
				margin-right: rem(10px);

				@include ie-only {
					content: none;
				}
			}
		}
	}

	input[type="radio"]:checked + label,
	input[type="radio"]:checked + span {
		&:after {
			content: '';
			display: block;
			width: rem(9px);
			height: rem(9px);
			border-radius: 50%;
			background: $color-brand-01;
			position: absolute;
			top: 0;
			bottom: 0;
			left: rem(6px);
			margin: auto;

			@include ie-only {
				content: none;
			}
		}
	}

	.form__group {
		margin-bottom: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(20px);
		}

		@include ie-only {
			display: block;
		}
	}

	.form__group--button {
		margin-bottom: 0;
	}

	.hero__search-form {
		position: relative;
	}

	input[type="text"].hero-search-field {
		width: 100%;
		height: auto;
		border: none;
		background-color: white;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB2aWV3Qm94PSIwIDAgNDAgNDAiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJtYXRyaXgoMS42NjY2NjY2NjY2NjY2NjY3LDAsMCwxLjY2NjY2NjY2NjY2NjY2NjcsMCwwKSI+PHBhdGggZD0iTTAuNzUwIDkuODEyIEE5LjA2MyA5LjA2MyAwIDEgMCAxOC44NzYgOS44MTIgQTkuMDYzIDkuMDYzIDAgMSAwIDAuNzUwIDkuODEyIFoiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2NDgyOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMuMDU2IDQuNjIpIHJvdGF0ZSgtMjMuMDI1KSI+PC9wYXRoPjxwYXRoIGQ9Ik0xNi4yMjEgMTYuMjJMMjMuMjUgMjMuMjUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2NDgyOCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSI+PC9wYXRoPjwvZz48L3N2Zz4=);
		background-repeat: no-repeat;
		background-position: rem(20px) center;
		background-size: rem(25px);
		margin: 0;
		padding: rem(20px) rem(30px) rem(20px) rem(65px);
		font-size: rem(16px);
		font-weight: bold;
		border-radius: rem(3px);
		position: relative;
		z-index: 2;

		@media (min-width: $break-grid-lg) {
			font-size: rem(22px);
		}

		@media (min-width: $break-grid-xl) {
			padding: rem(30px) rem(40px) rem(30px) rem(100px);
			background-position: rem(43px) center;
			font-size: rem(24px);
		}

		&::placeholder {
			color: #aeaeae;
		}

		&:focus + {
			.search-dropdown {
				opacity: 1;
				max-height: 1000px;
			}
		}
	}

	.header-search-form {
		display: flex;
		align-items: center;
		border-bottom: rem(3px) solid black;
	}

	input[type="text"].header-search-field {
		width: 100%;
		height: auto;
		border: none;
		background-color: transparent;
		padding: rem(10px) rem(30px) rem(10px) 0;
		margin: 0;
		font-size: rem(16px);
		font-weight: bold;
		color: black;
		border-radius: 0;
		position: relative;
		z-index: 1;

		@media (min-width: $break-grid-lg) {
			font-size: rem(30px);
		}

		@media (min-width: $break-grid-xl) {
			padding: rem(20px) rem(60px) rem(20px) 0;
			font-size: rem(60px);
			background-size: rem(43px);
		}

		&::placeholder {
			color: black;
		}

		&:focus + {
			.search-dropdown {
				opacity: 1;
				max-height: 1000px;
			}
		}
	}

	.header-search-button {
		color: black;
		background-color: transparent;
		border: none;
	}

	.header-search-button__icon {
		width: rem(20px);
		height: rem(20px);

		@media (min-width: $break-grid-lg) {
			width: rem(30px);
			height: rem(30px);
		}

		@media (min-width: $break-grid-xl) {
			width: rem(43px);
			height: rem(43px);
		}
	}

	.filter-search-field {
		border: 2px solid black;
		background-color: white;
		padding: rem(12px) rem(20px);
		font-family: $font-family-alt;
		font-size: rem(14px);
		width: 100%;
		max-width: rem(560px);
		min-height: rem(45px);
		margin-bottom: rem(10px);
		border-radius: 0;

		@media (min-width: $break-grid-sm) {
			margin-right: rem(15px);
		}
	}

	.filter-search-button {
		padding: rem(14.5px) rem(35px) !important;
		//background-image: url('/images/icon-sprite.svg') !important;
		//background-position: 0 0 !important;

		& > * {
			vertical-align: middle;
		}
	}

	input[type="submit"],
	input[type="button"],
	button[type="button"],
	button[type="submit"]:not(.header-search-button),
	.block-Checkout #oncourse-checkout input[type="submit"],
	.block-Checkout #oncourse-checkout button,
	.block-Checkout #oncourse-checkout #contactEditorForm .form-controls > span > a,
	.block-Checkout #oncourse-checkout div.amount-container button.btn-primary,
	.block-Checkout #oncourse-checkout div.amount-container #addCode,
	.block-Checkout #oncourse-checkout #tabable-container .tab-content .payer-selection > a.button,
	.block-Checkout #oncourse-checkout #tabable-container .tab-content #corporate-pass #addCorporatePass {
		@extend .button;
		@include type-style-10;
		font-weight: normal;
		padding: rem(20px) rem(35px);

		&:hover {
			cursor: pointer;
		}
	}

	.block-Checkout #oncourse-checkout {
		textarea {
			min-height: rem(110px);
		}

		.Select {
			margin: 0;
			max-width: rem(545px);

			.Select-control {
				padding: 0;
				background: transparent !important;

				.Select-multi-value-wrapper {
					width: 100%;
				}

				.Select-multi-value-wrapper,
				.Select-value-label {
					height: rem(47px);
				}

				.Select-value-label {
					> div {
						overflow: hidden;
					}

					em {
						color: black;
					}
				}

				.Select-placeholder,
				.Select-input,
				.Select-value {
					height: rem(47px);
					line-height: rem(47px);
					padding: 0 rem(25px);
				}
			}
		}

		fieldset {
			@extend .l-grid;

			> div:not(.pass-input) {
				@extend .l-grid__cell;
				@extend .l-grid__cell--50\@md;
				@extend .form__group;

				> div {
					margin: 0;
				}
			}

			.message {
				margin-bottom: rem(30px);

				@media (min-width: $break-grid-md) {
					width: 65% !important;
				}
			}

			.radio-list {
				input[type="radio"] {
					position: absolute;
					width: 100%;
					height: rem(21px);
					z-index: 1;
					border: none;

					& + span {
						margin-right: rem(15px);
					}

					@include ie-only {
						position: relative;
						width: rem(20px);
					}
				}
			}
		}

		form#contactEditorForm fieldset,
		form#payment-form .payment-conditions .conditions,
		.enrolmentInfo {
			input[type="checkbox"] {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: rem(21px);
				margin: 0 !important;
				z-index: 1;
				border: none;

				&::before {
					content: '';
					display: block;
					width: rem(21px);
					height: rem(21px);
					border: rem(2px) solid #a0a0a0;
					margin-right: rem(15px);
					flex-shrink: 0;
				}

				&::after {
					content: '';
					display: block;
					width: rem(13px);
					height: rem(13px);
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCI+CiAgPGltYWdlIGlkPSJjaGVja2JveGVzIiB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHhsaW5rOmhyZWY9ImRhdGE6aW1nL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUEwQUFBQUtDQVlBQUFCdjd0VEVBQUFBdmtsRVFWUW9rWTNRTVV0Q1VSUUg4Ri9wNHBCRDRDYmsyTnl1cllFb05BWU5RUTN0QllMNkFVeHdjM0JyYU5ESnBpQndFUHdBZm9XK1FnalNFRlJjZUJmZThCNjlzOXdMZjM3MzNITU90czJHZ25XQ1o5eVZDNEpUckZESDAyRUJjSVpOQXNKNSt4OXFZWTBhM25DQlhVVEhHYUNOZDFReHh5VytRaEJRRngvb3BNQVZYbEhCRE5mNGptRll4SG55MmhJM09NSVVKWXpReDIvNkN3RTlKRzBIZUVsbFBZeXpCbzB6RGZHWTNIOXdud2RpcDFnVGZHS1BSZTQrOFFja0hCNDJRajhWTmdBQUFBQkpSVTVFcmtKZ2dnPT0iLz4KPC9zdmc+Cg==);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 0;
					position: absolute;
					top: rem(4px);
					left: rem(4px);
					z-index: 1;
				}

				&:checked {
					&::before {
						border-color: black !important;
						border-radius: rem(4px);
					}

					&::after {
						background-size: auto;
					}
				}

				@include ie-only {
					width: rem(20px);
				}
			}
		}

		.enrolmentInfo {
			> div {
				display: block !important;
				margin: 0 !important;
			}

			label {
				margin-left: rem(20px);
				margin-bottom: rem(10px);
				padding-left: rem(10px) !important;
			}

			.enrolmentSelect {
				top: 0 !important;

				&:focus {
					outline: 0;
				}
			}
		}

		form#contactEditorForm {
			fieldset {
				&.opt-marketing {
					> div {
						margin: 0;
					}

					.message {
						margin-bottom: rem(10px);
					}

					& > div label {
						width: auto !important;
						padding: 0 0 0 rem(30px) !important;
					}
				}

				input[type="checkbox"] {
					position: relative;
				}
			}
		}

		form#payment-form {
			.payment-conditions .conditions {
				.conditions-text {
					padding-left: rem(30px);
				}

				a {
					color: $color-brand-01;
					text-decoration: underline;
				}

				input[type="checkbox"] {
					width: rem(21px);
				}
			}

			#tabable-container {
				.nav {
					li {
						padding: 0;

						&:before {
							content: none;
						}
					}
				}

				.radio-list {
					input {
						border: none;
					}

					> span {
						margin-bottom: rem(6px);

						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}

		#corporate-pass {
			fieldset {
				margin: rem(20px) 0 0;

				> div {
					padding: 0;
				}
			}
		}

		.form-controls > span > a {
			margin-right: rem(10px) !important;
		}

		div.amount-container {
			div.code-info .code-input .form-control {
				padding: rem(16px) rem(25px);
				width: 100%;
				margin-bottom: rem(10px);
			}
		}

		.note .alert {
			background-position: left center;
		}
	}
}

.cms-scope .md-checkbox input[type=checkbox] {
	margin-right: 20px !important;
}

.content-form {
	fieldset {
		@extend .l-grid;
	}

	.form-cont-row {
		@extend .l-grid__cell;
		@extend .l-grid__cell--50\@md;
		margin-bottom: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(20px);
		}

		input:not([type="radio"]),
		textarea,
		select {
			margin-top: rem(10px) !important;
		}

		input[type="radio"] {
			-webkit-appearance: radio;
			-moz-appearance: radio;
			appearance: auto;
		}
	}

	label {
		font-size: rem(15px);
		font-weight: bold;
		color: black;
	}

	#emailFormBlock_1 {
		font-weight: bold;
		color: $color-brand-01;
	}
}

.content-form-corporate {
	.form-cont-row {
		&:nth-child(8),
		&:nth-child(9) {
			width: 100%;
		}

		&:nth-child(9) {
			align-items: center;
			color: #000;
			display: flex;
			flex-wrap: wrap;

			@media (min-width: $break-grid-xxl) {
				margin-bottom: rem(45px);
			}

			label {
				margin-bottom: rem(10px);
				width: 100%;

				@media (min-width: $break-grid-xxl) {
					margin-bottom: 0;
					margin-right: auto;
					width: auto;
				}

				+ input[type="radio"] {
					margin-left: 0;
				}
			}

			input[type="radio"] {
				margin: 0 rem(5px) 0 rem(20px);
			}
		}
	}
}

input[name^="isMarketingVia"] {
  position: relative !important;
}
