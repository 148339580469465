.content-grid {

}

.content-grid__cell {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(60px);
	}
}