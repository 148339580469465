.featured-block {

	@media (min-width: $break-grid-md) {
		padding-top: rem(50px);
		position: relative;
	}

	.general-content & {
		margin: rem(40px) 0;

		@media (min-width: $break-grid-lg) {
			margin: rem(50px) 0;
		}

		@media (min-width: $break-grid-xl) {
			margin: rem(75px) 0;
		}
	}
}

.featured-block__tag {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.featured-block__image-wrap {

	@media (min-width: $break-grid-md) {
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.featured-block__image {
	height: 0;
	width: 100%;
	padding-bottom: percentage(320 / 575);
	background: no-repeat center center;
	background-size: cover;

	@media (min-width: $break-grid-md) {
		padding-bottom: percentage(420 / 575);
	}
}

.featured-block__background {
	background: $shade-01;
	padding: rem(40px) rem(30px);

	@media (min-width: $break-grid-md) {
		padding: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(100px) rem(95px);
	}
}

.featured-block__text {
	color: white;

	@media (min-width: $break-grid-md) {
		width: 39%;
	}
}

.featured-block__text__snippet {
	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
	}

	&:before {
		margin-bottom: rem(20px);

		@media (min-width: $break-grid-xl) {
			height: rem(3px);
		}
	}
}

h3.featured-block__text__heading {
	@media (min-width: $break-grid-xl) {
		font-size: rem(40px);
		margin-bottom: rem(30px);
	}
}

.featured-block__text__description {
	font-size: rem(14px);
	margin-left: rem(20px);

	@media (min-width: $break-grid-xl) {
		margin-left: rem(40px);
	}

	li {
		font-size: rem(14px) !important;
	}
}
