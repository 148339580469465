$header-height: rem(162);

.media-content {}

.media-content--full {
	overflow: hidden;
	position: relative;
	margin-bottom: rem(-1);
}

.media-content--hero {
	@media (min-width: $break-grid-md) {
		height: calc(80svh - #{$header-height});
	}

	@media (min-width: $break-grid-lg) {
		height: calc(70svh - #{$header-height});
	}
}

.media-content--strategy {
	padding: 0;
}

.media-content__container {
	position: relative;
}

.media-content__cell--copy {
	padding-top: rem(48);
	padding-bottom: rem(48);

	@media (min-width: $break-grid-md) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 0;
		padding-bottom: 0;
	}

	.media-content--full & {
		padding-bottom: 0;
	}

	.media-content--strategy & {
		padding-bottom: rem(48);

		@media (min-width: $break-grid-md) {
			align-items: center;
			padding-bottom: 0;
		}
	}

	.media-content--hero & {
		@media (min-width: $break-grid-md) {
			height: calc(80svh - #{$header-height});
		}

		@media (min-width: $break-grid-lg) {
			height: calc(70svh - #{$header-height});
		}
	}

	.media-content--strategy & {
		@media (min-width: $break-grid-md) {
			height: calc(62svh - #{rem($header-height)});
		}
	}
}

.media-content__image {
	width: 100%;
	height: 100%;
	aspect-ratio: 5 / 4;
	object-fit: cover;

	.media-content--full & {
		right: 0;
		width: 100%;
		aspect-ratio: 1;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media (min-width: $break-grid-md) {
			position: absolute;
			width: 45%;
			height: calc(80svh - #{$header-height});
		}

		@media (min-width: $break-grid-lg) {
			height: calc(70svh - #{$header-height});
		}
	}

	.media-content--strategy & {
		right: unset;
		left: 0;

		@media (min-width: $break-grid-md) {
			height: calc(80svh - #{$header-height});
		}
	}
}

.media-content__image--show\@md {
	display: none;

	@media (min-width: $break-grid-md) {
		display: block;
	}
}

.media-content__copy {
	.media-content--full & {
		@media (min-width: $break-grid-md) {
			max-width: rem(555);
		}
	}

	.media-content--inset-copy & {
		@media (min-width: $break-grid-md) {
			padding-left: rem(20);
		}

		@media (min-width: $break-grid-lg) {
			padding-left: rem(40);
		}

		@media (min-width: $break-grid-xl) {
			padding-left: rem(100);
		}
	}

	.media-content--strategy & {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 100%;
		text-align: center;
	}
}

.media-content__title {
	margin-bottom: rem(24) !important;
	@include type-style-26;
	color: $color-brand-01;

	.media-content--strategy & {
		color: $shade-01;
	}
}

.media-content__heading {
	@include type-style-38;
}

.media-content__text {
	font-size: rem(18);
	line-height: (28 / 18);

	.media-content--strategy & {
		margin-bottom: rem(32) !important;

		@media (min-width: $break-grid-md) {
			margin-bottom: rem(48) !important;
		}
	}
}

.media-content-actions {
	display: flex;
	flex-direction: column;
	gap: rem(16);
	margin-top: rem(48);
}

.media-content-strategy {
	display: flex;
	flex-direction: column;
	width: rem(125);
	margin-bottom: rem(32);
	text-align: left;
}

.media-content-strategy__head {
	font-family: $font-family-tertiary;
	font-size: rem(14);
}

.media-content-strategy__body {
	margin: rem(6) 0;
	width: fit-content;
	font-size: rem(54);
	line-height: 1;
	border-bottom: rem(1) solid $shade-01;
}

.media-content-strategy__foot {
	font-family: $font-family-tertiary;
	font-size: rem(16);
	font-style: italic;
	text-align: right;
}
