.page-class-detail {
	.location-main-title,
	.course-tagline{
		display: none !important;
	}

	.js-page-title {
		margin-bottom: rem(10px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(40px);
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(45px);
		}
	}
}

.page-class-detail-corp {
	.classDescription {
		padding-top: rem(19px);

		@media (min-width: $break-grid-lg) {
			padding-top: rem(26px);
		}

		@media (min-width: $break-grid-xl) {
			padding-top: rem(51px);
		}

		h1 {
			display: none;
		}
	}
}