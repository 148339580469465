.hero {
	min-height: rem(226px);
	background-color: $shade-03;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	color: white;
	position: relative;
	padding: rem(45px) 0;

	@media (min-width: $break-grid-md) {
		min-height: rem(238px);
	}

	@media (min-width: $break-grid-lg) {
		min-height: rem(298px);
		padding: rem(50px) 0;
	}

	@media (min-width: $break-grid-xl) {
		min-height: rem(429px);
		padding: rem(70px) 0;
	}

	.js-header-banner-disabled &,
	.page-courses-filter & {
		display: none;
	}

	.text-colour-block__snippet {
		max-width: rem(270px);
	}

	.text-colour-block__snippet--no-max-width {
		max-width: 100%;
	}

	.below-search {
		margin-top: rem(40px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(45px);
		}

		@media (min-width: $break-grid-xl) {
			margin-top: rem(55px);
		}
	}
}

.hero--overlay {
	&:before {
		content: '';
		display: block;
		background: rgba(44,44,44,0.6);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.hero__container {
	position: relative;
	z-index: 1;
}

.hero__text {
	max-width: rem(280px);

	@media (min-width: $break-grid-md) {
		max-width: rem(370px);
	}

	@media (min-width: $break-grid-lg) {
		max-width: rem(520px);
	}
}

.hero__below-heading {
	margin-left: rem(25px);

	@media (min-width: $break-grid-lg) {
		margin-left: rem(35px);
	}

	@media (min-width: $break-grid-xl) {
		margin-left: rem(47px);
	}
}

h1.hero__heading {
	margin-bottom: rem(18px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(20px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(25px);
	}
}

h4.hero__sub-heading {
	margin-bottom: rem(18px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(23px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(35px);
	}

	br {
		@media (min-width: $break-grid-md) {
			display: none;
		}
	}
}

p.hero__snippet {
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(45px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(80px);
	}
}

p.hero__snippet--reduced-margin {
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(35px);
	}

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(35px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(40px);
	}
}

.hero__centered-content {
	max-width: rem(800px);
	margin: auto;
	text-align: center;

	& > * {
		margin-bottom: rem(25px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(40px);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: white;
		text-decoration: none;

		&:hover {
			color: white;
		}
	}
}

.hero__centered-content__image {
	display: inline-block;
}

.hero__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
