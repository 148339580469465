.available-courses-list,
.course-description div[class*=' taggroup-'],
.course-description div[class^='taggroup-'] {
	@extend .topics;
	margin-bottom: rem(50px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(70px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(80px);
	}

	.courses-list > li {
		display: none;
	}
}

h2.subject-accordion-heading {
	@extend .h6;
	@extend .topics__ctas__heading;
	color: $color-brand-01;
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(21px);
		margin-bottom: rem(32px);
	}
}

.course-related-title--list {
	@extend .h6;
	@extend .topics__ctas__heading;
	width: 100% !important;
	color: $color-brand-01;

	@media (min-width: $break-grid-xl) {
		font-size: rem(21px);
	}
}

ul {
	.course-related &,
	.accordion &.courses-list-sub,
	.available-courses-list &.courses-list-sub,
	.course-description div[class*=' taggroup-'] &.courses-list-sub,
	.course-description div[class^='taggroup-'] &.courses-list-sub {
		@extend .topics__list;
		@extend .l-grid;
		@extend .l-grid--extra-padding;
		@extend .l-grid--extra-padding--00;
		margin-bottom: rem(30px) !important;

		@media (min-width: $break-grid-md) {
			margin-bottom: rem(40px) !important;
		}

		@media (min-width: $break-grid-xl) {
			font-size: rem(45px) !important;
		}

		li {
			@extend .topics__list__item;
			@extend .l-grid__cell;
			@extend .l-grid__cell--50\@md;
			@extend .l-grid__cell--extra-padding;
			@extend .l-grid__cell--extra-padding--00;

			padding-left: $l-grid-padding-default !important;
			margin-bottom: rem(10px) !important;

			@media (min-width: $break-grid-md) {
				padding-left: $l-grid-extra-padding-md !important;
			}

			@media (min-width: $break-grid-xl) {
				padding-left: $l-grid-extra-padding-00-xl !important;
				margin-bottom: rem(15px) !important;
			}

			&:last-child {
				margin-bottom: 0 !important;
			}

			&:before {
				display: none;
			}

			a {
				@extend .topics__list__item__link;
			}
		}
	}
}