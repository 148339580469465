.site-header {
	.primary-menu__list {
		display: flex;
	}

	.primary-menu__item {
		//margin-right: rem(18px);
		//position: relative;

		//@media (min-width: em(1280px)) {
		//	margin-right: rem(35px);
		//}
		//
		//@media (min-width: $break-grid-xl) {
		//	margin-right: rem(45px);
		//}

		&:last-child {
			margin-right: 0;
		}

		//&.hasChildren > .primary-menu__sub-menu-wrap {
		//	display: block;
		//}
	}

	.primary-menu__link {
		background: #fff;
		display: block;
		@include type-style-12;
		color: black;
		padding: rem(20px);
		position: relative;
		transition: $transition-default;
		transition-property: background, color;

		@media (min-width: $break-grid-xl) {
			padding: rem(22px) rem(20px);
		}

		&::after {
			background-color: $color-brand-01;
			position: absolute;
			bottom: 0;
			left: 50%;
			width: calc(100% - #{rem(40px)});
			height: 2.5px;
			opacity: 0;
			transform: translateX(-50%);
			content: '';
		}
	}

	.primary-menu__label {
		position: relative;
	}

	.primary-menu__sub-menu-wrap__tagline {
		font-family: $font-family-secondary;
		font-style: italic;
		font-size: rem(40px);
		font-weight: bold;
		color: $shade-01;
		margin-bottom: rem(35px);
		width: 100%;
	}

	//.primary-menu__sub-menu-wrap:hover,
	//.primary-menu__link:hover ~ .primary-menu__sub-menu-wrap {
	//	padding: rem(50px) 0 rem(55px);
	//	opacity: 1;
	//	max-height: rem(1000px);
	//}

	.primary-menu__list > .primary-menu__item:not(.activePage) > .primary-menu__link:hover,
	.is-active > .primary-menu__link {
		background-color: $color-brand-01;
		color: white;
	}

	.activePage > .primary-menu__link::after {
		opacity: 1;
	}
}

.primary-menu-sub {
	.primary-menu__item {
		margin-bottom: rem(15px);
		margin-right: 0;

		&.activePage .primary-menu__label {
			border-color: transparent;
			color: $color-brand-01;
		}
	}

	.primary-menu__link {
		display: block;
		font-size: rem(19px);
		font-weight: bold;
		line-height: 1.4;
		color: $shade-01;
		padding: rem(5px) rem(55px) rem(5px) 0;
		transition: color $transition-default;

		&:hover {
			.primary-menu__label {
				border-color: transparent;
				color: $color-brand-01;
			}
		}
	}

	.hasChildren {
		.primary-menu__link {
			background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNS4yIDEyLjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjIgMTIuOCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNlNjQ4MjgiIGQ9Ik0xNS4xLDYuN2MwLDAsMC4xLTAuMSwwLjEtMC4xYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLTAuMS0wLjEtMC4xLTAuMWwtNS45LTYKCUM5LDAsOC44LDAsOC42LDAuMWMtMC4yLDAuMi0wLjIsMC40LDAsMC42TDEzLjgsNkgwLjRDMC4yLDYsMCw2LjIsMCw2LjRjMCwwLjIsMC4yLDAuNCwwLjQsMC40aDEzLjRsLTUuMiw1LjIKCWMtMC4yLDAuMi0wLjIsMC40LDAsMC42YzAuMiwwLjIsMC40LDAuMiwwLjYsMEwxNS4xLDYuN3oiLz4KPC9zdmc+Cg==);
			background-repeat: no-repeat;
			background-size: rem(17px) rem(23px);
			background-position: 100% 50%;
		}
	}
}

.menu-drawer {
	background: $shade-06;
	display: block;
	height: calc(100vh - #{$site-header-height} - var(--notice-height));
	padding: rem(40px) rem(52px);
	position: absolute;
	opacity: 0;
	top: 0;
	transition: 0.2s ease-in-out;
	transition-property: opacity, right;
	right: rem(-890px);
	width: rem(890px);
	z-index: 100;

	&.is-active {
		opacity: 1;
		right: 0;
	}

	.sticky-header-active & {
		top: $site-header-height !important;
		height: calc(100vh - #{$site-header-height});
	}
}

.menu-drawer--tertiary {
	background: #fff;
	padding-top: rem(94px);
	width: rem(445px);
	z-index: 101;
}

.menu-drawer-btn {
	@include type-style-12(400);
	align-items: center;
	background: transparent;
	border: none;
	color: $color-brand-01;
	display: flex;
	text-decoration: underline;
	text-decoration-color: transparent;
	text-underline-offset: rem(6px);
	transition: text-decoration-color $transition-default;

	&:hover {
		text-decoration-color: $color-brand-01;
	}
}

.menu-drawer-btn__icon {
	margin-right: rem(13px);
	transform: rotate(180deg);
}

.menu-drawer-btn__label {

}

.primary-menu-sub {
	bottom: 0;
	display: block;
	height: calc(100vh - #{$site-header-height} - #{$primary-menu-sub-top-spacing} - var(--notice-height) );
	padding: rem(15px) rem(52px) rem(60px);
	position: fixed;
	opacity: 0;
	overflow: hidden;
	right: -100%;
	top: 0;
	transition: 0.2s ease-in-out;
	//transition-delay: 0.2s;
	transition-property: opacity, right;
	width: rem(890px);
	z-index: 101;

	&.is-active,
	.menu-drawer--tertiary & {
		opacity: 1;
		right: 0;
	}

	.sticky-header-active & {
		height: calc(100vh - #{$site-header-height} - #{$primary-menu-sub-top-spacing} );
		top: calc( $site-header-height + 5rem) !important;
	}

	.primary-menu__link {
		background-color: transparent;
		border-bottom: rem(2px) solid transparent;

		@at-root .site-header #{&} {
			font-weight: 400;
			transition-property: border-color, color;
		}

		&:hover {
			background-color: transparent;
			border-color: $color-brand-01;
		}
	}

	.primary-menu-sub {
		display: none;
	}

	.menu-drawer--tertiary & {
		padding: 0;
		position: relative;
		width: 100%;

		.sticky-header-active & {
			top: 0 !important;
		}
	}
}

.primary-menu-sub__grid {
	height: calc(100vh - #{$site-header-height} - #{$primary-menu-sub-top-spacing} - #{$primary-menu-sub-padding} - var(--notice-height));
}

.primary-menu-sub__cell--menu {
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: rem(30px);
	height: 100%;

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: #717171;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

}

.primary-menu-sub__cell--content {
	padding: 0 rem(20px) 0 rem(50px);
}

.primary-menu-feature {
	@include type-style-46;
	//visibility: hidden;
	//
	//@media (min-height: em(880px)) {
	//	visibility: visible;
	//}
}

.primary-menu-feature__image {
	margin-bottom: rem(25px);
	height: rem(186px);
	width: auto;

	@media (min-height: em(880px)) {
		height: auto;
		width: 100%;
	}
}

.primary-menu-feature__title {
	@include type-style-47;
	margin-bottom: rem(20px);
}

.primary-menu-feature__copy {
	padding-right: rem(20px);
}

.primary-menu-feature__link {
	color: $color-brand-01;
	display: inline-block;
	margin-top: rem(20px);
	text-decoration: underline;
	text-underline-offset: rem(6px);
	transition: text-decoratior-color $transition-default;

	&:hover {
		text-decoration-color: transparent;
	}
}
