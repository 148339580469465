.product-list__item {
	padding-bottom: rem(40px);
	margin-bottom: rem(35px);
	border-bottom: rem(1px) solid $shade-05;

	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: none;
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(70px);
		margin-bottom: rem(65px);
	}
}

.product-list__item__heading {
	font-weight: bold !important;
	color: $color-brand-01;
}

.product-list__item__heading__link {
	border-bottom: rem(1px) solid $color-brand-01 !important;
}

.product-list__item__description {
	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(30px) !important;
	}
}

.product-list__item__description__link {
	font-weight: bold;
	text-decoration: none;
}