.session {
	background: $shade-02;
	margin-bottom: rem(10px);
	padding: rem(25px) rem(30px);
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-md) {
		display: flex;
		flex-wrap: wrap;
	}

	@media (min-width: $break-grid-lg) {
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: rem(35px) rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		justify-content: normal;
	}

	p, li {
		@media (min-width: $break-grid-lg) {
			font-size: rem(16px);
		}
	}

	p {
		margin: 0;
	}

	a {
		text-decoration: none;
		color: $shade-01;
	}
}

.session__location {
	display: flex;
	align-items: flex-start;
	line-height: 1;
}

.session__location__icon {
	color: $color-brand-01;
	margin-right: rem(10px);
	flex-shrink: 0;
}

.session__location__title {
	a {
		border-bottom: rem(1px) solid $shade-01 !important;
		transition: $transition-default;

		&:hover {
			border-bottom-color: transparent !important;
		}
	}
}

.session__outer-cell {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		width: 65%;
		padding-right: rem(20px);
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		width: auto;
		padding-right: rem(50px);
	}

	&:last-child {
		margin-bottom: 0;
		padding-right: 0;
	}
}

.session__outer-cell--left {
	@media (min-width: $break-grid-md) {
		width: 35%;
	}

	@media (min-width: $break-grid-xl) {
		width: auto;
		display: flex;
	}
}

.session__outer-cell--right {
	width: 100%;
	margin-top: rem(20px);

	@media (min-width: $break-grid-lg) {
		width: 45%;
		margin-top: 0;
	}
}

.session__inner-cell {
	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
		margin-right: rem(50px);
	}

	&:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}
}

.session__date {
	font-weight: bold;
}