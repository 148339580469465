#launcher {
	//bottom: 15px!important;
	//margin: 0 !important;
	//right: 65px!important;
}

#ticketSubmissionForm {
	//bottom: 56px!important;
	//right: 51px!important;
}

.close-help-btn {
	//background-color: #cacaca;
	//@include rem(border-radius, 999px);
	//color: #fff;
	//cursor: pointer;
	//@include rem(bottom, 16px);
	//display:  none;
	//@include rem(font-size, 10px);
	//font-weight: bold;
	//@include rem(height, 46px);
	//@include rem(padding-top, 16px);
	//position: fixed;
	//@include rem(right, 16px);
	//text-align: center;
	//@include rem(width, 46px);
	//z-index: 999;
	//
	//.lt-ie9 & {
	//	display: none;
	//}
}

@media screen and (max-width: 767px) {
	#launcher,
	.close-help-btn,
	#ticketSubmissionForm {
		display: none !important;
	}
}