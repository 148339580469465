.people-profile__img {
	border-radius: rem(55);
	width: rem(110);
	height: rem(110);
	margin-bottom: rem(20);
	background-color: $shade-05;
	background-size: cover;
	background-position: center;
}
.people-profile__container {
	border-left: rem(4) solid #f1f2f2;
	padding-left: rem(14);
	margin-bottom: rem(40);
}
.people-profile__strapline {
	margin-bottom: rem(20);
	line-height: rem(25.7);
	color: #888;
	font-family: Antwerp, Georgia, serif;
	font-size: rem(18);
}
.people-profile__text {
	color: #6e6e6e;
	font-size: rem(14);
	font-weight: 400;
	margin-bottom: rem(10);
}
.people-profile {
	a {
		display: block;
		text-decoration: underline;
	}
}
a.people-profile__text:hover {
	color: #e64626;
}
.people-profile__link {
	color: #e64626;
	margin-bottom: rem(10);
	font-size: rem(14);
	&:hover {
		color: #ce3d20;
		text-decoration: none;
	}
}
.people-profile__summary {
	margin-bottom: rem(25);
	margin-top: rem(10);
	font-weight: 400;
	font-size: rem(14);
}
.people-profile__label {
	font-size: rem(14);
	color: #000;
	font-family: "Apercu Mono",Courier,monospace;
	margin-bottom: rem(5);
}
.people-profile__view {
	display: flex !important;
	flex-wrap: nowrap;
	align-items: center;
	position: relative;
	color: #e64626;
	font-family: "Apercu Mono",Courier,monospace;
	font-size: rem(14);
	&:hover{
		color: #ce3d20;
	}
}
.people-profile__view__text {
	text-decoration: underline;
}
.people-profile__view__icon {
	flex-shrink: 0;
	margin-left: rem(15);
}
