.subscribe--section {
	max-width: $l-container-width;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: $break-grid-lg) {
		position: relative;
		z-index: 1;
		margin-top: rem(-200px);
		margin-bottom: rem(-89px);
		padding-left: rem(90px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(-180px);
		padding-left: $l-container-padding-xl;
	}

	.subscribe__form__field,
	select {
		border-color: #fff !important;
		color: #fff !important;

		&::placeholder {
			color: #99A7B4 !important;
		}
	}

	.subscribe__form__sub-label {
		color: #fff;
	}

	.form__checkbox-wrap label:before {
		border-color: #fff;
	}
}

.subscribe--section .subscribe__container {
	background-color: #4d4d4d;
	background-image: url('/s/images/bg-newsletter.png');
	background-size: cover;
	padding-top: rem(40px);
	padding-bottom: rem(40px);
	position: relative;

	@media (min-width: $break-grid-lg) {
		padding-top: rem(50px);
		padding-left: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(95px);
		padding-bottom: rem(50px);
	}
}

.subscribe--footer {
	background-color: $shade-02;

	@media (min-width: $break-grid-xl) {
		padding: rem(90px) 0 rem(50px)
	}
}

.subscribe__text {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(40px);
	}
}

.subscribe__text__grid {
	align-items: flex-end;

	@media (min-width: $break-grid-xl) {
		align-items: center;
	}
}

.subscribe--section .subscribe__text__grid {
	@media (min-width: $break-grid-lg) {
		align-items: baseline;
	}

	@media (min-width: $break-grid-xl) {
		align-items: center;
	}
}

.subscribe__heading {
	color: black;
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(36px);
	}

	@media (min-width: $break-grid-xxl) {
		font-size: rem(40px);
	}
}

.subscribe--section .subscribe__heading {
	color: #fff;
}

.subscribe__snippet {
	@media (min-width: $break-grid-xl) {
		margin-top: rem(15px);
	}

	.subscribe--section & {
		color: #fff;
	}
}

.subscribe__tag {
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(17.5px);
	}
}

.subscribe--section .subscribe__tag {
	display: none;

	@media (min-width: $break-grid-lg) {
		display: block;
		font-size: rem(14px);
		transform: rotate(90deg);
		transform-origin: top left;
		position: absolute;
		top: 0;
		left: rem(-35px);
	}
}

.subscribe__form-wrap {
	margin-top: rem(20px);
}

.subscribe__form__heading,
.subscribe__form__text,
.subscribe__form__email-role {
	margin-bottom: rem(30px);

	.subscribe--section & {
		color: #fff;
	}
}

.subscribe__form__additional-fields {
	overflow: hidden;
	max-height: 0;
	opacity: 0;
	transition: $transition-default;
}

.subscribe__form__additional-fields--active {
	max-height: 1200px;
	opacity: 1;
}

.subscribe__form__response {
	margin-top: rem(20px);
	font-weight: bold;
	color: $color-brand-01;
}

.subscribe--footer {
	margin: 0;
}
