.podcastModule .channelTitle {
	border-bottom: rem(1) solid #000;
	font-size: rem(30);
	font-weight: 700;
	margin: 0 0 rem(15);
	padding-bottom: rem(5);
  }
  .podcastModule .channelImage,
  .podcastModule .podcastImage {
	margin-right: rem(-10);
  }
  .podcastModule .channelImage img,
  .podcastModule .podcastImage img {
	width: 100%;
	padding-bottom: 75%;
	padding-top: 0;
	height: 0;
	display: block;
  }
  .podcastModule .channelImage.noimage,
  .podcastModule .podcastImage.noimage {
	display: none;
  }
  .podcastModule .podcast {
	border-bottom: rem(1) solid #000;
	padding-bottom: rem(30);
  }
  .podcastModule .podcast .podcastMetadata,
  .podcastModule .podcast .podcastProgress,
  .podcastModule .podcast .podcastSummary,
  .podcastModule .podcast .podcastTitle {
	padding-left: rem(10);
  }
  .podcastModule .podcast .podcastTitle {
	color: #000;
	font-size: rem(24);
	font-weight: 700;
	margin-bottom: rem(15);
	margin-top: rem(35);
  }
  .podcastModule .podcast .podcastMetadata {
	color: #000;
	font-size: rem(14);
	font-family: "Apercu Mono", Courier, monospace;
	margin-bottom: rem(25);
  }
  .podcastModule .podcast .podcastSummary {
	font-size: rem(14);
	margin-bottom: rem(30);
  }
  .podcastModule .podcast .podcastControls {
	margin-bottom: rem(14);
  }
  .podcastModule .podcast .podcastControls .playButton,
  .podcastModule .podcast .podcastControls .playButton:hover {
	width: rem(26);
	height: rem(25);
	float: left;
	background: #363636;
	color: #e64626;
	padding-top: rem(2);
	padding-left: rem(4);
	text-decoration: none;
  }
  .podcastModule .podcast .podcastControls .playButton .pause,
  .podcastModule .podcast .podcastControls .playButton:hover .pause {
	display: none;
  }
  .podcastModule .podcast .podcastControls .progressBar {
	height: rem(25);
	position: relative;
	background: #d8dbdd;
	margin-left: rem(28);
  }
  .podcastModule .podcast .podcastControls .progressBarProgress {
	width: 0%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #e64626;
  }
  .podcastModule .podcast .podcastControls .placeholderImage {
	width: 100%;
	height: auto;
  }
  .podcastModule .podcast .podcastProgress {
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(12);
  }
  .podcastModule .downloadLink {
	  margin-top: rem(4);
  }
  .podcastModule .downloadLink a {
	  display: flex;
	  flex-wrap: nowrap;
	  align-items: center;
	color: #e64626;
	font-size: rem(14);
  }
	.downloadLink__icon {
		flex-shrink: 0;
		margin-right: rem(10);
	}
  .podcastModule .podcast.playing .podcastControls .playButton {
	padding-left: rem(5);
	padding-top: rem(4);
  }
  .podcastModule .podcast.playing .podcastControls .playButton .play {
	display: none;
  }
  .podcastModule .podcast.playing .podcastControls .playButton .pause {
	display: block;
  }
  @media (min-width: 768px) {
	.podcastModule .channelTitle {
	  font-size: rem(28);
	}
	.podcastModule .channelImage,
	.podcastModule .podcastImage {
	  margin-right: rem(-40);
	}
	.podcastModule .podcast .podcastMetadata,
	.podcastModule .podcast .podcastProgress,
	.podcastModule .podcast .podcastSummary,
	.podcastModule .podcast .podcastTitle {
	  padding-left: 0;
	}
	.podcastModule .podcast .podcastTitle {
	  font-size: rem(22);
	}
  }
  @media (min-width: 992px) {
	.podcastModule .channelImage,
	.podcastModule .podcastImage {
	  margin-right:rem( -90);
	}
  }
  @media (min-width: 1200px) {
	.podcastModule .channelTitle {
	  font-size: rem(30);
	}
	.podcastModule .channelImage,
	.podcastModule .podcastImage {
	  margin-right: rem(-110);
	}
	.podcastModule .podcast .podcastTitle {
	  font-size: rem(24);
	}
  }
