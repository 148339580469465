p.courses-list-section__heading {
	margin: 0 0 rem(25px);
	padding-top: rem(25px);
	border-top: solid rem(1px) $shade-05;
}

.courses-list-sub {
	@extend .l-grid;
}

.courses-list__sub-level {
	display: none;
}

.courses-list__item {
	margin-bottom: rem(10px);
}

.courses-list__item__link {
	color: $shade-01 !important;
	font-size: rem(16px);
	line-height: 1;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: rem(60px);
	padding: rem(10px) rem(20px);
	border-left: 5px solid $subject-art-humanities;
	background: $shade-02;
	transition: $transition-default;
	text-decoration: none !important;

	&:hover {
		color: $color-brand-01 !important;
		background: $shade-06;
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
		line-height: 1.2;
		min-height: rem(66px);
	}

	&--arts-and-humanities {
		border-color: $subject-art-humanities;
	}
	&--business-and-management {
		border-color: $subject-business-management;
	}
	&--english {
		border-color: $subject-english;
	}
	&--horticulture-and-gardening {
		border-color: $subject-horticulture-gardening;
	}
	&--hsc-preparation-years-10-12 {
		border-color: $subject-hsc-prep;
	}
	&--human-resources {
		border-color: $subject-human-resources;
	}
	&--information-technology {
		border-color: $subject-info-tech;
	}
	&--language-and-culture {
		border-color: $subject-language-culture;
	}
	&--marketing {
		border-color: $subject-marketing;
	}
	&--music-and-film {
		border-color: $subject-music-film;
	}
	&--project-management {
		border-color: $subject-project-management;
	}
	&--psychology-and-psychiatry {
		border-color: $subject-psych;
	}
	&--public-sector {
		border-color: $subject-public-sector;
	}
}

.courses-list__item__link--active {
	& span {
		color: $color-brand-01;
		text-decoration: underline;
	}
}

.courses-list__item__title {
	color: $shade-01 !important;
	text-decoration: none !important;

	&:hover {
		color: $color-brand-01 !important;
	}
}

.courses-list__item__actions {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	margin-left: rem(15px);
}

.courses-list__item__classes {
	display: none;
	padding: 0 rem(20px) rem(20px);
	border-left: 5px solid $subject-art-humanities;
	background: $shade-02;

	.class-item {
		&:last-child {
			.schedule__class {
				margin-bottom: 0;
			}
		}
	}

	.class-note {
		display: none;
	}

	.schedule__location {
		display: none;
	}

	.schedule__class {
		border-left: 0;
		background: white;
	}

	.schedule__class__icon-text--location {
		display: flex;
	}
}

.courses-list__item__desc {
	margin-bottom: rem(30px);
}

.courses-list__item__arrow-link {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.courses-list__item__arrow {
	color: $color-brand-01;
	flex-shrink: 0;
	margin-left: rem(15px);
}

.courses-list-classes-trigger {
	display: flex;
	align-items: center;
	font-size: rem(14px);
	text-decoration: none !important;
}

.courses-list-classes-trigger__icon {
	display: block;
	width: rem(6px);
	height: rem(6px);
	border-right: 1px solid $color-brand-01;
	border-bottom: 1px solid $color-brand-01;
	margin-top: rem(-3px);
	margin-left: rem(5px);
	transform: rotate(45deg);
	transition: $transition-default;

	.courses-list-classes-trigger.is-active & {
		margin-top: rem(-1px);
		transform: rotate(-135deg);
	}
}

.courses-list-below {
	display: none;
}

.courses-list-below__show-all-button {
	display: flex;
	align-items: center;
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
		margin-left: rem(20px);
	}
}

.courses-list-below__show-all-button__icon {
	margin-left: rem(15px);
	transform: rotate(-90deg);
}

.courses-list-below__advanced-search {
	display: flex;
	align-items: center;
	color: $color-brand-01;
	font-size: rem(14px);

	&:hover {
		color: $color-brand-01-alt
	}
}

.courses-list-below__advanced-search__icon {
	margin-right: rem(10px);
}
