.home__hero {
	background-image: url('/s/images/custom-new/home-hero.jpg');
	background-position: 70% 50%;
	padding-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		background-position: 50%;
		padding-bottom: rem(45px);
	}

	@media (min-width: $break-grid-ds) {
		background-position: right 50%;
	}

	@media (min-width: $break-grid-xl) {
		background-position: right 32%;
		padding: rem(95px) 0 rem(75px);
	}

	&:before {
		content: '';
		display: block;
		background: rgba(44,44,44,0.6);
		background: linear-gradient(0deg, rgba(40,40,40,1) 0%, rgba(40,40,40,0.8) 30%, rgba(40,40,40,0.6) 100%);
		position: absolute;
		top: 0;
		bottom: rem(100px);
		left: 0;
		right: 0;

		@media (min-width: $break-grid-lg) {
			background: linear-gradient(90deg, rgba(44,44,44,0.7) 0%, rgba(44,44,44,0.5) 60%, rgba(44,44,44,0) 75%);
			bottom: 0;
		}

		@media (min-width: $break-grid-xl) {
			background: linear-gradient(90deg, rgba(44,44,44,0.7) 0%, rgba(44,44,44,0.5) 40%, rgba(44,44,44,0) 70%);
		}
	}

	&:after {
		content: '';
		display: block;
		height: rem(100px);
		background: #282828;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		@media (min-width: $break-grid-lg) {
			display: none;
		}
	}

	.hero__text {
		@media (min-width: $break-grid-lg) {
			max-width: rem(600px);
		}
	}
}
