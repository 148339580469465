.youtube {
	&__wrapper {
		margin-bottom: rem(20);
		position: relative;
		padding-top: rem(20);
		border-top: rem(3) solid #000;
	}
	&__title {
		margin: rem(24) 0 rem(10);
		line-height: 1.3em;
		font-size: rem(23.8);
		font-family: inherit;
		font-weight: 500;
		color: inherit;
	}
	&__icon {
		display: block;
		margin-bottom: rem(33);
		font-size: rem(9);
		padding: rem(9) rem(15);
		float: left !important;
		&:before {
			content: "";
			font-family: "Glyphicons Halflings";
			font-style: normal;
			font-weight: 400;
			line-height: 1;
		}
	}
}
