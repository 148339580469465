.related-articles__heading {
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(26px);
	}
}

.related-articles__item {
	display: block;
	border-top: rem(1px) solid black;
	padding: rem(28px) 0;
}

.related-articles__item__heading {
	font-size: rem(16px);
	line-height: 1.4;
	color: black;
	transition: color $transition-default;

	.related-articles__item:hover & {
		color: $color-brand-01;
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
	}
}

.related-articles__item__meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem(15px);
}

.related-articles__item__meta__arrow {
	color: $color-brand-01;
	margin-left: rem(20px);
	flex-shrink: 0;
}

.related-articles__more {
	border-top: rem(3px) solid black;
	padding-top: rem(24px);
}

.related-articles__more__link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: black;
	transition: color $transition-default;

	&:hover {
		color: $color-brand-01;
	}
}

.related-articles__more__link__text {
	line-height: 1;
}

.related-articles__more__link__arrow {
	color: $color-brand-01;
	margin-left: rem(20px);
	flex-shrink: 0;
}



.related-articles--subject {
	display: none;
}

.related-articles {
	.subject-page & {
		display: none;
	}

	.subject-page--arts-humanities &--arts-humanities,
	.subject-page--horticulture &--horticulture,
	.subject-page--information-technology &--information-technology,
	.subject-page--music-film &--music-film,
	.subject-page--public-sector &--public-sector,
	.subject-page--business-management &--business-management,
	.subject-page--hsc-preparation &--hsc-preparation,
	.subject-page--language-culture &--language-culture,
	.subject-page--project-management &--project-management,
	.subject-page--english &--english,
	.subject-page--human-resources &--human-resources,
	.subject-page--marketing &--marketing,
	.subject-page--psychology-psychiatry &--psychology-psychiatry,
	.subject-page--microcredentials &--microcredentials {
		display: block;
	}
}