.statistics__grid {
    gap: rem(48) 0;
    padding: 0 rem(32);

    @media (min-width: $break-grid-lg) {
        gap: 0;
    }

    @media (min-width: $break-grid-sm) {
        padding: 0 rem(72);
    }
}

.statistics-item__heading {
    margin-bottom: rem(8);
    @include type-style-35;
}

.statistics-item__text {
    @include type-style-36;
}