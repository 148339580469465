.section-arrow-link-wrap {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section-arrow-link {
  color: $color-brand-01;
  display: flex;
  align-items: center;
}

.section-arrow-link__text {
  margin-right: rem(13px);
}

.section-arrow-link__before-icon {
  margin-right: rem(13px);
  height: rem(19px);
}
