.blockquote {
	background-color: $shade-09;
}

.blockquote__copy {
    display: flex;
    flex-direction: column;
    gap: rem(48) 0;
    text-align: center;
}

.blockquote__icon {
    height: rem(20);
    pointer-events: none;
}

.blockquote__text {
    @include type-style-34;
    color: $color-brand-01;
}
