.search-dropdown {
	color: $shade-01;
	box-shadow: 0 0 rem(22px) 0 rgba(black, .3);
	clip-path: inset(0 rem(-30px) rem(-30px) rem(-30px));
	position: absolute;
	top: rem(57px);
	width: 100%;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: $transition-default;

	@media (min-width: $break-grid-lg) {
		top: rem(65px);
	}

	@media (min-width: $break-grid-xl) {
		top: rem(88px);
	}
}

.quicksearch-wrap-container {
	position: absolute;
	z-index: 1;

	.page-courses-filter & {
		margin-top: rem(-5px);
	}
}

.quicksearch-wrap {
	width: rem(260px);
	position: relative;
	top: rem(-2px);
	z-index: 0;

	@media (min-width: $break-grid-lg) {
		width: rem(350px);
	}

	> div {
		padding: 0;

		ul {
			margin: 0;
			padding: 0;

			li:first-child.search-dropdown__list__heading {
				font-size: rem(16px);
				color: $shade-01 !important;
				background: #d7dbde;
				padding: rem(10px) rem(15px);
				margin: 0;
				font-weight: bold;
				text-align: left;

				@media (min-width: $break-grid-lg) {
					font-size: rem(19px);
					padding: rem(20px) rem(25px);
				}

				.search-dropdown__list:first-child & {
					padding-top: rem(12px);

					@media (min-width: $break-grid-lg) {
						padding-top: rem(22px);
					}

					@media (min-width: $break-grid-xl) {
						padding-top: rem(23px);
					}
				}
			}

			.search-dropdown__list__item {
				background: white;
				font-size: rem(16px);
				padding-top: rem(5px);

				&:nth-child(2) {
					padding-top: rem(10px);

					@media (min-width: $break-grid-lg) {
						padding-top: rem(20px);
					}
				}

				&:last-child {
					padding-bottom: rem(10px);

					@media (min-width: $break-grid-lg) {
						padding-bottom: rem(20px);
					}
				}

				@media (min-width: $break-grid-lg) {
					font-size: rem(19px);
				}
			}

			.search-dropdown__list__item__link {
				display: block;
				padding: 0 rem(15px);
				color: $shade-01 !important;

				@media (min-width: $break-grid-lg) {
					padding: 0 rem(25px);
				}

				&:hover {
					background: transparent;
					color: $color-brand-01 !important;
				}
			}
		}
	}
}