.cards__cell {
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	&:not(.cards__cell--margin-bottom):last-child {
		margin-bottom: 0;
	}
}

.cards__cell--margin-bottom {
	margin-bottom: rem(40px);
}

.cards__cell--margin-top {
	margin-top: rem(50px);

	@media (min-width: $break-grid-md) {
		margin-top: rem(60px);
	}

	@media (min-width: $break-grid-lg) {
		margin-top: rem(90px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(125px);
	}
}
