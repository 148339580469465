.share {
	position: relative;
}

.share__content {
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;

	@media (min-width: $break-grid-lg) {
		justify-content: normal;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.share__text {
	margin-right: rem(15px);
	margin-top: rem(-3px);

	@media (min-width: $break-grid-lg) {
		transform: rotate(90deg);
		transform-origin: top left;
		position: absolute;
		top: 0;
		left: rem(20px);
		margin-right: 0;
		margin-top: 0;
	}
}

.share__icons {

	@media (min-width: $break-grid-lg) {
		padding-top: rem(60px);
	}
}

.share__icon {
	color: $color-brand-01;

	@media (min-width: $break-grid-lg) {
		display: block;
	}

	&:first-child {
		margin-right: rem(5px);

		@media (min-width: $break-grid-lg) {
			margin-right: 0;
			margin-bottom: rem(12px);
		}
	}
}

.addthis_inline_share_toolbox {
	width: 100%;
	text-align: right;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: $transition-default;
	z-index: -1;

	@media (min-width: $break-grid-lg) {
		position: absolute;
		left: rem(-55px);
		overflow: visible;
	}

	&--active {
		opacity: 1;
		max-height: rem(300px);
		z-index: 1;
	}
}