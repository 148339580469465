.search-trigger-wrapper {
	position: relative;
}

.site-header .search-form {
	background: white;
	display: none;
	padding: rem(20px);
	position: absolute;
	right: 0;
	width: 100%;
	max-width: rem(780px);
	z-index: 10;
}