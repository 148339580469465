.featured-articles {
	background: $shade-02;
}

.featured-articles__header {
	align-items: baseline;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(58px);
	}

	.features-articles__link {
		@media (max-width: max-width($break-grid-sm)) {
			display: none;
		}
	}
}

.featured-articles__title {
	margin-right: rem(20px);
}

.features-articles__link {
	@include type-style-21;
	color: $color-brand-01;
	text-decoration: underline;
}

.featured-articles__items {
	border-bottom: rem(1px) solid #D9D9D9;
	padding-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(69px);
	}
}

.featured-articles__card {
	margin-bottom: ($l-grid-padding-default * 2);

	@media (min-width: $break-grid-xl) {
		margin-bottom: ($l-grid-padding-xl * 2);
	}
}

.featured-articles__footer {
	margin-top: rem(30px);

	@media (min-width: $break-grid-sm) {
		display: none;
	}
}
