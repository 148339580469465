.page-courses-filter {
	.title-section--mobile-only {
		display: block;

		@media (min-width: $break-grid-lg) {
			display: none;
		}
	}

	.general-content {
		.title-section__heading,
		.course-description {
			display: none;

			@media (min-width: $break-grid-lg) {
				display: block;
			}
		}
	}

	.title-section__heading {
		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(30px);
		}
	}

	.course-description {
		margin-bottom: rem(30px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(40px);
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(45px);
		}
	}

	.filter__search-form,
	.courses-menu {
		display: block;
	}

	.related-articles,
	.courses-list-section__heading,
	.title-section__tag {
		display: none;
	}
}