.below-search {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	a:focus {
		color: white;
	}
}

.below-search__advanced-search {
	margin-left: auto;
}

.below-search__locations__desktop-heading {
	display: none;

	@media (min-width: em(1730px)) {
		display: block;
		font-size: rem(19px);
		font-weight: bold;
		margin-right: rem(35px);
	}
}

.below-search__locations__list-wrap {
	order: 1;
	width: 100%;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: all $transition-default;

	@media (min-width: $break-grid-md) {
		display: flex;
		order: 0;
		width: auto;
		opacity: 1;
		max-height: rem(100px);
	}
}

.below-search__locations__list-wrap--active {
	opacity: 1;
	max-height: rem(100px);
}

.below-search__locations__list {
	display: flex;
	flex-wrap: wrap;
	margin-top: rem(15px);
	max-width: rem(300px);

	@media (min-width: $break-grid-md) {
		margin-top: 0;
		max-width: 100%;
	}
}

.below-search__locations__list__item {
	display: flex;
	width: 50%;
	margin-top: rem(10px);

	@media (min-width: $break-grid-md) {
		width: auto;
		margin-top: 0;
		margin-right: rem(18);
	}

	@media (min-width: em(1485px)) {
		margin-right: rem(30);
	}
}

.below-search__locations__list__item__link {
	display: flex;
	align-items: center;
}

.below-search__locations__list__item__icon {
	margin-left: rem(12);
}

.below-search__icon-text {
	display: flex;
	align-items: baseline;
}

.below-search__icon-text--mobile-heading {
	@media (min-width: $break-grid-md) {
		display: none;
	}
}

.below-search__icon-text__icon {
	color: $color-brand-01;
	flex-shrink: 0;
	margin-left: rem(10px)
}