.comparison-table {
	overflow: auto;
	font-size: rem(16px);

	td, th {
		border: 1px solid $shade-03;
		vertical-align: middle;
	}

	th {
		color: #000;
		font-size: rem(16px);
		font-weight: 900;
		margin-bottom: rem(10px);
		padding: rem(10px) rem(20px);
	}

	td {
		padding: 10px;
		text-align: center;

		&:first-child {
			text-align: left;
		}
	}

	img {
		margin: 0 auto;
	}

	a {
		@extend .text-link;
	}
}