.link-list__heading {
	margin-bottom: rem(10);
	line-height: 1.3em;
	font-size: rem(24);
	font-weight: 700;
}
.link-list__summary {
	margin-bottom: rem(20);
}
.link-list-group__item {
	padding: rem(10) 0;
	display: block;
	position: relative;
}
.link-list-link {
	color: #e64626;
	text-decoration: underline;
	&:hover {
		color: #ce3d20;
	}
}
.link-list__text {
	display: block;
	padding-right: rem(25);
	font-size: rem(14);
	font-family: "Apercu Mono", Courier, monospace;
}
.link-list__links--no-padding {
	padding: 0;
}
.link-list__link-item {
	padding: rem(10) 0;
	font-family: "Apercu Mono", Courier, monospace;
}
.link-list__link-item--bullet::before {
	display: block;
	float: left;
	padding-left: rem(3);
	width: rem(25);
	color: #000;
	content: "\2014 ";
}
.link-list--heavy-top {
	border-top: rem(2) solid #000;
}
.link-list__links {
	padding: rem(10) 0;
	list-style: none;
	margin: 0;
}
.link-list-icon--external-link:before {
	content: "\e007";
	display: block;
	width: rem(12);
	height: rem(12);
	text-transform: none;
	text-decoration: none;
	font-family: icon-font;
	font-weight: 400;
	font-style: normal;
	font-variant: normal;
	font-size: 12px;
}
.link-list__wrapper .link-list__icon {
	position: absolute;
}
.link-list__icon {
	display: block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	padding-top: rem(2);
}
.link-list-icon--right-arrow {
	transform: translateY(-50%) rotate(180deg);
}
.link-group__item {
	list-style: none;
}
