.cart-wrap {
	#shortlist {
		padding: 0 0 0 rem(14px);
		margin: rem(17px) 0 0;

		&:before {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB2aWV3Qm94PSIwIDAgMjUgMjUiIHdpZHRoPSIyNiIgaGVpZ2h0PSIyNiI+PGcgdHJhbnNmb3JtPSJtYXRyaXgoMS4wNDE2NjY2NjY2NjY2NjY3LDAsMCwxLjA0MTY2NjY2NjY2NjY2NjcsMCwwKSI+PHBhdGggZD0iTTE3LjE3NCw4LjI1SDQuNTYxQTEuNSwxLjUsMCwwLDAsMy4xMjUsOS42NjNsLTIsMTEuODQyQTEuNTMzLDEuNTMzLDAsMCwwLDIuNTYyLDIzLjI1SDE5LjE3M2ExLjUzMywxLjUzMywwLDAsMCwxLjQzNy0xLjc0NWwtMi0xMS44NDJBMS41LDEuNSwwLDAsMCwxNy4xNzQsOC4yNVoiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSI+PC9wYXRoPjxwYXRoIGQ9Ik0xNC41NTMsNS4zMDdBMy43NjMsMy43NjMsMCwwLDAsMTAuODY4LDIuMjVoMEEzLjc2NCwzLjc2NCwwLDAsMCw3LjE4Niw1LjI4OCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij48L3BhdGg+PC9nPjwvc3ZnPg==);
			background-position: center;
			top: rem(-14px);
			left: 0;
			width: rem(26px);
			height: rem(26px);
			z-index: -1;
		}

		.shortlistInfo {
			background: $color-brand-01;
			height: rem(17px);
			width: rem(17px);
			line-height: rem(17px);
			border-radius: 50%;
			text-align: center;
			font-size: rem(10px);

			span {
				background: transparent;
				color: white;
				font-size: rem(10px);
				font-weight: bold;
				line-height: rem(17px);
			}
		}

		.shortlistAction {
			top: rem(-20px);
			left: 0;
		}

		ul.shortListOrder {
			width: rem(280px);
			top: rem(30px);
			right: rem(-100px);

			@media (min-width: $break-grid-md) {
				width: rem(400px);
				top: rem(40px);
				right: 0;
			}
		}
	}
}

.cart-summary-wrap {
	margin-bottom: rem(30px);

	#shortlist {
		float: none;
		padding: 0;
		margin: 0;

		&:before {
			content: none;
		}

		ul.shortListOrder {
			display: block !important;
			position: static;
			background: transparent;
			padding: 0;

			li {
				color: $shade-01;
				line-height: 1.3;
				padding: 0 rem(18px) rem(15px) 0;
				margin-bottom: rem(15px);
				border-bottom: rem(1px) solid rgba(black, .08);

				&:nth-last-child(2) {
					margin-bottom: 0;
				}

				&:before {
					content: none;
				}

				> a {
					color: $shade-01;
					font-size: rem(16px);
					font-weight: bold;
					margin-bottom: rem(5px);
				}
			}

			.deleteItem {
				a {
					color: $shade-01;
				}
			}
		}

		.shortListOrderClasses {
			display: block;
		}

		.shortlistInfo,
		.shortListOrderEnrol,
		.shortlistAction {
			display: none;
		}
	}
}