.subject-cards {
	.site-wrapper .page-content & {
		margin-top: rem(50px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(70px);
		}

		@media (min-width: $break-grid-xl) {
			margin-top: rem(80px);
		}
	}
}