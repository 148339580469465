.topics {
	background: $shade-02;
	padding: rem(30px);
	position: relative;

	@media (min-width: $break-grid-lg) {
		padding: rem(45px) rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(80px) rem(80px) rem(55px);
	}
}

.topics__heading {
	color: black;
	margin-bottom: rem(10px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(20px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(45px);
		margin-bottom: rem(35px);
	}
}

.topics__list {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(45px);
	}
}

.topics__list__item {
	margin-bottom: rem(10px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(15px);
	}

	&:last-child {
		margin-bottom: 0;
	}
}

//.topics__list__item__arrow {
//	color: $color-brand-01;
//	flex-shrink: 0;
//}

.topics__list__item__link {
	color: $shade-01 !important;
	font-size: rem(16px);
	font-weight: bold;
	display: block;
	width: 100%;
	padding: rem(5px) rem(30px) rem(5px) 0;
	transition: color $transition-default;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNS4yIDEyLjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjIgMTIuOCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNlNjQ4MjgiIGQ9Ik0xNS4xLDYuN2MwLDAsMC4xLTAuMSwwLjEtMC4xYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLTAuMS0wLjEtMC4xLTAuMWwtNS45LTYKCUM5LDAsOC44LDAsOC42LDAuMWMtMC4yLDAuMi0wLjIsMC40LDAsMC42TDEzLjgsNkgwLjRDMC4yLDYsMCw2LjIsMCw2LjRjMCwwLjIsMC4yLDAuNCwwLjQsMC40aDEzLjRsLTUuMiw1LjIKCWMtMC4yLDAuMi0wLjIsMC40LDAsMC42YzAuMiwwLjIsMC40LDAuMiwwLjYsMEwxNS4xLDYuN3oiLz4KPC9zdmc+Cg==');
	background-repeat: no-repeat;
	background-position: right rem(8px);
	background-size: rem(23px) rem(17px);
	text-decoration: none !important;

	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
		background-position: right rem(10px);
	}

	&:hover {
		color: $color-brand-01 !important;
	}
}

.topics__ctas {
	border-top: rem(1px) solid rgba(black, .08);
	padding-top: rem(20px);

	@media (min-width: $break-grid-md) {
		padding-top: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(50px);
	}
}

.topics__ctas__cell {
	&:first-child {
		margin-bottom: rem(20px);

		@media (min-width: $break-grid-md) {
			margin-bottom: 0;
		}
	}
}

.topics__ctas__link {
	display: flex;
}

.topics__ctas__icon {
	color: $color-brand-01;
	flex-shrink: 0;
	margin-right: rem(20px);

	@media (min-width: $break-grid-md) {
		margin-right: rem(15px);
	}

	@media (min-width: $break-grid-xl) {
		margin-right: rem(30px);
	}
}

h6.topics__ctas__heading {
	color: $color-brand-01;
	margin-bottom: rem(5px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(21px);
		margin-bottom: rem(3px);
	}
}
