h1,
.h1 {
    @include type-style-01;
}

h2,
.h2 {
    @include type-style-02;
}

h3,
.h3 {
    @include type-style-03;
}

h4,
.h4 {
    @include type-style-04;
}

h5,
.h5 {
    @include type-style-05;
}

h6,
.h6 {
    @include type-style-06;
}

p,
li {
    @include type-style-07;
}

.lead {
	@include type-style-08;
}

.snippet {
	@include type-style-09;
}

.tag {
	@include type-style-10;
}

.tag--small {
	@include type-style-10-alt;
}

.tag--large {
	@include type-style-10-alt-2;
}

.statement {
	@include type-style-11;
}

.heading--line:before {
	content: '';
	display: block;
	width: rem(50px);
	height: rem(3px);
	background: currentColor;
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-xl) {
		height: rem(4px);
	}
}

.heading--line-sm {
	&:before {
		height: rem(3px);
		margin-bottom: rem(25px);
	}
}
