.class-note {
	background: none;
	font-size: rem(14px);
	padding: 0 0 rem(30px);
	text-align: center;

	&:before {
		color: $color-brand-01;
		float: none;
	}

	&:hover {
		background: none;
	}
}

.class-note-important {
	background-color: transparent;

	&:before {
		color: $color-brand-01;
	}
}
