.client-overview {
	padding: rem(20px);
	border: rem(1px) solid $shade-05;

	@media (min-width: $break-grid-md) {
		display: flex;
		padding: rem(40px);
	}
}

.client-overview__logo {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		order: 1;
		padding-left: rem(65px);
	}
}

.client-overview__logo__image {

}

.client-overview__table {
	flex-grow: 1;
}

.client-overview__table__row {
	padding: rem(13px) 0;
	border-bottom: rem(1px) solid $shade-07;

	&:last-child {
		padding-bottom: 0;
		border: none;
	}

	@media (min-width: $break-grid-md) {
		display: flex;
	}
}

.client-overview__table__cell {
}

.client-overview__table__cell--left {
	@media (min-width: $break-grid-md) {
		width: 27%;
		padding-right: rem(40px);
	}

	p {
		font-size: rem(16px);
		font-weight: bold;
	}
}

.client-overview__table__cell--right {
	@media (min-width: $break-grid-md) {
		width: 73%;
	}

	p {
		@media (min-width: $break-grid-md) {
			font-size: rem(16px);
		}
	}
}
