@mixin video-image-background {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #444;
}

@mixin video-image-background-media {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.video-image-background {
	@include video-image-background;
}

.video-image-background__media {
	@include video-image-background-media
}
