$site-offcanvas-color-highlight: $color-brand-01 !default;
$site-offcanvas-color-background: $shade-03 !default;
$site-offcanvas-transition: .2s ease-out !default;
$site-offcanvas-break-disable: em(1024px) !default;

.site-offcanvas {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
	color: black;
	background: $site-offcanvas-color-background;
	overflow: auto;
	transform: translateX(100%);
	transition: transform $site-offcanvas-transition;
	overflow-x: hidden;

	&.site-offcanvas--is-active {
		transform: translateX(0);
	}

	@media (min-width: $site-offcanvas-break-disable) {
		transform: translateX(100%) !important;
	}
}

.site-offcanvas__header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: rem(130px);
	padding: 0 rem(30px);
	background: white;
}

.site-offcanvas__control {
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem(50px);
	width: rem(50px);
	border: 0;
	border-radius: 999px;
	color: white;
	cursor: pointer;
	background: lighten($shade-04, 10);
	transition: $site-offcanvas-transition;
	transition-property: opacity, visibility, background-color;

	&:hover,
	&:focus {
		background-color: $site-offcanvas-color-highlight;
	}
}

.site-offcanvas__control--back {
	opacity: 0;
	visibility: hidden;
	transition: $site-offcanvas-transition;
	transition-property: opacity, visibility;

	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

.site-offcanvas__icon {
	fill: white;
}

.site-offcanvas__icon--back {
	//transform: scale(1.4) rotate(-180deg);
	//margin-left: rem(-4px);
}

.site-offcanvas__icon--close {
	position: relative;
	height: rem(20px);
	width: rem(20px);
	transform: scale(0.6);

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: rem(9px);
		height: rem(2px);
		width: rem(20px);
		background: white;
		border-radius: 999px;
	}

	&::after {
		transform: rotate(45deg);
	}

	&::before {
		transform: rotate(-45deg);
	}
}

.site-offcanvas__content {
	padding: 0 rem(30px) rem(50px);
	max-width: rem(375px);
	margin: 0 auto;
}

.site-offcanvas__actions {
	margin-top: rem(28px);
}
