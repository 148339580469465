.js-content-menu-enabled {
	.content-menu {
		display: block;
	}

	.related-articles {
		display: none;
	}
}

.js-header-banner-disabled {
	.title-section--mobile-only {
		display: block;

		@media (min-width: $break-grid-lg) {
			display: none;
		}

		.course-tagline {
			font-family: $font-family-secondary;
			font-size: rem(20px);
			font-weight: 100;
			line-height: em(36px, 28px, true);
			margin: 0 0 rem(21px);
		}
	}

	.general-content {
		.title-section__tag,
		h1,
		.course-tagline {
			display: none;

			@media (min-width: $break-grid-lg) {
				display: block;
			}
		}
	}

	h1 {
		color: black;
		margin-bottom: rem(20px);
	}
}

.course-features-container,
.breadcrumbs-bar,
.page-banner-img,
.page-banner-video {
	display: none;
}

.course-tagline {
	@extend .snippet;
	font-weight: 100;

	strong {
		font-weight: normal;
	}
}

.page-customisable {
	.site-main > h1.page-title {
		display: none;
	}
}
