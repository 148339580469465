.text-colour-block {
	background: rgba($color-brand-01, .95);
	padding: rem(25px) rem(40px) rem(30px);
	position: relative;

	@media (min-width: $break-grid-lg) {
		padding: rem(45px) rem(40px) rem(60px);
		max-width: rem(360px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(70px) rem(80px) rem(85px);
		max-width: rem(550px);
	}

	&:before,
	&:after {
		content: '';
		display: block;
		width: rem(4px);
		height: rem(30px);
		position: absolute;
		background: black;

		@media (min-width: $break-grid-lg) {
			height: rem(35px);
		}

		@media (min-width: $break-grid-xl) {
			height: rem(50px);
		}
	}

	&:before {
		top: 0;
		left: rem(20px);

		@media (min-width: $break-grid-lg) {
			left: rem(40px);
		}

		@media (min-width: $break-grid-xl) {
			left: rem(80px);
		}
	}

	&:after {
		background: white;
		transform: rotate(90deg);
		transform-origin: top right;
		right: 0;
		bottom: rem(10px);

		.subject-page & {
			background: black;
		}
	}

	.subject-page & {
		background: rgba($shade-09, .95);
	}
}

.text-colour-block__heading {
	color: black;
	margin-bottom: rem(10px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(15px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(25px);
	}
}

.text-colour-block__snippet {
	color: white;

	@media (min-width: $break-grid-md) {
		margin-left: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		margin-left: rem(37px);
	}

	strong {
		font-weight: normal;
	}

	.subject-page & {
		color: black;
	}
}

.text-colour-block__image {
	position: relative;
	display: block;
	height: 0;
	width: 100%;
	padding-bottom: percentage(160 / 280);
	background: no-repeat center bottom;
	background-size: cover;

	@media (min-width: $break-grid-lg) {
		padding-bottom: 100%;
	}
}
