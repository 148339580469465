.location-directions {
	padding-top: rem(35px);
	margin-top: rem(35px);
	border-top: rem(1px) solid $shade-05;

	@media (min-width: $break-grid-lg) {
		padding-top: rem(55px);
		margin-top: rem(45px);
	}
}

.locations-grid {
	margin-top: rem(35px);
	margin-bottom: rem(-50px);

	@media (min-width: $break-grid-lg) {
		margin-top: rem(55px);
	}
}

.location-name,
.site-postal-address {
	@media (min-width: $break-grid-lg) {
		font-size: rem(20px);
	}
}

.page-location {
	.location-title {
		margin-top: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(40px);
		}
	}
}

#gmapCanvas {
	@media (min-width: $break-grid-xl) {
		height: rem(475px);
	}
}