.facts__item {
	padding: rem(25px) 0;
	border-top: rem(3px) solid black;

	&:last-child {
		padding-bottom: 0;
	}
}

.facts__item__number {
	font-size: rem(30px);
	color: $color-brand-01;

	@media (min-width: $break-grid-lg) {
		font-size: rem(50px);
	}
}

.facts__item__description {
	font-weight: bold;

	@media (min-width: $break-grid-lg) {
		font-size: rem(16px);
	}
}