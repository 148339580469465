.fact-tile {
	position: relative;
	margin-bottom: rem(20);
	padding-top: rem(8);
}
.fact-tile__link {
	display: block;
	position: relative;
	cursor: pointer;
	text-decoration: none;
}
.fact-tile__link:focus,
.fact-tile__link:hover {
	background-color: #f1f1f1;
	text-decoration: none;
	color: inherit;
}
.fact-tile__label {
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);
}
.fact-tile__figure {
	position: relative;
	margin-bottom: rem(6) !important;
	line-height: 1.135;
	letter-spacing: rem(-2);
	color: #e64626;
	font-size: rem(50);
}
.fact-tile__figure--long {
	padding-top: rem(7);
	padding-bottom: rem(5);
	font-size: rem(50);
}
.fact-tile__description {
	position: relative;
	padding-right: rem(20);
	color: #000;
	font-weight: 700;
}
@media (min-width: 550px) {
	.fact-tile__figure {
		font-size: rem(80);
	}
	.fact-tile__figure--long {
		font-size: rem(80);
	}
}
@media (min-width: 768px) {
	.fact-tile__figure {
		font-size: rem(80);
	}
	.fact-tile__figure--long {
		font-size: rem(55);
	}
}
@media (min-width: 1200px) {
	.fact-tile__figure--long {
		font-size: rem(80);
	}
	.col-md-6 .fact-tile__figure--long,
	.leftSidebarColumn .fact-tile__figure--long,
	.rightSidebarColumn .fact-tile__figure--long {
		font-size: rem(55);
	}
}
.fact-tile-rule--heavier-top {
	border-top: rem(3) solid #000;
}
.fact-tile-rule--heavier-top {
	padding-top: rem(20);
}
