.title-section {
	padding-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(45px);
	}
}

.title-section--no-padding {
	padding-bottom: 0;
}

.title-section--mobile-only {
	display: none;
}

.title-section--desktop-only {
	display: none;

	@media (min-width: $break-grid-lg) {
		display: block;
	}
}

p.title-section__tag {
	margin-bottom: rem(10px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(15px);
	}
}

.title-section__heading {
	color: black;
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(30px);
	}
}

.title-section__heading--top {
	margin-top: -0.1em;
}
