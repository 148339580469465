.horizontal-line {
	&-wrapper {
	}
	border-color: #000;

	&.horizontal-line--light {
		border-width: rem(1);
	}
	&--heavy {
		border-width: rem(3);
	}
}

