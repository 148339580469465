.button-block {
	background: $shade-02 !important;
	border-left: rem(5px) solid $color-brand-01 !important;
	padding: rem(20px) rem(20px) rem(20px) rem(30px) !important;
	margin: 0;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	@media (min-width: $break-grid-sm) {
		flex-wrap: nowrap;
	}
}

.button-block__button-wrap {
	@media (min-width: $break-grid-sm) {
		margin-left: auto;
	}
}

.button-block.classItem .classAction {

	.enrolAction {
		@extend .button;
		@extend .button--arrow;
		width: auto;
		margin: 0;
		text-transform: lowercase;

		&:first-letter {
			text-transform: uppercase;
		}

		&.enrol-added-class {
			background: #dedede;
			padding: rem(15px) rem(51px);
			color: $color-brand-01;

			&:hover {
				border: none;
			}
		}

		&.disabled {
			text-align: center;
		}
	}
}

.button-block__text {
	font-size: rem(16px);
	margin-bottom: rem(10px) !important;
	width: 100%;

	@media (min-width: $break-grid-sm) {
		width: auto;
		margin-bottom: 0 !important;
		margin-right: rem(30px);
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(18px);
	}
}

.button-block__text__highlight {
	font-weight: bold;
	color: $color-brand-01;
}

.button-block-below {
	background: #e8e8e8;
	border-left: rem(5px) solid $color-brand-01;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;

	@media (min-width: $break-grid-md) {
		flex-wrap: nowrap;
	}
}

.button-block-below__info {
	padding: rem(20px);
	width: 100%;

	@media (min-width: $break-grid-md) {
		width: auto;
	}

	&:first-child {
		padding-left: rem(30px);
		padding-bottom: 0;

		@media (min-width: $break-grid-md) {
			padding-bottom: rem(20px);
		}
	}
}

.button-block-below__heading {
	display: block;
	color: $color-brand-01;
}

.button-block-below__courses-list {
	margin: 0 !important;
}

.button-block-below__courses-list__item {
	margin: 0 !important;
	font-size: rem(15px);
}