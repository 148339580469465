.page-overlay {
	position: fixed;
	z-index: 2;
}

.element-overlay {
	background: rgba(0,0,0,.55);
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9;
}