.imgd4ebc655 {
	background-image: url("../images/angele-taggart.jpg");
	background-size: cover;
	background-position: 50% 50%;
}

.call-out {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&-left {
		flex: 0 0 100%;
		padding-right: rem(60);
		@media (min-width: $break-grid-md) {
			flex: 0 0 rem(232);
		}
	}
	&-right {
		flex: 0 0 100%;
		@media (min-width: $break-grid-md) {
			flex: 1;
		}
		margin-bottom: rem(20);
	}
	&-name {
		flex: 0 0 100%;
		margin-bottom: rem(30);
		line-height: 1.3em;
		font-size: rem(23.8);
		font-family: Apercu, Helvetica, sans-serif;
		font-weight: 700;
	}
	&-image {
		border-radius: rem(55);
		width: rem(110);
		height: rem(110);
		margin-bottom: rem(20);
		background-color: $shade-05;
		background-size: cover;
		background-position: center;
	}
	&-strapline {
		display: block;
		margin-bottom: rem(40);
		line-height: 1.42;
		font-weight: 400;
		font-size: rem(16);
	}
	&-quote {
		line-height: 1.6em;
		color: #4c4c4c;
		font-family: Antwerp, Georgia, serif;
		font-size: rem(18);
		font-style: italic;
		padding-right: rem(50);
	}
	&-link {
		color: #e64626;
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(14);

		&__text {
			text-decoration: underline;
		}

		&__icon {
			transform: rotate(180deg);
		}
	}
}
