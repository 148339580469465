.child-page-list {
	ul {
		display: block;
		list-style-type: disc;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}
	li {
		display: list-item;
		list-style: disc;
	}
	a {
		text-decoration: underline;
		color: #e64626;
	}
	a:focus,
	a:hover {
		color: #ce3d20;
	}
}
