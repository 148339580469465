.callout-copy__content {
    max-width: rem(1100);
    @include type-style-25;

    br {
        display: none;

        @media (min-width: $break-grid-md) {
            display: block;
        }
    }
}