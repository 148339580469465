.feature-card {
	text-align: left;
	margin-bottom: rem(40px);
}

.feature-card__highlight {
	@include type-style-16;
	border-bottom: rem(3px) solid #000;
	color: $color-brand-01;
	margin-bottom: rem(20px);
	padding-bottom: rem(20px);
}

.feature-card__title {
	@include type-style-41;
}

.feature-card__description {
	@include type-style-40;
	margin-top: rem(6px);
}

.feature-card__link {
	color: $color-brand-01;
	margin-top: rem(6px);
	text-decoration: underline;
	transition: text-decoration-color $transition-default;

	&:hover {
		text-decoration-color: transparent;
	}
}
