.page-tutor {
	.page-content {
		position: relative;

		h1 {
			@media (min-width: $break-grid-lg) {
				width: 75%;
			}
		}

		.tutor-picture {
			@media (min-width: $break-grid-lg) {
				width: 20%;
				position: absolute;
				top: 0;
				right: 0;
			}

			img {
				margin: auto;

				@media (min-width: $break-grid-lg) {
					margin-right: 0;
					max-height: rem(150px);
				}

				@media (min-width: $break-grid-xl) {
					max-height: rem(185px);
				}
			}
		}
	}
}

.tutor-class-block {
	padding-top: rem(35px);

	@media (min-width: $break-grid-lg) {
		padding-top: rem(55px);
	}
}