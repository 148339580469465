.tabbed-content {
	background: #fff;
	padding: rem(55px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(70px) 0 rem(80px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(130px) 0 rem(140px);
	}
}

.tabbed-content__grid {
	@media (min-width: $break-grid-xl) {
		flex-direction: column;
		max-height: 1300px;
	}

	@include ie-only {
		display: block;

		&:after {
			content: '';
			clear: both;
			display: table;
		}
	}
}

.tabbed-content__cell--tabs-intro {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(50px);
	}
}

.tabbed-content__cell--images,
.tabbed-content__cell--subject {
	@media (min-width: $break-grid-md) {
		order: 1;
	}

	@media all and (min-width: $break-grid-md) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		float: right;
	}
}

.tabbed-content__cell--images--inactive {
	display: none;
}

.tabbed-content__cell--content-tab1 {
	@media all and (min-width: $break-grid-md) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		float: left;
	}
}

.tabbed-content__tabs {
	margin-bottom: rem(30px);
	display: flex;

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(50px);
	}
}

p.tabbed-content__lead-para {
	max-width: rem(620px);

	@media (min-width: $break-grid-lg) {
		max-width: rem(710px);
	}

	@media (min-width: $break-grid-xl) {
		max-width: rem(750px);
	}
}

.tabbed-content__copy-grid {
	margin-bottom: rem(35px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(55px);
	}
}

.tabbed-content__tab {
	font-size: rem(22px);
	font-weight: bold;
	line-height: 1.18;
	color: #808080;
	display: inline-block;
	padding-bottom: rem(15px);
	margin-right: rem(20px);
	border-bottom: 3px solid #dcdcdc;
	transition: $transition-default;
	width: 50%;

	&:last-child {
		margin-right: 0;
	}

	@media (min-width: $break-grid-md) {
		width: auto;
		border-color: white;
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(28px);
		margin-right: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(36px);
	}

	br {
		@media (min-width: $break-grid-md) {
			display: none;
		}
	}
}

.tabbed-content__tab:hover,
.tabbed-content__tab--active {
	color: $color-brand-01;
	border-color: $color-brand-01;
}

.tabbed-content__content {
	position: relative;
	display: none;
}

.tabbed-content__content--active {
	display: block;
}

.tabbed-content__content--inactive {
	display: none;

	@media (min-width: $break-grid-lg) {
		display: block;
	}
}



.tabbed-content__content__images {
	position: relative;
}

.tabbed-content__content__images--tab1 {
	padding-bottom: rem(20px);
	display: none;

	&.tabbed-content__content--active {
		@media (min-width: $break-grid-lg) {
			display: block;
		}
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(50px);
	}
}

.tabbed-content__primary-image--tab1 {
	padding-right: rem(80px);

	@media (min-width: $break-grid-xl) {
		padding-right: rem(140px);
	}
}

.tabbed-content__secondary-image--tab1 {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 45%;

	@media (min-width: $break-grid-xl) {
		width: auto;
	}
}

p.tabbed-content__primary-tag--tab1 {
	margin-top: rem(30px);
	margin-bottom: 0;

	@media (min-width: $break-grid-xl) {
		margin-top: rem(40px);
	}
}



.tabbed-content__content__images--tab2 {
	text-align: right;
	padding-bottom: rem(40px);
	margin-left: rem(50px);
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(100px);
		margin-left: 0;
	}
}

.tabbed-content__content__tagged-image--tab2 {
	position: relative;
	display: inline-block;
	padding-left: rem(60px);

	@media (min-width: $break-grid-lg) {
		padding-left: rem(80px);
	}

	@media (min-width: $break-grid-xl) {
		padding-left: rem(50px);
	}

	@include ie-only {
		display: block;
	}
}

.tabbed-content__secondary-image--tab2 {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 45%;

	@media (min-width: $break-grid-xl) {
		width: auto;
	}
}

.tabbed-content__primary-tag--tab2 {
	transform: rotate(90deg);
	transform-origin: top left;
	position: absolute;
	top: 0;
	left: rem(30px);

	@media (min-width: $break-grid-lg) {
		left: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		left: rem(15px);
	}
}

.tabbed-content__content__text {
	margin-bottom: rem(15px);

	p:last-child {
		margin-bottom: 1.5em;
	}
}



.tabbed-content__subject {
	display: none;
}

.tabbed-content__subject--active {
	display: block;
}

.tabbed-content__subject__nav {
	border-top: rem(1px) solid #dcdcdc;
	padding-top: rem(20px);
	font-size: rem(14px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
	}
}

.tabbed-content__subject__nav--top {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-lg) {
		display: none;
	}
}

.tabbed-content__subject__nav--bottom {
	padding-top: rem(35px);
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (min-width: $break-grid-lg) {
		display: block;
		border: none;
		padding: 0;
		width: 25%;
		text-align: right;
	}

	@media (min-width: $break-grid-xl) {
		width: 40%;
	}
}

.tabbed-content__subject__nav-link {
	> * {
		vertical-align: middle;
	}

	& > :first-child {
		margin-right: rem(15px);
	}
}

.tabbed-content__subject__nav-link--back {
	@media (min-width: $break-grid-lg) {
		display: none;
	}
}

.tabbed-content__subject__nav-link__icon {
	color: $color-brand-01;
}

.tabbed-content__subject__nav-link__icon--left {
	transform: rotate(-180deg);
}

.tabbed-content__subject__image {
	height: 0;
	width: 100%;
	padding-bottom: percentage(200 / 280);
	background: $shade-03 no-repeat center top;
	background-size: cover;
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		padding-bottom: percentage(200 / 440);
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: percentage(388 / 440);
	}

	&--art-humanities {
		background-image: url('/s/images/custom-new/tabbed-tabbed-arts-humanities-01.jpg');

		@media (min-width: $break-grid-md) {
			background-position: center;
		}
	}

	&--business-management {
		background-image: url('/s/images/custom-new/tabbed-business-management-01.jpg');

		@media (min-width: $break-grid-md) {
			background-position: center;
		}
	}

	&--english {
		background-image: url('/s/images/custom-new/tabbed-english-01.jpg');

		@media (min-width: $break-grid-md) {
			background-position: center;
		}
	}

	&--horticulture-gardening {
		background-image: url('/s/images/custom-new/tabbed-horticultre-gardening-01.jpg');
	}

	&--hsc-preparation {
		background-image: url('/s/images/custom-new/tabbed-hsc-prep-01.jpg');

		@media (min-width: $break-grid-md) {
			background-position: center;
		}
	}

	&--human-resources {
		background-image: url('/s/images/custom-new/tabbed-human-resources-01.jpg');
	}

	&--information-technology {
		background-image: url('/s/images/custom-new/tabbed-information-technology-01.jpg');
	}

	&--language-culture {
		background-image: url('/s/images/custom-new/tabbed-language-culture-01.jpg');
	}

	&--marketing {
		background-image: url('/s/images/custom-new/tabbed-marketing-01.jpg');
	}

	&--music-film {
		background-image: url('/s/images/custom-new/tabbed-music-film-01.jpg');
	}

	&--project-management {
		background-image: url('/s/images/custom-new/tabbed-project-management-01.jpg');
	}

	&--psychology-psychiatry {
		background-image: url('/s/images/custom-new/tabbed-psychology-psychiatry-01.jpg');

		@media (min-width: $break-grid-md) {
			background-position: center;
		}
	}

	&--public-sector {
		background-image: url('/s/images/custom-new/tabbed-public-sector-01.jpg');
	}
}

.tabbed-content__subject__text {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		width: 75%;
		padding-top: rem(20px);
	}

	@media (min-width: $break-grid-lg) {
		background: white;
		padding-top: rem(40px);
		padding-right: rem(60px);
		margin-top: -39%;
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		width: 60%;
		padding-top: rem(45px);
		margin-top: -30%;
	}
}

.tabbed-content__subject__heading {
	color: black;
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(30px);
	}
}

.tabbed-content__subject__description {
	@media (min-width: $break-grid-xl) {
		font-size: rem(18px);
	}
}

.tabbed-content__subject__below-text {
	position: relative;

	@media (min-width: $break-grid-lg) {
		display: flex;
		background: white;
		padding-top: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(40px);
	}
}

.tabbed-content__subject__list {
	margin-bottom: rem(35px);

	@media (min-width: $break-grid-md) {
		width: 75%;
		padding-right: rem(25px);
	}

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		width: 60%;
	}
}

.tabbed-content__subject__list__item {
	margin-bottom: rem(20px);

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
	}
}
