.profiles-section {
	text-align: center;

	@media (min-width: $break-grid-md) {
		text-align: left;
	}

	.site-wrapper .page-content & {
		margin-top: rem(45px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(65px);
		}

		@media (min-width: $break-grid-xl) {
			margin-top: rem(85px);
		}
	}
}

.profiles-section__heading {
	color: black;
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(80px);
	}
}

.profiles__cell--heading {
	margin-bottom: rem(40px);
}

.profiles__cell--profile {
	margin-bottom: rem(30px);

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-md) {
		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(35px);
	}
}

.profiles__cell--image {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: 0;
	}
}

.profiles__cell--details {
	@media (min-width: $break-grid-xl) {
		padding-left: rem(30px)
	}
}

.profiles__heading {
	color: black;

	&:before {
		margin-bottom: rem(22px);
	}
}

.profiles__image {
	width: 31%;
	padding-bottom: 31%;
	background: $shade-02 no-repeat center top;
	background-image: url('/images/profile-placeholder.png');
	background-size: cover;
	border-radius: 50%;
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(30px);
	}

	@media (min-width: $break-grid-lg) {
		width: 37%;
		padding-bottom: 37%;
	}

	@media (min-width: $break-grid-xl) {
		width: 100%;
		height: auto;
		padding-bottom: 100%;
	}

	&--md-full-width {
		@media (min-width: $break-grid-md) {
			width: 100%;
			padding-bottom: 100%;
		}
	}

	.profiles-section & {
		margin-left: auto;
		margin-right: auto;

		@media (min-width: $break-grid-md) {
			margin-left: 0;
		}
	}
}

.profiles__below-image {
	@media (min-width: $break-grid-xl) {
		text-align: center;
	}
}

.profiles__below-image__text {
	font-size: rem(16px);
	text-decoration: none;
	border-bottom: rem(1px) solid $color-brand-01-alt;
	transition: $transition-default;

	.tutor-profile-link:hover & {
		border-color: transparent;
	}
}

.profiles__below-image__icon {
	vertical-align: middle;
	margin-left: rem(15px);
	color: $color-brand-01;

	@media (min-width: $break-grid-xl) and (max-width: 1520px) {
		margin-left: 0;
	}
}

p.profiles__name {
	font-size: rem(20px);
	margin-bottom: rem(10px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(20px);
	}
}

p.profiles__about {
	margin-bottom: rem(17px);

	.profiles-section & {
		max-width: rem(500px);
		margin: auto;

		@media (min-width: $break-grid-md) {
			max-width: 100%;
		}
	}
}

.profiles__link-list__item {
	margin-bottom: rem(10px);

	&:last-child {
		margin-bottom: 0;
	}
}
