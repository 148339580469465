.corporate__hero {
	background-image: url('/s/images/custom-new/corporate-hero.jpg');
	background-position: center;
	padding-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(45px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(95px) 0 rem(115px);
	}

	&:before {
		content: '';
		display: block;
		background: linear-gradient(90deg, rgba(44,44,44,0.7) 0%, rgba(44,44,44,0.5) 60%, rgba(44,44,44,0) 75%);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		@media (min-width: $break-grid-xl) {
			background: linear-gradient(90deg, rgba(44,44,44,0.7) 0%, rgba(44,44,44,0.5) 40%, rgba(44,44,44,0) 70%);
		}
	}
}

.corporate__intro {
	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(40px);
	}
}

.corporate__training__first-cell {
	margin-bottom: rem(30px);
	margin-top: rem(50px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}
}

.corporate__text-colour-block {
	@media (min-width: $break-grid-md) {
		padding-right: rem(110px);
	}

	@media (min-width: $break-grid-xl) {
		max-width: rem(450px);
	}

	@media (min-width: $break-grid-xxl) {
		padding-right: rem(130px);
		max-width: rem(500px);
	}
}

.corporate__text-colour-block__image {
	background-image: url('/s/images/custom-new/meeting-table.jpg');

	@media (min-width: $break-grid-md) {
		padding-bottom: 30%;
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: percentage(824 / 630);
	}
}

//.corporate__training {
//	@media (min-width: $break-grid-xl) {
//		padding-bottom: rem(140px);
//	}
//}

.corporate__training__heading {
	@include type-style-03;
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(90px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(110px);
	}
}

.corporate__training__text-image-wrap {
	position: relative;

	@media (min-width: $break-grid-xl) {
		padding-right: rem(35px);
	}
}

.corporate__training__tag {
	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(30px) !important;
	}
}

.corporate__training__snippet {
	margin-bottom: rem(20px) !important;

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(30px) !important;
	}

	@media (min-width: $break-grid-xxl) {
		margin-bottom: rem(40px) !important;
	}
}

.corporate__delivery__heading {
	margin-bottom: rem(36px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(53px);
	}
}

.corporate__delivery__first-cell {
	margin-bottom: rem(50px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}
}

.corporate__customised {
	@media (min-width: $break-grid-xl) {
		padding: rem(210px) 0 rem(140px);
	}
}

.corporate__customised__heading {
	margin-bottom: rem(30px);

	&:before {
		margin-bottom: rem(20px);
	}

	br {
		display: none;

		&.br-lg {
			@media (min-width: $break-grid-lg) {
				display: block;
			}

			@media (min-width: 1700px) {
				display: none;
			}
		}

		&.br-xl {
			@media (min-width: 1700px) {
				display: block;
			}
		}
	}
}

.corporate__customised__text-indent {
	padding-left: rem(20px);

	@media (min-width: $break-grid-lg) {
		padding-left: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		padding-left: rem(60px);
	}
}

.corporate__customised__cell {
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}

	&:nth-child(2) {
		@media (min-width: $break-grid-xl) {
			padding-left: rem(45px);
		}
	}
}

.corporate__benefits__heading,
.corporate__spotlight__heading {
	color: black;
	margin-right: rem(20px);
	margin-bottom: rem(20px);
}

.corporate__benefits__section-arrow-link-wrap {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(60px);
	}
}

.corporate__spotlight__section-arrow-link-wrap {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(60px);
	}
}

.corporate__benefits__item {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-sm) {
		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
	}

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(50px);
	}

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(60px);

		&:nth-last-child(-n+3) {
			margin-bottom: 0;
		}
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(90px);
	}

	.copy {
		text-align: center;

		@media (min-width: $break-grid-sm) {
			text-align: left;
		}
	}
}

.corporate__benefits__item__image-wrap {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	img {
		margin: auto;

		@media (min-width: $break-grid-sm) {
			margin: 0;
		}
	}
}

.corporate__spotlight__item {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(50px);

		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
	}

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}
}

.corporate-course-areas__title {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(60px);
	}
}
