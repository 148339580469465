.site-header__breadcrumbs {
	background: $shade-02;
	padding: rem(15px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(30px) 0;
	}

	div[class*=' taggroup-'],
	div[class^='taggroup-'] {
		display: inline-block;
	}

	.courses-list {
		ul {
			display: inline-block;
			margin: 0;
		}

		li {
			@extend .site-header__breadcrumbs__text;
			@extend .tag--small;
			display: none;

			&.active_tag {
				display: inline-block;

				.page-single-course & > a:after {
					margin-right: 0;
				}
			}

			&.parent_tag {
				display: inline-block;

				> a {
					&:after {
						margin: 0 0 0 rem(9px);
					}
				}

				.page-single-course &:after {
					content: '/';
					display: inline-block;
					margin-right: rem(9px);
					text-decoration: none;
				}
			}

			&.parent_tag .parent_tag:after,
			&.parent_tag .active_tag > a:after,
			&.active_tag.hasChildren > a:after {
				content: none;
			}

			a {
				@extend .site-header__breadcrumbs__link;
				display: inline-block;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.page-products-list &,
	.page-single-product &,
	.page-class-detail &,
	.page-location &,
	.page-tutor &,
	#SiteList & {
		display: none;
	}

	.sticky-header & {
		padding: rem(15px) 0;
	}
}

.site-header__breadcrumbs__text {
	color: black;
	line-height: 1.4;
}

.site-header__breadcrumbs__text--static {
	display: inline-block;
}

.site-header__breadcrumbs__link {
	color: black;
	transition: color $transition-default;

	&:hover {
		color: $color-brand-01;
	}

	&:after {
		content: '/';
		display: inline-block;
		margin: 0 rem(9px);
		text-decoration: none;
	}
}

.site-header__breadcrumbs__link--subjects {
	.page-courses-filter & {
		&:after {
			content: none;
		}
	}
}
