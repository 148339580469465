.card {
	display: block;
	color: black;
}

.card--top-line {
	border-top: 3px solid black;
	padding-top: rem(20px);
}

.card--bottom-line {
	border-bottom: 3px solid black;
	padding-bottom: rem(20px);
	height: 100%;
}

.card__tag {
	margin-bottom: rem(20px);
}

.card__header {
	border-bottom: 1px solid black;
	border-top: 3px solid black;
	padding: rem(24px) 0;
}

.card__image {
	position: relative;
	display: block;
	height: 0;
	width: 100%;
	padding-bottom: percentage(260 / 380);
	background: no-repeat center center;
	background-size: cover;

	&:after {
		content: '';
		display: block;
		background: rgba($color-brand-01, .4);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transition: $transition-default;
		opacity: 0;

		.card:hover & {
			opacity: 1;
		}
	}
}

.card__image--large {
	padding-bottom: percentage(248 / 420);
}

.card__image__arrow-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	width: rem(70px);
	height: rem(70px);
	background: $color-brand-01;
	color: white;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;
}

.card__content {
	padding-top: rem(25px);

	@media (min-width: $break-grid-xl) {
		padding-top: rem(30px);
	}
}


.card__title {
	@include type-style-05;
	margin-bottom: rem(12px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(17px);
	}

	.card--client &,
	.featured-articles & {
		@media (min-width: $break-grid-xl) {
			min-height: rem(55px);
		}
	}
}

.card__title--inline {
	@media (min-width: $break-grid-xl) {
		width: 40%;
	}
}

.card__content--inline {
	@media (min-width: $break-grid-xl) {
		display: flex;
		align-items: baseline;
	}
}

.card__content--inline .card__triangle {
	@media (min-width: $break-grid-xl) {
		flex-shrink: 0;
		margin: 0 4% 0 0;
	}
}

.card__content--inline-top-aligned {
	@media (min-width: $break-grid-xl) {
		align-items: flex-start;
	}
}

.card__content__inner {
	@media (min-width: $break-grid-xl) {
		display: flex;
		align-items: baseline;
		margin-left: rem(15px);
	}

	@media (min-width: $break-grid-xl) {
		margin-left: 5%;
		margin-right: 5%;
		width: 50%;
	}
}

.card__triangle {
	margin-bottom: rem(7px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(12px);
	}
}

.card__excerpt {
	color: $shade-01;
}

.card__excerpt--60 {
	@media (min-width: $break-grid-md) {
		width: 60%;
	}
}

.card__excerpt__para-with-margin {
	margin-bottom: 1.5em;
}

.card__excerpt__link {
	font-size: rem(16px);
}

.card__excerpt__arrow {
	color: $color-brand-01;
	transition: transform $transition-default;

	.card__link:hover & {
		transform: translateX(#{rem(5px)});
	}
}

.card__course {
	@include type-style-17;
	align-items: center;
	color: $color-brand-01;
	display: flex;
}

.card__course-icon {
	flex-shrink: 0;
	margin-right: rem(10px);
}

.card__link-text {
	color: $color-brand-01;
	font-family: $font-family-alt;
	display: flex;
	font-size: rem(14px);
	margin-bottom: rem(20px);
	transition: color $transition-default;

	.card__link:hover & {
		color: black;
	}
}
