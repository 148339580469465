.block-Checkout {
	#oncourse-checkout {
		margin: 0;
		font-size: rem(16px);

		h2 {
			margin-bottom: rem(5px);
		}

		.student-name {
			margin-bottom: rem(20px);

			.student-info {
				h3 {
					color: $color-brand-01;
				}
			}
		}

		.progress-steps {
			margin-bottom: rem(30px);

			@media (min-width: $break-grid-xl) {
				margin-bottom: rem(40px);
			}

			.validation {
				li {
					a {
						text-decoration: underline;
					}
				}
			}

			ul {
				background: transparent;
				border-radius: 0;

				li {
					width: auto;
					text-align: left;
					padding-right: rem(20px);
					margin-right: rem(15px);

					&:before,
					&:after {
						content: '';
						display: block;
						width: rem(7px);
						height: rem(1px);
						background: #a2a2a2;
						position: absolute;
						left: auto;
						right: 0;
						border: none;
					}

					&:before {
						transform: rotate(45deg);
						top: calc(50% - 2.5px);
					}

					&:after {
						transform: rotate(-45deg);
						top: calc(50% + 2.5px);
					}

					&:last-child {
						&:before,
						&:after {
							content: none;
						}
					}

					&.active {
						a {
							text-decoration: none;
						}
					}

					&.disable {
						a {
							color: $shade-01;
							text-decoration: none;

							&:hover,
							&:focus {
								color: $shade-01;
							}
						}
					}

					a {
						background: transparent;
						padding: 0 !important;
						color: $color-brand-01;
						text-decoration: underline;
						font-weight: normal;
						font-size: rem(16px);

						@media (min-width: $break-grid-md) {
							font-size: rem(18px);
						}

						&:hover,
						&:focus {
							color: $color-brand-01;
						}
					}
				}
			}
		}

		.payment-summary {
			.checkoutList {
				&:after {
					content: none;
				}

				.enrolmentItem {
					&:after {
						width: calc(100% - 30px);
					}

					.enrolmentInfo {
						padding-left: rem(15px);
					}
				}
			}

			.new-student-link {
				color: $color-brand-01;
			}
		}

		div.amount-container {
			background: $shade-02;
			border-radius: 0;
			padding: rem(25px);

			.amount-content {
				margin-bottom: rem(20px);
			}
		}
	}
}