.stylised-table {
	margin-top: rem(50px);

	@media (min-width: $break-grid-xl) {
		margin-top: rem(74px);
	}
}

.stylised-table__title {
	@include type-style-22;
	margin-bottom: rem(24px);
	text-align: center;

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(45px);
	}
}

.stylised-table__wrapper {
	overflow-x: auto;
}

.stylised-table__table {
	@include type-style-23;
	border-collapse: collapse;

	thead th:not(:first-child),
	tbody td:not(:first-child) {
		padding-left: rem(8px);

		@media (min-width: $break-grid-lg) {
			padding-left: rem(16px);
		}
	}

	thead tr {
		height: 1px;

		th {
			height: inherit;
		}

		@-moz-document url-prefix() {

			height: 50px; // Your min height

			th {
				height: auto;
			}
		}
	}

	tbody {
		tr {
			height: 1px;

			td {
				border-bottom: rem(1px) solid rgba(#000, 0.1);
				height: inherit;

				@supports (height: -moz-available) {
					height: 100%;
				}

				@media (min-width: $break-grid-sm) {
					width: 20%;
				}
			}

			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
}


.stylised-table__header {
	background: $color-brand-01;
	color: #fff;
	height: 100%;
	min-width: rem(115px);

	display: table;
	width: 100%;
	text-align: center;

	.stylised-table--dark & {
		background: #363636;
	}
}

.stylised-table__icon {
	display: block;
	margin: rem(20px) auto 0;
	max-height: rem(44px);
	max-width: rem(44px);
	width: 100%;
	height: auto;

	@media (min-width: $break-grid-lg) {
		margin-top: rem(25px);
	}

	+ .stylised-table__heading {
		padding-top: rem(10px);
	}
}

.stylised-table__heading {
	display: block;
	padding: rem(20px) rem(10px) rem(15px);

	@media (min-width: $break-grid-lg) {
		padding: rem(25px) rem(15px) rem(20px);
	}
}

.stylised-table__label {
	display: block;
	padding: rem(15px) rem(10px) rem(15px) 0;
	min-width: rem(125px);

	@media (min-width: $break-grid-lg) {
		padding: rem(23px) rem(20px) rem(23px) 0;
	}
}

.stylised-table__cell {
	background: #feead3;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: rem(26px) rem(10px);
	width: 100%;

	.stylised-table--dark & {
		background: #F5F5F5;
	}
}

.stylised-table__note {
	color: $color-brand-01;
	font-weight: bold;
}

.stylised-table__footnote {
	margin-top: rem(43px);
	text-align: center;

	p {
		@include type-style-18;
		line-height: (25 / 16);
	}

	strong {
		color: $color-brand-01;
	}
}
