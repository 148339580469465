.respects {
	background: #fff;
	padding: 0 0 rem(50px);

	@media (min-width: $break-grid-lg) {
		padding: rem(10px) 0 rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		padding: 0 0 rem(70px);
	}
}

.respects__cell {
	max-width: rem(700px);
	margin: auto;
	text-align: center;
}

.respects__link {
	color: $color-brand-01;
	text-decoration: underline;
	text-decoration-color: rgba($color-brand-01, .2);
}
