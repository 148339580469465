.sticky-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	transform: translateY(-100%);
	background: white;
	box-shadow: 0 rem(-4px) rem(8px) 0 rgba(0,0,0,0.75);
	transition: $transition-default;

	&.sticky-header--is-active {
		transform: translateY(0);
	}
}

.sticky-header__top-section {
	padding: rem(15px) 0 !important;
}

.sticky-header__bottom-section {
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	padding: 0 !important;
	transition: $transition-default;

	&.sticky-header__bottom-section--is-active {
		@media (min-width: $break-grid-lg) {
			max-height: rem(100px);
			opacity: 1;
		}
	}
}

.sticky-header__unit {
	@media (min-width: $break-grid-lg) {
		margin-left: rem(35px) !important;
	}
}

.sticky-header__unit--offcanvas-trigger {
	display: block !important;
}