.featured-image__figure {
	position: relative;
}

.featured-image__image {
	width: 100%;
}

.featured-image__caption {
	background: $shade-01;
	color: white;
	font-size: rem(20px);
	max-width: rem(950px);
	padding: rem(25px);

	@media (min-width: $break-grid-md) {
		bottom: 0;
		padding: rem(40px) rem(45px);
		position: absolute;
		right: 0;
		width: 72%;

		.feature-image--align-left & {
			left: 0;
		}
	}

	@media (min-width: $break-grid-lg) {
		font-size: rem(25px);
		line-height: (43.5 / 33);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(33px);
		padding: rem(50px) rem(60px) rem(53px);
	}
}

.featured-image__label {
	display: block;
	margin-top: rem(20px);

	@media (min-width: $break-grid-lg) {
		margin-top: rem(27px);
	}
}