.course-download {
	align-items: center;
	background: $color-brand-04;
	display: flex;
	padding: rem(22px) rem(20px);
	position: relative;

	@media (min-width: $break-grid-md) {

		display: flex;
		padding: rem(22px) rem(30px) rem(22px) rem(143px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(32px);
		padding-top: rem(32px);
	}

	.overview + & {
		margin-top: rem(-1px);
	}

	&:hover {
		background: #ffe6c7;
	}
}

.course-download__thumb {
	margin-right: rem(20px);

	@media (min-width: $break-grid-md) {
		bottom: 0;
		position: absolute;
		left: rem(30px);
	}
}

.course-download__content {
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (min-width: $break-grid-sm) {
		margin-right: rem(25px);
	}

	@media (min-width: $break-grid-xl) {
		align-items: center;
		flex-direction: row;
	}
}

.course-download__title {
	@include type-style-24;
	color: $color-brand-01;
	margin-bottom: rem(5px);
	text-decoration: underline;
	text-decoration-color: transparent;
	text-underline-offset: rem(3px);
	transition: text-decoration $transition-default;

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		margin-right: rem(25px);
	}

	.course-download:hover & {
		text-decoration-color: inherit;
	}
}

.course-download__desc {
	@media (max-width: em(499px)) {
		font-size: rem(14px);
	}
}

.course-download__icon {
	color: $color-brand-01;
	flex-shrink: 0;
	display: none;

	@media (min-width: $break-grid-sm) {
		display: block;
	}

	@media (min-width: $break-grid-md) {
		margin-left: auto;
	}
}
