.clients-grid__cell {
    margin-bottom: rem(40px);

    &:last-child {
        margin-bottom: 0;
    }

    @media (min-width: $break-grid-sm) {
        margin-bottom: rem(70px);

        &:nth-last-child(-n+2) {
            margin-bottom: 0;
        }
    }

    @media (min-width: $break-grid-md) {
        &:nth-last-child(-n+3) {
            margin-bottom: 0;
        }
    }
}

.clients-grid__item {
    display: block;
}

.clients-grid__item__image-wrap {
    margin-bottom: rem(30px);

    @media (min-width: $break-grid-sm) {
        min-height: rem(110px);
    }
}

.clients-grid__item__image-wrap--padding-top-20 {
    @media (min-width: $break-grid-sm) {
        padding-top: rem(20px);
    }
}

.clients-grid__item__image-wrap--padding-top-15 {
    @media (min-width: $break-grid-sm) {
        padding-top: rem(15px);
    }
}

.clients-grid__item__copy {
    margin-bottom: rem(20px);
}

.clients-grid__item__tag {
    color: black;
}

.clients-grid__item__heading {
    color: black;
    transition: $transition-default;

    .clients-grid__item:hover & {
        color: $color-brand-01;
    }
}

.clients-grid__item__icon {
    color: $color-brand-01;
}