.button,
.daterangepicker .btn {
	width: auto;
	height: auto;
	margin: 0;
	font-size: rem(14px);
	font-weight: bold;
	display: inline-block;
	background-color: $color-brand-01;
	padding: rem(15px) rem(28px);
	color: white;
	border: none;
	border-radius: 0;
	transition: background-color $transition-default;
	white-space: nowrap;

	&:hover,
	&:focus {
		background-color: $color-brand-01-alt;
		color: white;
	}

	&.disabled {
		background: #dedede;
		padding: rem(15px) rem(41px);
		color: #888;

		&:hover {
			border: none;
		}
	}
}

.button__icon {
	.button--tertiary & {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(56);
		height: rem(56);
		background-color: transparent;
		border: rem(1) solid $color-brand-01;
		border-radius: 50%;
		transition: all $transition-default;
	}

	.button--tertiary:hover & {
		background-color: transparent;
		border-color: white;
	}

	svg {
		.button--tertiary & {
			color: $color-brand-01;
		}

		.button--tertiary:hover & {
			color: white;
		}
	}
}

.button--tertiary {
	display: flex;
	align-items: center;
	gap: rem(16);
	padding: 0;
	background-color: transparent !important;
	font-size: rem(20);
	font-weight: 700;
	transition: all $transition-default;

	&:hover {
		color: $color-brand-01;
	}
}

.button--arrow {
	padding: rem(15px) rem(60px) rem(15px) rem(18px);
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNS4yIDEyLjgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjIgMTIuOCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0xNS4xLDYuN2MwLDAsMC4xLTAuMSwwLjEtMC4xYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLDAtMC4xLDAtMC4yYzAtMC4xLTAuMS0wLjEtMC4xLTAuMWwtNS45LTYKCUM5LDAsOC44LDAsOC42LDAuMWMtMC4yLDAuMi0wLjIsMC40LDAsMC42TDEzLjgsNkgwLjRDMC4yLDYsMCw2LjIsMCw2LjRjMCwwLjIsMC4yLDAuNCwwLjQsMC40aDEzLjRsLTUuMiw1LjIKCWMtMC4yLDAuMi0wLjIsMC40LDAsMC42YzAuMiwwLjIsMC40LDAuMiwwLjYsMEwxNS4xLDYuN3oiLz4KPC9zdmc+Cg==);
	background-repeat: no-repeat;
	background-size: rem(15px) rem(17px);
	background-position: right rem(18px) top 50%;
	min-width: rem(160px);
	text-align: left;
}

.button--arrow-down {
	display: flex;
	align-items: center;
	justify-content: center;
	width: rem(96);
	height: rem(96);
	background-color: transparent;
	border: rem(1) solid $color-brand-01;
	border-radius: 50%;
	transition: all 0.2s ease-in-out;

	&:hover {
		background-color: white;
		border-color: white;
	}
}

.button--mono {
	@include type-style-14;
	font-weight: normal;
}

.button--large {
	padding: rem(20px) rem(30px);

	@media (min-width: $break-grid-xl) {
		padding: rem(25px) rem(35px);
	}
}

.button--rounded {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: rem(10);
	padding: rem(15) rem(40);
	width: fit-content;
	background-color: transparent;
	border: rem(1) solid $color-brand-01;
	border-radius: rem(9999);
	color: $color-brand-01;
	font-size: rem(20);
	font-weight: 700;
	line-height: 1.3;
	white-space: unset;
	transition: all $transition-default;

	&:hover {
		background-color: $color-brand-01;
		color: white;
	}
}
