.general-content {
	&--centered {
		text-align: center;
	}

	h2:not(.default):not(.course-tagline):not(.subject-accordion-heading):not(.article-list__title):not(.featured-courses__heading):not(.profiles-section__heading):not(.accordion__trigger):not(.vimeo__title):not(.youtube__title):not(.events-list__title):not(.fact-list-title):not(.link-list__heading):not(.article-detail__section-title):not(.full-width-media__title):not(.icon-text__title):not(.feature-card__title):not(.media-text-bar__title) {
		font-size: rem(24px);
		font-weight: normal;
		padding-top: rem(15px);

		@media (min-width: $break-grid-lg) {
			padding-top: rem(30px);
		}

		@media (min-width: $break-grid-xl) {
			font-size: rem(30px);
		}

		&:first-child {
			padding-top: 0;
		}
	}

	h3:not(.topics__heading):not(.accordion__trigger):not(.featured-block__text__heading):not(.events-list__title):not(.call-out-name):not(.title):not(.contact-information__title):not(.cta-block__heading):not(.media-text-bar__title):not(.media-text-bar__subheading):not(.full-width-media__title) {
		font-size: rem(19px);
		color: $color-brand-01;
	}

	h4:not(.media-text-bar__subheading) {
		@extend .h6;
		margin-top: rem(15px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(30px);
		}
	}

	ol {
		margin-bottom: 1.5em;

		li {
			display: list-item;
			list-style: decimal;
			padding-left: rem(13px) !important;
			margin-left: rem(17px);

			&:before {
				display: none;
			}
		}

		&:not(.retain-margin):last-child {
			margin-bottom: 0;
		}
	}

	ul:not(.courses-list):not(.schedule__class__tutors):not(.filter__applied__selections):not(.courses-list-sub):not(.courses-list-sub-level):not(.cce-icon-block):not(.search-dropdown__list) {
		margin-bottom: 1.5em;

		ul {
			margin-top: 0.7em;
		}

		&:not(.retain-margin):last-child {
			margin-bottom: 0;
		}
	}

	li:not(.courses-list__item):not(.schedule__class__tutor):not(.filter__applied__selections__item):not(.course-related-title--list):not(.cce-icon-block__item):not(.paginationjs-page):not(.paginationjs-prev):not(.paginationjs-next):not(.paginationjs-ellipsis):not(.search-dropdown__list__heading):not(.search-dropdown__list__item):not(.topics__list__item):not(.sites__item):not(.child-page-list__item):not(.list-group__item):not(.fact-list-group__item):not(.link-list-group__item) {
		padding-left: rem(30px);
		position: relative;
		margin-bottom: rem(5px);

		@media (min-width: $break-grid-lg) {
			font-size: rem(16px);
			margin-bottom: rem(10px);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			content: '\2014';
			position: absolute;
			left: 0;
		}
	}

	p:not(.lead):not(.tag):not(.snippet):not(.testimonials__carousel__item__quote):not(.filter__applied__label):not(.filter__applied__count):not(.profiles__name):not(.testimonials__carousel__item__name):not(.title-section__tag):not(.topics__ctas__para):not(.featured-block__text__description):not(.article-list__excerpt):not(.button-block__text):not(.fact-tile__figure):not(.fact-tile__label):not(.link-list__summary):not(.media-text-bar__subheading) {
		line-height: 1.6;

		@media (min-width: $break-grid-lg) {
			font-size: rem(16px);
		}
	}

	*[id],
	a[name] {
		scroll-margin-top: rem(70px);

		@media (min-width: $break-grid-lg) {
			scroll-margin-top: rem(140px);
		}

		@media (min-width: $break-grid-xl) {
			scroll-margin-top: rem(160px);
		}
	}

	small {
		@include type-style-43;
	}
}
