.image-card {
    position: relative;

    @media (max-width: max-width($break-grid-md)) {
        &:not(:last-child) {
            margin-bottom: rem(24);
        }
    }
}

.image-card__image-wrapper {
    position: relative;

    &::before {
        background: rgba(0, 0, 0, 0.35);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        pointer-events: none;
    }
}

.image-card__image {
    width: 100%;
    height: rem(400);
    object-fit: cover;
}

.image-card__copy {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: rem(16);
    margin: 0 rem(10);

    @media (min-width: $break-grid-md) {
        padding: rem(24) rem(32);
    }

    @media (min-width: $break-grid-xl) {
        margin: 0 rem(20);
    }
}

.image-card__title {
    margin-bottom: auto !important;
    font-size: rem(30);
    line-height: (36 / 30);
    color: $color-brand-01;
}

.image-card__text {
    max-width: rem(350);
    font-size: rem(26);
    font-weight: 700;
    line-height: (32 / 26);
}