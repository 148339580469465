.timeline-blocks {
	position: relative;
	margin-top: rem(50px);

	@media (min-width: $break-grid-md) {
		margin-top: rem(80px);
	}

	&:before {
		content: '';
		background: black;
		width: rem(1px);
		height: 100%;
		position: absolute;
		left: rem(4px);

		@media (min-width: $break-grid-md) {
			left: 0;
			right: 0;
			margin: auto;
		}
	}
}

.timeline-blocks__item {
	position: relative;
	margin-bottom: rem(50px);

	@media (min-width: $break-grid-md) {
		height: rem(465px);
		margin-bottom: 0;
	}

	&:last-child {
		height: auto;
	}

	&:before {
		content: '';
		width: rem(9px);
		height: rem(9px);
		background-color: $shade-03;
		position: absolute;
		left: 0;
		border-radius: rem(9px);
		box-shadow: 0 0 0 rem(16px) white;

		@media (min-width: $break-grid-md) {
			top: rem(-25px);
			right: 0;
			margin: auto;
		}
	}

	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.timeline-blocks__item__content {
	position: relative;
	margin-left: rem(30px);

	@media (min-width: $break-grid-md) {
		width: 43.5%;
		margin-left: 0;
	}

	.timeline-blocks__item:nth-child(even) & {
		@media (min-width: $break-grid-md) {
			float: right;
		}
	}
}

.timeline-blocks__item__image {
	background-color: $shade-03;
	background-repeat: no-repeat;
	background-size: cover;
	padding-bottom: 75%;
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		margin-bottom: rem(45px);
	}
}