.checklistModule {
	counter-reset: checklistModuleCounter;
}
.checklistModule .title {
	font-weight: 700;
	font-size: 30px;
	margin: 0 0 14px 0;
}
@media (min-width: $break-grid-md) {
	.checklistModule .title {
		font-size: 28px;
	}
}
@media (min-width: 1200px) {
	.checklistModule .title {
		font-size: 30px;
	}
}
.checklistModule .strapline {
	font-size: 14px;
}
.checklistModule .item {
	position: relative;
	padding: 30px 0;
	border-bottom: 1px solid #bcbcbc;
}
.checklistModule .item:last-child {
	border-bottom: none;
}
.checklistModule .item .number {
	display: inline-block;
	vertical-align: top;
	position: absolute;
	top: 30px;
	left: 0;
	font-family: "Apercu Mono", Courier, monospace;
	font-size: 40px;
	line-height: 40px;
	color: #e64626;
}
.checklistModule .item .content {
	margin-left: 50px;
}
.checklistModule .checklistItem {
	font-size: 14px;
}
.checklistModule .checklistItem h3 {
	margin-top: 0;
}
.checklistModule .checklistItem h4 {
	margin-top: 2px;
}
.checklistModule .checklistCTA {
	color: #ce3d20;
	text-decoration: none;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin-top: 10px;
	font-size: 14px;
}
.checklistModule .checklistCTA__icon {
	flex-shrink: 0;
	margin-left: 10px;
}
@media print {
	.checklistModule a:after {
		content: " [" attr(href) "] ";
	}
}
