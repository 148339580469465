.card-collection--grid {
	margin-bottom: rem(-30px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(-48px);
	}
}

.card-collection__cell {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(48px);
	}
}
