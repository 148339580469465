.filter__search-form {
	display: none;
}

.filter__head {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	border-bottom: rem(1px) solid black;
	padding-bottom: rem(15px);
}

.filter__head__heading {
	@media (min-width: $break-grid-xl) {
		font-size: rem(26px);
	}
}

.filter__head__link {
	color: $color-brand-01;
	font-size: rem(14px);
	text-decoration: underline;
}

.filter__group {
	padding: rem(28px) 0;
	border-bottom: rem(1px) solid $shade-05;

	&:last-child {
		padding-bottom: rem(40px);

		@media (min-width: $break-grid-lg) {
			padding-bottom: 0;
			border: none;
		}
	}

	.courses-list-sub {
		display: block;
		margin: 0;
	}
}

.filter__group__heading {
	color: $color-brand-01;
	font-size: rem(16px);
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
	}
}

.filter__group__list__item {
	font-size: rem(14px);
	margin-bottom: rem(12px);

	&:last-child {
		margin-bottom: 0;
	}
}

.filter-option {
	@extend .filter__group__list__item;
	@extend .form__checkbox-wrap;
}

.filter-option-label {
	font-weight: 400;
}

.filter-option-counter {
	display: none;
}

.filter__applied {
	padding: rem(20px) 0;
	margin-top: rem(40px);
	border-top: rem(1px) solid $shade-05;

	@media (min-width: $break-grid-md) {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}
}

.filter__applied__cell {
	@media (min-width: $break-grid-md) {
		display: flex;
		align-items: baseline;
	}
}

.filter__applied__cell--left {
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
		max-width: 50%;
	}

	@media (min-width: $break-grid-xl) {
		max-width: 60%;
	}
}

.filter__applied__cell--right {

	@media (min-width: $break-grid-md) {
		max-width: 50%;
	}

	@media (min-width: $break-grid-xl) {
		max-width: 40%;
	}
}

p.filter__applied__label {
	font-weight: bold;
	color: $color-brand-01;
	margin-right: rem(25px);
	margin-bottom: 0;
}

.filter__applied__selections {

}

.filter__applied__selections__item {
	display: inline-block;
	margin-right: rem(30px);
}

.filter__applied__selections__item__text {
	display: inline-block;
	vertical-align: middle;
	margin-right: rem(10px);
}

.filter__applied__selections__item__icon {
	display: inline-block;
	vertical-align: middle;
	font-size: rem(19px);
	font-weight: bold;
	color: $color-brand-01;
	position: relative;
	transform: rotate(45deg);
	text-decoration: none !important;
}

p.filter__applied__count {
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-md) {
		padding-right: rem(17px);
		margin-right: rem(15px);
		position: relative;
		margin-bottom: 0;
	}

	&:after {
		@media (min-width: $break-grid-md) {
			content: '';
			display: block;
			width: rem(1px);
			height: rem(14px);
			background: rgba(black, .2);
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
}

.filter__applied__count__number {
	font-weight: bold;
	color: $color-brand-01;
}

.filter__applied__refine-dropdown {
	border: none;
	font-size: rem(14px);
	color: $shade-01;
	padding-right: rem(20px);
	background-position: right rem(5px);
	width: auto !important;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	position: relative !important;
	opacity: 1 !important;
	height: auto !important;

	&.courses-filter-refine-custom {
		display: none !important;
	}
}

.filter-courses-title {
	display: none;
}

.filter-mobile-dropdown {
	display: block;
	padding: rem(10px) rem(20px);
	border: rem(1px) solid $shade-05;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAzElEQVQYlWP4/7B65mVbl/9txa9C/v//zwDFzFAM4b9PNbhTof5/T+2uXWCBj6frPl2wdfnfV3I9iuH/f6b////L/f//XwWs6Xau/e0K9f/n6jb893n8XxpmIoqmH///l/7//7/5/+1cO2TFIHVwDSD8+UzdkwtuLv/P7Xl2/c/Xcw9vZkMU17z6rwpTg6IBoqkerOlCkvr/M/Wb3sU8BjsPLo+hAey8M3XHz9WtewRzBjJmBOvCDtgZGBh+oksx4VLNwMDAgiHCwMAAAOIoz3Gx+PZWAAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	background-position: right rem(15px) center;
	transition: none;

	@media (min-width: $break-grid-lg) {
		display: none;
	}

	&.is-active {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAApUlEQVQokWP8////fwYKABMlmhkIGfD1xmyGq307GN7+JcOAr5d6Ge7kr2L4c7WA4Uk3HkP+YwFfLvb8v+Dm8v/a8Vf//3y78v9mtvr/c3Ub/r/5g6kWw4BP51r/X7B1+X/93Mf/cPXfbv+/XYHdEAaCmgkYgjDg7Yb/l0GaL/7A5isI+PPo/x2QIQvOwoVITwd/GRh+MzAwsDJDuIM8IREEDAwMAIPYCH/hrioJAAAAAElFTkSuQmCC);
	}
}

.filters-container {
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: $transition-default;

	@media (min-width: $break-grid-lg) {
		max-height: rem(2230px);
		overflow: visible;
		opacity: 1;
		margin: 0;
	}

	&.is-active {
		max-height: rem(2257px);
		overflow: visible;
		opacity: 1;
		margin: rem(30px) 0;
	}
}

.filter__date-range {
	padding: rem(16px) !important;
}


.courses-menu {
	display: none;
}

.courses-menu li {
	padding: 0;
}

.courses-menu > ul,
.courses-menu ul.courses-list {
	margin: 0;
	padding: 0;
	background: transparent;
}
.courses-menu > ul > ul > li,
.courses-menu ul.courses-list > ul > li {
	color: $shade-01;
	padding: 0;
	border-bottom: none;
	margin-right: 0;
}
