.cart-alert-box {
	background-color: $color-brand-01;
	display: none;
	height: 100%;
	position: absolute;
	top: 0;
	left: -99999px;
	z-index: 10;
	color: white;
	font-weight: bold;
	opacity: 0;
	padding: rem(10px) rem(20px);
	width: 100%;

	&.is-active {
		display: block;
		left: 0;
	}

	@media (min-width: $break-grid-md) {
		font-size: rem(25px);
	}

	a {
		color: white !important;
	}

	.btn-close-box {
		color: white !important;
		display: block;
		font-size: rem(11px);
		margin: rem(20px) 0;

		&:hover {
			color: black;
		}
	}

	.full-classes & {
		display: none !important;
	}
}

.cart-alert-box__container {
	align-items: center;
	display: flex !important;
	flex-direction: column;
	height: 100%;
	justify-content: center;

	@media (min-width: $break-grid-md) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.cart-alert-box__title {
	display: inline-block;
	font-size: rem(23px);
	text-align: center;

	a {
		color: white !important;
		border-bottom: 1px solid;

		&:hover {
			border-color: transparent;
		}
	}
}
