.full-width-image {
    padding: 0;
}

.full-width-image__image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;

    @media (min-width: $break-grid-md) {
        aspect-ratio: 10 / 4;
    }
}