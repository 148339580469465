.article-detail {
	h2 {
		font-size: rem(26px) !important;
		font-weight: 700 !important;
		line-height: 1.14 !important;

		@media (min-width: $break-grid-md) {
			font-size: rem(40px) !important;
		}

		@media (min-width: $break-grid-xl) {
			font-size: rem(54px) !important;
		}
	}

	h3 {
		margin-top: rem(30px);

		@media (min-width: $break-grid-xl) {
			margin-top: rem(45px);
		}
	}

	img {
		margin: rem(26px) 0;

		@media (min-width: $break-grid-md) {
			margin: rem(40px) 0;
		}

		@media (min-width: $break-grid-xl) {
			margin: rem(54px) 0;
		}
	}
}

.article-detail__label {
	@extend .tag--large;
	@extend .title-section__tag;
}

.article-detail__section-title {
	border-top: rem(3px) solid black;
	margin-top: rem(26px);
	padding-top: rem(26px);

	@media (min-width: $break-grid-md) {
		margin-top: rem(40px);
		padding-top: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(54px);
		padding-top: rem(54px);
	}
}
