.clients {
	background: #fff;
	text-align: center;
}

.clients__heading {
	color: black;
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}

}

.clients__carousel {
	.slick-track {
		display: flex;
		align-items: center;
	}

	.slick-slide {
		margin-right: rem(30px);

		@media (min-width: $break-grid-lg) {
			margin-right: rem(35px);
		}

		@media (min-width: $break-grid-xl) {
			margin-right: rem(60px);
		}
	}
}

.clients__carousel__images {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}
}

.clients__carousel__nav {
	justify-content: center;

	@media (min-width: $break-grid-lg) {
		display: none;
	}
}

.clients__carousel__item__image {
	margin: auto;
}
