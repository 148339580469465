.o-figure {
	border-bottom: 1px solid #000;
	margin-bottom: rem(34px);
	margin-top: rem(30px);

	img {
		margin: 0;
		width: 100%;
	}
}

.o-figure--right {
	@media (min-width: em(650px)) {
		float: right;
		margin: 9px 0 50px 50px;
		max-width: 50%;
	}
}

.o-figure--left {
	@media (min-width: em(650px)) {
		float: left;
		margin: 9px 50px 50px 0;
		max-width: 50%;
	}
}

.o-figure__caption {
	font-size: rem(12px);
	line-height: (24 / 12);
	padding: rem(7px) 0 rem(5px);
}