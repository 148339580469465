.paginationjs-pages {
	ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: 0 !important;
	}

	a {
		align-items: center;
		border: rem(1px) solid black;
		display: flex;
		justify-content: center;
		margin-bottom: rem(6px);
		margin-right: rem(6px);
		height: rem(50px);
		width: rem(50px);

		&:not(.disabled):hover {
			border-color: $color-brand-01;
			color: $color-brand-01;
		}
	}

	.active {
		a {
			border: rem(2px) solid $color-brand-01;
			color: $color-brand-01;
			font-weight: 700;
			text-decoration: none;
		}
	}
}

.paginationjs-next {
	a {
		background: $color-brand-01 url(data:image/svg+xml;base64,PHN2ZyBpZD0iOTgyOTU3OTYtNjkxNy00MTRkLTkzNGYtZWRjYmI2M2NiZGM3IiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjcuNyIgaGVpZ2h0PSIxMy4xMyIgdmlld0JveD0iMCAwIDcuNyAxMy4xMyI+PHRpdGxlPmNoZXZyb24tcmlnaHQtd2hpdGU8L3RpdGxlPjxwYXRoIGQ9Ik03LjU3LDYuODJhLjMyLjMyLDAsMCwwLC4xMy0uMjYuMzMuMzMsMCwwLDAtLjEzLS4yNkwxLjM4LjExQS4zNS4zNSwwLDAsMCwxLjExLDAsLjM2LjM2LDAsMCwwLC44My4xMWwtLjcuN2EuNDguNDgsMCwwLDAtLjEzLjMuMy4zLDAsMCwwLC4xLjI1bDUuMiw1LjJMLjEsMTEuNzZBLjMxLjMxLDAsMCwwLDAsMTJhLjQ2LjQ2LDAsMCwwLC4xMy4yOWwuNy43YS4zOC4zOCwwLDAsMCwuNTUsMFoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=) no-repeat center center;
		border-color: $color-brand-01;

		&:not(.disabled):hover {
			background-color: black;
			border-color: black;
		}
	}
}

.paginationjs-prev {
	a {
		background: $color-brand-01 url(data:image/svg+xml;base64,PHN2ZyBpZD0iOTdjODA1NWEtZTVlZS00ODBjLTgyNTEtNDE1YmY4MzZjOTNmIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjcuNyIgaGVpZ2h0PSIxMy4xMyIgdmlld0JveD0iMCAwIDcuNyAxMy4xMyI+PHRpdGxlPmNoZXZyb24tbGVmdC13aGl0ZTwvdGl0bGU+PHBhdGggZD0iTS4xMyw2LjMxQS4zLjMsMCwwLDAsMCw2LjU3YS4zLjMsMCwwLDAsLjEzLjI1TDYuMzIsMTNhLjM2LjM2LDAsMCwwLC4yNy4xMkEuMzYuMzYsMCwwLDAsNi44NywxM2wuNy0uN0EuNDMuNDMsMCwwLDAsNy43LDEyYS4zNS4zNSwwLDAsMC0uMS0uMjZMMi40LDYuNTdsNS4yLTUuMmEuMzUuMzUsMCwwLDAsLjEtLjI2QS40My40MywwLDAsMCw3LjU3LjgybC0uNy0uN2EuMzguMzgsMCwwLDAtLjU1LDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjZmZmIi8+PC9zdmc+) no-repeat center center;
		border-color: $color-brand-01;

		&:not(.disabled):hover {
			background-color: black;
			border-color: black;
		}
	}
}