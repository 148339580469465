.testimonials {
	background: #fff;

	@media (min-width: $break-grid-lg) {
		padding: rem(80px) 0 rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(140px) 0 rem(80px);
	}
}

.testimonials-block {
	background: $shade-03;
	padding: rem(30px);

	@media (min-width: $break-grid-lg) {
		padding: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(70px) rem(135px) rem(80px);
	}
}

.testimonials-block--single {
	background: $shade-03;
	padding: rem(40px) rem(30px);

	@media (min-width: $break-grid-lg) {
		padding: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(100px) rem(110px);
	}
}

.testimonials__grid {
	background-color: $shade-02;
	margin: auto;
	padding: rem(40px) rem(10px) rem(35px);
	text-align: center;

	@media (min-width: $break-grid-lg) {
		padding: rem(50px) rem(30px);
		text-align: left;
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(100px) rem(48px);
	}
}

.testimonials__cell--heading {
	@media (min-width: $break-grid-lg) and (max-width: $break-grid-lg-max) {
		padding-right: 0;
	}
}

.testimonials__heading {
	margin-bottom: rem(25px);
	color: black;

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}

	&:before {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: rem(25px);

		@media (min-width: $break-grid-lg) {
			margin-left: 0;
		}
	}
}

.testimonials__cell--carousel {
	@media (min-width: $break-grid-lg) {
		margin-top: rem(10px);
	}
}

.testimonials__carousel {
	position: relative;
}

.testimonials__carousel__nav {
	justify-content: center;

	@media (min-width: $break-grid-lg) {
		position: absolute;
		bottom: rem(10px);
		left: 57%;
	}

	.testimonials-block & {
		margin-top: rem(20px);

		@media (min-width: $break-grid-lg) {
			position: relative;
			bottom: auto;
			left: auto;
		}
	}

	.testimonials-block--single &,
	.testimonials__carousel--extended-name & {
		margin-top: rem(20px);

		@media (min-width: $break-grid-lg) {
			bottom: rem(-20px);
		}

		@media (min-width: $break-grid-xl) {
			bottom: rem(-30px);
			left: 0;
			right: 0;
		}
	}
}

.testimonials__carousel__item {
	.testimonials__carousel--extended-name & {
		padding: 0 rem(4px);
	}
}

.testimonials__carousel__item__image {
	width: 31%;
	padding-bottom: 31%;
	background: $shade-01 no-repeat center center;
	background-size: cover;
	border-radius: 50%;
	margin: 0 auto rem(28px);

	@media (min-width: $break-grid-md) {
		width: rem(130px);
		height: rem(130px);
		padding-bottom: 0;
	}

	@media (min-width: $break-grid-lg) {
		width: 100%;
		height: auto;
		padding-bottom: 100%;
		margin-bottom: 0;
	}

	&--jonathon {
		background-image: url('/images/testimonial-profile.png');
	}

	.testimonials-block--single & {
		@media (min-width: $break-grid-md) {
			width: rem(110px);
			height: rem(110px);
		}

		@media (min-width: $break-grid-lg) {
			width: 100%;
			height: auto;
		}
	}
}

.testimonials__carousel__item__text {
	@media (min-width: $break-grid-lg) {
		padding-left: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		padding-left: rem(50px);
	}

	.testimonials-block--single & {
		@media (min-width: $break-grid-xl) {
			padding-left: rem(80px);
		}
	}
}

.testimonials__carousel__item__quote {
	font-size: rem(16px);
	font-style: italic;
	margin-bottom: rem(35px);

	@media (min-width: $break-grid-lg) {
		font-size: rem(17px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(23px);
	}

	.testimonials-block & {
		text-align: center;
		margin-bottom: 0;

		&:before,
		&:after {
			content: '"';
			display: block;
			font-size: rem(24px);

			@media (min-width: $break-grid-lg) {
				font-size: rem(28px);
			}
		}

		&:before {

		}

		&:after {
			margin-top: rem(15px);
		}
	}

	.testimonials-block--single & {
		text-align: center;
		margin-bottom: rem(25px);

		@media (min-width: $break-grid-md) {
			text-align: left;
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(35px);
		}
	}
}

.testimonials__carousel__item__name {
	margin-bottom: rem(35px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: 0;
	}

	.testimonials-block & {
		@include type-style-13;
		text-align: center;
		color: #6e6e6e;

		@media (min-width: $break-grid-lg) {
			text-align: right;
			margin-bottom: rem(35px);
			margin-right: 10%;
		}
	}

	.testimonials-block--single &,
	.testimonials__carousel--extended-name & {
		@include type-style-13;
		line-height: 1.4;
		color: black;
		margin: 0;
		text-align: center;

		@media (min-width: $break-grid-md) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-align: left;
		}

		> span {
			display: block;

			@media (min-width: $break-grid-md) {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
			}

			&:first-child {
				margin-bottom: rem(5px);

				@media (min-width: $break-grid-md) {
					margin-right: rem(25px);
					margin-bottom: 0;
				}
			}

			> span {
				display: block;
				color: #6e6e6e;
				vertical-align: middle;
				margin-bottom: rem(10px);
				position: relative;

				@media (min-width: $break-grid-md) {
					padding-right: rem(25px);
					margin-right: rem(25px);
					margin-bottom: 0;
				}

				&:after {
					@media (min-width: $break-grid-md) {
						content: '';
						display: block;
						width: rem(1px);
						height: rem(14px);
						background: #b5b5b5;
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
					}
				}
			}

			a {
				color: $color-brand-01;
				display: block;
				line-height: 0;
			}
		}

		.icon-social-linkedin-alt {
			@media (min-width: $break-grid-xl) {
				margin-top: rem(-2px);
			}
		}
	}
}

.course-testimonials {
	display: none;
}
