:root {
	--notice-height: 0px;
}

.notice {
    background: $color-brand-02;
    padding: 20px 0;
    transition: $transition-default;
}

.notice--hidden {
    max-height: 0;
    padding: 0;
    overflow: hidden;
}

.notice__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notice__cell {
    display: flex;
    align-items: center;
}

.notice__text {
    margin: 0 rem(20px);
}

.notice__icon {
    color: $color-brand-01;
    flex-shrink: 0;
}
