.featured-courses {
	.site-wrapper .page-content & {
		margin-top: rem(45px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(65px);
		}

		@media (min-width: $break-grid-xl) {
			margin-top: rem(75px);
		}
	}
}

.featured-courses__heading {
	color: black;
	margin-bottom: rem(40px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(60px);
	}
}
