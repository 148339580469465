.home-intro-video {
	padding: rem(55px) 0;

	@media (min-width: $break-grid-lg) {
		padding: rem(70px) 0 rem(260px);
	}

	@media (min-width: $break-grid-xl) {
		padding-bottom: rem(320px);
		padding-top: rem(140px);
	}
}
