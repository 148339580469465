// h1
@mixin type-style-01 {
	font-size: rem(36px);
	font-weight: 700;
	line-height: 1.08;

	@media (min-width: $break-grid-md) {
		font-size: rem(52px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(70px);
	}
}

// h2
@mixin type-style-02 {
	font-size: rem(26px);
	font-weight: 700;
	line-height: 1.14;

	@media (min-width: $break-grid-md) {
		font-size: rem(40px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(54px);
	}
}

// h3
@mixin type-style-03 {
	font-size: rem(24px);
	font-weight: 700;
	line-height: 1.1;

	@media (min-width: $break-grid-md) {
		font-size: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(50px);
	}
}

// h4
@mixin type-style-04 {
	font-size: rem(22px);
	font-weight: 700;
	line-height: 1.13;

	@media (min-width: $break-grid-xl) {
		font-size: rem(30px);
	}
}

// h5
@mixin type-style-05 {
	font-size: rem(20px);
	font-weight: 700;
	line-height: 1.13;

	@media (min-width: $break-grid-xl) {
		font-size: rem(24px);
	}
}

// h6
@mixin type-style-06 {
	font-size: rem(18px);
	font-weight: 700;
	line-height: 1.2;

	@media (min-width: $break-grid-xl) {
		font-size: rem(20px);
	}
}

// p
@mixin type-style-07 {
	font-size: rem(14px);
	font-weight: 400;
	line-height: 1.4;
}

// lead
@mixin type-style-08 {
	font-size: rem(17px);
	font-weight: 400;
	line-height: 1.5;

	@media (min-width: $break-grid-md) {
		font-size: rem(18px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(20px);
	}
}

// snippet
@mixin type-style-09 {
	font-family: $font-family-secondary;
	font-size: rem(17px);
	line-height: 1.5;

	@media (min-width: $break-grid-xl) {
		font-size: rem(23px);
	}
}

// footer statement
@mixin type-style-09-alt {
  	font-family: $font-family-secondary;
	font-size: rem(20px);
  	font-style: italic;
  	line-height: 1.25;

	@media (min-width: $break-grid-md) {
		font-size: rem(22px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(30px);
	}
}

// tag
// button
@mixin type-style-10 {
	font-family: $font-family-alt;
	font-size: rem(14px);
	line-height: 1.1;
}

// tag small
// secondary menu
// carousel nav
@mixin type-style-10-alt {
	font-family: $font-family-alt;
	font-size: rem(12.5px);
	line-height: 1.1;
}

// tag large
@mixin type-style-10-alt-2 {
	font-family: $font-family-alt;
	font-size: rem(14px);
	line-height: 1.1;

	@media (min-width: $break-grid-xl) {
		font-size: rem(17.5px);
	}
}

// respects statement
@mixin type-style-11 {
	font-family: $font-family-secondary;
	font-style: italic;
}

// primary menu link
@mixin type-style-12($weight: 700) {
	font-size: rem(15px);
	font-weight: $weight;
	line-height: 1.5;

	@media (min-width: em(1280px)) {
		font-size: rem(18px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(19px);
	}
}

// quote name
@mixin type-style-13 {
	font-family: $font-family-alt;
	font-size: rem(12px);
	line-height: 1.1;

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
	}
}

// button mono
@mixin type-style-14 {
	font-family: $font-family-alt;
	font-size: rem(14px);
	line-height: 1.1;

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
	}
}

// progress-steps strong
@mixin type-style-15 {
	font-size: rem(18px);
	font-weight: bold;
	line-height: (26 / 22);

	@media (min-width: $break-grid-xl) {
		font-size: rem(22px);
	}
}

// progress-steps counter
@mixin type-style-16 {
	font-size: rem(50px);
	font-weight: 500;
	line-height: 86%;

	@media (min-width: $break-grid-xl) {
		font-size: rem(78px);
	}
}

// card - course
@mixin type-style-17 {
	font-size: rem(14px);
	font-weight: 700;
	line-height: (20 / 14);
}

// course-area-card__count
@mixin type-style-18 {
	font-size: rem(14px);

	@media (min-width: $break-grid-lg) {
		font-size: rem(16px);
	}
}

// course-area-card__title
@mixin type-style-19 {
	font-size: rem(20px);
	font-weight: 700;
	line-height: 1.13;

	@media (min-width: $break-grid-xl) {
		font-size: rem(24px);
	}

	@media (min-width: $break-grid-xxl) {
		font-size: rem(28px);
	}
}

// course-area-card__tag
@mixin type-style-20 {
	font-size: rem(14px);
	line-height: (20 / 14);
}

// featured-articles__link
@mixin type-style-21 {
	font-family: $font-family-alt;
	font-size: rem(14px);
	line-height: (15 / 14);
}

// stylised-table__title
@mixin type-style-22 {
	font-size: rem(22px);
	font-weight: 400;
	line-height: 1.13;

	@media (min-width: $break-grid-lg) {
		font-size: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(38px);
		line-height: 1;
	}
}

@mixin type-style-23 {
	font-size: rem(14px);
	font-weight: 700;
	line-height: (24 / 18);

	@media (min-width: $break-grid-lg) {
		font-size: rem(17px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(18px);
	}
}


// course-download_title
@mixin type-style-24 {
	font-size: rem(17px);
	font-weight: 700;
	letter-spacing: -0.025em;
	line-height: (27 / 20);

	@media (min-width: $break-grid-xl) {
		font-size: rem(20px);
	}
}


// .callout-copy__content
// .media-content__title
@mixin type-style-25 {
	font-size: rem(36);
	font-weight: 700;
	line-height: (46 / 36);

	@media (min-width: $break-grid-md) {
		font-size: rem(56);
		line-height: (66 / 56);
	}
}

// image-card-carousel__text
@mixin type-style-25--xl {
	font-size: rem(36);
	font-weight: 700;
	line-height: (46 / 36);

	@media (min-width: $break-grid-xl) {
		font-size: rem(56);
		line-height: (66 / 56);
	}
}

// .full-media-content__title
// .media-content__title
// .image-card-carousel__title
// .media-text-bar__title
@mixin type-style-26 {
	font-size: rem(22);
	line-height: (36 / 30);

	@media (min-width: $break-grid-md) {
		font-size: rem(30);
	}
}

@mixin type-style-26--xl {
	font-size: rem(22);
	font-weight: 700;
	line-height: (36 / 30);

	@media (min-width: $break-grid-xl) {
		font-size: rem(30);
	}
}

// image-card-slider__title
@mixin type-style-27 {
	font-family: $font-family-tertiary;
	font-style: italic;
	font-size: rem(30);
	font-weight: 700;
	line-height: (60 / 75);
	color: $color-brand-01;

	@media (min-width: $break-grid-xl) {
		font-size: rem(80);
	}
}
// .image-card-slider__text
@mixin type-style-28 {
	font-family: $font-family-tertiary;
	font-style: italic;
	font-size: rem(26);
	font-weight: 700;
	line-height: (32 / 26);

	@media (min-width: $break-grid-xl) {
		font-size: rem(40);
		line-height: (50 / 40);
	}
}

// .image-card-slider-card__title
@mixin type-style-29 {
	font-family: $font-family-tertiary;
	font-style: italic;
	font-size: rem(30);
	font-weight: 700;
	line-height: 1.2;
	color: $color-brand-01;

	@media (min-width: $break-grid-md) {
		font-size: rem(40);
	}
}

// .image-card-slider-card__text
@mixin type-style-30 {
	font-size: rem(18);
	font-weight: 400;
	line-height: (28 / 18);
}

// image-content-carousel-item__title-text
@mixin type-style-31 {
	font-size: rem(30);
	font-weight: 700;
	line-height: (36 / 30);

	@media (min-width: $break-grid-md) {
		font-size: rem(36);
		line-height: (46 / 36);
	}
}

// image-content-carousel-item__text
@mixin type-style-32 {
	font-size: rem(26);
	font-weight: 700;
	line-height: (32 / 26);
}

@mixin type-style-33 {
	font-family: $font-family-tertiary;
	font-size: rem(66);
	font-weight: 500;
	line-height: 1;
}

// blockquote__text
@mixin type-style-34 {
	font-family: $font-family-tertiary;
	font-size: rem(28);
	line-height: (40 / 28);
	font-weight: 400;
	letter-spacing: -0.01em;

	@media (min-width: $break-grid-md) {
		font-size: rem(36);
		line-height: (48 / 36);
	}
}

// statistics-item__heading
@mixin type-style-35 {
	font-size: rem(48);
	font-weight: 700;
	line-height: (51 / 48);
}

// statistics-item__text
@mixin type-style-36 {
	font-family: $font-family-tertiary;
	font-style: italic;
	font-size: rem(20);
	font-weight: 500;
	line-height: 1.2;
}

// call-to-action__title
@mixin type-style-37 {
	font-size: rem(26);

	@media (min-width: $break-grid-md) {
		font-size: rem(30);
	}
}

// media-content__heading
@mixin type-style-38 {
	font-size: rem(46);
	font-weight: 700;
	line-height: (46 / 46);

	@media (min-width: $break-grid-md) {
		font-size: rem(56);
		line-height: (66 / 56);
	}
}

// icon-text__title
@mixin type-style-39 {
	font-size: rem(18px);
	font-weight: bold;
	line-height: (28 / 22);

	@media (min-width: $break-grid-xl) {
		font-size: rem(22px);
	}
}

// icon-text__description
// feature-card__description
@mixin type-style-40 {
	font-size: rem(14px);
	line-height: (24 / 14);
}

// feature-card__title
@mixin type-style-41 {
	font-size: rem(18px);
	line-height: 1;

	@media (min-width: $break-grid-xl) {
		font-size: rem(20px);
	}
}

// media-text-bar__subheading
@mixin type-style-42 {
	font-size: rem(18px);
	font-weight: bold;
	line-height: (33 / 24);

	@media (min-width: $break-grid-xl) {
		font-size: rem(24px);
	}
}

// small
@mixin type-style-43 {
	font-size: rem(14px);
	line-height: (21 / 14);
}


// media-content__title
@mixin type-style-44 {
	font-size: rem(28px);
	font-weight: bold;
	line-height: (50 / 41);

	@media (min-width: $break-grid-xl) {
		font-size: rem(36px);
	}

	@media (min-width: $break-grid-xxl) {
		font-size: rem(41px);
	}
}


// header phone number
@mixin type-style-45 {
	font-size: rem(16px);
	font-weight: bold;
	line-height: 1.1;
}

// primary-menu-feature
@mixin type-style-46 {
	font-size: rem(17px);
	font-weight: 400;
	line-height: 1.5;

	@media (min-height: em(880px)) {
		font-size: rem(20px);
	}
}

@mixin type-style-47 {
	font-size: rem(20px);
	font-weight: 700;
	line-height: 1.13;

	@media (min-height: em(880px)) {
		font-size: rem(24px);
	}
}

