.header-search {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    background: rgba($color-brand-01, .95);
    padding: rem(20px) 0 rem(50px);

    @media (min-width: $break-grid-lg) {
        padding: rem(25px) 0 rem(50px);
    }

    @media (min-width: $break-grid-xl) {
        padding-bottom: 12%;
    }
}

.header-search__wrap {
    position: relative;
}

.header-search__close {
    display: block;
    width: rem(26px);
    height: rem(25px);
    position: absolute;
    top: 0;
    right: 0;

    &:before,
    &:after {
        content: '';
        display: block;
        width: rem(30px);
        height: rem(2px);
        background: black;
        position: absolute;
        top: 50%;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.header-search__logo {
    background-image: url('/s/images/custom-new/logo-uni-syd-black.svg') !important;
    margin-bottom: rem(50px);

    @media (min-width: $break-grid-xl) {
        margin-bottom: rem(140px);
    }
}

.header-search__instructions {
    color: black;
    margin-bottom: rem(10px);

    @media (min-width: $break-grid-xl) {
        font-size: rem(19px);
        margin-bottom: 0;
    }
}

.header-search__search-form {
    position: relative;
    margin-bottom: rem(30px);

    @media (min-width: $break-grid-lg) {
        margin-bottom: rem(35px);
    }

    @media (min-width: $break-grid-xl) {
        margin-bottom: rem(45px);
    }
}

.header-search__below-search {
    color: black;

    a:focus {
        color: black !important;
    }
}

.header-search__below-search__icon-text__icon {
    color: black !important;
}

.header-search__below-search__locations__desktop-heading {
    font-size: rem(24px) !important;
}

.header-search__below-search__tag {
    @media (min-width: $break-grid-xl) {
        font-size: rem(16px) !important;
    }
}