.learn-more-menu {
	background: $shade-02;
	padding: rem(15px) 0;
	color: black;
}

.learn-more-menu__container {
	@media (min-width: $break-grid-md) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media (min-width: $break-grid-lg) {
		align-items: baseline;
	}
}

.learn-more-menu__content {
	@media (min-width: $break-grid-lg) {
		display: flex;
		align-items: baseline;
	}
}

.learn-more-menu__list {
	margin: rem(5px) 0 rem(15px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-lg) {
		margin: 0 rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		margin: 0 rem(40px);
	}
}

.learn-more-menu__list__item {
	font-weight: bold;
	margin-bottom: rem(5px);

	@media (min-width: $break-grid-sm) {
		display: inline-block;
		margin-bottom: 0;
		margin-right: rem(15px);
	}

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
		margin-right: rem(30px);
	}
}

.learn-more-menu__list__item__link {
	color: black;
	transition: $transition-default;

	&:hover {
		color: $color-brand-01;
	}
}

.learn-more-menu__list__item__link--active {
	color: $color-brand-01;
	text-decoration: underline;
}