.cta-block-wrapper {
	position: relative;
	margin-top: rem(50px);
	padding-left: rem(30px);

	@media (min-width: $break-grid-md) {
		padding-left: rem(50px);
	}

	@media (min-width: $break-grid-lg) {
		margin-top: rem(70px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(80px);
	}

	+ .available-courses-list {
		display: none;
	}
}

.cta-block {
	padding: rem(40px) rem(30px);
	background-color: $shade-01;
	background-image: url('/s/images/bg-newsletter-signup.jpg');
	background-size: cover;
	background-position: center;
	color: white;

	a {
		color: white;

		&:hover {
			color: white;
		}
	}

	@media (min-width: $break-grid-md) {
		padding: rem(70px) rem(50px);
	}

	@media (min-width: $break-grid-lg) {
		padding: rem(80px) rem(60px);
	}

	@media (min-width: $break-grid-xl) {
		padding: rem(90px) rem(70px);
	}

	+ .available-courses-list {
		display: none;
	}
}

.cta-block--light {
	background-color: $shade-06;
	background-image: url('/s/images/custom-new/cta-block-bg-light.jpg');
	color: black;

	a:not(.button) {
		color: black;

		&:hover {
			color: black;
		}
	}
}

.cta-block--in-content {
	//margin-bottom: rem(50px);

	@media (min-width: $break-grid-md) {
		padding: rem(50px) rem(30px);
	}

	//@media (min-width: $break-grid-lg) {
	//	margin-bottom: rem(70px);
	//}
	//
	//@media (min-width: $break-grid-xl) {
	//	margin-bottom: rem(80px);
	//}

	@media (min-width: $break-grid-xxl) {
		padding: rem(70px) rem(50px);
	}

	.snippet {
		@media (min-width: $break-grid-xl) {
			font-size: rem(20px);
		}
	}
}

.cta-block--sidebar {
	display: none;
	margin-bottom: rem(40px);
	padding: rem(30px) rem(25px);

	.page-courses-filter &,
	//.subject-page--top-level.subject-page--english &,
	.subject-page--top-level.subject-page--human-resources &,
	.subject-page--top-level.subject-page--marketing &,
	.subject-page--top-level.subject-page--psychology-psychiatry &,
	.subject-page--top-level.subject-page--business-management &,
	.subject-page--top-level.subject-page--information-technology &,
	.subject-page--top-level.subject-page--project-management &,
	.subject-page--top-level.subject-page--public-sector & {
		display: block;
	}

	p {
		font-size: rem(16px);
		margin-bottom: rem(30px);
	}

	.button {
		width: 100%;

		@media (min-width: $break-grid-md) {
			width: auto;
		}

		@media (min-width: $break-grid-lg) {
			width: 100%;
		}
	}
}

.cta-block__cell {
	margin-bottom: rem(30px);

	&:last-child {
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: 0;
	}
}

.cta-block__cell--heading {
	align-self: flex-start;
}

.cta-block__cell--button {
	@media (min-width: $break-grid-xl) {
		text-align: right;
	}
}

.cta-block__heading {
	&:before {
		margin-bottom: rem(25px);
	}

	.cta-block--in-content & {
		@media (min-width: $break-grid-xl) {
			font-size: rem(40px);
		}
	}

	.cta-block--sidebar & {
		margin-bottom: rem(15px);
	}
}
