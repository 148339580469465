.callToActionModuleWrapper {
	+ .available-courses-list {
		display: none;
	}
}

.callToActionModule {
	display: block;
	padding: 25px 20px;
	color: #fff;
	text-decoration: none;
	background-color: #e64626;
	margin-bottom: rem(40);
	@media (min-width: $break-grid-md) {
		padding: 25px;
	}
}

.callToActionModule .title {
	font-size: 30px;
	font-weight: 700;
	margin: 0 0 14px 0;
}
.callToActionModule .strapline {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
}
@media (min-width: $break-grid-md) {
	.callToActionModule {
		padding: 25px;
	}
	.callToActionModule .title {
		font-size: 20px;
	}
}
@media (min-width: 1200px) {
	.callToActionModule .title {
		font-size: 30px;
	}
}
.callToActionModule:focus,
.callToActionModule:hover {
	background-color: #ce3d20;
	text-decoration: none;
	color: #fff;
}
.callToActionModule.style2 {
	background-color: #f1f1f1;
	color: #000;

	.call-to-action__icon {
		color: #ce3d20;
	}
}
.callToActionModule.style2:focus,
.callToActionModule.style2:hover {
	background-color: #d8dbdd;
}
.callToActionModule.style3 {
	background-color: #424242;
	color: #fff;
}
.callToActionModule.style3:focus,
.callToActionModule.style3:hover {
	background-color: #363636;
}
@media print {
	.callToActionModule {
		display: none;
	}
}

.call-to-action--sidebar {
	display: none;
	margin-top: rem(40px);
}

.call-to-action--museum-discount {
	.subject-page--top-level.subject-page--arts-humanities & {
		display: block;
	}
}

.call-to-action--discount {
	.subject-page--top-level.subject-page--english &,
	.subject-page--top-level.subject-page--music-film &,
	.subject-page--top-level.subject-page--horticulture &,
	.subject-page--top-level.subject-page--psychology-psychiatry &,
	.subject-page--top-level.subject-page--public-sector &,
	.subject-page--top-level.subject-page--marketing & {
		display: block;
	}
}

.call-to-action--repeat-discount {
	.subject-page--top-level.subject-page--language-culture &,
	.subject-page--top-level.subject-page--information-technology & {
		display: block;
	}
}

.call-to-action--multiple {
	.subject-page--top-level.subject-page--hsc-preparation & {
		display: block;
	}
}

.call-to-action__copy {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.call-to-action__title {
	@include type-style-37;
	color: $color-default;
}
