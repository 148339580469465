.o-blockquote {
	font-family: $font-family-secondary;
	font-style: italic;
	font-size: rem(20px);
	font-weight: 300;
	line-height: (30 / 20);
	margin: rem(44px) auto;
	max-width: rem(715px);
	text-align: center;
	clear: both;

	+ img {
		margin-top: rem(15px);
	}


	@media (min-width: em(400px)) {
		padding: 0 rem(30px);
	}

	@media (min-width: em(768px)) {
		font-size: rem(25px);
		line-height: (40.5 / 30);
	}

	@media (min-width: em(1200px)) {
		font-size: rem(30px);
		padding: 0;
	}

	p {
		font-size: rem(20px) !important;

		@media (min-width: em(768px)) {
			font-size: rem(25px) !important;
		}

		@media (min-width: em(1200px)) {
			font-size: rem(30px) !important;
		}
	}
}

.o-blockquote__quote {
	position: relative;

	&:before,
	&:after {
		background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iZmUxZmM3MTItM2I4YS00MzViLTllNGYtMWY2OTNmY2NmYTkwIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwLjc5IiBoZWlnaHQ9IjEzLjk4IiB2aWV3Qm94PSIwIDAgMjAuNzkgMTMuOTgiPjx0aXRsZT5xdW90ZS1tYXJrPC90aXRsZT48cGF0aCBkPSJNMTAuMywwQTIxLjI4LDIxLjI4LDAsMCwwLDYuNTIsMS4yLDE0LjE0LDE0LjE0LDAsMCwwLDMuMjMsMy4xMiw5Ljc2LDkuNzYsMCwwLDAsLjg5LDUuNzYsNi41Niw2LjU2LDAsMCwwLDAsOS4xNmE1LDUsMCwwLDAsLjQsMi4wNSw0LjY0LDQuNjQsMCwwLDAsMSwxLjUyLDQuNDIsNC40MiwwLDAsMCwxLjM4LjkzQTMuNTQsMy41NCwwLDAsMCw0LjIyLDE0YTMuMjIsMy4yMiwwLDAsMCwxLjIzLS4zLDUuODgsNS44OCwwLDAsMCwxLjMzLS44LDQuNzIsNC43MiwwLDAsMCwxLTEuMTQsMi40NSwyLjQ1LDAsMCwwLC40Mi0xLjM3QTQuODIsNC44MiwwLDAsMSw1LjY2LDguOTFhMy4yNywzLjI3LDAsMCwxLS45MS0yLjE4QTQuMzIsNC4zMiwwLDAsMSw2LjI2LDMuNTdhMTEsMTEsMCwwLDEsNC41My0yLjMyWm0xMCwwYTIxLjI4LDIxLjI4LDAsMCwwLTMuNzgsMS4yLDEzLjgsMTMuOCwwLDAsMC0zLjI3LDEuOTIsOS41Miw5LjUyLDAsMCwwLTIuMzIsMi42NEE2LjU2LDYuNTYsMCwwLDAsMTAsOS4xNmE1LDUsMCwwLDAsLjQsMi4wNSw0LjUxLDQuNTEsMCwwLDAsMSwxLjUyLDQuMjcsNC4yNywwLDAsMCwxLjM3LjkzLDMuNTQsMy41NCwwLDAsMCwxLjQzLjMyLDMuMTQsMy4xNCwwLDAsMCwxLjIzLS4zLDUuNjcsNS42NywwLDAsMCwxLjMzLS44LDQuNzIsNC43MiwwLDAsMCwxLjA1LTEuMTQsMi40NSwyLjQ1LDAsMCwwLC40Mi0xLjM3LDQuODIsNC44MiwwLDAsMS0yLjU5LTEuNDYsMy4zMiwzLjMyLDAsMCwxLS45MS0yLjE4LDQuMzUsNC4zNSwwLDAsMSwxLjUtMy4xNiwxMC44NywxMC44NywwLDAsMSw0LjUxLTIuMzJaIiBmaWxsPSIjZTY0NjI2Ii8+PC9zdmc+);
		background-repeat: no-repeat;
		content: '';
		display: inline-block;
		height: rem(15px);
		margin-bottom: rem(24px);
		width: rem(21px);
	}

	&:after {
		transform: rotate(-180deg);
		margin-bottom: 0;
		margin-top: rem(35px);
	}
}

.o-blockquote__author {
	font-family: $font-family-alt;
	font-size: rem(16px);
	font-style: normal;
	text-align: right;
	color: #6e6e6e;
}

.o-blockquote--floated {
	color: $color-brand-01;
	float: left;
	margin: rem(10px) 0 rem(40px);
	padding: 0 0 rem(28px);
	position: relative;
	width: 100%;
	text-align: left;

	@media (min-width: $break-grid-md) {
		margin: rem(20px) rem(40px) rem(40px) 0;
		max-width: rem(500px);
		padding-bottom: rem(34px);
		width: 50%;
	}

	@media (min-width: $break-grid-xxl) {
		font-size: rem(34px);
		line-height: (48 / 34);
		margin: rem(30px) rem(60px) rem(60px) 0;
		padding-bottom: rem(44px);
	}

	p {
		@media (min-width: $break-grid-xxl) {
			font-size: rem(34px) !important;
			line-height: (48 / 34) !important;
		}
	}

	&::before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iZmUxZmM3MTItM2I4YS00MzViLTllNGYtMWY2OTNmY2NmYTkwIiBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwLjc5IiBoZWlnaHQ9IjEzLjk4IiB2aWV3Qm94PSIwIDAgMjAuNzkgMTMuOTgiPjx0aXRsZT5xdW90ZS1tYXJrPC90aXRsZT48cGF0aCBkPSJNMTAuMywwQTIxLjI4LDIxLjI4LDAsMCwwLDYuNTIsMS4yLDE0LjE0LDE0LjE0LDAsMCwwLDMuMjMsMy4xMiw5Ljc2LDkuNzYsMCwwLDAsLjg5LDUuNzYsNi41Niw2LjU2LDAsMCwwLDAsOS4xNmE1LDUsMCwwLDAsLjQsMi4wNSw0LjY0LDQuNjQsMCwwLDAsMSwxLjUyLDQuNDIsNC40MiwwLDAsMCwxLjM4LjkzQTMuNTQsMy41NCwwLDAsMCw0LjIyLDE0YTMuMjIsMy4yMiwwLDAsMCwxLjIzLS4zLDUuODgsNS44OCwwLDAsMCwxLjMzLS44LDQuNzIsNC43MiwwLDAsMCwxLTEuMTQsMi40NSwyLjQ1LDAsMCwwLC40Mi0xLjM3QTQuODIsNC44MiwwLDAsMSw1LjY2LDguOTFhMy4yNywzLjI3LDAsMCwxLS45MS0yLjE4QTQuMzIsNC4zMiwwLDAsMSw2LjI2LDMuNTdhMTEsMTEsMCwwLDEsNC41My0yLjMyWm0xMCwwYTIxLjI4LDIxLjI4LDAsMCwwLTMuNzgsMS4yLDEzLjgsMTMuOCwwLDAsMC0zLjI3LDEuOTIsOS41Miw5LjUyLDAsMCwwLTIuMzIsMi42NEE2LjU2LDYuNTYsMCwwLDAsMTAsOS4xNmE1LDUsMCwwLDAsLjQsMi4wNSw0LjUxLDQuNTEsMCwwLDAsMSwxLjUyLDQuMjcsNC4yNywwLDAsMCwxLjM3LjkzLDMuNTQsMy41NCwwLDAsMCwxLjQzLjMyLDMuMTQsMy4xNCwwLDAsMCwxLjIzLS4zLDUuNjcsNS42NywwLDAsMCwxLjMzLS44LDQuNzIsNC43MiwwLDAsMCwxLjA1LTEuMTQsMi40NSwyLjQ1LDAsMCwwLC40Mi0xLjM3LDQuODIsNC44MiwwLDAsMS0yLjU5LTEuNDYsMy4zMiwzLjMyLDAsMCwxLS45MS0yLjE4LDQuMzUsNC4zNSwwLDAsMSwxLjUtMy4xNiwxMC44NywxMC44NywwLDAsMSw0LjUxLTIuMzJaIiBmaWxsPSIjZTY0NjI2Ii8+PC9zdmc+);
		background-repeat: no-repeat;
		bottom: 0;
		content: '';
		display: block;
		height: rem(15px);
		position: absolute;
		right: 0;
		transform: rotate(180deg);
		width: rem(21px);
	}

	&::after {
		background: $color-brand-01;
		bottom: 0;
		content: '';
		height: rem(3px);
		left: 0;
		position: absolute;
		width: calc(100% - #{rem(57px)});
	}
}

.o-blockquote--float-right {
	float: right;
	margin: rem(20px) 0 rem(40px) rem(40px);

	@media (min-width: $break-grid-xxl) {
		margin: rem(30px) 0 rem(60px) rem(60px);
	}
}
