.courses-cards {
	overflow: hidden;
	background-color: $shade-03;
	padding-top: rem(50px);
	padding-bottom: rem(55px);

	@media (min-width: $break-grid-lg) {
		padding-top: rem(140px);
		padding-bottom: rem(80px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(200px);
		padding-bottom: rem(140px);
	}
}

.courses-cards__carousel__header {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-bottom: rem(35px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(55px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(80px);
	}
}

.courses-cards__carousel__nav {
	margin-left: rem(15px);
	margin-bottom: rem(4px);
}

.courses-cards__carousel__title {
	color: black;
}

.courses-cards__carousel {
	.slick-list {
		padding-right: 100%;
		margin-right: -100%;
	}

	.slick-slide {
		margin-right: rem(20px);

		@media (min-width: $break-grid-xl) {
			margin-right: rem(40px);
		}
	}
}
