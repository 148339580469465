.content-menu {
	display: none;
	background: $shade-02;
	padding: rem(25px);

	@media (min-width: $break-grid-lg) {
		position: sticky;
		top: rem(155px);
	}

	@media (min-width: $break-grid-xl) {
		top: rem(180px);
	}

	#page67 & {
		position: static;
		top: 0;
	}
}

.content-menu__list__item {
	font-size: rem(12px);
	font-weight: 700;
	padding-bottom: rem(10px);
	margin-bottom: rem(10px);
	border-bottom: rem(1px) solid rgba(black, .08);

	@media (min-width: $break-grid-xl) {
		font-size: rem(16px);
	}
}

.content-menu__list__item__link {
	transition: color $transition-default;

	&:hover {
		color: $color-brand-01;
	}
}

.content-menu__cta {
	display: block;
	text-align: center;
	padding-top: rem(15px);

	&:hover {
		color: $color-brand-01;
	}
}

.content-menu__cta--training,
.content-menu__list__item--quote {
	display: none;
}

.content-menu__cta__icon {
	color: $color-brand-01;
	margin-bottom: rem(5px);
}

.content-menu__cta__heading {
	font-size: rem(16px);
	font-weight: 700;
	color: $color-brand-01;
	margin-bottom: rem(5px);
}

