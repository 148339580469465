.full-width-media {
	border-top: rem(3px) solid black;
	margin-top: rem(40px);
	position: relative;

	@media (min-width: $break-grid-lg) {
		margin-top: rem(50px);
	}

	@media (min-width: $break-grid-xl) {
		margin-top: rem(70px);
	}

	// components page
	.sidebar-section__main-content__section__heading + & {
		margin-top: rem(30px)
	}

	.course-description & {
		margin-bottom: rem(40px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(50px);
		}

		@media (min-width: $break-grid-xl) {
			margin-bottom: rem(70px);
		}
	}
}

.full-width-media__title {
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);
	font-weight: normal;
	padding: rem(20px) 0;
}

.full-width-media__content {
	aspect-ratio: 16 / 9;
	position: relative;
	z-index: 2;
}


.full-width-media__background {
	@include video-image-background;
	background-color: #444;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		height: 100%;
		width: 100%;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 74.97%, rgba(0, 0, 0, 0.20) 91.53%, rgba(0, 0, 0, 0.30) 100%);
	}
}

.full-width-media__background--video {
	cursor: pointer;

	&::before {
		background: url('/s/images/icons/icon-play-rectangle.svg');
		background-size: contain;
		content: '';
		height: rem(37px);
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: rem(55px);
		z-index: 1;

		@media (min-width: $break-grid-sm) {
			height: rem(57px);
			width: rem(85px);
		}
	}
}

.full-width-media__media {
	@include video-image-background-media;
}

.full-width-media__caption {
	bottom: 0;
	color: #fff;
	left: 0;
	padding: rem(15px) rem(20px);
	position: absolute;
	z-index: 1;

	@media (min-width: $break-grid-sm) {
		padding: rem(25px) rem(30px);
	}
}
