.discount {
	position: relative;
	padding-left: rem(30px);

	@media (min-width: $break-grid-md) {
		padding-left: rem(50px);
	}

	.site-wrapper .page-content &:not(.restricted-course-notice) {
		margin-top: rem(50px);

		@media (min-width: $break-grid-lg) {
			margin-top: rem(70px);
		}

		@media (min-width: $break-grid-xl) {
			margin-top: rem(80px);
		}
	}

	&.restricted-course-notice {
		margin-bottom: rem(50px);

		@media (min-width: $break-grid-lg) {
			margin-bottom: rem(60px);
		}
	}
}

.discount__content {
	background: $subject-secondary-art-humanities;
	padding: rem(20px);

	@media (min-width: $break-grid-md) {
		padding: rem(40px);
	}
}

.discount__heading {
	color: $color-brand-01;
}

.discount__tag {
	position: absolute;
	top: 0;
	left: rem(12px);
	transform: rotate(90deg);
	transform-origin: top left;
}
