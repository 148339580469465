.downloadsModule {
	padding: rem(25) rem(20) rem(50);
	margin-bottom: rem(40);
}
.downloadsModule .title {
	font-weight: 400;
	font-size: rem(15);
	margin: 0 0 rem(10) 0;
}
.downloadsModule .filesize {
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);
	float: left;
}
.downloadsModule .downloadLink {
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);
	float: right;
}
.downloadsModule .downloadIcon {
	width: rem(17);
	height: rem(17);
	display: inline-block;
	position: relative;
	top: rem(2);
	margin-left: rem(5);
}
@media (min-width: 768px) {
	.downloadsModule {
		padding: rem(25);
	}
	.downloadsModule .title {
		font-size: rem(18);
	}
	.downloadsModule .filesize {
		float: none;
		margin-bottom: rem(45);
	}
	.downloadsModule .downloadLink {
		float: none;
	}
}
@media (min-width: 1200px) {
	.downloadsModule .title {
		font-size: rem(23);
	}
}
.downloadsModule.style1 {
	background-color: #f1f1f1;
}
.downloadsModule.style1 .filesize,
.downloadsModule.style1 .title {
	color: #363636;
}
.downloadsModule.style1 .downloadLink {
	color: #e64626;
	text-decoration: underline;

}
.downloadsModule.style2 {
	background-color: #e64626;
}
.downloadsModule.style2 .title {
	color: #fff;
}
.downloadsModule.style2 .downloadLink{
	text-decoration: underline;
}
.downloadsModule.style2 .downloadLink,
.downloadsModule.style2 .filesize {
	color: #000;
}
@media print {
	.downloadsModule {
		display: none;
	}
}
