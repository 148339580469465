.tabsModule .contentItem .content {
	font-size: rem(14);
}
.tabsModule .contentItem .content h1,
.tabsModule .contentItem .content h2,
.tabsModule .contentItem .content h3,
.tabsModule .contentItem .content h4,
.tabsModule .contentItem .content h5 {
	margin-top: 0;
}
@media (max-width: 767px) {
	.tabsModule .tabs {
		display: none;
	}
	.tabsModule .contentItem .trigger {
		font-weight: 700;
		font-size: rem(16);
		background-color: #f1f1f1;
		border-top: rem(2) solid #fff;
	}
	.tabsModule .contentItem .trigger a {
		color: #000;
		display: block;
		text-decoration: none;
		background-image: url(../images/downChevronRed.svg);
		-webkit-background-size: rem(16) rem(8);
		background-size: rem(16) rem(8);
		background-repeat: no-repeat;
		background-position: 100% 50%;
		padding: rem(20) rem(20);
	}
	.tabsModule .contentItem .content {
		display: none;
		padding: rem(10) rem(10) 0 rem(10);
	}
	.tabsModule .contentItem.open .trigger {
		background-color: #fff;
		border-top: rem(2) solid #e64626;
	}
	.tabsModule .contentItem.open .trigger a {
		background-image: url(../images/upChevronGrey.svg);
	}
	.tabsModule .contentItem:first-child .content {
		display: block;
	}
}
@media (min-width: 768px) {
	.tabsModule .contentItem .trigger {
		display: none;
	}
	.tabsModule .tab {
		cursor: pointer;
		float: left;
		font-weight: 700;
		font-size: rem(16);
		background-color: #f1f1f1;
		border-top: rem(4) solid #fff;
		border-right: rem(2) solid #fff;
		color: #363636;
		display: table;
	}
	.tabsModule .tab a {
		color: #000;
		text-decoration: none;
		padding-top: rem(27);
		padding-bottom: rem(21);
		text-align: center;
		width: 100%;
		display: table-cell;
		vertical-align: middle;
	}
	.tabsModule .tab a:focus {
		text-decoration: underline;
	}
	.tabsModule .tab.open {
		background-color: #fff;
		border-top: rem(4) solid #e64626;
		color: #000;
	}
	.tabsModule .contentItems {
		border-bottom: rem(1) solid #000;
	}
	.tabsModule .contentItem .content {
		display: none;
		padding: rem(15) rem(10) 0 rem(25);
		color: #363636;
	}
	.tabsModule .contentItem:first-child .content {
		display: block;
	}
}
@media print {
	.tabsModule .contentItem .trigger {
		background-color: #fff;
		border-top: none !important;
	}
	.tabsModule .contentItem .trigger a {
		padding-left: 0;
		background-image: none !important;
	}
	.tabsModule .contentItem .content {
		display: block;
		padding-left: 0;
	}
}
.tabsModule p {
	margin-bottom: rem(20) !important;
	font-size: rem(14) !important;
}
