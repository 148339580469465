.site-footer {
    background: #282828;
    padding-bottom: rem(60px);
    color: white;
    font-size: rem(12px);
	line-height: 2.1;
	font-weight: bold;

	p:not(.site-footer__statement):not(.site-footer__paragraph),
	li {
		font-size: rem(12px);

		@media (min-width: $break-grid-xl) {
			font-size: rem(14px);
		}
	}

	p:not(.site-footer__social__text):not(.site-footer__statement):not(.site-footer__paragraph),
	li {
		line-height: 2.1;
	}

	a {
		transition: color $transition-default;

		&:hover {
			color: $color-brand-01;
		}
	}
}

.site-footer__heading {
	margin-bottom: rem(15px);
	line-height: 1.5;
}

.site-footer__top-section {
	@media (min-width: $break-grid-md) {
		margin-bottom: rem(50px);
	}
}

.site-footer__top-section__container {
	padding-top: rem(90px);
	position: relative;
	display: flex;
	align-items: center;

	&:before {
		content: '';
		display: block;
		background: white;
		position: absolute;
		top: 0;
		left: $l-container-padding-default;
		width: rem(4px);
		height: rem(50px);

		@media (min-width: $break-grid-md) {
			left: $l-container-padding-md;
		}

		@media (min-width: $break-grid-lg) {
			left: $l-container-padding-lg;
		}

		@media (min-width: $break-grid-xl) {
			left: $l-container-padding-xl;
		}
	}

	&:after {
		@media (min-width: $break-grid-md) {
			content: '';
			display: block;
			background: white;
			width: rem(50px);
			height: rem(4px);
		}
	}
}

.site-footer__bottom-section {
	@include type-style-10-alt;
	font-weight: normal;

	@media (min-width: $break-grid-lg) {
		margin-left: rem(60px);
	}

	.l-grid__cell--50 {
		@media (min-width: $break-grid-md) {
			width: auto;
			padding-right: 0;
		}

		p, li {
			@media (min-width: $break-grid-md) {
				display: inline-block;
			}
		}

		li {
			&:last-child {
				margin-right: 0;
			}

			@media (min-width: $break-grid-md) {
				margin-right: rem(8px);
			}

			@media (min-width: $break-grid-lg) {
				margin-right: rem(10px);
			}

			@media (min-width: $break-grid-xl) {
				margin-right: rem(18px);
			}
		}
	}
}

.site-footer__bottom-section__line-container {
	@media (min-width: $break-grid-lg) and (max-width: $break-grid-lg-max) {
		padding-right: 0;
	}
}

.site-footer__bottom-section__line-grid {
	@media (min-width: $break-grid-lg) and (max-width: $break-grid-lg-max) {
		margin-right: 0;
	}
}

.site-footer__bottom-section__line-cell {
	@media (min-width: $break-grid-lg) and (max-width: $break-grid-lg-max) {
		padding-right: 0;
	}
}

.site-footer__bottom-section__line {
	border: none;
	margin: rem(40px) 0 rem(45px);
	border-top: 1px solid rgba(198, 194, 193, 0.1);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(30px);
	}

	@media (min-width: $break-grid-xl) {
		margin-bottom: rem(20px);
	}
}




.site-footer__statement {
    @include type-style-09-alt;
    color: $color-brand-01;
}

.site-footer__contact-link {
    display: flex;
    align-items: center;
}

.site-footer__contact-link__icon {
	display: none;

	@media (min-width: $break-grid-md) {
		display: block;
		flex-shrink: 0;
		margin-right: rem(10px);
	}
}

.site-footer__paragraph {
	margin-top: rem(10px);
    font-size: rem(13px);
    line-height: 1.8;
}



.site-footer__social__icon {
	display: block;
	margin-bottom: rem(10px);

	&.icon-social-youtube {
		margin-bottom: rem(7px);
	}
}

.site-footer__social__text {
	@include type-style-10-alt;
	transform: rotate(90deg);
	transform-origin: bottom;
	white-space: nowrap;
	width: 0;
	margin-top: rem(40px);
}

.site-footer__social__text--bottom {
	position: absolute;
	margin-top: rem(-13px);
}

.site-footer__social-wrap {
	position: relative;
}

.site-footer__social-icons-wrap {
	margin-left: rem(35px);
}

.site-footer__logo-statement-wrap {
	flex: 1;
	align-items: center;
}

.site-footer__logo {
	width: rem(172px);
	height: rem(59px);
	display: block;
	background: no-repeat center center;
	background-size: cover;
	background-image: url('/s/images/custom-new/logo-uni-syd-white.svg');
}

.site-footer__rb-logo {
	width: rem(23px);
	height: rem(17px);
	display: block;
	background: no-repeat center center;
	background-size: cover;
	background-image: url('/s/images/custom-new/logo-rb.svg');
}



.site-footer__cell--padding-bottom {
	padding-bottom: rem(40px);

	@media (min-width: $break-grid-lg) {
		padding-bottom: 0;
	}
}

.site-footer__cell--logo,
.site-footer__cell--statement {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}
}

.site-footer__cell--social-top {
	display: none;

	@media (min-width: $break-grid-md) {
		display: block;
	}

	@media (min-width: $break-grid-lg) {
		padding-bottom: rem(100px);
	}
}

.site-footer__cell--social-bottom {
	@media (min-width: $break-grid-md) {
		display: none;
	}
}

.site-footer__cell--bottom-list {
	@media (min-width: $break-grid-xl) {
		padding-right: rem(60px) !important;
	}
}

.site-footer__cell--students {
	@media (min-width: $break-grid-md) {
		margin-left: 30%;
	}

	@media (min-width: $break-grid-lg) {
		margin-left: 0;
	}
}

.site-footer__cell--credit {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	@media (min-width: $break-grid-md) {
		justify-content: flex-end;
	}

	@media (min-width: $break-grid-lg) {
		width: auto;
		align-items: baseline;
		margin-left: auto;
	}

	a:first-child {
		margin-right: rem(30px);
	}
}

