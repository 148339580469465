.events-list {
	margin-bottom: rem(40);
	&__title {
		font-size: rem(30);
		vertical-align: bottom;
		line-height: rem(33);
		font-family: Apercu, Helvetica, sans-serif;
		font-weight: 700;
		float: left !important;
		margin-bottom: rem(20);
		color: inherit;
		&-container {
			margin-bottom: rem(20);
			border-bottom: rem(3) solid #000;
		}
	}
	&__see-more-link {
		padding-top: rem(14);
		vertical-align: bottom;
		float: right !important;
		text-decoration: underline;
		color: #e64626;
		display: none;

		&:focus,
		&:hover {
			color: #ce3d20;
		}
	}
	&--full {
		.events-list__see-more-link {
			display: block;
		}
	}
	&__event-date--label {
		display: block;
		border-top: rem(1) solid #000;
		border-bottom: rem(1) solid #000;
		padding: rem(10) 0;
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(16);
	}
	&--full {
		.events-list__event-date--label {
			display: none;
		}
	}
	&__date-list {
		border-bottom: rem(1) solid #000;
	}
	&__event-details {
		position: relative;
		padding-top: rem(35);
		padding-bottom: rem(40);
		display: block;
		text-decoration: none;
		color: #363636;
	}
	&__icon {
		position: absolute;
		bottom: rem(20);
		left: 0;
		color: #e64626;
	}
	&--full {
		.events-list__icon {
			@media (min-width: em(431px)) {
				top: 0;
				bottom: 0;
				right: rem(10);
				left: auto;
				width: rem(34px);
				height: rem(25px);
				margin: auto;
			}
		}
	}
	&__event-details:focus,
	&__event-details:hover {
		background-color: #f1f1f1;
		text-decoration: none;
		color: #363636;
	}
	&__event-column--image {
		display: none;
		float: left;
		padding-right: 8.23529%;
		width: 29.41176%;
	}
	&--full {
		.events-list__event-column--image {
			@media (min-width: em(431px)) {
				display: block;
			}
		}
	}
	&__figure-image {
		max-width: 100%;
		background-color: $shade-05;
		background-size: cover;
		background-position: center;
	}
	&__figure-image--ratio-4-3 {
		width: 100%;
		padding-bottom: 75%;
		padding-top: 0;
		height: 0;
		display: block;
	}

	&__event-column--text {
		padding-bottom: rem(20);
		padding-right: 0;
		display: block;
		vertical-align: top;
		color: #363636;
	}
	&--full {
		.events-list__event-column--text {
			@media (min-width: em(431px)) {
				float: right;
				padding-bottom: 0;
				padding-left: 4%;
				width: 70.58824%;
				padding-right: 21.42857%;
			}
		}
	}
	&__heading {
		margin-bottom: rem(10);
		line-height: rem(30);
		font-size: rem(24);
		font-weight: 400;
		font-family: Apercu, Helvetica, sans-serif;
	}
	&__subheading {
		margin-bottom: rem(20);
		line-height: rem(30);
		font-size: rem(16);
		font-weight: 400;
		font-family: Apercu, Helvetica, sans-serif;
	}
	&__date {
		display: none;
		margin-bottom: rem(5);
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(18);
	}
	&--full {
		.events-list__date {
			display: block;
		}
	}
	&__time {
		margin-bottom: rem(15);
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(14);
		display: none;
		&.visible {
			display: block;
			margin-bottom: rem(5);
		}
	}

	&__location {
		margin-bottom: rem(5);
		font-size: rem(14);
	}
	&__postscript {
		font-family: "Apercu Mono", Courier, monospace;
		font-size: rem(14);
	}
	&__image-link {
		position: relative;
	}
	&__image-cover {
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		transition: width 0.2s, height 0.2s, opacity 0.2s;
		background-color: #e64626;
		opacity: 1;
		width: 0;
		height: 0;
	}
	&__image-link:focus &__image-cover,
	&__image-link:hover &__image-cover {
		opacity: 0.25;
		width: 100%;
		height: 100%;
	}
}
.form-control {
	height: auto;
	border: 0;
	margin-bottom: rem(25);
	padding: 0;
}
.input-style {
	border: rem(2) solid black;
	border-radius: 0;
	width: 100%;
	height: auto;
	color: black;
	margin: 0;
	padding: rem(16) rem(25);
	background-color: transparent;

	&::placeholder {
		color: #7b7b7b;
	}
}
.events-list__input {
	line-height: rem(20);
	color: #363636;
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);
	display: block;
	border: none;
	border-radius: 0;
	background-color: transparent;
	background-image: none;
	padding: rem(10) rem(20);
	width: 100%;
	height: 100%;
}
.custom-select {
	position: relative;
	cursor: pointer;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAzElEQVQYlWP4/7B65mVbl/9txa9C/v//zwDFzFAM4b9PNbhTof5/T+2uXWCBj6frPl2wdfnfV3I9iuH/f6b////L/f//XwWs6Xau/e0K9f/n6jb893n8XxpmIoqmH///l/7//7/5/+1cO2TFIHVwDSD8+UzdkwtuLv/P7Xl2/c/Xcw9vZkMU17z6rwpTg6IBoqkerOlCkvr/M/Wb3sU8BjsPLo+hAey8M3XHz9WtewRzBjJmBOvCDtgZGBh+oksx4VLNwMDAgiHCwMAAAOIoz3Gx+PZWAAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	background-position: center right rem(15);
	padding-right: rem(45);
	border: rem(2) solid #000;

	&.active {
		border-color: black;

		.custom-select-options {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.custom-select-options {
	background-color: white;
	border: rem(1) solid black;

	position: absolute;
	left: rem(-1);
	top: 99%;
	height: auto;
	width: calc(100% + 2px);
	opacity: 0;
	pointer-events: none;
	z-index: 1;
}

.custom-select-option {
	margin: 0;
	border: rem(1) solid #000;
	border-left: 0;
	border-right: 0;
	background: #fff;
	padding: rem(10) rem(20);
	font-family: "Apercu Mono", Courier, monospace;
	font-size: rem(14);

	&:hover,
	&.selected {
		background: #ce3d20;
		color: #fff;
	}
	&:first-child {
		border-top: none;
	}
	&:last-child {
		border-bottom: none;
	}
}

.form-control label {
	position: absolute;
	opacity: 0;
	pointer-events: none;
}