.location-directions {
    form {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: $break-grid-md) {
            align-items: baseline;
        }
    }
}

.location-directions-form {
    margin-bottom: rem(30px);
}

.location-directions-label {
    @extend h2;
    width: 100%;

    @media (min-width: $break-grid-md) {
        width: auto;
        margin-right: rem(40px);
    }
}

.location-directions-field {
    width: auto !important;
    margin-right: rem(20px) !important;

    @media (min-width: $break-grid-md) {
        height: rem(55px) !important;
    }

    @media (min-width: $break-grid-xl) {
        width: rem(380px) !important;
    }
}

.location-directions-button {
    width: auto;
    font-family: $font-family-main !important;
    font-weight: bold !important;
    padding: rem(20px) rem(25px) !important;
}