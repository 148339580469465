$color-selection: white !default;
$color-selection-bg: rgba(25,47,3,0.3) !default;

*,
*:after,
*:before {
	box-sizing: border-box;
}

body {
	margin: 0;
	background: $color-page-bg;
	color: $color-default;
	font-family: $font-family-main;
	font-size: rem($font-size-base);
	line-height: 1.5;
	overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto;
}


// Text Selection
/* Set text selection colour & kill text shadow */
::selection {
	background: $color-selection-bg;
	color: $color-selection;
	text-shadow: none;
}


//---------------------------------------------------------------------------
//	Type
//---------------------------------------------------------------------------

strong {
	font-weight: bold;
}

button,
input[type="submit"] {
	cursor: pointer;
}

p:empty {
	display: none;
}

p a,
.copy li a:not(.courses-list__item__link),
.text-link,
.attachments li a {
    color: $color-brand-01;
    text-decoration: underline;
	transition: color $transition-default;

	&:hover {
		color: $color-brand-01-alt;
	}
}

a {
	&:focus {
		color: $color-brand-01-alt;
	}
}

abbr[title] {
	border: none;
	text-decoration: none;
	cursor: help;
}

hr {
	margin-top: rem(40);
	margin-bottom: rem(40);
	border: 0;
	border-top: rem(1) solid #eee;
}

.richTextModule {
    font-size: rem(14);
    margin-top: rem(10);
    margin-bottom: rem(40);
}

.clearfix{
	&:before{
		content: " ";
		display: table;
	}
	&:after{
		content: " ";
		display: table;
		clear: both;
	}
}

.main-page {
	background: $shade-02;
}
