.case-study__section {
	h3 {
		color: black;
	}
}

.case-study__text-grid {
	margin-bottom: rem(30px);
}

.case-study__tag {
	margin-bottom: rem(32px);
}

.case-study__text-grid__first-cell {
	margin-bottom: rem(15px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}
}