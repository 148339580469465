.featuredLinkModule {
	position: relative;
	min-height: rem(160);
	margin-bottom: rem(40);
}
.featuredLinkModule .image {
	width: 100%;
	padding-bottom: 114.28571%;
	padding-top: 0;
	height: 0;
	display: block;
	background-color: $shade-05;
	background-size: cover;
	background-position: center;
}
.featuredLinkModule .callToAction {
	display: block;
	background-color: #f1f1f1;
	color: #000;
	padding: rem(25) rem(25);
	width: rem(265);
	position: absolute;
	bottom: 0;
	text-decoration: none;
	font-size: rem(25);
}
@media screen and (min-width: 480px) and (max-width: 590px) {
	.\31\/2--mobile-up .featuredLinkModule .callToAction {
		font-size: rem(22);
		width: rem(200);
	}
}

.featuredLinkModule .callToAction .arrow {
	display: block;
	margin-top: rem(23);
	color: #ce3d20;
}
@media (min-width: 768px) {
	.featuredLinkModule .callToAction {
		font-size: rem(22);
	}
}
@media (min-width: 1200px) {
	.featuredLinkModule .callToAction {
		font-size: rem(24);
	}
}
.featuredLinkModule .callToAction:hover {
	background-color: #d8dbdd;
}
.featuredLinkModule.noimage .image {
	display: none;
}
.featuredLinkModule.noimage .callToAction {
	position: static;
	width: 100%;
}
@media (min-width: 1200px) {
	.featuredLinkModule .callToAction {
		font-size: rem(30);
		line-height: rem(35);
	}
	.featuredLinkModule .image {
		width: 100%;
		padding-bottom: 75%;
		padding-top: 0;
		height: 0;
		display: block;
	}
}
@media (min-width: 768px) {
	.b-page__column .b-page__content .featuredLinkModule .image,
	.bodyColumn .featuredLinkModule .image {
		padding-left: rem(48);
	}
	.b-page__column .b-page__content .featuredLinkModule .callToAction,
	.bodyColumn .featuredLinkModule .callToAction {
		top: rem(60);
		bottom: auto;
	}
}
@media (min-width: 992px) {
	.b-page__column .b-page__content .featuredLinkModule .image,
	.bodyColumn .featuredLinkModule .image {
		padding-left: rem(62);
	}
}
@media (min-width: 1200px) {
	.b-page__column .b-page__content .featuredLinkModule .image,
	.bodyColumn .featuredLinkModule .image {
		padding-left: rem(75);
		width: 100%;
		padding-bottom: 75%;
		padding-top: 0;
		height: 0;
		display: block;
	}
}
@media (min-width: 768px) {
	.b-page__column .b-page__sidebar .featuredLinkModule .image,
	.rightSidebarColumn .featuredLinkModule .image {
		margin-left: rem(32);
		width: rem(226);
		height: rem(258.28571);
		padding-bottom: 0;
		display: block;
	}
	.b-page__column .b-page__sidebar .featuredLinkModule .callToAction,
	.rightSidebarColumn .featuredLinkModule .callToAction {
		width: 100%;
		top: 0;
		bottom: auto;
	}
}
@media (min-width: 992px) {
	.b-page__column .b-page__sidebar .featuredLinkModule .image,
	.rightSidebarColumn .featuredLinkModule .image {
		margin-left: rem(41.33333);
		width: rem(241.11111);
		height: rem(275.55556);
		padding-bottom: 0;
		display: block;
	}
}
@media (min-width: 1200px) {
	.b-page__column .b-page__sidebar .featuredLinkModule .image,
	.rightSidebarColumn .featuredLinkModule .image {
		width: rem(291.66667);
		height: rem(333.33333);
		padding-bottom: 0;
		display: block;
		margin-left: rem(50);
	}
}
