.iframe-container {
	position: relative;
	height: 0;
	padding-top: 56.25%;
	overflow: hidden;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}
}
