$site-offcanvas-color-highlight: $color-brand-01 !default;
$site-offcanvas-color-background: $shade-03 !default;
$site-offcanvas-transition: .2s ease-out !default;
$site-offcanvas-break-disable: 1024px !default;

.offcanvas-menu--primary {
	margin-bottom: rem(20px);
	background: white;
	padding: 0 150%;
	margin: 0 -150%;

	.primary-menu__list {
		display: block;
	}

	.primary-menu__item {
		font-weight: bold;
		font-size: rem(16px);
		border-bottom: rem(1px) solid $shade-05;
		margin: 0;

		&:last-child {
			border: none;
		}

		&.hasChildren .primary-menu__link {
			&:after {
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6.5px 0 6.5px 7px;
				border-color: transparent transparent transparent #cdcdcd;
			}
		}
	}

	.primary-menu__link {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(15px) 0;
		font-size: rem(16px);
		color: black;
		line-height: 1.4;
	}

	.primary-menu__label {
		border: none;

		//.primary-menu__link:hover &,
		//.primary-menu__link:focus & {
		//	&::after {
		//		transform: scale3d(1, 1, 1);
		//		opacity: 1;
		//	}
		//}
	}

	.primary-menu__sub-menu-wrap {
		display: block;
		position: fixed;
		top: rem(130px);
		left: 0;
		min-height: 100vh;
		width: 100vw;
		z-index: 100;
		padding: 0 rem(30px) rem(50px);
		color: black;
		background: white;
		overflow: auto;
		transform: translateX(100%);
		transition: transform $site-offcanvas-transition;

		&.is-active {
			transform: none;
		}
	}

	.l-container {
		max-width: 100%;
		margin: 0;
		padding: 0;
	}

	.primary-menu__sub-menu-wrap__tagline {
		display: none;
	}

	.primary-menu__sub-menu {
		display: block;
		max-width: rem(375px);
		margin: 0 auto;
		padding-bottom: rem(30px);

		.primary-menu__item {
			padding: 0;
		}

		.primary-menu__link {
			background: none;

			&:after {
				display: none !important;
			}
		}
	}
}

.offcanvas-menu__item {
	font-weight: bold;
	line-height: 1.4;

	.offcanvas-menu--primary & {
		font-size: rem(16px);
		border-bottom: rem(1px) solid $shade-05;
	}

	.offcanvas-menu--secondary & {
		font-size: rem(14px);
		border-bottom: rem(1px) solid #b8babc;
		color: #444444;
		text-decoration: underline;
	}

	&:last-child {
		border: none;
	}
}

.offcanvas-menu__link {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: rem(15px) 0;

	.offcanvas-menu--secondary & {
		position: relative;
		padding: rem(14px) 0;

		&:before,
		&:after {
			content: '';
			display: block;
			width: rem(7px);
			height: rem(1px);
			background: $color-brand-01;
			position: absolute;
			right: 0;
		}

		&:before {
			transform: rotate(45deg);
			top: calc(50% - 2.5px);
		}

		&:after {
			transform: rotate(-45deg);
			top: calc(50% + 2.5px);
		}
	}
}

.offcanvas-menu__label {

	@include ie-only {
		margin-right: auto;
	}


	//.offcanvas-menu__link:hover &,
	//.offcanvas-menu__link:focus & {
	//	&::after {
	//		transform: scale3d(1, 1, 1);
	//		opacity: 1;
	//	}
	//}
}

.offcanvas-menu__accessory {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6.5px 0 6.5px 7px;
	border-color: transparent transparent transparent #cdcdcd;

	//flex: 0 0 rem(18px);
	//position: relative;
	//height: rem(18px);
	//width: rem(18px);
	//margin-left: rem(10px);
	//
	//&::before,
	//&::after {
	//	content: "";
	//	position: absolute;
	//	background: $site-offcanvas-color-highlight;
	//	transition: transform .25s ease-out;
	//}
	//
	//&::before {
	//	top: 0;
	//	left: 50%;
	//	height: 100%;
	//	width: 2px;
	//	margin-left: -1px;
	//}
	//
	//&::after {
	//	top: 50%;
	//	left: 0;
	//	height: 2px;
	//	width: 100%;
	//	margin-top: -1px;
	//}
}

.offcanvas-menu-submenu {
	position: fixed;
	top: rem(130px);
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: 100;
	padding: 0 rem(30px) rem(50px);
	color: black;
	background: white;
	overflow: auto;
	transform: translateX(100%);
	transition: transform $site-offcanvas-transition;

	&.is-active {
		transform: none;
	}
}

.offcanvas-menu-submenu__list {
	max-width: rem(375px);
	margin: 0 auto;
}

.offcanvas-menu-submenu__title {
	@include type-style-10;
	font-weight: normal;
	max-width: rem(375px);
	margin: 0 auto rem(10px);
	color: $site-offcanvas-color-highlight;
}

.offcanvas-menu-submenu__item {
	font-size: rem(16px);
	font-weight: bold;
	border-bottom: rem(1px) solid $shade-05;

	.offcanvas-menu-submenu__list--secondary & {
		font-size: rem(17px);
		margin-bottom: rem(10px);
	}
}

.offcanvas-menu-submenu__link {
	display: flex;
	align-items: center;
	padding: rem(15px) 0;
}

.offcanvas-menu-submenu__label {
	//.offcanvas-menu-submenu__link:hover &,
	//.offcanvas-menu-submenu__link:focus & {
	//	&::after {
	//		transform: scale3d(1, 1, 1);
	//		opacity: 1;
	//	}
	//}
}

.offcanvas-menu-submenu__list--secondary {
	margin-top: rem(20px);
}

.offcanvas-menu-submenu__accessory {
	flex: 0 0 rem(18px);
	position: relative;
	height: rem(18px);
	width: rem(18px);
	margin-left: rem(10px);

	&::before,
	&::after {
		content: "";
		position: absolute;
		background: $site-offcanvas-color-highlight;
		transition: transform .25s ease-out;
	}

	&::before {
		top: 0;
		left: 50%;
		height: 100%;
		width: 2px;
		margin-left: -1px;
	}

	&::after {
		top: 50%;
		left: 0;
		height: 2px;
		width: 100%;
		margin-top: -1px;
	}
}
