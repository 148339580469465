.page-checkout {
	overflow-x: hidden;

	.title-section--mobile-only {
		display: block;

		@media (min-width: $break-grid-lg) {
			display: none;
		}
	}

	#oncourse-checkout .summaryPaymentsInfo {
		h1 {
			@extend .h2;
			margin-bottom: 0.5em;
		}

		.message {
			padding: rem(14px) rem(20px);
			background: $color-brand-03;
			border-radius: 0;
			color: $color-brand-01;

			div {
				color: $shade-01;
			}
		}

		.checkoutList {
			> .row {
				&:after {
					top: rem(30px);
				}
			}

			.enrolmentInfo label {
				margin: 0;
			}
		}

		.student-name .student-info h3 {
			display: block;
		}

		.checkoutList {
			.enrolmentItem {
				margin-bottom: rem(60px);
				padding-bottom: 0;

				&:after {
					top: rem(34px);
				}
			}

			.waitingList {
				margin-bottom: rem(60px);

				&:after {
					width: calc(100% - 30px);
					border-bottom: rem(1px) dotted #e2e2e2;
					position: relative;
					margin: 0 auto;
					top: rem(34px);
				}

				.enrolmentInfo label {
					font-size: 17px;
					font-weight: normal;
					margin-bottom: rem(10px);
				}

				fieldset div {
					margin-bottom: 0;
				}
			}
		}
	}
}