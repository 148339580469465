.schedule {
	padding-top: rem(25px);
	border-top: rem(3px) solid black;

	@media (min-width: $break-grid-lg) {
		padding-top: rem(30px);
	}

	.page-class-detail &#courseClasses {
		padding-top: 0;
	}
}

.schedule__location-wrap {
	margin-bottom: rem(25px);

	@media (min-width: $break-grid-lg) {
		margin-bottom: rem(30px);
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.schedule__location {
	display: flex;
	align-items: center;
	line-height: 1.6;
	margin: rem(25px) 0;

	@media (min-width: $break-grid-md) {
		font-size: rem(18px);
	}

	@media (min-width: $break-grid-lg) {
		margin: rem(30px) 0;
	}

	.schedule > div > .grouped-classes > &:first-child {
		margin-top: 0;
	}

	.page-course-detail .classItem & {
		@extend .u-visually-hidden;
	}
}

.schedule__location__icon {
	color: $color-brand-01;
	margin-right: rem(10px);
}

.schedule__location__title {
	a {
		border-bottom: rem(1px) solid $shade-01 !important;

		&:hover {
			color: $color-brand-01;
			border-color: transparent !important;
		}
	}
}

.schedule__class {
	background: $shade-02;
	border-left: rem(5px) solid $color-brand-01;
	margin-bottom: rem(10px);
	padding: rem(20px) rem(30px) rem(30px);
	position: relative;

	@media (min-width: $break-grid-sm) {
		display: flex;
		flex-wrap: wrap;
	}

	@media (min-width: $break-grid-xl) {
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: rem(20px) rem(20px) rem(20px) rem(30px);
	}

	&:last-child {
		margin-bottom: 0;
	}

	p, li, div {
		@media (min-width: $break-grid-xl) {
			font-size: rem(16px);
		}
	}

	p {
		margin: 0;
	}

	a {
		text-decoration: none !important;
		color: $shade-01 !important;
	}
}

.schedule__class__outer-cell {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-sm) {
		width: 50%;
		padding-right: rem(20px);
	}

	@media (min-width: $break-grid-xl) {
		width: 35%;
		margin-bottom: 0;
	}

	@media (min-width: $break-grid-xxl) {
		padding-right: rem(30px);
	}

	&:last-child {
		margin-bottom: 0;
		padding-right: 0;
	}
}

.schedule__class__outer-cell--right {
	@media (min-width: $break-grid-md) {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	@media (min-width: $break-grid-xl) {
		width: 32%;
	}

	@media (min-width: $break-grid-xxl) {
		width: 30%;
	}
}

.schedule__class__inner-cell {
	margin-bottom: rem(20px);

	@media (min-width: $break-grid-xl) {
		margin-bottom: 0;
		margin-right: rem(20px);
	}

	&:last-child {
		margin-bottom: 0;
		margin-right: 0;
	}
}

.schedule__class__date {
	font-weight: bold;
}

.schedule__class__icon-text {
	display: flex;
	align-items: center;
	margin-bottom: rem(7px);

	&:last-child {
		margin-bottom: 0;
	}
}

.schedule__class__icon-text--location {
	display: none;
}

.schedule__class__icon-text__icon {
	color: $color-brand-01;
	margin-right: rem(10px);
	flex-shrink: 0;
}

.schedule__class__icon-text__text {
	a {
		border-bottom: rem(1px) solid $shade-01;
		color: $shade-01 !important;
		text-decoration: none !important;
		transition: $transition-default;

		&:hover {
			color: $color-brand-01;
			border-color: transparent;
		}
	}
}

.schedule__class__tutor {
	display: inline-block;

	&:after {
		content: '';
		display: inline-block;
		width: rem(4px);
		height: rem(4px);
		background: $color-brand-01;
		border-radius: 50%;
		margin: auto rem(10px) rem(2px);
	}

	&:last-child {
		&:after {
			display: none;
		}
	}
}

.schedule__class__price {
	font-weight: bold;
}

.schedule__class__price__numbers {
	color: $color-brand-01;
}

.schedule__class__price-container {
	ul {
		margin: 0 !important;

		li {
			display: none;
			font-weight: bold;
			color: $color-brand-01;
			margin: 0 !important;
			padding: 0 !important;

			&:before {
				content: none !important;
			}

			&:first-child {
				display: inline-block;
			}

			&.gst {
				display: inline-block;
				color: $shade-01;
				margin-left: rem(5px) !important;
			}
		}
	}
}

.schedule__class__inner-cell--button {
	flex-shrink: 0;
}

.classItem .schedule__class__button .classAction {
	text-align: left;

	.enrolAction {
		@extend .button;
		@extend .button--arrow;
		width: auto;
		margin: 0;
		text-transform: lowercase;

		&:first-letter {
			text-transform: uppercase;
		}

		&.enrol-added-class {
			background: #dedede;
			padding: rem(15px) rem(51px);
			color: $color-brand-01;

			&:hover {
				border: none;
			}
		}

		&.disabled {
			text-align: center;
		}
	}
}

.schedule__waiting-list-wrap .actionLink,
.schedule__waiting-list {
	background: $color-brand-03;
	padding: rem(15px) rem(20px);
	margin-top: rem(10px);
	display: flex;
	align-items: center;
	justify-content: space-between;
	transition: $transition-default;
	color: $shade-01;
	text-decoration: none;

	@media (min-width: $break-grid-lg) {
		padding: rem(35px) rem(40px);
	}

	&:hover {
		background: #ffe6c7;
		color: $shade-01;
	}
}

.schedule__waiting-list__text__link {
	font-weight: bold;
	color: $color-brand-01;
	text-decoration: underline;
}

.schedule__waiting-list__icon {
	color: $color-brand-01;
	flex-shrink: 0;
	margin-left: rem(10px);
}



.classItem {
	padding: 0;
	background: transparent;
	border: none;
	margin-bottom: 0;
}

.classStatus,
.confirmOrderDialog {
	display: none !important;
}
