.media {
	aspect-ratio: 16 / 9;
	position: relative;
	z-index: 2;
}

.media__background {
	@include video-image-background;
}

.media__background--video {
	cursor: pointer;

	&::before {
		background: url('/s/images/icons/icon-play-rectangle.svg');
		background-size: contain;
		content: '';
		height: rem(37px);
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: rem(55px);
		z-index: 1;

		@media (min-width: $break-grid-sm) {
			height: rem(57px);
			width: rem(85px);
		}
	}
}

.media__placeholder {
	@include video-image-background-media;
}

.media-text__media {
	margin-bottom: rem(30px);

	@media (min-width: $break-grid-md) {
		margin-bottom: 0;
	}
}

.media-text__copy {

	@media (min-width: $break-grid-lg) {
		padding-left: rem(20px);
		padding-top: rem(15px);
		padding-right: rem(20px);
	}

	@media (min-width: $break-grid-xl) {
		padding-top: rem(12px);
	}

	@media (min-width: $break-grid-xxl) {
		padding-left: rem(40px);
		padding-top: rem(58px);
	}
}

.media-text__tag {
	margin-bottom: rem(15px);
}

.media-text__title {
	@include type-style-44;

	.media-text & {
		margin-bottom: rem(12px)
	}
}

.media-text__triangle {
	margin-bottom: rem(7px);
}
