[class*="icon"] {
    fill: currentColor;
}

.icon-menu {
    display: block;
    position: relative;

    &,
    &::before,
    &::after {
        height: rem(2px);
        width: rem(32px);
        background-color: $color-default;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
    }

    &::before {
        top: rem(-10px);
    }

    &::after {
        top: rem(10px);
    }
}

.icon-info {
    height: rem(20px);
    width: rem(20px);
}

.icon-close {
    height: rem(10px);
    width: rem(10px);
}

.icon-cart {
    height: rem(25px);
    width: rem(24px);
}

.icon-chevron-up {
    height: rem(8px);
    width: rem(12px);
}

.icon-download {
    height: rem(17px);
    width: rem(17px);
}

.icon-search {
    height: rem(25px);
    width: rem(25px);
}

.icon-search-xl {
	height: rem(43px);
	width: rem(43px);
}

.icon-search-lg {
    height: rem(40px);
    width: rem(40px);
}

.icon-search-sm {
	height: rem(15px);
	width: rem(15px);
}

.icon-phone {
    height: rem(13px);
    width: rem(13px);
}

.icon-phone-lg {
    height: rem(15px);
    width: rem(15px);
}

.icon-email {
    height: rem(12px);
    width: rem(16px);
}

.icon-phone-email {
    height: rem(45px);
    width: rem(39px);
}

.icon-arrow-2xl {
	width: rem(28);
	height: rem(30);
}

.icon-arrow-xl {
	height: rem(17px);
	width: rem(23px);
}

.icon-arrow-lg {
    height: rem(15px);
    width: rem(17px);
}

.icon-arrow-sm {
    height: rem(12px);
    width: rem(12px);
}

.icon-plus-lg {
    height: rem(12px);
    width: rem(12px);
}

.icon-plus-sm {
    height: rem(8px);
    width: rem(8px);
}

.icon-triangle {
    height: rem(6px);
    width: rem(10px);
}

.icon-social-facebook {
    height: rem(17px);
    width: rem(17px);
}

.icon-social-instagram {
    height: rem(17px);
    width: rem(17px);
}

.icon-social-linkedin {
    height: rem(17px);
    width: rem(17px);
}

.icon-social-linkedin-alt {
    height: rem(15px);
    width: rem(15px);
}

.icon-social-youtube {
	height: rem(17px);
	width: rem(17px);
}

.icon-share {
    height: rem(16px);
    width: rem(15px);
}

.icon-print {
    height: rem(17px);
    width: rem(17px);
}

.icon-profile {
	height: rem(20px);
	width: rem(17px);
}

.icon-map-marker {
	height: rem(21px);
	width: rem(17px);
}

.icon-training {
    height: rem(32px);
    width: rem(32px);
}

.icon-book {
	fill: none;
	height: rem(17px);
	width: rem(17px);
}

.icon-arrow-down {
	transform: rotate(90deg);
}
